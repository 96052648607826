<script>
  import FabricOpennessFactor from './FabricOpennessFactor';

  export default {
    props: [
      'parentFabricOpennessFactors',
    ],

    components: {
      FabricOpennessFactor
    },

    data() {
      return {
        fabricOpennessFactorList: [],
        errors: []
      }
    },

    mounted() {
      this.fabricOpennessFactorList = this.parentFabricOpennessFactors;
    },

    computed: {
      endpointAddRow() {
        return '/admin/settings/fabric-openness/add-row';
      },

      isDisabled() {
        return false;
      },
    },

    methods: {
      addFabricOpennessFactor(event) {
        event.preventDefault();
        axios.post(this.endpointAddRow)
        .then((response) => {
          let newOpennessFactor = response.data.fabricOpennessFactor;
            newOpennessFactor.isNew = response.data.isNew;
          let newFabricAttribute = response.data.fabricAttribute;
            newFabricAttribute.isNew = true;

          newOpennessFactor.fabric_attributes = [ newFabricAttribute ];
          this.fabricOpennessFactorList.push( newOpennessFactor );

        })
        .catch(error => {
          //flash(error.response.data.status_type, error.response.data.status);
        });
      },

      deleteFabricOpennessFactor(opennessFactor) {
        let i = this.fabricOpennessFactorList.map(obj => obj.id).indexOf(opennessFactor.id);
        this.fabricOpennessFactorList.splice(i, 1) // remove it from array
      },

      getSaveData() {
        var fabricOpennessFactorData = {};
        if( Object.keys(this.$refs).length && this.$refs.fabricOpennessFactor.length ) {
          this.$refs.fabricOpennessFactor.forEach(function(obj){
            fabricOpennessFactorData[obj.opennessFactor.id] = obj.opennessFactor;
            fabricOpennessFactorData[obj.opennessFactor.id]['fabric_attributes'] = obj.$refs.fabricAttributes[0].getSaveData();
          });
        }
        return fabricOpennessFactorData;
      },

      setErrors(errors) {
        this.errors = errors;
      }
    }
  };
</script>
