/**
 *
 * Vue Config
 *
**/
Vue.config.productionTip = false


/**
 *
 * Bootstrap Vue
 *
**/
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
//Optional - uncomment line below if bootstrap vue IconsPlugin needed
  //Vue.use(IconsPlugin)


/**
 *
 * Vue Mask (v-mask)
 *
**/
import VueMask from 'v-mask'
Vue.use(VueMask);


/**
 *
 * Vuex
 *
**/
import Vuex from 'vuex'
Vue.use(Vuex)
import store from './store/index.js'


/**
 *
 * Vue Components
 *
**/

//Global
Vue.component('favorite', require('./components/global/Favorite.vue').default);
Vue.component('search', require('./components/global/Search.vue').default);
Vue.component('left-sidebar', require('./components/global/LeftSidebar.vue').default);
Vue.component('sortable-records', require('./components/global/sort/SortableRecords.vue').default);
Vue.component('copy-record', require('./components/global/CopyRecord.vue').default);

//Notifications
Vue.component('flash', require('./components/notifications/Flash.vue').default);
Vue.component('modal-delete', require('./components/notifications/ModalDelete.vue').default);

//Admin
Vue.component('part', require('./components/admin/settings/part/Part.vue').default);
Vue.component('part-types', require('./components/admin/settings/partTypes/PartTypes.vue').default);
Vue.component('field-measure-questions', require('./components/admin/settings/fieldMeasure/FieldMeasureQuestions.vue').default);
Vue.component('installer-equipment-category', require('./components/admin/settings/installerEquipment/InstallerEquipmentCategory.vue').default);
Vue.component('fabric', require('./components/admin/settings/fabrics/Fabric.vue').default);

//Admin - User Groups and Permissions
Vue.component('user-role', require('./components/admin/settings/permissions/rolePermissions/UserRole.vue').default);

//Admin - Company (Dealer/Designer) Account Form
Vue.component('company-account-form', require('./components/admin/companies/account/CompanyAccountForm.vue').default);

//Admin - Product Models & Parts
Vue.component('product-model', require('./components/admin/settings/product/ProductModel.vue').default);
Vue.component('product-model-parts', require('./components/admin/settings/product/ProductModelParts.vue').default);

//Admin - Order Notifications
Vue.component('project-order-types', require('./components/admin/settings/configuration/order_notification/ProjectOrderTypes.vue').default);
Vue.component('coordination-labors', require('./components/admin/settings/configuration/proposal/CoordinationLabors.vue').default);
Vue.component('payment-terms', require('./components/admin/settings/configuration/paymentTerm/PaymentTerms.vue').default);

//Admin - Manufacturer
Vue.component('manufacturers', require('./components/admin/settings/manufacturer/Manufacturers.vue').default);

//Admin - Document Library
Vue.component('document-categories', require('./components/admin/documentLibrary/DocumentCategories.vue').default);
Vue.component('document-category', require('./components/admin/documentLibrary/DocumentCategory.vue').default);

//User - Shade Unit Configs
Vue.component('my-shade-configurations', require('./components/myAccount/MyShadeConfigurations.vue').default);

//User - Document Library
Vue.component('dl-user-resource-attachment', require('./components/documentLibrary/ResourceAttachment.vue').default);

//Project
Vue.component('installer-information', require('./components/project/installerInformation/InstallerInformation.vue').default);

//Estimating (Shade Order/Estimates & Proposals)
const ShadeOrder = Vue.component('shade-order', require('./components/estimating/estimate/ShadeOrder.vue').default);
Vue.component('shade-order-settings', require('./components/estimating/estimate/shadeOrderSettings/ShadeOrderSettings.vue').default);
Vue.component('order-proposal', require('./components/estimating/proposal/OrderProposal.vue').default);
Vue.component('trim-cuts', require('./components/estimating/proposal/trimCuts/TrimCuts.vue').default);

//Masked Inputs
Vue.component('convert-measurement-text-input-mask', require('./components/inputs/ConvertMeasurementTextInputMask.vue').default);


/**
 *
 * Event Bus - Easily pass events between components
 *
 */
Vue.prototype.$eventHub = new Vue();


/**
 *
 * Initialize Vue.. And away we go!
 *
**/
const app = new Vue({
  el: '#app',
  //router,
  store,

  created: function() {
    window.addEventListener(
      'resize', () => {
        this.$store.commit('setWindowWidth');
      }
    )
  }
});
