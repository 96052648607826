$(document).ready( function() {

  /**
   *
   * TODO
   *
   */
      //Datepicker
      //Currency field mask
      //Decimal field mask
      //Inches field mask
      //Phone field mask
      //


  /**
   *
   * Input Masks
   *
   */
  $('.mask-inches').inputmask('9{1,3}.9{1,3}"');

  $('.mask-phone').inputmask("(999) 999-9999", {
    showMaskOnHover: false
  });

  $('.mask-percentage').inputmask("decimal", {
    radixPoint: ".",
    groupSeparator: "",
    autoGroup: true,
    rightAlign: false,
    suffix: "%",
    clearMaskOnLostFocus: false
  });

  /**
   *
   * Toggle
   *
   */
  $('[data-toggle]').on('click', function(e){
    if( typeof $(this).data('toggle-target') == 'undefined' || $(this).closest('form').find($(this).data('toggle-target')).length <= 0 ) {
      return;
    }

    var $toggleTarget = $(this).data('toggle-target')
        $toggleElement = $(this).closest('form').find($toggleTarget);

    if( $(this).prop("checked") == true ) {
      $toggleElement.find(':input').attr('disabled', false);
      $toggleElement.hide().removeClass('hide').slideDown('slow', function(){
        $(this).show();
      });
    } else {
      $toggleElement.slideUp('slow', function(){
        $(this).addClass('hide');
        $(this).find(':input').attr('disabled', true);
      });
    }
  });


  /**
   *
   * Tooltips
   *
   */
   $('[data-toggle="tooltip"]').tooltip();


  /**
   *
   * Accordion (jQuery UI)
   *
   */
  //$('.accordion').accordion({ header: ".accordion-header", collapsible: true, active: false });


  /**
   *
   * Tabs (Bootstrap)
   * Maintain active tab on page refresh
   *
   * TODO - This was a quick implementation. Will need to change as layout is honed in
   *
   */
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    var hash = $(e.target).attr('href');
    if (history.pushState) {
      history.pushState(null, null, hash);
    } else {
      location.hash = hash;
    }
  });

  var hash = window.location.hash;
  if (hash) {
    $('.nav-link[href="' + hash + '"]').tab('show');
  }

  /**
   *
   * Disabled Overlay
   * For locking form fields that shouldn't be submitted yet... (temporary)
   *
   */
  if( $('.disabled-overlay').length ) {
    console.log('length is good...');
    $('.disabled-overlay').find('.form-control, .form-check-input, .btn, input[type=checkbox]').attr('disabled', true);
  }


  /**
   *
   * Ajax Defaults
   *
   */

  ajaxLoadTimer = null;

  $(document).ajaxStart(function() {
/*
    ajaxLoadTimer = setTimeout(() => {
        $('html, body').css('cursor', 'wait');
    }, 250);
*/
  }).ajaxComplete(function() {
/*
    clearTimeout(ajaxLoadTimer);
    $('html, body').css('cursor', 'auto');
*/
  });
});
