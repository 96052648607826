export const trimCutMixin = {

  methods: {
    saveTrimCut(event) {
      event.preventDefault();
      this.$parent.$parent.saveAndContinueTrimCutsMixin(event);
    }
  },

  directives: {
    trimCutFieldDirective: {
      bind: function (el, binding, vnode) {
        var checkInputs = function(e) {
          var allInputsFilled = true,
              $el = $(el),
              $parentTableRow = $(el).closest('tr');

          //Check required fields
          if( $parentTableRow.find(':input.required').length ) {
            var $requiredInputs = $parentTableRow.find(':input.required').not(':input[disabled]', ':input[readonly]');

            $.each($requiredInputs, function(index, item) {
              if( $(item).val() === null || $(item).val() == '' ) {
                allInputsFilled = false;
              }
            });
          }

          if( allInputsFilled === true ) {
            vnode.context['saveTrimCut'](e);
          }
        }

        //
        // Add the event listeners
        //
        if( $(el).is(':checkbox') ) {
          el.addEventListener('change', checkInputs, true);
        } else {
          el.addEventListener('blur', checkInputs, true);
        }
      }
    }
  }
}
