var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "table" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("tbody", [
      _c("tr", [
        _c("td", [_vm._v("Field Measure")]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.orderProposal.fm_labor_hours))]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(_vm.orderProposal.labor_summary_field_measure_formatted)
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("Installation")]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.orderProposal.install_labor_hours))]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.orderProposal.labor_summary_install_formatted)),
        ]),
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("Coordination")]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.orderProposal.labor_summary_coordination_hours)),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(_vm.orderProposal.labor_summary_coordination_formatted)
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("Travel")]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.orderProposal.travel_total_hours))]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.orderProposal.labor_summary_travel_formatted)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("Hours")]),
        _vm._v(" "),
        _c("th", [_vm._v("Cost ($)")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }