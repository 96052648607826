<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'miscExpense',
      'miscExpenseId'
    ],

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('ProposalMiscExpense.vue (SINGULAR) - Mounted');
        console.log(this.orderProposal);
        console.log(this.currentMiscExpense);
        console.log('');
      */
    },

    computed: {
      currentMiscExpense() {
        return this.miscExpense;
      },

      orderProposal() {
        return this.$parent.currentOrderProposal;
      },

      errors() {
        return [];
      },
    },

    methods: {
      getInputName(id, field) {
        return 'proposal_misc_expenses['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      deleteProposalMiscExpense(event) {
        event.preventDefault();
        this.$store.dispatch('proposalMiscExpense/deleteMiscExpense', this.currentMiscExpense.id);
      },
    }
  };
</script>

<template>
  <tr class="require-all">
    <td>
      <input type="text"
             class="form-control min-width-field"
             v-bind:class="errors['name'] ? 'is-invalid' : ''"
             placeholder="Parking, On site storage, etc."
             v-bind:name="getInputName(currentMiscExpense.id, 'name')"
             v-bind:id="getInputId(currentMiscExpense.id, 'name')"
             v-model="currentMiscExpense.name"
             v-proposal-field-directive>
    </td>
    <td>
      <input type="text"
             class="form-control min-width-field"
             v-bind:class="errors['cost'] ? 'is-invalid' : ''"
             placeholder="$100.00"
             v-bind:name="getInputName(currentMiscExpense.id, 'cost')"
             v-bind:id="getInputId(currentMiscExpense.id, 'cost')"
             v-model="currentMiscExpense.cost"
             v-proposal-field-directive>
    </td>
    <td>
      <a
        href="#"
        class="btn icon-only btn-delete"
        v-bind:class="orderProposal.is_locked_for_edits ? 'disabled' : ''"
        @click="deleteProposalMiscExpense($event)"
        title="Delete">
      </a>
    </td>
  </tr>
</template>
