var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hasPartDependencies,
          expression: "hasPartDependencies",
        },
      ],
      staticClass: "child-item-list",
    },
    [
      _c(
        "div",
        { staticClass: "content-block" },
        [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-sm-12 toggle-list" }, [
              _c(
                "a",
                {
                  staticClass: "toggle-list-link",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleFullView($event)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.toggleLinkText))]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.partDependencies, function (dependentPart) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showList,
                    expression: "showList",
                  },
                ],
                staticClass: "list-items",
              },
              [
                _c("div", { staticClass: "list-item" }, [
                  _c("div", { staticClass: "title-bar-wrapper" }, [
                    _c("div", { staticClass: "title-bar" }, [
                      _c("div", { staticClass: "summary" }, [
                        _c("p", { staticClass: "title" }, [
                          _vm._v(_vm._s(dependentPart.part.name)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "setting-text" }, [
                          _vm._v(
                            "Part Number: " +
                              _vm._s(_vm.dependentPartNumber(dependentPart))
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }