//import { TOGGLE_SIDEBAR } from '../mutation-types'

import { mapGetters, mapActions } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import { ENDPOINT_ORDER_PROPOSAL_UPDATE,
         ENDPOINT_ORDER_PROPOSAL_UNLOCK,
         ENDPOINT_ORDER_PROPOSAL_LOCK,
         ENDPOINT_ORDER_PROPOSAL_ACCEPT,
         ENDPOINT_ORDER_PROPOSAL_DOWNLOAD,
         ENDPOINT_ORDER_PROPOSAL_UPLOAD_SIGNED_PROPOSAL,
         ENDPOINT_ORDER_PROPOSAL_DOWNLOAD_SIGNED_PROPOSAL,
         ENDPOINT_ORDER_PROPOSAL_DELETE_SIGNED_PROPOSAL
} from '../endpoints'

export default {
  namespaced: true,


  //state
  state: {
    orderProposalsInitialState: [],
    orderProposals: [],
    errors: [],

    selectOptions: [],
  },


  //getters
  getters: {
    orderProposals: state => state.orderProposals,
    currentOrderProposal: state => state.orderProposals[0],
    currentOrderProposalInitialState: state => state.orderProposalsInitialState[0],

    errors: state => state.errors,
    selectOptions: state => state.selectOptions,

    projectNotifications: (state, getters) => {
      var orderProposal = getters.currentOrderProposal;
      if( orderProposal.hasOwnProperty('project_notifications') ) {
        return orderProposal.project_notifications;
      }

      return [];
    },

/*
    projectNotifications: (state, getters) => {
      var orderProposal = getters.currentOrderProposal;
      var notificationList = [];

      if( orderProposal.is_custom_color_estimate_required == true && orderProposal.has_custom_color_estimated_cost == false) {
        notificationList.push({
                  alert_type: 'alert-danger',
                  alert_message: '<strong>Warning:</strong> This project requires custom color pricing before a proposal can be issued.'
                });
      }

      return notificationList;
    },
*/

    // vuex-map-fields plugin
    getField,
  },


  //actions
  actions: {
    initOrderProposal({commit, dispatch, state}, payload) {
      if( payload.hasOwnProperty('selectOptions') ) {
        dispatch('applySelectOptions', payload.selectOptions);
      }

      if( payload.hasOwnProperty('orderProposal') ) {
        dispatch('applyOrderProposal', payload.orderProposal);
      }

      //Set user permissions
      if( payload.hasOwnProperty('userCanEdit') ) {
        dispatch('applyUserPermissions', payload.userCanEdit);
      } else {
        dispatch('applyUserPermissions', false);
      }
    },

    applyOrderProposal({commit, getters, dispatch, state}, orderProposal) {
      if( state.selectOptions.proposalLanguageDeclarationOptions
          && state.selectOptions.proposalLanguageDeclarationOptions.length
          && orderProposal.language_declaration == null ) {
        orderProposal.language_declaration = state.selectOptions.proposalLanguageDeclarationOptions[0].value;
      }

      if( state.selectOptions.proposalLanguageProductQuotedAsOptions
         && state.selectOptions.proposalLanguageProductQuotedAsOptions.length
         && orderProposal.language_product_quoted_as == null ) {
        orderProposal.language_product_quoted_as = state.selectOptions.proposalLanguageProductQuotedAsOptions[0].value;
      }

      //setting output defaults
      orderProposal.hasChanges = false; //used with tracking initial state
      orderProposal.custom_color_cost = orderProposal.custom_color_cost_formatted;
      orderProposal.margin_pct = orderProposal.margin_pct_formatted;
      orderProposal.part_cost_adjustment_pct = orderProposal.part_cost_adjustment_pct_formatted;
      orderProposal.dealer_commission_pct = orderProposal.dealer_commission_pct_formatted;
      orderProposal.sales_tax_pct = orderProposal.sales_tax_pct_formatted;
      orderProposal.install_overnight_lodging_rate = orderProposal.install_overnight_lodging_rate_formatted;
      orderProposal.freight_pct = orderProposal.freight_pct_formatted;
      orderProposal.overhead_pct = orderProposal.overhead_pct_formatted;
      orderProposal.contingency_pct = orderProposal.contingency_pct_formatted;
      orderProposal.profit_pct = orderProposal.profit_pct_formatted;
      orderProposal.desired_profit_pct = orderProposal.desired_profit_pct_formatted;
      orderProposal.performance_bond_pct = orderProposal.performance_bond_pct_formatted;
      orderProposal.override_price = orderProposal.override_price_formatted;
      orderProposal.company_overhead_pct = orderProposal.company_overhead_pct_formatted;
      orderProposal.outbound_shipping_pct = orderProposal.outbound_shipping_pct_formatted;

      if( orderProposal.hasOwnProperty('proposal_coordination_labors') ) {
        dispatch('proposalCoordinationLabor/applyProposalCoordinationLabor', orderProposal.proposal_coordination_labors, {root: true});
          delete orderProposal.proposal_coordination_labors;
      }

      if( orderProposal.hasOwnProperty('proposal_misc_expenses') ) {
        dispatch('proposalMiscExpense/applyProposalMiscExpense', orderProposal.proposal_misc_expenses, {root: true});
          //delete orderProposal.proposal_misc_expenses;
      }

      if( orderProposal.hasOwnProperty('proposal_project_expenses') ) {
        dispatch('proposalProjectExpense/applyProposalProjectExpense', orderProposal.proposal_project_expenses, {root: true});
          delete orderProposal.proposal_project_expenses;
      }

      if( getters.currentOrderProposal ) {
        orderProposal.userCanEdit = getters.currentOrderProposal.userCanEdit;
      }

      commit('SET_ORDER_PROPOSALS', [orderProposal]);
    },

    applySelectOptions({commit, state}, selectOptions) {
      commit('SET_ORDER_PROPOSAL_SELECT_OPTIONS', selectOptions);
    },

    applyUserPermissions({commit, getters, state}, payload) {
      var orderProposal = getters.currentOrderProposal,
          userCanEdit = false;

      if( payload == true && orderProposal.is_locked_for_edits == false ) {
        userCanEdit = true;
      }
      commit('SET_USER_PERMISSION', userCanEdit);
    },

    updateOrderProposal({commit, getters, dispatch, rootState, rootGetters}) {
      var orderProposal = getters.currentOrderProposal,
          shadeOrder = rootGetters['shadeOrder/shadeOrder'],
          project = rootGetters['orderProposalProject/currentProject'],
          proposalCoordinationLabors = rootState.proposalCoordinationLabor.proposalCoordinationLabors,
          proposalProjectExpenses = rootState.proposalProjectExpense.proposalProjectExpenses,
          proposalMiscExpenses = rootState.proposalMiscExpense.proposalMiscExpenses,
          proposalShadeLineItems = rootGetters['proposalShadeLineItems/shadeLineItems'];

      //Do we still want this?
      /*
        if( orderProposal.hasChanges === false ) {
          return;
        }
      */

      var submitData = {
            _method: 'PUT',
            order_proposal: orderProposal,
            shade_order: shadeOrder,
            project: project,
            proposal_coordination_labors: proposalCoordinationLabors,
            proposal_project_expenses: proposalProjectExpenses,
            proposal_misc_expenses: proposalMiscExpenses,
            order_items: proposalShadeLineItems,
          };

      return new Promise((resolve, reject) => {
        axios.post(ENDPOINT_ORDER_PROPOSAL_UPDATE + orderProposal.id, submitData)
        .then((response) => {
          //Dispatch the updated order proposal so it can be saved to state
          dispatch('applyOrderProposal', response.data.data.orderProposal);

          //Update the Shade Order
          if( response.data.data.hasOwnProperty('shadeOrder') ) {
            dispatch('shadeOrder/applyShadeOrder', {shadeOrder: response.data.data.shadeOrder}, {root: true});
          }

          //Handle updated shadeGroups / shadeLineItems
          if( response.data.data.hasOwnProperty('shadeGroups') ) {
            var shadeGroups = response.data.data.shadeGroups;
            shadeGroups.forEach(group => {
              group.isNew = false;
              group.isUpdate = true;
            });

            dispatch('shadeGroups/applyShadeGroups', shadeGroups, {root: true});
          }

          if( response.data.data.hasOwnProperty('orderItemAdditions') ) {
            let orderItemAdditionPayload = {
              isUpdate: true,
              orderItemAdditions: response.data.data.orderItemAdditions
            };
            dispatch('orderItemAddition/applyOrderItemAddition', orderItemAdditionPayload, {root: true});
          }

          if( response.data.data.hasOwnProperty('motorizedOrderItemAdditions') ) {
            let motorizedOrderItemAdditionPayload = {
              isUpdate: true,
              orderItemAdditions: response.data.data.motorizedOrderItemAdditions
            };
            dispatch('motorizedOrderItemAddition/applyOrderItemAddition', motorizedOrderItemAdditionPayload, {root: true});
          }

          //clear any form errors we might have
          dispatch('setFormErrors', {});

          //show the flash message
          flash(response.data.status_type, response.data.status);

          //resolve the promise
          resolve(response);
        })
        .catch(error => {
          //dispatch the setFormErrors action
          if( error.response.data.hasOwnProperty('errors') ) {
            var payload = {
              errors: error.response.data.errors
            };

            dispatch('setFormErrors', payload);
          }

          //show the error message
          flash(error.response.data.status_type, error.response.data.status);

          //reject the promise
          reject(error.response.data);
        });
      });
    },


    //
    // Lock the Proposal
    //
    lockOrderProposal({commit, getters, dispatch, rootGetters}) {
      var orderProposal = getters.currentOrderProposal,
          shadeOrder = rootGetters['shadeOrder/shadeOrder'];
      var submitData = {
            _method: 'PUT',
            attempt_to_lock_proposal: true,
            order_proposal: orderProposal,
            shade_order: shadeOrder
          };

      axios.post(ENDPOINT_ORDER_PROPOSAL_LOCK + orderProposal.id, submitData)
      .then((response) => {

        //Dispatch the updated order proposal so state can be updated
        dispatch('applyOrderProposal', response.data.data.orderProposal);
      })
      .catch(error => {
        //dispatch the setFormErrors action
        if( error.response.data.hasOwnProperty('errors') && error.response.data.errors.hasOwnProperty('order_proposal') ) {
          var payload = {
            errors: error.response.data.errors
          };
          dispatch('setFormErrors', payload);
        }

        //show the error message
        flash(error.response.data.status_type, error.response.data.status);
      });
    },


    //
    // Unlock the Proposal
    //
    unlockOrderProposal({commit, getters, dispatch, rootState, rootGetters}) {
      var orderProposal = getters.currentOrderProposal;
      var submitData = {
            _method: 'PUT'
          };

      axios.post(ENDPOINT_ORDER_PROPOSAL_UNLOCK + orderProposal.id, submitData)
      .then((response) => {

        //Dispatch the updated order proposal so it can be saved to state
        dispatch('applyOrderProposal', response.data.data.orderProposal);

        //Handle updated shadeGroups / shadeLineItems
        if( response.data.data.hasOwnProperty('shadeGroups') ) {
          var shadeGroups = response.data.data.shadeGroups;
          shadeGroups.forEach(group => {
            group.isNew = false;
            group.isUpdate = true;
          });

          dispatch('shadeGroups/applyShadeGroups', shadeGroups, {root: true});
        }

        //clear any form errors we might have
        dispatch('setFormErrors', {});

        //show the flash message
        flash(response.data.status_type, response.data.status);
      })
      .catch(error => {
        //dispatch the setFormErrors action
        if( error.response.data.hasOwnProperty('errors') && error.response.data.errors.hasOwnProperty('order_proposal') ) {
          var payload = {
            errors: error.response.data.errors
          };

          dispatch('setFormErrors', payload);
        }

        //show the error message
        flash(error.response.data.status_type, error.response.data.status);
      });
    },


    //
    // Accept Order Proposal (Apply Customer Signature and Proceed with Order)
    //
    acceptOrderProposal({commit, getters, dispatch, rootState, rootGetters}) {
      var orderProposal = getters.currentOrderProposal,
          shadeOrder = rootGetters['shadeOrder/shadeOrder'];
      var submitData = {
            _method: 'PUT',
            accept_proposal: true,
            order_proposal: orderProposal,
            shade_order: shadeOrder
          };

      axios.post(ENDPOINT_ORDER_PROPOSAL_ACCEPT + orderProposal.id, submitData)
      .then((response) => {

        //Dispatch the updated order proposal so it can be saved to state
        dispatch('applyOrderProposal', response.data.data.orderProposal);

        //Handle updated shadeGroups / shadeLineItems
        if( response.data.data.hasOwnProperty('shadeGroups') ) {
          var shadeGroups = response.data.data.shadeGroups;
          shadeGroups.forEach(group => {
            group.isNew = false;
            group.isUpdate = true;
          });

          dispatch('shadeGroups/applyShadeGroups', shadeGroups, {root: true});
        }

        //clear any form errors we might have
        dispatch('setFormErrors', {});

        //show the flash message
        flash(response.data.status_type, response.data.status);
      })
      .catch(error => {
        //dispatch the setFormErrors action
        if( error.response.data.hasOwnProperty('errors') && error.response.data.errors.hasOwnProperty('order_proposal') ) {
          var payload = {
            errors: error.response.data.errors
          };

          dispatch('setFormErrors', payload);
        }

        //show the error message
        flash(error.response.data.status_type, error.response.data.status);
      });
    },


    //
    // Handle Proposal Attachment Upload
    //
    uploadProposalAttachment({commit, getters, dispatch, rootState, rootGetters}, payload) {
      var orderProposal = getters.currentOrderProposal,
          submitData = payload.formData;
      submitData.append('_method', 'PUT');

      var requestSettings = {
            headers: { 'content-type': 'multipart/form-data' }
          };

      axios.post(ENDPOINT_ORDER_PROPOSAL_UPLOAD_SIGNED_PROPOSAL + orderProposal.id, submitData, requestSettings)
      .then((response) => {

        //Dispatch the updated order proposal so it can be saved to state
        dispatch('applyOrderProposal', response.data.data.orderProposal);

        //clear any form errors we might have
        dispatch('setFormErrors', {});

        //show the flash message
        flash(response.data.status_type, response.data.status);
      })
      .catch(error => {

        //dispatch the setFormErrors action
        if( error.response.data.hasOwnProperty('errors') && error.response.data.errors.hasOwnProperty('order_proposal') ) {
          var payload = {
            errors: error.response.data.errors
          };
          dispatch('setFormErrors', payload);
        }

        //show the error message
        flash(error.response.data.status_type, error.response.data.status);
      });
    },


    //
    // Download the Proposal Attachment File
    //
    downloadProposalAttachment({commit, getters, dispatch, rootState, rootGetters}) {
      var orderProposal = getters.currentOrderProposal;
      axios({
          url: ENDPOINT_ORDER_PROPOSAL_DOWNLOAD_SIGNED_PROPOSAL + orderProposal.id,
          method: 'GET',

      }).then((response) => {
        var fileLink = document.createElement('a');
        fileLink.href = response.data.data.downloadUrl;
        fileLink.setAttribute('download', 'file.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => {
        //show the error message
        flash(error.response.data.status_type, error.response.data.status);
      });
    },


    //
    // Remove the signed order proposal attachment file
    //
    deleteProposalAttachment({commit, getters, dispatch, rootState, rootGetters}) {
      var orderProposal = getters.currentOrderProposal;
      var submitData = {
            _method: 'PUT'
          };

      axios.post(ENDPOINT_ORDER_PROPOSAL_DELETE_SIGNED_PROPOSAL + orderProposal.id, submitData)
      .then((response) => {

        //Dispatch the updated order proposal so it can be saved to state
        dispatch('applyOrderProposal', response.data.data.orderProposal);

        //Handle updated shadeGroups / shadeLineItems
        if( response.data.data.hasOwnProperty('shadeGroups') ) {
          var shadeGroups = response.data.data.shadeGroups;
          shadeGroups.forEach(group => {
            group.isNew = false;
            group.isUpdate = true;
          });

          dispatch('shadeGroups/applyShadeGroups', shadeGroups, {root: true});
        }

        //clear any form errors we might have
        dispatch('setFormErrors', {});

        //show the flash message
        flash(response.data.status_type, response.data.status);
      })
      .catch(error => {
        //dispatch the setFormErrors action
        if( error.response.data.hasOwnProperty('errors') && error.response.data.errors.hasOwnProperty('order_proposal') ) {
          var payload = {
            errors: error.response.data.errors.order_proposal
          };

          dispatch('setFormErrors', payload);
        }

        //show the error message
        flash(error.response.data.status_type, error.response.data.status);
      });
    },


    //
    // Set Form Errors
    //
    setFormErrors({commit, dispatch}, payload) {
      if( !payload.errors || Object.keys(payload.errors).length <= 0 ) {
        commit('SET_ORDER_PROPOSAL_ERRORS', []);
        return;
      }

      if( Object.keys(payload.errors).length && payload.errors.hasOwnProperty('order_proposal') ) {
        commit('SET_ORDER_PROPOSAL_ERRORS', payload.errors.order_proposal);
      }
    },
  },


  //mutations
  mutations: {
    SET_ORDER_PROPOSALS(state, orderProposals) {
      state.orderProposals = orderProposals;

      orderProposals.forEach(function(proposal, index) {
        Vue.set(state.orderProposalsInitialState, index, _.cloneDeep(proposal));
      });
    },

    SET_ORDER_PROPOSAL_SELECT_OPTIONS(state, selectOptions) {
      state.selectOptions = selectOptions;
    },

    SET_USER_PERMISSION(state, payload) {
      state.orderProposals.forEach(function(proposal, index) {
        state.orderProposals[index].userCanEdit = payload;
      });

      state.orderProposalsInitialState.forEach(function(proposal, index) {
        state.orderProposalsInitialState[index].userCanEdit = payload;
      });
    },

    SET_ORDER_PROPOSAL_ERRORS(state, payload) {
      state.errors = payload;
    },


    // vuex-map-fields plugin
    //updateField,

    //
    // vuex-map-fields plugin - updateField method override
    //  This method overrides the default updateField logic. It's used to allow us to track the
    //  "Initial State" of the object so we can determine if changes have been made and save the record accordingly.
    //
    updateField(state, { path, value }) {
      var splitPath = path.split(/[.[\]]+/),
          subjectObj = state.orderProposals[splitPath[1]],
          subjectObjInitialState = null;

      //To swap all of this to byId - remember that we need to change the 'splitPath[1]' below to the
      //subject objects record id...
      if( typeof subjectObj !== 'undefined' && subjectObj.hasOwnProperty('id') ) {
        subjectObjInitialState = state.orderProposalsInitialState[splitPath[1]];
      }

      path.split(/[.[\]]+/).reduce((prev, key, index, array) => {
        if (array.length === index + 1) {
          // eslint-disable-next-line no-param-reassign
          prev[key] = value;

          if( value != subjectObjInitialState[key] ) {
            prev['hasChanges'] = true;
          }
        }

        return prev[key];
      }, state);
    }
  }
}
