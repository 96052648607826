<script>
  import FabricOpennessFactors from './FabricOpennessFactors';

  export default {
    props: [
      'parentFabric',
      'parentIsNew',
      'parentFabricOpennessFactors',
      'parentFabricAttributes',
      'parentSelectOptions'
    ],

    components: {
      FabricOpennessFactors
    },

    data() {
      return {
        fabric: {
          name: null,
          manufacturer_id: null,
          fabric_type: 'interior',
          description: null
        },
        fabricOpennessFactorList: [],
        isNew: false,
        errors: []
      }
    },

    mounted() {
      this.fabric = { ...this.fabric, ...this.parentFabric };
      this.fabricOpennessFactorList = this.parentFabricOpennessFactors;
      this.isNew = ( this.parentIsNew == true ) ? true : false;
    },

    computed: {
      endpointCreate() {
        return '/admin/settings/fabric';
      },

      endpointUpdate() {
        return '/admin/settings/fabric/' + this.fabric.id;
      },

      isDisabled() {
        return false;
      },

      manufacturerOptions() {
        return this.parentSelectOptions.fabricManufactureList;
      },

      fabricTypeOptions() {
        return this.parentSelectOptions.fabricTypeOptions;
      },

      hasErrors() {
        if( Object.keys(this.errors).length ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'fabric[' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      save(event) {
        event.preventDefault();
        var endpoint = ( this.isNew ) ? this.endpointCreate : this.endpointUpdate;
        var fabricData = {};
            fabricData = _.clone(this.fabric);
        var fabricOpennessData = this.$refs.fabricOpennessFactors.getSaveData();
        var submitData = {
              _method: ( this.isNew ) ? 'POST' : 'PUT',
              fabric: fabricData,
              fabric_openness_factors: fabricOpennessData
            };

        axios.post(endpoint, submitData)
        .then((response) => {
          this.setErrors({});
          if( response.data.redirectTo != '' ) {
            window.location.href = response.data.redirectTo;
          }
        })
        .catch(error => {
          this.setErrors(error.response.data.errors);
          flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
        });
      },

      setErrors(errors) {
        if( errors.hasOwnProperty('fabric') ) {
          this.errors = errors.fabric;
        } else {
          this.errors = errors;
        }

        if( errors.hasOwnProperty('fabric_openness_factors') ) {
          this.$refs.fabricOpennessFactors.setErrors(errors.fabric_openness_factors);
        }
      }
    }
  };
</script>
