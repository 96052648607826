<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'shadeGroupId',
      'shadeUnit'
    ],

    data() {
      return {}
    },

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('ManufacturingLineItemCostsRow.vue - Mounted');
        console.log('shadeGroupId');
        console.log(this.shadeGroupId);
        console.log('shadeLineItem');
        console.log(this.shadeUnit);
        console.log('');
      */
    },

    computed: {
      shadeGroup() {
        return this.$store.getters['shadeGroups/find'](this.shadeGroupId);
      },

      shadeLineItem() {
        return this.shadeUnit;
      },
    },

    methods: {

    }
  };
</script>

<template>
  <tr v-if="shadeLineItem.isNew === false">
    <td>{{ shadeLineItem.sort_order_number_textual }}</td>
    <td>{{ shadeLineItem.quantity }}</td>
    <td>{{ shadeLineItem.actual_width_formatted }}</td>
    <td>{{ shadeLineItem.actual_length_formatted }}</td>
    <td>{{ shadeLineItem.product_model_name }}</td>
    <td>{{ shadeLineItem.square_feet }}</td>
    <td>{{ shadeLineItem.square_yards }}</td>
    <td>{{ shadeLineItem.unit_hardware_cost_formatted }}</td>
    <td>{{ shadeLineItem.unit_chain_cost_formatted }}</td>
    <td>{{ shadeLineItem.unit_aluminum_cost_formatted }}</td>
    <td>{{ shadeLineItem.total_aluminum_cost_formatted }}</td>
    <td>{{ shadeLineItem.unit_motorized_cost_formatted }}</td>
    <td>{{ shadeLineItem.total_motorized_cost_formatted }}</td>
    <td>{{ shadeLineItem.unit_fabric_1_cost_formatted }}</td>
    <td>{{ shadeLineItem.unit_fabric_2_cost_formatted }}</td>
    <td>{{ shadeLineItem.total_fabric_cost_formatted }}</td>
    <td>{{ shadeLineItem.unit_material_cost_formatted }}</td>
    <td>{{ shadeLineItem.total_material_cost_formatted }}</td>
    <td>{{ shadeLineItem.shade_mfg_unit_hours }}</td>
    <td>{{ shadeLineItem.shade_mfg_labor_cost_formatted }}</td>
    <td>{{ shadeLineItem.trim_mfg_unit_hours }}</td>
    <td>{{ shadeLineItem.trim_mfg_labor_cost_formatted }}</td>
    <td>{{ shadeLineItem.side_channel_mfg_unit_hours }}</td>
    <td>{{ shadeLineItem.side_channel_unit_labor_cost_formatted }}</td>
    <td>{{ shadeLineItem.mfg_unit_hours }}</td>
    <td>{{ shadeLineItem.mfg_unit_labor_cost_formatted }}</td>
    <td>{{ shadeLineItem.total_mfg_hours }}</td>
    <td>{{ shadeLineItem.total_mfg_labor_cost_formatted }}</td>
  </tr>
</template>
