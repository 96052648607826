var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shadeGroup.isNew == false
    ? _c(
        "tbody",
        [
          _vm._l(_vm.shadeLineItems, function (shadeLineItem) {
            return [
              _c("shade-installation-table-row", {
                attrs: {
                  "shade-group-id": _vm.shadeGroupId,
                  "shade-unit": shadeLineItem,
                },
              }),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }