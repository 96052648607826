<script>
  export default {
    props: [
      'parentInstallationEquipment'
    ],

    components: {},

    data() {
      return {
        equipmentOption: {
          name: null,
          uom: null,
          type: null,
          selected_value: null,
          quantity: null
        }
      }
    },

    mounted() {
      this.equipmentOption = {...this.equipmentOption, ...this.parentInstallationEquipment };

      if( typeof this.installationEquipmentSelection != 'undefined' ) {
        this.equipmentOption.selected_value = this.equipmentOption.id;
        this.equipmentOption.quantity = this.installationEquipmentSelection.pivot.quantity;
      }
    },

    computed: {
      installationEquipmentSelection() {
        return this.$parent.installationEquipmentSelections.find(selectedEquipment => {
          return selectedEquipment.pivot.installer_equipment_id == this.equipmentOption.id;
        });
      },

      isSelected() {
        return ( this.equipmentOption.selected_value != null ) ? true : false;
      },

      showQuantityField() {
        return this.isSelected;
      },

      isDisabled() {
        return false;
      },

      errors() {
        if( this.$parent.errors.hasOwnProperty(this.equipmentOption.id) ) {
          return this.$parent.errors[this.equipmentOption.id];
        } else {
          return [];
        }
      }
    },

    methods: {
      getChecklistInputName(id, field) {
        return 'installer_dependency[' + id + ']';
      },

      getChecklistInputId(id, field) {
        var inputName = this.getChecklistInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      getInputName(id, field) {
        return 'installer_dependency[' + id + ']['+ field +']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      }
    }
  };
</script>
