var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tbody",
    [
      _vm.trimCutLineItemsList.length <= 0
        ? [
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "colored-bg text-center",
                  attrs: { colspan: "10" },
                },
                [
                  _vm._v("No Trim Cuts Specified. "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.addRow($event)
                        },
                      },
                    },
                    [_vm._v("Add a record")]
                  ),
                  _vm._v(" to get started."),
                ]
              ),
            ]),
          ]
        : [
            _vm._l(_vm.trimCutLineItemsList, function (trimCutLineItem, index) {
              return [
                _c("trim-cut-line-item", {
                  key: trimCutLineItem.id,
                  attrs: {
                    "master-trim-cut-line-item": trimCutLineItem,
                    "master-select-options": _vm.masterSelectOptions,
                    index: index,
                  },
                }),
              ]
            }),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "colored-bg text-center",
                  attrs: { colspan: "10" },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.addRow($event)
                        },
                      },
                    },
                    [_vm._v("Add Row")]
                  ),
                ]
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }