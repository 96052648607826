<script>
  import RolePermission from './RolePermission';

  export default {
    props: [
      'parentPermissionsList',
      'parentRolePermissions'
    ],

    components: {
      RolePermission,
    },

    data() {
      return {
        permissionsList: [],
        currentRolePermissions: []
      }
    },

    mounted() {
      this.permissionsList = this.parentPermissionsList;
      this.currentRolePermissions = this.parentRolePermissions;

      /*
        console.log('RolePermissions.vue Mounted...');
        console.log('this.permissionsList');
        console.log(this.permissionsList);
        console.log('this.currentRolePermissions');
        console.log(this.currentRolePermissions);
        console.log(this.parentRolePermissions);
        console.log('');
      */
    },

    computed: {
      adminAccessPermissionRecord() {
        return this.$children[0].rolePermission;
      },

      canHaveAdminPermissions() {
        return ( this.adminAccessPermissionRecord.applyPermission == true ) ? true : false;
      },
    },

    methods: {
      getInputName(id) {
        return this.$parent.getInputName(id);
      },

      getInputId(id) {
        return this.$parent.getInputName(id);
      },

      getAppliedRolePermissions() {
        let appliedPermissions = [];
        this.$children.forEach(function(permission) {
          if( permission.rolePermission.applyPermission == true ) {
            appliedPermissions.push( permission.rolePermission.id );
          }
        });
        return appliedPermissions;
      }
    }
  }
</script>
