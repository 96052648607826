import { mapGetters, mapActions } from 'vuex'
import { normalizeRelations, resolveRelations } from '../../helpers/helpers';

/*
  import { ENDPOINT_ADD_SHADE_GROUP,
            ENDPOINT_CREATE_SHADE_GROUP,
            ENDPOINT_UPDATE_SHADE_GROUP,
            ENDPOINT_DESTROY_SHADE_GROUP,
            ENDPOINT_COPY_SHADE_GROUP
          } from '../endpoints'
*/

export default {
  namespaced: true,

  //
  // State
  //
  state: {
    byId: {},
    allIds: []
  },

  //
  // Getters
  //
  getters: {
    //
    // Return a single shade group with the given id.
    //
    find: (state, _, __, rootGetters) => id => {
      // Swap ID referenes with the resolved author objects.
      return resolveRelations(state.byId[id], ["shadeLineItems"], rootGetters);
    },

    //
    // Return a list of articles in the order of `allIds`.
    //
    list: (state, getters) => {
      return state.allIds.map(id => getters.find(id));
    }
  },


  //
  // Actions
  //
  actions: {
    //
    // Add Shade Groups to the store (this handles the initial loading of shade groups into the store)
    //
    applyShadeGroups({commit, dispatch, state, rootState}, shadeGroups) {
      /*
        console.log('initial load - proposalShadeGroups/applyShadeGroups');
        console.log('state:');
        console.log(state);
        console.log('rootState:');
        console.log(rootState);
        console.log('shadeGroups');
        console.log(shadeGroups);
        console.log('');
      */

      if( shadeGroups ) {
        shadeGroups.forEach(group => {
          group.shadeLineItems = ( group.hasOwnProperty('order_items') ) ? group.order_items : [];
            delete group.order_items;

          commit("ADD_PROPOSAL_SHADE_GROUP", normalizeRelations(group, ["shadeLineItems"]));
          dispatch('proposalShadeLineItems/applyShadeLineItems', group.shadeLineItems, {root: true});
        });
      }
    },

  },


  //
  // Mutations
  //
  mutations: {
    ADD_PROPOSAL_SHADE_GROUP(state, item) {
      Vue.set(state.byId, item.id, item);
      if (state.allIds.includes(item.id)) return;
      state.allIds.push(item.id);
    },


  }
}
