<script>
  import ModalConfirm from '../../../notifications/ModalConfirm.vue';

  export default {
    props: [
      'parentFabricAttribute',
    ],

    components: {
      ModalConfirm
    },

    data() {
      return {
        fabricAttribute: {
          color: null,
          color_number: null,
          cost_per_yard: null,
          cost_per_yard_formatted: null,
          max_roll_width: null,
          max_roll_width_formatted: null,
          max_roll_length: null,
          max_roll_length_formatted: null,
          weight_oz: null,
          thickness_in: null,
          thickness_in_formatted: null,
          stock: null,
          isNew: false
        },
        //errors: []
      }
    },

    mounted() {
      this.fabricAttribute = { ...this.fabricAttribute, ...this.parentFabricAttribute };
        this.fabricAttribute.cost_per_yard = this.fabricAttribute.cost_per_yard_formatted;
        this.fabricAttribute.max_roll_width = this.fabricAttribute.max_roll_width_formatted;
        this.fabricAttribute.max_roll_length = this.fabricAttribute.max_roll_length_formatted;
        this.fabricAttribute.thickness_in = this.fabricAttribute.thickness_in_formatted;
    },

    computed: {
      endpointDestroy() {
        return '/admin/settings/fabric-attribute/' + this.fabricAttribute.id;
      },

      isNew() {
        //If the parent openness factor is new, this must be true
        if( this.$parent.$parent.isNew == true ) {
          return true;
        }
        return this.fabricAttribute.isNew;
      },

      isDisabled() {
        return false;
      },

      errors() {
        if( this.$parent.errors.hasOwnProperty(this.fabricAttribute.id) ) {
          return this.$parent.errors[this.fabricAttribute.id];
        } else {
          return [];
        }
      }
    },

    methods: {
      getInputName(id, field) {
        return 'fabric_attributes['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      destroyClick(event) {
        event.preventDefault();
        if( this.isNew == true ) {
          this.destroy();
        } else {
          this.$bvModal.show('modal-' + this.fabricAttribute.id);
        }
      },

      //Modal - Handle modal function call
      handleFunctionCall(functionName) {
        this[functionName]();
      },

      //Modal - Cancel out of an open/active modal without completeing action
      cancelModalAction() {
        return false;
      },

      destroy() {
        if( this.isNew === true ) {
          this.$parent.deleteFabricAttribute( this.fabricAttribute );
          flash('alert-success', 'Fabric deleted');

        } else {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            this.$parent.deleteFabricAttribute( this.fabricAttribute );
            flash(response.data.status_type, response.data.status);
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      }
    }
  };
</script>

<template>
  <tr>
    <td>
      <input
        type="text"
        class="form-control"
        maxlength="50"
        v-bind:class="errors['color'] ? 'is-invalid' : ''"
        placeholder="Color"
        v-bind:name="getInputName(fabricAttribute.id, 'color')"
        v-bind:id="getInputId(fabricAttribute.id, 'color')"
        v-model="fabricAttribute.color">
      <span class="invalid-feedback" role="alert" v-if="errors['color']">
        {{ errors['color'][0] }}
      </span>
    </td>
    <td>
      <input
        type="text"
        class="form-control"
        maxlength="50"
        v-bind:class="errors['color_number'] ? 'is-invalid' : ''"
        placeholder="Color Number"
        v-bind:name="getInputName(fabricAttribute.id, 'color_number')"
        v-bind:id="getInputId(fabricAttribute.id, 'color_number')"
        v-model="fabricAttribute.color_number">
      <span class="invalid-feedback" role="alert" v-if="errors['color_number']">
        {{ errors['color_number'][0] }}
      </span>
    </td>
    <td>
      <input
        type="text"
        class="form-control"
        maxlength="50"
        v-bind:class="errors['cost_per_yard'] ? 'is-invalid' : ''"
        placeholder="$15.00"
        v-bind:name="getInputName(fabricAttribute.id, 'cost_per_yard')"
        v-bind:id="getInputId(fabricAttribute.id, 'cost_per_yard')"
        v-model="fabricAttribute.cost_per_yard">
      <span class="invalid-feedback" role="alert" v-if="errors['cost_per_yard']">
        {{ errors['cost_per_yard'][0] }}
      </span>
    </td>
    <td>
      <input
        type="text"
        class="form-control"
        maxlength="50"
        v-bind:class="errors['max_roll_width'] ? 'is-invalid' : ''"
        placeholder='128.00"'
        v-bind:name="getInputName(fabricAttribute.id, 'max_roll_width')"
        v-bind:id="getInputId(fabricAttribute.id, 'max_roll_width')"
        v-model="fabricAttribute.max_roll_width">
      <span class="invalid-feedback" role="alert" v-if="errors['max_roll_width']">
        {{ errors['max_roll_width'][0] }}
      </span>
    </td>
    <td>
      <input
        type="text"
        class="form-control"
        maxlength="50"
        v-bind:class="errors['max_roll_length'] ? 'is-invalid' : ''"
        placeholder='30'
        v-bind:name="getInputName(fabricAttribute.id, 'max_roll_length')"
        v-bind:id="getInputId(fabricAttribute.id, 'max_roll_length')"
        v-model="fabricAttribute.max_roll_length">
      <span class="invalid-feedback" role="alert" v-if="errors['max_roll_length']">
        {{ errors['max_roll_length'][0] }}
      </span>
    </td>
    <td>
      <input
        type="text"
        class="form-control"
        maxlength="50"
        v-bind:class="errors['weight_oz'] ? 'is-invalid' : ''"
        placeholder='12.17'
        v-bind:name="getInputName(fabricAttribute.id, 'weight_oz')"
        v-bind:id="getInputId(fabricAttribute.id, 'weight_oz')"
        v-model="fabricAttribute.weight_oz">
      <span class="invalid-feedback" role="alert" v-if="errors['weight_oz']">
        {{ errors['weight_oz'][0] }}
      </span>
    </td>
    <td>
      <input
        type="text"
        class="form-control"
        maxlength="50"
        v-bind:class="errors['thickness_in'] ? 'is-invalid' : ''"
        placeholder='0.027'
        v-bind:name="getInputName(fabricAttribute.id, 'thickness_in')"
        v-bind:id="getInputId(fabricAttribute.id, 'thickness_in')"
        v-model="fabricAttribute.thickness_in">
      <span class="invalid-feedback" role="alert" v-if="errors['thickness_in']">
        {{ errors['thickness_in'][0] }}
      </span>
    </td>
    <td>
      <input
        type="text"
        class="form-control"
        maxlength="50"
        v-bind:class="errors['stock'] ? 'is-invalid' : ''"
        placeholder='0.027'
        v-bind:name="getInputName(fabricAttribute.id, 'stock')"
        v-bind:id="getInputId(fabricAttribute.id, 'stock')"
        v-model="fabricAttribute.stock">
      <span class="invalid-feedback" role="alert" v-if="errors['stock']">
        {{ errors['stock'][0] }}
      </span>
    </td>
    <td>
      <button
        type="button"
        class="btn icon-only btn-delete"
        @click="destroyClick($event)"
        title="Delete">
      </button>

      <modal-confirm
        :modal-id="fabricAttribute.id"
        :modal-title="'Are you sure?'"
        :modal-body="'Are you sure you want to delete this fabric color? This action cannot be undone.'"
        :dismiss-button="'Cancel'"
        :confirm-button="'Delete'"
        :hide-header-close="true"
        @confirmed="handleFunctionCall('destroy')"
        @cancel="handleFunctionCall('cancelModalAction')">
      </modal-confirm>
    </td>
  </tr>
</template>
