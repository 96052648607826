//import { TOGGLE_SIDEBAR } from '../mutation-types'

import { mapGetters, mapActions } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import {
  ENDPOINT_PROPOSAL_MISC_EXPENSE_NEW,
  ENDPOINT_PROPOSAL_MISC_EXPENSE_DESTROY
} from '../../endpoints'


export default {
  namespaced: true,


  //state
  state: {
    proposalMiscExpenses: [],
    errors: []
  },


  //getters
  getters: {
    proposalMiscExpenses: state => state.proposalMiscExpenses,
    //currentOrderProposal: state => state.orderProposals[0],
    //errors: state => state.errors,

    // vuex-map-fields plugin
    getField,
  },


  //actions
  actions: {
    applyProposalMiscExpense({commit, dispatch}, miscExpenses) {
      if( miscExpenses ) {
        miscExpenses.forEach(function(expense, index) {
          expense.cost = expense.cost_formatted;

          if( !expense.hasOwnProperty('isNew') ) {
            expense.isNew = false;
          }
        });

        commit('SET_PROPOSAL_MISC_EXPENSES', miscExpenses);
      }
    },

    applyNewProposalMiscExpense({commit, dispatch}, miscExpense) {
      commit('ADD_NEW_PROPOSAL_MISC_EXPENSE', miscExpense);
    },


    //
    // Create a new misc expense on the fly
    //  This creates an empty "shell" that is used to generate the input fields within the vue component
    //
    addNewProposalMiscExpense({dispatch, rootState, rootGetters}) {
      var currentOrderProposal = rootGetters['orderProposal/currentOrderProposal'];

      axios.post(ENDPOINT_PROPOSAL_MISC_EXPENSE_NEW + currentOrderProposal.id)
      .then((response) => {
        response.data.data.proposalMiscExpense.isNew = true;

        dispatch('applyNewProposalMiscExpense', response.data.data.proposalMiscExpense);
        flash(response.data.status_type, response.data.status);
      })
      .catch(error => {
        flash(error.response.data.status_type, error.response.data.status);
      });
    },


    //
    // Delete misc expense record from proposal
    //
    deleteMiscExpense({commit, state, dispatch}, id) {
      var targetUnitIndex = state.proposalMiscExpenses.findIndex(expense => expense.id == id),
          targetUnit = state.proposalMiscExpenses[targetUnitIndex];

      if( targetUnit.isNew === true ) {
        //Just remove the record from state
        commit('DELETE_NEW_MISC_EXPENSE', targetUnit.id);

      } else {
        axios.delete(ENDPOINT_PROPOSAL_MISC_EXPENSE_DESTROY + targetUnit.id)
        .then((response) => {
          dispatch('orderProposal/applyOrderProposal', response.data.data.orderProposal, {root: true});

          flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status);
        });

      }
    },
  },


  //mutations
  mutations: {
    SET_PROPOSAL_MISC_EXPENSES(state, miscExpenses) {
      state.proposalMiscExpenses = miscExpenses;
    },

    ADD_NEW_PROPOSAL_MISC_EXPENSE(state, miscExpense) {
      state.proposalMiscExpenses.push(miscExpense);
    },

    DELETE_NEW_MISC_EXPENSE(state, miscExpenseId) {
      var index = state.proposalMiscExpenses.findIndex(expense => expense.id == miscExpenseId);
      state.proposalMiscExpenses.splice(index, 1);
    },

    // vuex-map-fields plugin
    updateField,
  }
}
