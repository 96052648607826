<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'currentOrderProposal',
      'includeHeadingRow',
      'headingText',
    ],

    mixins: [proposalMixin],

    data() {
      return {
        defaultHeadingText: 'Profit Check'
      }
    },

    mounted() {
      /*
        console.log('ProfitCheck.vue - Mounted');
        console.log(this.orderProposal);
        console.log('');
      */
    },

    computed: {
      orderProposal() {
        return this.currentOrderProposal;
      },

      errors() {
        return [];
      },

      showHeadingRow() {
        return ( this.includeHeadingRow == false ) ? false : true;
      },

      headingRowHeadingText() {
        var heading = '';

        if( this.showHeadingRow == true ) {
          heading = ( typeof this.headingText != 'undefined' && this.headingText != '' ) ? this.headingText : this.defaultHeadingText;
        }

        return heading;
      }
    },

    methods: {
      getInputName(field) {
        return this.$parent.getOrderProposalInputName(field);
      },

      getInputId(field) {
        return this.$parent.getOrderProposalInputId(field);
      },
    }
  };
</script>

<template>
  <div class="form-row estimate-widget profit-check">
    <div class="form-group col-sm-12 mb-0" v-if="showHeadingRow">
      <h2>{{ headingRowHeadingText }}</h2>
    </div>

    <div class="form-group col-sm-12 table-responsive">
      <table class="table">
        <tbody>
          <tr>
            <td class="text-nowrap">Direct Costs</td>
            <td></td>
            <td></td>
            <td>{{ orderProposal.pc_direct_cost_formatted }}</td>
          </tr>
          <tr>
            <td class="text-nowrap"><strong>Price to Charge</strong></td>
            <td></td>
            <td></td>
            <td><strong>{{ orderProposal.pc_price_to_charge_formatted }}</strong></td>
          </tr>
          <tr>
            <td class="text-nowrap">Overhead Costs</td>
            <td></td>
            <td></td>
            <td>{{ orderProposal.pc_overhead_cost_formatted }}</td>
          </tr>
          <tr>
            <td class="text-nowrap">Total Costs</td>
            <td></td>
            <td></td>
            <td>{{ orderProposal.pc_total_cost_formatted }}</td>
          </tr>
          <tr>
            <td class="text-nowrap">Net Profit ($)</td>
            <td></td>
            <td></td>
            <td>{{ orderProposal.pc_net_profit_formatted }}</td>
          </tr>
          <tr>
            <td class="text-nowrap">Net Profit (%)</td>
            <td></td>
            <td></td>
            <td>{{ orderProposal.pc_net_profit_rate_formatted }}</td>
          </tr>
          <tr>
            <td class="text-nowrap">Override Price</td>
            <td></td>
            <td></td>
            <td class="min-width-field">
              <input type="text"
                     class="form-control"
                     v-bind:class="errors['override_price'] ? 'is-invalid' : ''"
                     placeholder=""
                     v-bind:name="getInputName('override_price')"
                     v-bind:id="getInputId('override_price')"
                     v-model="orderProposal.override_price"
                     v-proposal-field-directive>
            </td>
          </tr>
          <tr>
            <td class="text-nowrap">Actual Contract Price</td>
            <td></td>
            <td></td>
            <td>{{ orderProposal.pc_actual_contract_price_formatted }}</td>
          </tr>
          <tr>
            <td class="text-nowrap">Gross Profit</td>
            <td></td>
            <td></td>
            <td>{{ orderProposal.pc_gross_profit_formatted }}</td>
          </tr>
          <tr>
            <td class="text-nowrap">Gross Profit Margin</td>
            <td></td>
            <td></td>
            <td>{{ orderProposal.pc_gross_profit_margin_formatted }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
