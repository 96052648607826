<script>
  //import RolePermissions from './RolePermissions';

  export default {
    props: [
      'parentRolePermission'
    ],

    components: {
      //RolePermissions,
    },

    data() {
      return {
        rolePermission: {
          id: null,
          name: null,
          label: null,
          type: null,
          applyPermission: false,
        }
      }
    },

    mounted() {
      this.rolePermission = { ...this.rolePermission, ...this.parentRolePermission };
      this.rolePermission.applyPermission = this.$parent.currentRolePermissions.some(perm => perm.id === this.rolePermission.id);

      /*
        console.log('RolePermission.vue SINGULAR Mounted...');
        console.log('this.rolePermission');
        console.log(this.rolePermission);
        console.log('');
      */
    },

    computed: {
      isAdminPermission() {
        return ( this.rolePermission.type == 'Admin' ) ? true : false;
      },

      isAdminAccessPermission() {
        return ( this.isAdminPermission && this.rolePermission.name == 'admin view' ) ? true : false;
      },

      canHaveAdminPermissions() {
        if( this.isAdminAccessPermission ) {
          return true;
        } else {
          return this.$parent.canHaveAdminPermissions;
        }
      },

      isPermissionDisabled() {
        if( this.isAdminPermission ) {
          return ( this.canHaveAdminPermissions ) ? false : true;
        } else {
          return false;
        }
      }
    },

    methods: {
      getInputName(id) {
        return this.$parent.getInputName(id);
      },

      getInputId(id) {
        return this.$parent.getInputName(id);
      }
    },

    watch: {
      'isPermissionDisabled': function (newVal, oldVal) {
        if( oldVal == false && newVal == true ) {
          this.rolePermission.applyPermission = false;
        }
      },
    }
  }
</script>
