<script>
  export default {
    props: [
      'parentPaymentTerm',
    ],

    components: {},

    data() {
      return {
        reassignTo: null,
        isCompanyListMaximized: false,
        errors: {}
      }
    },

    mounted() {},

    computed: {
      paymentTerm() {
        return this.parentPaymentTerm;
      },

      paymentTermCompanies() {
        return this.$parent.paymentTermCompanies;
      },

      endpointDestroy(id) {
        return '/admin/settings/app-config/proposals/payment-terms/delete/' + this.paymentTerm.id;
      },

      hasCompaniesAssignedToPaymentTerm() {
        return ( this.paymentTermCompanies.length ) ? true : false;
      },

      companyCount() {
        return this.paymentTermCompanies.length;
      },

      paymentTermSelectOptions() {
        let list = this.$parent.$parent.$refs['paymentTerm'].filter(obj => {
          if( obj.isNew == false && obj.paymentTerm.id != this.paymentTerm.id ) {
            return obj.paymentTerm;
          }
        });
        return list;
      },

      //
      // Modal
      modalIdAttr() {
        return 'deletePaymentTermModal' + this.paymentTerm.id;
      },

      modalSizeAttr() {
        return 'md';
      },

      modalTitleText() {
        return 'Are you sure?';
      },

      confirmButtonText() {
        return 'Delete Payment Term';
      },

      dismissButtonText() {
        return 'Cancel';
      },

      hideHeaderCloseButton() {
        return true;
      },

      //
      // Errors
      hasErrors() {
        if( Object.keys(this.errors).length ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      //
      // Inputs
      getInputName(id, field) {
        return 'deleted_payment_term[' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      //
      // Modals
      emitConfirm(bvModalEvt) {
        bvModalEvt.preventDefault();
        var submitData = {
          _method: 'PUT'
        };
        if( this.hasCompaniesAssignedToPaymentTerm ) {
          submitData['deleted_payment_term'] = {
            reassign_to: this.reassignTo
          };
        }

        axios.post(this.endpointDestroy, submitData)
        .then((response) => {
          this.setErrors({});
          this.$parent.$parent.deletePaymentTermRow( this.paymentTerm );
          flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          this.setErrors(error.response.data.errors);
          flash(error.response.data.status_type, error.response.data.status);
        });
      },

      emitCancel() {
        this.$emit('cancel');
      },

      //
      // Errors
      setErrors(errors, form) {
        if( typeof errors.deleted_payment_term != 'undefined' ) {
          this.errors = errors.deleted_payment_term;
        } else {
          this.errors = {};
        }
      },

      //
      // Slide Toggle Company List List
      toggleFullView(event) {
        event.preventDefault();
        this.isCompanyListMaximized = ( this.isCompanyListMaximized == true ) ? false : true;
      }
    }
  };
</script>

<template>
  <div>
    <b-modal
      :id="modalIdAttr"
      :size="modalSizeAttr"
      :title="modalTitleText"
      :ok-title="confirmButtonText"
      :cancel-title="dismissButtonText"
      :no-close-on-backdrop="true"
      :hide-header-close="hideHeaderCloseButton"
      :ref="'deletePaymentTermFormModal'"
      @ok="emitConfirm"
      @cancel="emitCancel">

      <template v-if="hasCompaniesAssignedToPaymentTerm == false">
        <div class="form-row">
          <div class="form-group col-sm-12 mb-0">
            <p class="mb-0">Are you sure you want to delete this payment term? This action cannot be undone.</p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <p class="">This payment term has {{ companyCount }} existing dealer accounts assigned to it. In order to delete this record, these dealer accounts must be reassigned to a new payment term. This change <strong>will not</strong> impact existing estimates or orders.</p>
          </div>

          <div class="form-group col-sm-12">
            <div class="expandable-block small mb-2">
              <div
                style="max-height: 500px; overflow-y: auto;"
                class="expandable-body"
                v-bind:class="(isCompanyListMaximized == true) ? 'maximized' : 'minimized'">

                <div class="title-bar-wrapper"><!-- start: title-bar-wrapper -->
                  <div class="title-bar" @dblclick="toggleFullView($event)">
                    <div class="summary">
                      <h2>Dealer List</h2>
                    </div>
                    <div class="actions">
                      <div class="link show" v-show="isCompanyListMaximized == false">
                        <a @click="toggleFullView($event)"></a>
                      </div>
                      <div class="link minimize" v-show="isCompanyListMaximized == true">
                        <a @click="toggleFullView($event)"></a>
                      </div>
                    </div>
                  </div><!-- end: title-bar -->
                </div><!-- end: title-bar-wrapper -->

                <div class="content-wrapper"><!-- start: content-wrapper -->
                  <transition name="slide">
                    <div class="transition-container" v-show="isCompanyListMaximized">
                      <div class="content-block" v-show="isCompanyListMaximized">
                        <div class="form-row">
                          <div class="form-group col-sm-12 mb-0 pb-0">
                            <ol class="py-0 px-3 mb-0">
                              <template v-for="company in paymentTermCompanies">
                                <li>{{ company.name }}</li>
                              </template>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-sm-12">
            <label :for="getInputId(paymentTerm.id, 'reassign_to')">Reassign Dealers To</label>
            <select
              class="form-control"
              v-bind:class="errors['reassign_to'] ? 'is-invalid' : ''"
              v-bind:name="getInputName(paymentTerm.id, 'reassign_to')"
              v-bind:id="getInputId(paymentTerm.id, 'reassign_to')"
              v-model="reassignTo">
                <option :value="null">--Choose One--</option>
                <option v-for="option in paymentTermSelectOptions" :value="option.paymentTerm.id">{{ option.paymentTerm.title }}</option>
            </select>
            <span class="invalid-feedback" role="alert" v-if="errors['reassign_to']">
              {{ errors['reassign_to'][0] }}
            </span>
          </div>
        </div>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="secondary"
            v-html="dismissButtonText"
            @click="cancel()">
          </b-button>

          <b-button
            variant="primary"
            v-html="confirmButtonText"
            @click="ok()">
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
