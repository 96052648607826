var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tbody",
    [
      _c("tr", { staticClass: "require-all" }, [
        _c("td", [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentAdditionalItem.part_id,
                  expression: "currentAdditionalItem.part_id",
                },
                {
                  name: "motorized-order-item-addition-field-directive",
                  rawName: "v-motorized-order-item-addition-field-directive",
                },
              ],
              staticClass: "form-control",
              class: _vm.errors["part_id"] ? "is-invalid" : "",
              attrs: {
                name: _vm.getInputName(_vm.currentAdditionalItem.id, "part_id"),
                id: _vm.getInputId(_vm.currentAdditionalItem.id, "part_id"),
              },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.currentAdditionalItem,
                    "part_id",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { domProps: { value: null } }, [
                _vm._v("--Choose One--"),
              ]),
              _vm._v(" "),
              _vm._l(_vm.orderItemAdditionSelectOptions, function (partType) {
                return _c(
                  "optgroup",
                  { attrs: { label: partType.name } },
                  _vm._l(partType.parts, function (part) {
                    return _c("option", { domProps: { value: part.id } }, [
                      _vm._v(_vm._s(part.name)),
                    ])
                  }),
                  0
                )
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentAdditionalItem.part_color_id,
                  expression: "currentAdditionalItem.part_color_id",
                },
                {
                  name: "motorized-order-item-addition-field-directive",
                  rawName: "v-motorized-order-item-addition-field-directive",
                },
              ],
              staticClass: "form-control",
              class: _vm.errors["part_color_id"] ? "is-invalid" : "",
              attrs: {
                name: _vm.getInputName(
                  _vm.currentAdditionalItem.id,
                  "part_color_id"
                ),
                id: _vm.getInputId(
                  _vm.currentAdditionalItem.id,
                  "part_color_id"
                ),
                disabled: _vm.hasPartColors == false,
              },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.currentAdditionalItem,
                    "part_color_id",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { domProps: { value: null } }, [
                _vm._v("--Choose One--"),
              ]),
              _vm._v(" "),
              _vm._l(_vm.partColorOptions, function (partColor) {
                return _vm.hasPartColors == true
                  ? [
                      _c("option", { domProps: { value: partColor.id } }, [
                        _vm._v(_vm._s(partColor.color)),
                      ]),
                    ]
                  : _vm._e()
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.partNumber))]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.partUom))]),
        _vm._v(" "),
        _c("td", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currentAdditionalItem.quantity,
                expression: "currentAdditionalItem.quantity",
              },
              {
                name: "motorized-order-item-addition-field-directive",
                rawName: "v-motorized-order-item-addition-field-directive",
              },
            ],
            staticClass: "form-control",
            class: _vm.errors["quantity"] ? "is-invalid" : "",
            attrs: {
              type: "integer",
              placeholder: "1",
              name: _vm.getInputName(_vm.currentAdditionalItem.id, "quantity"),
              id: _vm.getInputId(_vm.currentAdditionalItem.id, "quantity"),
              disabled: _vm.isLinearFootPart,
            },
            domProps: { value: _vm.currentAdditionalItem.quantity },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.currentAdditionalItem,
                  "quantity",
                  $event.target.value
                )
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(_vm.currentAdditionalItem.addtl_item_unit_price_formatted)
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(_vm.currentAdditionalItem.addtl_item_total_price_formatted)
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _c("a", {
            staticClass: "btn icon-only btn-save",
            class: _vm.orderProposal.userCanEdit ? "" : "disabled",
            attrs: { href: "#", title: "Save" },
            on: {
              click: function ($event) {
                return _vm.saveOrderItemAddition($event)
              },
            },
          }),
          _vm._v(" "),
          _c("a", {
            staticClass: "btn icon-only btn-delete",
            class: _vm.orderProposal.userCanEdit ? "" : "disabled",
            attrs: { href: "#", title: "Delete" },
            on: {
              click: function ($event) {
                return _vm.deleteOrderItemAddition($event)
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _vm.isLinearFootPart
        ? [
            _c("tr", { staticClass: "require-all" }, [
              _c(
                "td",
                { attrs: { colspan: "8" } },
                [
                  _c("motorized-order-item-addition-cuts", {
                    attrs: {
                      "additional-item": _vm.currentAdditionalItem,
                      "additional-item-id": _vm.additionalItem.id,
                      "current-order-proposal": _vm.orderProposal,
                      "master-order-item-addition-cuts":
                        _vm.currentAdditionalItem.order_item_addition_cuts,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }