<script>
  import InstallationEquipments from './InstallationEquipments';

  export default {
    props: [
      'parentCategoryName',
      'parentInstallationEquipments'
    ],

    components: {
      InstallationEquipments
    },

    data() {
      return {
        categoryName: null,
        equipmentList: []
      }
    },

    mounted() {
      this.categoryName = this.parentCategoryName;
      this.equipmentList = this.parentInstallationEquipments;
    },

    computed: {
      isDisabled() {
        return false;
      },

      errors() {
        return this.$parent.errors;
      }
    }
  };
</script>
