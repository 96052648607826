<script>
  export default {
    props: [
      'shadeUnit',
      'containerClass',
      'hembarEndcapColorLabel',
      'defaultSelectOptionText',
      'errors',
      'disabled'
    ],

    computed: {
      parentContainerClass() {
        return this.containerClass;
      },

      selectOptions() {
        var selectOptions = [];
        selectOptions = this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.hembarEndcapColorOptions;
        return selectOptions;
      },

      isExteriorShade() {
        return this.$parent.isExteriorShade;
      },

      isDisabled() {
        if( this.disabled == true || this.shadeUnit.hembar_style == 'Sealed Hembar' ) {
          return true;
        }
        return false;
      },

      hasErrors() {
        if( typeof this.errors.hembar_endcap_color != 'undefined' ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return this.$parent.getInputName(id, field);
      },

      getInputId(id, field) {
        return this.$parent.getInputId(id, field);
      }
    }
  };
</script>

<template>
  <div :class="parentContainerClass" v-if="!isExteriorShade">
    <label v-bind:for="getInputName(shadeUnit.id, 'hembar_endcap_color')">
      {{ hembarEndcapColorLabel }}
    </label>

    <select
      class="form-control"
      :class="errors['hembar_endcap_color'] ? 'is-invalid' : ''"
      v-bind:name="getInputName(shadeUnit.id, 'hembar_endcap_color')"
      v-bind:id="getInputId(shadeUnit.id, 'hembar_endcap_color')"
      v-model="shadeUnit.hembar_endcap_color"
      :disabled="isDisabled">
      <option :value="null">{{ defaultSelectOptionText }}</option>
      <option
        v-for="endcapColor in selectOptions"
        :value="endcapColor.value">
          {{ endcapColor.label }}
      </option>
    </select>

    <span
      class="invalid-feedback"
      role="alert"
      v-if="errors['hembar_endcap_color']">
      {{ errors['hembar_endcap_color'][0] }}
    </span>
  </div>
</template>
