var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide" } }, [
    _c(
      "tbody",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isMaximized,
            expression: "isMaximized",
          },
        ],
      },
      [
        _vm._l(_vm.orderItemAdditions, function (additionalItem, index) {
          return [
            _c("motorized-part", {
              attrs: {
                "additional-item": additionalItem,
                "additional-item-id": additionalItem.id,
              },
            }),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }