var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-responsive" }, [
    _c(
      "table",
      { staticClass: "table" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm.shadeGroups.length <= 0 &&
        _vm.orderProposal.addtl_items_total <= 0 &&
        _vm.orderProposal.motor_items_total <= 0
          ? [_vm._m(1)]
          : _vm._l(_vm.shadeGroups, function (shadeGroup) {
              return [
                _c("dealer-calc-table-group", {
                  attrs: { "shade-group-id": shadeGroup.id },
                }),
              ]
            }),
        _vm._v(" "),
        _vm.shadeGroups.length > 0
          ? [
              _c("tbody", [
                _c("tr", { staticClass: "colored-bg" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.orderProposal.total_material_cost_formatted)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.orderProposal.total_mfg_labor_cost_formatted)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.order_item_pkg_labor_hours_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.order_item_pkg_labor_cost_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal
                          .order_item_install_labor_cost_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.orderProposal.order_item_total_cost_formatted)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.order_item_labor_expense_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.order_item_material_expense_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.order_item_gross_profit_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.orderProposal.order_item_subtotal_formatted)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.order_item_commission_total_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.orderProposal.order_item_total_price_formatted)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.order_item_pct_of_total_formatted
                      )
                    ),
                  ]),
                ]),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.orderProposal.addtl_items_total > 0
          ? [
              _c("non-motorized-parts"),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", { staticClass: "colored-bg" }, [
                  _c("td", { staticClass: "text-nowrap" }, [
                    _c("a", {
                      staticClass: "pull-left mt-1",
                      class: _vm.toggleNonMotorizedPartsClass,
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleNonMotorizedParts($event)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("strong", [_vm._v("Additional Parts")]),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal
                          .additional_parts_total_material_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.additional_parts_mfg_labor_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.orderProposal.additional_parts_pkg_labor_hours)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.additional_parts_pkg_labor_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal
                          .addtl_item_install_labor_cost_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.additional_parts_total_cost_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal
                          .additional_parts_labor_expense_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal
                          .additional_parts_material_expense_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal
                          .additional_parts_gross_profit_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.additional_parts_subtotal_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal
                          .additional_parts_commission_total_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.additional_parts_total_price_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal
                          .additional_parts_pct_of_total_formatted
                      )
                    ),
                  ]),
                ]),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.orderProposal.motor_items_total > 0
          ? [
              _c("motorized-parts"),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", { staticClass: "colored-bg" }, [
                  _c("td", { staticClass: "text-nowrap" }, [
                    _c("a", {
                      staticClass: "pull-left mt-1",
                      class: _vm.toggleMotorizedPartsClass,
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleMotorizedParts($event)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("strong", [_vm._v("Motorized Parts")]),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal
                          .motorized_parts_total_material_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.motorized_parts_mfg_labor_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.orderProposal.motorized_parts_pkg_labor_hours)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.motorized_parts_pkg_labor_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal
                          .motor_item_install_labor_cost_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.motorized_parts_total_cost_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal
                          .motorized_parts_labor_expense_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal
                          .motorized_parts_material_expense_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.motorized_parts_gross_profit_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.motorized_parts_subtotal_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal
                          .motorized_parts_commission_total_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.motorized_parts_total_price_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.motorized_parts_pct_of_total_formatted
                      )
                    ),
                  ]),
                ]),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.shadeGroups.length > 0 ||
        _vm.orderProposal.addtl_items_total > 0 ||
        _vm.orderProposal.motor_items_total > 0
          ? [
              _c("tbody", [
                _c("tr", { staticClass: "colored-bg" }, [
                  _vm._m(3),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.dealer_calc_material_cost_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.dealer_calc_mfg_labor_cost_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.orderProposal.dealer_calc_pkg_labor_hours)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.dealer_calc_pkg_labor_cost_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.orderProposal.install_labor_cost_formatted)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.orderProposal.dealer_calc_total_cost_formatted)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.dealer_calc_labor_expense_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.dealer_calc_material_expense_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.dealer_calc_gross_profit_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.orderProposal.dealer_calc_subtotal_formatted)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.orderProposal.dealer_commission_cost_formatted)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.dealer_calc_total_price_formatted
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.orderProposal.dealer_calc_pct_of_total_formatted
                      )
                    ),
                  ]),
                ]),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Item")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Total Material ($)")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Total MFG Labor ($)")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Pkg & Handling (Hours)"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Pkg & Handling ($)")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-nowrap", attrs: { scope: "col" } }, [
          _vm._v("Supply Only"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Total Install Labor ($)"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Total Cost ($)")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Unit Cost ($)")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Labor Expense")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Material Expense")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Gross Profit")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Subtotal")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Commission Fee")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Total")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Cost Per Shade")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("% of Total")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tbody", [
      _c("tr", [
        _c(
          "td",
          { staticClass: "colored-bg text-center", attrs: { colspan: "17" } },
          [_vm._v("No Order Items")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [_c("strong", [_vm._v("Shade Totals")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [_c("strong", [_vm._v("Total")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }