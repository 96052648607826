<script>
  import ResourceAttachments from './ResourceAttachments';
  import ModalConfirm from '../../notifications/ModalConfirm.vue';

  export default {
    props: [
      'parentResource',
      'parentCategory',
      'parentSelectOptions',
    ],

    components: {
      ResourceAttachments,
      ModalConfirm
    },

    data() {
      return {
        isMaximized: false,
        maxAttachments: 1,
        errors: {},
      }
    },

    mounted() {
      if( this.isNew ) {
        this.$nextTick(() => {
          this.editResourceClick(event);
        });
      }
    },

    computed: {
      resource() {
        return this.parentResource;
      },

      resourceAttachments() {
        return this.parentResource.resource_attachments;
      },

      endpointCreate() {
        return '/admin/document-library/resource/' + this.parentCategory.id;
      },

      endpointUpdate() {
        return '/admin/document-library/resource/' + this.resource.id;
      },

      endpointDestroy() {
        return '/admin/document-library/resource/' + this.resource.id;
      },

      isNew() {
        return ( this.resource.isNew === true ) ? true : false;
      },

      expandableBodyClass() {
        return ( this.isMaximized == true ) ? 'maximized' : 'minimized';
      },

      summaryTitle() {
        return ( this.isNew && !this.resource.name ) ? 'New Resource' : this.resource.name;
      },

      summaryDescription() {
        if( !this.parentResource.description ) {
          return null;
        } else {
          return this.parentResource.description.split(' ').slice(0,10).join(' ') + '...';
        }
      },

      canAddAttachment() {
        return ( this.resourceAttachments.length < this.maxAttachments ) ? true : false;
      },

      modalCancelAction() {
        return 'cancelModalAction';
      },

      modalDestroyConfirmButtonAction() {
        return 'destroy';
      },

      hasErrors() {
        if( Object.keys(this.errors).length ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'document_resource[' + id + '][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      resetResource() {
        if( this.isNew ) {
          this.destroy();
        }
        //this.resource = this.parentResource;
      },


      save(showFlashMessages = true, minimizeOnSave = true) {
        let endpoint = ( this.isNew == true ) ? this.endpointCreate : this.endpointUpdate,
            formMethod = ( this.isNew == true ) ? 'POST' : 'PUT',
            submitData = {
              _method: formMethod,
              document_resource: this.resource
            };

        return new Promise((resolve, reject) => {
          axios.post(endpoint, submitData)
          .then((response) => {
            let resource = response.data.data.resource;
            this.setErrors({});
            this.$parent.$parent.applySavedResource(this.resource.id, resource);

            if( showFlashMessages === true ) {
              flash(response.data.status_type, response.data.status);
            }

            if( minimizeOnSave === true ) {
              this.isMaximized = false;
            }

            resolve(response); //resolve the promise
          })
          .catch(error => {
            this.setErrors(error.response.data.errors);
            if( showFlashMessages ) {
              flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
            }
            reject(error.response.data); //reject the promise
          });
        });
      },

      destroy() {
        event.preventDefault();
        if( this.isNew === true ) {
          this.$parent.$parent.destroyResource( this.resource );

        } else {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            this.$parent.$parent.destroyResource( this.resource );
            flash(response.data.status_type, response.data.status);
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      },

      editResourceClick(event) {
        event.preventDefault();
        this.isMaximized = true;

        //this.$bvModal.show('resourceModal' + this.resource.id);
      },

      hideResourceClick(event) {
        event.preventDefault();
        this.isMaximized = false;
      },

      destroyResourceClick(event) {
        event.preventDefault();
        this.$bvModal.show('modal-' + this.resource.id);
      },

      addResourceAttachment(event) {
        event.preventDefault();
        this.$bvModal.show('resourceModal' + this.resource.id);
      },

      //Errors
      setErrors(errors, form) {
        if( typeof errors.document_resource != 'undefined' ) {
          this.errors = errors.document_resource;
        } else {
          this.errors = {};
        }
      },

      //
      // Modals
      //

      //Handle modal function call
      handleFunctionCall(functionName) {
        this[functionName]();
      },

      //Cancel out of an open/active modal without completeing action
      cancelModalAction() {
        return false;
      },
    }
  }
</script>
