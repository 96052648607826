import { mapGetters, mapActions } from 'vuex'
import { normalizeRelations, resolveRelations } from '../../helpers/helpers';
import { ENDPOINT_ADD_SHADE_GROUP,
          ENDPOINT_CREATE_SHADE_GROUP,
          ENDPOINT_UPDATE_SHADE_GROUP,
          ENDPOINT_DESTROY_SHADE_GROUP,
          ENDPOINT_COPY_SHADE_GROUP,
          ENDPOINT_SORT_SHADE_GROUP
        } from '../../endpoints'


export default {
  namespaced: true,

  //
  // State
  //
  state: {
    byId: {},
    allIds: []
  },

  //
  // Getters
  //
  getters: {
    //
    // Return a single shade group with the given id.
    //
    find: (state, _, __, rootGetters) => id => {
      // Swap ID referenes with the resolved shade line item objects.
      return resolveRelations(state.byId[id], ["shadeLineItems"], rootGetters);
    },

    //
    // Return a list of articles in the order of `allIds`.
    //
    list: (state, getters) => {
      return state.allIds.map(id => getters.find(id));
    }
  },


  //
  // Actions
  //
  actions: {
    //
    // Add Shade Groups to the store (this handles the initial loading of shade groups into the store)
    //
    applyShadeGroups({commit, dispatch, state, rootState}, shadeGroups) {
      if( shadeGroups ) {
        shadeGroups.forEach(group => {
          group.shadeLineItems = ( group.hasOwnProperty('order_items') ) ? group.order_items : [];
            delete group.order_items;

          if( group.isNew == true ) {
            group.shadeLineItems.isNew = true;
          } else {
            group.isNew = false;
          }

          commit("ADD_SHADE_GROUP", normalizeRelations(group, ["shadeLineItems"]));

          if( group.isUpdate == true ) {
            dispatch('shadeLineItems/updateExistingShadeLineItems', group.shadeLineItems, {root: true});
          } else {
            dispatch('shadeLineItems/applyShadeLineItems', group.shadeLineItems, {root: true});
          }
        });
      }
    },

    //
    // Create a new shade group on the fly
    //  This creates an empty "shell" that is used to generate the input fields within the vue component
    //
    addNewShadeGroup({dispatch, rootState}) {
      axios.get(ENDPOINT_ADD_SHADE_GROUP + rootState.shadeOrder.shadeOrder.id)
      .then((response) => {
        response.data.data.shadeGroup.isNew = true;

        dispatch('applyShadeGroups', [response.data.data.shadeGroup]);
        flash(response.data.status_type, response.data.status);
      })
      .catch(error => {
        flash(error.response.data.status_type, error.response.data.status);
      });
    },

    //
    // Save Shade Group
    //   This action will route the save shade group request to the appropriate action
    //
    saveShadeGroup({dispatch, getters, rootState}, id) {
      var targetShadeGroup = getters.find(id);

      if( targetShadeGroup.isNew == true ) {
        dispatch('createShadeGroup', id)
          .catch(error => '');
      } else {
        dispatch('updateShadeGroup', id)
          .catch(error => '');
      }
    },

    //
    // Create/save a new shade group
    //   This action makes the actual request to the application to CREATE a new shade group with shade line items
    //
    createShadeGroup({dispatch, getters, rootState}, id) {
      var targetShadeGroup = getters.find(id),
          shadeOrder = rootState.shadeOrder.shadeOrder;
      var clonedShadeLineItems = _.cloneDeep(targetShadeGroup.shadeLineItems);

      //Remove unncessary data from object
      clonedShadeLineItems.forEach(shadeLineItem => {
        shadeLineItem.shade_group_id = id;

        delete shadeLineItem.product_model;
        delete shadeLineItem.fabric_attribute_options;
        delete shadeLineItem.fabric_openness_factor_options;
        delete shadeLineItem.fabric_options;

        delete shadeLineItem.dual_fabric_attribute_options;
        delete shadeLineItem.dual_fabric_openness_factor_options;
        delete shadeLineItem.dual_fabric_options;

        delete shadeLineItem.motor_power_options;
        delete shadeLineItem.motor_sound_options;
        delete shadeLineItem.motor_type_options;

        if( shadeLineItem.hasOwnProperty('order_item_assembly') ) {
          shadeLineItem.order_item_assembly.hembar_composition = shadeLineItem.order_item_assembly_hembar_composition;
          shadeLineItem.order_item_assembly.notes = shadeLineItem.order_item_assembly_notes;
        }
      });

      var submitData = {
            _method: 'POST',
            order_items: clonedShadeLineItems
          };

      return new Promise((resolve, reject) => {
        axios.post(ENDPOINT_CREATE_SHADE_GROUP + shadeOrder.id, submitData)
        .then((response) => {
          //this is now saved so it's no longer new...
          response.data.data.shadeGroup.isNew = false;

          var payload = {
            oldShadeGroupId: id,
            shadeGroup: response.data.data.shadeGroup
          };

          //Dispatch the updated shade data so it can be committed to state...
          dispatch('updateNewStoredShadeGroup', payload);

          if( response.data.data.hasOwnProperty('orderProposal') ) {
            //update the orderProposal
            dispatch('orderProposal/applyOrderProposal', response.data.data.orderProposal, {root: true});
          }

          //show the flash message
          flash(response.data.status_type, response.data.status);

          //resolve the promise
          resolve(response);
        })
        .catch(error => {
          //dispatch the setFormErrors action
          var payload = {
            shadeGroupId: targetShadeGroup.id,
            errors: error.response.data.errors.order_items
          };
          dispatch('shadeLineItems/setFormErrors', payload, {root: true});

          //show the error message
          flash(error.response.data.status_type, error.response.data.status);

          //reject the promise
          reject(error.response.data);
        });
      });
    },

    //
    // Update a NEW Shade Group stored in state
    //  This action is used when a new (dynamic) shade group with a placeholder shade group id has been saved.
    //  It's basically going to remap the placeholder shade group id with the new/official shade group id so we can continue working with it.
    //
    updateNewStoredShadeGroup({commit, dispatch}, payload) {
      var group = payload.shadeGroup,
          existingShadeGroupId = payload.oldShadeGroupId;

      if( group ) {
        group.shadeLineItems = ( group.hasOwnProperty('order_items') ) ? group.order_items : [];
          delete group.order_items;

        group.shadeLineItems.isNew = ( group.isNew == true ) ? true : false;

        payload.shadeGroup = normalizeRelations(group, ["shadeLineItems"]);

        //The var below doesn't appear to be used anywhere?
        var shadeLineItemsPayload = {
          oldShadeLineItems: '',
          shadeLineItems: payload.shadeGroup.shadeLineItems
        };
        dispatch('shadeLineItems/updateNewShadeLineItems', group.shadeLineItems, {root: true});

        commit("UPDATE_NEW_SHADE_GROUP", payload);
      }
    },

    //
    // Update an existing shade group
    //
    updateShadeGroup({dispatch, getters, rootState}, id) {
      var targetShadeGroup = getters.find(id),
          clonedShadeLineItems = _.cloneDeep(rootState.shadeLineItems.byGroupId[id].shadeLineItems);

      //Remove unncessary data from object
      clonedShadeLineItems.forEach(shadeLineItem => {
        delete shadeLineItem.product_model;
        delete shadeLineItem.fabric_attribute_options;
        delete shadeLineItem.fabric_openness_factor_options;
        delete shadeLineItem.fabric_options;

        delete shadeLineItem.dual_fabric_attribute_options;
        delete shadeLineItem.dual_fabric_openness_factor_options;
        delete shadeLineItem.dual_fabric_options;

        delete shadeLineItem.order_item_assembly_errors;
        delete shadeLineItem.order_item_install_labors;
        delete shadeLineItem.order_item_install_side_channel_labors;
        delete shadeLineItem.order_item_install_trim_labors;

        delete shadeLineItem.order_item_manufacture_labors;
        delete shadeLineItem.order_item_manufacture_side_channel_labors;
        delete shadeLineItem.order_item_manufacture_trim_labors;

        delete shadeLineItem.motor_power_options;
        delete shadeLineItem.motor_sound_options;
        delete shadeLineItem.motor_type_options;

        delete shadeLineItem.shade_order;
        delete shadeLineItem.errors;

        if( shadeLineItem.hasOwnProperty('order_item_assembly') ) {
          shadeLineItem.order_item_assembly.hembar_composition = shadeLineItem.order_item_assembly_hembar_composition;
          shadeLineItem.order_item_assembly.notes = shadeLineItem.order_item_assembly_notes;
        }
      });

      var submitData = {
            _method: 'PUT',
            order_items: clonedShadeLineItems
          };

      return new Promise((resolve, reject) => {
        axios.post(ENDPOINT_UPDATE_SHADE_GROUP + targetShadeGroup.id, submitData)
        .then((response) => {
          //this is now saved so it's no longer new...
          response.data.data.shadeGroup.isNew = false;
          response.data.data.shadeGroup.isUpdate = true;

          clonedShadeLineItems.forEach(function(item, index){
            response.data.data.shadeGroup.order_items[index].old_id = item.id;
          });

          //Dispatch the updated shade data so it can be committed to state...
          dispatch('applyShadeGroups', [response.data.data.shadeGroup]);

          if( response.data.data.hasOwnProperty('orderProposal') ) {
            //update the orderProposal
            dispatch('orderProposal/applyOrderProposal', response.data.data.orderProposal, {root: true});
          }

          //show the flash message
          flash(response.data.status_type, response.data.status);

          //resolve the promise
          resolve(response);
        })
        .catch(error => {
          //dispatch the setFormErrors action
          if( error.response.data.hasOwnProperty('errors') && error.response.data.errors.hasOwnProperty('order_items') ) {
            var payload = {
              shadeGroupId: targetShadeGroup.id,
              errors: error.response.data.errors.order_items
            };

            dispatch('shadeLineItems/setFormErrors', payload, {root: true});
          }

          //show the error message
          flash(error.response.data.status_type, error.response.data.status);

          //reject the promise
          reject(error.response.data);
        })
      });//end Promise
    },

    //
    // Delete entire shade group
    //  This action triggers a request to the application to delete a given shade group, then deletes those records from the store
    //
    deleteShadeGroup({commit, dispatch, getters}, id) {
      var shadeGroup = getters.find(id);

      if( shadeGroup.isNew == false ) {
        axios.delete(ENDPOINT_DESTROY_SHADE_GROUP + shadeGroup.id)
        .then((response) => {
          dispatch('shadeLineItems/deleteShadeGroupLineItems', id, {root: true});
          commit('DESTROY_SHADE_GROUP', id);

          if( response.data.data.hasOwnProperty('shadeGroups') ) {
            let shadeGroups = response.data.data.shadeGroups;
            shadeGroups.forEach(function(group, index){
              group.isNew = false;
              group.isUpdate = true;
            });

            commit("SET_SORT_ORDER", shadeGroups);
            dispatch('applyShadeGroups', shadeGroups);
          }

          if( response.data.data.hasOwnProperty('orderProposal') ) {
            //update the orderProposal
            dispatch('orderProposal/applyOrderProposal', response.data.data.orderProposal, {root: true});
          }

          flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status);
        });

      } else {
          dispatch('shadeLineItems/deleteShadeGroupLineItems', id, {root: true});
          commit('DESTROY_SHADE_GROUP', id);
      }
    },

    //
    // Delete shade line item from shade group
    //  This action will delete a singular shade line item from the corresponding shade group
    //
    deleteNewShadeLineItemFromShadeGroup({commit}, payload) {
      commit('DELETE_NEW_SHADE_LINE_ITEM_FROM_SHADE_GROUP', payload);
    },

    //
    // Copy/Clone the entire shadeGroup
    //  This action makes a request to the api that clones the given shade group then adds the cloned records to the store
    //
    copyShadeGroup({getters, dispatch}, id) {
      var shadeGroup = getters.find(id);

      axios.post(ENDPOINT_COPY_SHADE_GROUP + shadeGroup.id)
      .then((response) => {
        var newShadeGroup = response.data.data.shadeGroup;
        newShadeGroup.isNew = false;

        //Dispatch the updated shade data so it can be committed to state...
        dispatch('applyShadeGroups', [newShadeGroup]);

        if( response.data.data.hasOwnProperty('orderProposal') ) {
          //update the orderProposal
          dispatch('orderProposal/applyOrderProposal', response.data.data.orderProposal, {root: true});
        }

        flash(response.data.status_type, response.data.status);
      })
      .catch(error => {
        flash(error.response.data.status_type, error.response.data.status);

      });
    },

    //
    // Update shade line items in shade group
    //  This is used in conjunction with the shadeLineItems/copyShadeLineItem action. All it does is sync up the existing
    //  shadeLineItems under the specified shadeGroup.
    //
    //  The logic here can probably be refactored and offloaded to an existing action
    //
    updateShadeGroupLineItems({commit}, payload) {
      let group = payload;
      commit("ADD_COUPLED_SHADE_TO_SHADE_GROUP", normalizeRelations(group, ["shadeLineItems"]));
    },

    //
    // Add a shade line item record to an existing shade group
    //  This is used in conjuction with coupled shades. When a coupled shade line item is added, this action will add the line item data
    //  to the shade group
    //
    addCoupledShadeToShadeGroup({commit, getters}, payload) {
      var group = getters.find(payload.shadeGroupId),
          shadeLineItem = payload.shadeLineItem;

      group.shadeLineItems.push(shadeLineItem);
      commit("ADD_COUPLED_SHADE_TO_SHADE_GROUP", normalizeRelations(group, ["shadeLineItems"]));
    },


    //
    // Update sort order
    //
    updateSortOrder({commit, dispatch, getters, rootGetters}, payload) {
      var endpoint = ENDPOINT_SORT_SHADE_GROUP + rootGetters['shadeOrder/shadeOrder'].id;
      var sort_order = payload.map(shadeGroup => shadeGroup.id);
      var submitData = {
            _method: 'PUT',
            sort_order: sort_order
          };

      axios.post(ENDPOINT_SORT_SHADE_GROUP + rootGetters['shadeOrder/shadeOrder'].id, submitData)
      .then((response) => {
        let shadeGroups = response.data.data.shadeGroups;
        shadeGroups.forEach(function(group, index){
          group.isNew = false;
          group.isUpdate = true;
        });

        commit("SET_SORT_ORDER", shadeGroups);
        dispatch('applyShadeGroups', shadeGroups);

        //flash(response.data.status_type, response.data.status);
      })
      .catch(error => {
        flash(error.response.data.status_type, error.response.data.status);
      });
    }
  },


  //
  // Mutations
  //
  mutations: {
    ADD_SHADE_GROUP(state, item) {
      Vue.set(state.byId, item.id, item);
      if (state.allIds.includes(item.id)) return;
      state.allIds.push(item.id);
    },

    UPDATE_NEW_SHADE_GROUP(state, payload) {
      var oldShadeGroupId = payload.oldShadeGroupId,
          newShadeGroupId = payload.shadeGroup.id,
          shadeGroup = payload.shadeGroup;

      Vue.set(state.byId, shadeGroup.id, shadeGroup);
        delete state.byId[oldShadeGroupId];

      if (state.allIds.includes(shadeGroup.id)) {
        return;
      } else {
        var oldIndex = state.allIds.indexOf(oldShadeGroupId);
        state.allIds.splice(oldIndex, 1, shadeGroup.id);
      }
    },

    DESTROY_SHADE_GROUP(state, id) {
      var index = state.allIds.indexOf(id);
      delete state.byId[id];
      state.allIds.splice(index, 1);
    },

    DELETE_NEW_SHADE_LINE_ITEM_FROM_SHADE_GROUP(state, payload) {
      state.byId[payload.shadeGroupId].shadeLineItems.forEach(function(shadeLineItemId, index) {
        if( shadeLineItemId == payload.shadeLineItemId ) {
          state.byId[payload.shadeGroupId].shadeLineItems.splice(index, 1);
        }
      });
    },

    ADD_COUPLED_SHADE_TO_SHADE_GROUP(state, shadeGroup) {
      //
      // The logic below was causing the shade line items to disappear from the pricing tabs
      // when they were run (all except for the take off tab). They don't appear to be
      // performing a pertinent functionality so removing for the immediate time being.
      //

      //Vue.set(state.byId, shadeGroup.id, shadeGroup);
      //state.byId[shadeGroup.id].shadeLineItems = shadeGroup.shadeLineItems;

      //if (state.allIds.includes(shadeGroup.id)) return;
      //state.allIds.push(shadeGroup.id);
    },

    SET_SORT_ORDER(state, shadeGroups) {
      shadeGroups.forEach(function(shadeGroup, index) {
        state.allIds.splice(index, 0, state.allIds.splice(state.allIds.indexOf(shadeGroup.id), 1)[0]);
      });
    },
  }
}
