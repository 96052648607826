var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "motor-configuration col" }, [
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-sm-12 col-md mb-4" }, [
        _c(
          "label",
          {
            attrs: {
              for: "getInputName(shadeUnit.id, 'motor_manufacturer_id')",
            },
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.motorManufacturerLabel) + "\n      "
            ),
          ]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.shadeUnit.motor_manufacturer_id,
              expression: "shadeUnit.motor_manufacturer_id",
            },
          ],
          attrs: {
            type: "hidden",
            name: _vm.getInputName(_vm.shadeUnit.id, "motor_manufacturer_id"),
          },
          domProps: { value: _vm.shadeUnit.motor_manufacturer_id },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.shadeUnit,
                "motor_manufacturer_id",
                $event.target.value
              )
            },
          },
        }),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.shadeUnit.motor_manufacturer_id,
                expression: "shadeUnit.motor_manufacturer_id",
              },
            ],
            staticClass: "form-control",
            class: _vm.errors["motor_manufacturer_id"] ? "is-invalid" : "",
            attrs: {
              name: _vm.getInputName(_vm.shadeUnit.id, "motor_manufacturer_id"),
              id: _vm.getInputName(_vm.shadeUnit.id, "motor_manufacturer_id"),
              disabled:
                _vm.disabled == true ||
                _vm.isMotorized == false ||
                _vm.isExteriorShade,
            },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.shadeUnit,
                  "motor_manufacturer_id",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { domProps: { value: null } }, [
              _vm._v(_vm._s(_vm.defaultSelectOptionText)),
            ]),
            _vm._v(" "),
            _vm._l(_vm.motorManufacturerOptions, function (motorManufacturer) {
              return _c(
                "option",
                { domProps: { value: motorManufacturer.id } },
                [_vm._v(_vm._s(motorManufacturer.name))]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.errors["motor_manufacturer_id"]
          ? _c(
              "span",
              { staticClass: "invalid-feedback", attrs: { role: "alert" } },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.errors["motor_manufacturer_id"][0]) +
                    "\n      "
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-sm-12 col-md mb-4" }, [
        _c(
          "label",
          { attrs: { for: "getInputName(shadeUnit.id, 'motor_type')" } },
          [_vm._v("\n        " + _vm._s(_vm.motorTypeLabel) + "\n      ")]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.shadeUnit.motor_type,
              expression: "shadeUnit.motor_type",
            },
          ],
          attrs: {
            type: "hidden",
            name: _vm.getInputName(_vm.shadeUnit.id, "motor_type"),
          },
          domProps: { value: _vm.shadeUnit.motor_type },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.shadeUnit, "motor_type", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.shadeUnit.motor_type,
                expression: "shadeUnit.motor_type",
              },
            ],
            staticClass: "form-control",
            class: _vm.errors["motor_type"] ? "is-invalid" : "",
            attrs: {
              name: _vm.getInputName(_vm.shadeUnit.id, "motor_type"),
              id: _vm.getInputName(_vm.shadeUnit.id, "motor_type"),
              disabled:
                _vm.disabled == true ||
                _vm.isMotorized == false ||
                _vm.isExteriorShade,
            },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.shadeUnit,
                  "motor_type",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { domProps: { value: null } }, [
              _vm._v(_vm._s(_vm.defaultSelectOptionText)),
            ]),
            _vm._v(" "),
            _vm._l(_vm.shadeUnit.motor_type_options, function (motorType) {
              return _c("option", { domProps: { value: motorType.value } }, [
                _vm._v(_vm._s(motorType.label)),
              ])
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.errors["motor_type"]
          ? _c(
              "span",
              { staticClass: "invalid-feedback", attrs: { role: "alert" } },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.errors["motor_type"][0]) +
                    "\n      "
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-sm-12 col-md mb-4" }, [
        _c(
          "label",
          { attrs: { for: "getInputName(shadeUnit.id, 'motor_power')" } },
          [_vm._v("\n        " + _vm._s(_vm.motorPowerLabel) + "\n      ")]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.shadeUnit.motor_power,
              expression: "shadeUnit.motor_power",
            },
          ],
          attrs: {
            type: "hidden",
            name: _vm.getInputName(_vm.shadeUnit.id, "motor_power"),
          },
          domProps: { value: _vm.shadeUnit.motor_power },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.shadeUnit, "motor_power", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.shadeUnit.motor_power,
                expression: "shadeUnit.motor_power",
              },
            ],
            staticClass: "form-control",
            class: _vm.errors["motor_power"] ? "is-invalid" : "",
            attrs: {
              name: _vm.getInputName(_vm.shadeUnit.id, "motor_power"),
              id: _vm.getInputName(_vm.shadeUnit.id, "motor_power"),
              disabled:
                _vm.disabled == true ||
                _vm.isMotorized == false ||
                _vm.isExteriorShade,
            },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.shadeUnit,
                  "motor_power",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { domProps: { value: null } }, [
              _vm._v(_vm._s(_vm.defaultSelectOptionText)),
            ]),
            _vm._v(" "),
            _vm._l(_vm.shadeUnit.motor_power_options, function (motorPower) {
              return _c("option", { domProps: { value: motorPower.value } }, [
                _vm._v(_vm._s(motorPower.label)),
              ])
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.errors["motor_power"]
          ? _c(
              "span",
              { staticClass: "invalid-feedback", attrs: { role: "alert" } },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.errors["motor_power"][0]) +
                    "\n      "
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-sm-12 col-md mb-4" }, [
        _c(
          "label",
          { attrs: { for: "getInputName(shadeUnit.id, 'motor_sound')" } },
          [_vm._v("\n        " + _vm._s(_vm.motorSoundLabel) + "\n      ")]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.shadeUnit.motor_sound,
              expression: "shadeUnit.motor_sound",
            },
          ],
          attrs: {
            type: "hidden",
            name: _vm.getInputName(_vm.shadeUnit.id, "motor_sound"),
          },
          domProps: { value: _vm.shadeUnit.motor_sound },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.shadeUnit, "motor_sound", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.shadeUnit.motor_sound,
                expression: "shadeUnit.motor_sound",
              },
            ],
            staticClass: "form-control",
            class: _vm.errors["motor_sound"] ? "is-invalid" : "",
            attrs: {
              name: _vm.getInputName(_vm.shadeUnit.id, "motor_sound"),
              id: _vm.getInputName(_vm.shadeUnit.id, "motor_sound"),
              disabled: _vm.disabled == true || _vm.isMotorized == false,
            },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.shadeUnit,
                  "motor_sound",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { domProps: { value: null } }, [
              _vm._v(_vm._s(_vm.defaultSelectOptionText)),
            ]),
            _vm._v(" "),
            _vm._l(_vm.shadeUnit.motor_sound_options, function (motorSound) {
              return _c("option", { domProps: { value: motorSound.value } }, [
                _vm._v(_vm._s(motorSound.label)),
              ])
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.errors["motor_sound"]
          ? _c(
              "span",
              { staticClass: "invalid-feedback", attrs: { role: "alert" } },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.errors["motor_sound"][0]) +
                    "\n      "
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }