<script>
  import VideoModal from '../global/VideoModal.vue';

  export default {
    props: [
      'parentResource',
      'parentAttachment',
    ],

    components: {
      VideoModal
    },

    data() {
      return {}
    },

    mounted() {
      //
    },

    computed: {
      attachment() {
        return this.parentAttachment;
      },

      endpointDownload() {
        return '/document-library/download/' + this.attachment.id;
      },

      isVideo() {
        return ( this.attachment.attachment_type == 'video' ) ? true : false;
      },

      isFile() {
        return ( this.attachment.attachment_type == 'file' ) ? true : false;
      },

      attachmentTypeText() {
        let attachmentType = this.attachment.attachment_type;
        return attachmentType.charAt(0).toUpperCase() + attachmentType.slice(1);
      },

      attachmentFileNameText() {
        return this.attachment.file_name;
      },

      attachmentFileExtensionText() {
        return this.attachment.file_extension;
      },

      viewAttachmentLinkText() {
        if( this.isVideo ) {
          return 'Watch ' + ' <i class="fa fa-eye"></i>';
        } else {
          return 'Download ' + this.attachmentFileExtensionText + ' <i class="fa fa-download"></i>';
        }
      },
    },

    methods: {
      //
      // View Attachments
      //
      viewAttachment(event) {
        event.preventDefault();

        if( this.isFile ) {
          this.downloadFile(event);
        } else {
          this.watchVideo(event);
        }
      },

      downloadFile(event) {
        event.preventDefault();
        let endpoint = this.endpointDownload;

        axios.post(endpoint)
        .then((response) => {
          var fileLink = document.createElement('a');
          fileLink.href = response.data.data.downloadUrl;
          fileLink.setAttribute('download', 'file.pdf');
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status); //show the error message
        });
      },

      watchVideo(event) {
        event.preventDefault();
        this.$bvModal.show('videoModal-' + this.attachment.id);
      },
    }
  };
</script>
<template>
  <p class="mb-0">
    <a href="#" @click="viewAttachment($event)" v-html="viewAttachmentLinkText"></a>
    <video-modal
      :modal-id="attachment.id"
      :video-url="attachment.attachment_path"
      :auto-play="true"
      :modal-size="'lg'"
      :modal-title="''"
      :modal-body="''"
      :dismiss-button="''"
      :confirm-button="''"
      :hide-header-close="false"
      @confirmed="handleFunctionCall(modalDestroyConfirmButtonAction)"
      @cancel="handleFunctionCall(modalCancelAction)">
    </video-modal>
  </p>
</template>
