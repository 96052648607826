//import { TOGGLE_SIDEBAR } from '../mutation-types'

import { mapGetters, mapActions } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import { normalizeRelations, resolveRelations } from '../../helpers/helpers';
import {
  ENDPOINT_ORDER_ITEM_ADDITION_NEW,
  ENDPOINT_ORDER_ITEM_ADDITION_CREATE,
  ENDPOINT_ORDER_ITEM_ADDITION_UPDATE,
  ENDPOINT_ORDER_ITEM_ADDITION_DESTROY
} from '../../endpoints'


export default {
  namespaced: true,


  //state
  state: {
    orderItemAdditionSelectOptions: [],
    byId: {},
    allIds: [],
    errors: [],
  },


  //getters
  getters: {
    allOrderItemAdditions: (state, getters) => {
      return getters.list;
    },

    orderItemAdditionSelectOptions: state => state.orderItemAdditionSelectOptions,

    orderItemAdditionPartById: (state, getters) => id => {
      var targetPart = null;
      var locatedPart = state.orderItemAdditionSelectOptions.some(function(partType) {
        targetPart = partType.parts.find(part => part.id == id);

        if( targetPart != 'undefined' ) {
          return targetPart;
        }
      });

      if( typeof targetPart == 'undefined' ) {
        return null;
      } else {
        return targetPart;
      }
    },

    //
    // Return a single shade group with the given id.
    //
    find: (state, _, __, rootGetters) => id => {
      // Swap ID referenes with the resolved order item addition cuts.
      return resolveRelations(state.byId[id], ["motorizedOrderItemAdditionCuts"], rootGetters);
    },

    //
    // Return a list of articles in the order of `allIds`.
    //
    list: (state, getters) => {
      return state.allIds.map(id => getters.find(id));
    },

    getErrorsById: ( state, getters ) => id => {
      return ( state.errors[id] ) ? state.errors[id] : [];
    },

    // vuex-map-fields plugin
    getField,
  },


  //actions
  actions: {
    //
    // Apply orderItemAdditions
    //
    applyOrderItemAddition({commit, dispatch}, payload) {
      let orderItemAdditions = payload.orderItemAdditions,
          isUpdate = payload.isUpdate;

      if( Array.isArray(orderItemAdditions) && orderItemAdditions.length > 0 ) {
        orderItemAdditions.forEach(function(item, index) {
          item.motorizedOrderItemAdditionCuts = ( item.hasOwnProperty('order_item_addition_cuts') ) ? item.order_item_addition_cuts : [];
            delete item.order_item_addition_cuts;

          if( !item.hasOwnProperty('isNew') ) {
            item.isNew = false;
          }

          commit('ADD_NEW_ORDER_ITEM_ADDITION', normalizeRelations(item, ["motorizedOrderItemAdditionCuts"]));

          if( isUpdate ) {
            dispatch('motorizedOrderItemAdditionCuts/updateOrderItemAdditionCuts', item.motorizedOrderItemAdditionCuts, {root: true});
          } else {
            dispatch('motorizedOrderItemAdditionCuts/applyOrderItemAdditionCuts', item.motorizedOrderItemAdditionCuts, {root: true});
          }
        });
      }
    },


    //
    // Apply/set orderItemAdditionSelectOptions
    //
    applyOrderItemAdditionSelectOptions({commit}, selectOptions) {
      commit('SET_ORDER_ITEM_ADDITION_SELECT_OPTIONS', selectOptions);
    },


    //
    // Apply a new orderItemAddition
    //
    applyNewOrderItemAddition({commit, dispatch}, orderItemAddition) {
      commit('ADD_NEW_ORDER_ITEM_ADDITION', orderItemAddition);
    },


    //
    // Create a new order item addition on the fly
    //  This creates an empty "shell" that is used to generate the input fields within the vue component
    //
    addNewOrderItemAddition({dispatch, rootState, rootGetters}) {
      var currentOrderProposal = rootGetters['orderProposal/currentOrderProposal'];

      axios.get(ENDPOINT_ORDER_ITEM_ADDITION_NEW)
      .then((response) => {
        response.data.data.orderItemAddition.isNew = true;

        dispatch('applyNewOrderItemAddition', response.data.data.orderItemAddition);
        //flash(response.data.status_type, response.data.status);
      })
      .catch(error => {
        flash(error.response.data.status_type, error.response.data.status);
      });
    },


    //
    // Add a new Order Item Addition Cut Record
    //
    addNewOrderItemAdditionCut({commit, state, dispatch, getters}, payload) {
      let orderItemAddition = getters.find(payload.orderItemAdditionId),
          newCut = payload.newCut;

      if( orderItemAddition.motorizedOrderItemAdditionCuts ) {
        orderItemAddition.motorizedOrderItemAdditionCuts.push(newCut);
      } else {
        orderItemAddition.motorizedOrderItemAdditionCuts = [];
        orderItemAddition.motorizedOrderItemAdditionCuts.push(newCut);
      }

      commit('ADD_NEW_ORDER_ITEM_ADDITION_CUT_TO_ORDER_ITEM_ADDITION', normalizeRelations(orderItemAddition, ["motorizedOrderItemAdditionCuts"]));
    },


    //
    // Save Order Item Addition Record
    //
    saveOrderItemAddition({commit, state, getters, dispatch, rootGetters}, id) {
      let currentOrderProposal = rootGetters['orderProposal/currentOrderProposal'],
          targetUnit = getters.find(id),
          submitData = {};

      targetUnit.orderItemAdditionCuts = targetUnit.motorizedOrderItemAdditionCuts;
      delete targetUnit.motorizedOrderItemAdditionCuts;

      if( targetUnit.isNew ) {
        var endpoint = ENDPOINT_ORDER_ITEM_ADDITION_CREATE + currentOrderProposal.shade_order_id;
      } else {
        var endpoint = ENDPOINT_ORDER_ITEM_ADDITION_UPDATE + targetUnit.id;
        submitData._method = 'PUT';
      }
      submitData.order_item_addition = targetUnit;

      return new Promise((resolve, reject) => {
        axios.post(endpoint, submitData)
        .then((response) => {
          let item = response.data.data.orderItemAddition;
              item.old_id = targetUnit.id;
              item.isNew = false;
              item.motorizedOrderItemAdditionCuts = ( item.hasOwnProperty('order_item_addition_cuts') ) ? item.order_item_addition_cuts : [];
                delete item.order_item_addition_cuts;

          dispatch('orderProposal/applyOrderProposal', response.data.data.orderProposal, {root: true});
          dispatch('orderProposal/applySelectOptions', response.data.data.selectOptions, {root: true});

          if( targetUnit.isNew ) {
            commit('UPDATE_NEW_ORDER_ITEM_ADDITION', normalizeRelations(item, ["motorizedOrderItemAdditionCuts"]));
            let oiaCutPayload = {
              orderItemAdditionId: id,
              oldOrderItemAdditionId: item.old_id,
              orderItemAdditionCuts: item.motorizedOrderItemAdditionCuts
            };
            dispatch('motorizedOrderItemAdditionCuts/updateNewOrderItemAdditionCuts', oiaCutPayload, {root: true});

          } else {
            commit('UPDATE_EXISTING_ORDER_ITEM_ADDITION', normalizeRelations(item, ["motorizedOrderItemAdditionCuts"]));
            dispatch('motorizedOrderItemAdditionCuts/updateOrderItemAdditionCuts', item.motorizedOrderItemAdditionCuts, {root: true});
          }

          dispatch('setFormErrors', {});
          flash(response.data.status_type, response.data.status);

          //resolve the promise
          resolve(response);
        })
        .catch(error => {
          if( error.response.data.errors ) {
            dispatch('setFormErrors', {id: id, errors: error.response.data.errors });
          }
          flash(error.response.data.status_type, error.response.data.status);

          //reject the promise
          reject(error.response.data);
        });
      });

    },


    //
    // Delete order item addition record
    //
    deleteOrderItemAddition({commit, state, getters, dispatch}, id) {
      let targetUnit = getters.find(id);

      if( targetUnit.isNew === true ) {
        //Just remove the record from state
        commit('DELETE_NEW_ORDER_ITEM_ADDITION', targetUnit);

      } else {
        axios.delete(ENDPOINT_ORDER_ITEM_ADDITION_DESTROY + targetUnit.id)
        .then((response) => {
          dispatch('orderProposal/applyOrderProposal', response.data.data.orderProposal, {root: true});
          dispatch('orderProposal/applySelectOptions', response.data.data.selectOptions, {root: true});

          if( response.data.data.orderItemAdditions.length ) {
            dispatch('motorizedOrderItemAddition/applyOrderItemAddition', {
                  orderItemAdditions: response.data.data.orderItemAdditions,
                  isUpdate: true
                },
                {
                  root: true
                }
            );
          }

          commit('DELETE_NEW_ORDER_ITEM_ADDITION', targetUnit);
          flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status);
        });
      }
    },


    //
    // Delete order item addition cut from orderItemAddition
    //  This action will delete a singular cut record from the corresponding order item addition record
    //
    deleteOrderItemAdditionCut({commit}, payload) {
      commit('DELETE_ORDER_ITEM_ADDITION_CUT', payload);
    },


    //
    // Set the form errors
    //
    setFormErrors({commit, dispatch}, payload) {
      if( Object.keys(payload).length <= 0 || !payload.errors.order_item_addition ) {
        commit('SET_ERRORS', []);
        return;
      }

      let errors = {};
      errors[payload.id] = payload.errors.order_item_addition;
      commit('SET_ERRORS', errors);
    },
  },


  //mutations
  mutations: {
    ADD_NEW_ORDER_ITEM_ADDITION(state, item) {
      Vue.set(state.byId, item.id, item);
      if (state.allIds.includes(item.id)) return;
      state.allIds.push(item.id);
    },

    ADD_NEW_ORDER_ITEM_ADDITION_CUT_TO_ORDER_ITEM_ADDITION(state, item) {
      Vue.set(state.byId, item.id, item);
      state.byId[item.id].motorizedOrderItemAdditionCuts = item.motorizedOrderItemAdditionCuts;

      if(state.allIds.includes(item.id)) return;
      state.allIds.push(item.id);
    },

    UPDATE_EXISTING_ORDER_ITEM_ADDITION(state, updatedItem) {
      var item = state.byId[updatedItem.id];

      if( typeof item == 'undefined' || item == null ) {
        item = state.byId[updatedItem.old_id];
      }

      item = _.merge(item, updatedItem);

      //Set state for shade line items byId
      Vue.set(state.byId, item.id, item);

      if(state.allIds.includes(item.id)) return;
      state.allIds.push(item.id);
    },

    UPDATE_NEW_ORDER_ITEM_ADDITION(state, updatedItem) {
      var item = state.byId[updatedItem.id];

      if( typeof item == 'undefined' || item == null ) {
        item = state.byId[updatedItem.old_id];
      }

      item = _.merge(item, updatedItem);

      //Set state for shade line items byId
      Vue.set(state.byId, item.id, item);

      if(state.allIds.includes(item.id)) return;
      state.allIds.push(item.id);

      if( item.old_id != item.id && state.allIds.includes(item.old_id) ) {
        var index = state.allIds.indexOf(item.old_id);
        state.allIds.splice(index, 1);
        Vue.delete(state.byId, item.old_id);
      }
    },

    SET_ORDER_ITEM_ADDITION_SELECT_OPTIONS(state, selectOptions) {
      state.orderItemAdditionSelectOptions = selectOptions;
    },

    DELETE_NEW_ORDER_ITEM_ADDITION(state, item) {
      var index = state.allIds.indexOf(item.id);
      state.allIds.splice(index, 1);
      Vue.delete(state.byId, item.id);
    },

    DELETE_ORDER_ITEM_ADDITION_CUT(state, payload) {
      state.byId[payload.orderItemAdditionId].motorizedOrderItemAdditionCuts.forEach(function(cutRecordId, index) {
        if( cutRecordId == payload.orderItemAdditionCutId ) {
          state.byId[payload.orderItemAdditionId].motorizedOrderItemAdditionCuts.splice(index, 1);
        }
      });
    },

    SET_ERRORS(state, payload) {
      state.errors = payload;
    },

    // vuex-map-fields plugin
    updateField
  }
}
