<script>
  export default {
    props: [
      'shadeUnit',
      'unitType',
      'fabricNumber',
      'disabled',
      'errors'
    ],

    mounted() {
      /*
        console.log('shadeFabric.vue - mounted');
        console.log(this);
        console.log(this.disabled);
        console.log('');
      */
    },

    computed: {
      isExteriorShade() {
        return this.$parent.isExteriorShade;
      },

      supportsDualFabric() {
        if( this.isExteriorShade || this.fabricNumber == 'fabric_2' && this.shadeUnit.product_model_dual_shade == 0 ) {
          this.shadeUnit.dual_fabric_id = null;
          this.shadeUnit.dual_fabric_openness_factor_id = null;
          this.shadeUnit.dual_fabric_attribute_id = null;
          return false;
        } else {
          return true;
        }
      },

      fabricSelectOptions() {
        if( this.$parent.isExteriorShade ) {
          return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.exteriorFabricOptions;
        } else {
          return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.interiorFabricOptions;
        }
      },

      getFabricSummary() {
        if( this.fabricNumber == 'fabric_1' ) {
          if( this.shadeUnit.fabric_attribute != null ) {
            return this.shadeUnit.fabric_attribute.fabric.name + ' ' + this.shadeUnit.fabric_attribute.color + ' ' + this.shadeUnit.fabric_attribute.fabric_openness_factor.name;
          } else {
            return null;
          }

        } else if ( this.fabricNumber == 'fabric_2' ) {
          if( this.shadeUnit.dual_fabric_attribute != null ) {
            return this.shadeUnit.dual_fabric_attribute.fabric.name + ' ' + this.shadeUnit.dual_fabric_attribute.color + ' ' + this.shadeUnit.dual_fabric_attribute.fabric_openness_factor.name;
          }

        } else {
          return null;
        }
      }
    },

    methods: {
      getInputName(id, field) {
        return this.$parent.getInputName(id, field);
      },

      getInputId(id, field) {
        return this.$parent.getInputId(id, field);
      },

      updateFabricOpennessOptions(event) {
        if( this.unitType == 'shadeConfigUnit' ) {
          var payload = { id: this.shadeUnit.id, fabric_number: this.fabricNumber, fabric_manufacturer_id: event.target.value };
          this.$store.dispatch('shadeUnitConfigs/setFabricOpennessFactorOptions', payload);
        } else {
          var payload = { id: this.shadeUnit.id, fabric_number: this.fabricNumber, fabric_manufacturer_id: event.target.value };
          this.$store.dispatch('shadeLineItems/setFabricOpennessFactorOptions', payload);
        }
      },

      updateFabricAttributeOptions(event) {
        if( this.unitType == 'shadeConfigUnit' ) {
          var payload = { id: this.shadeUnit.id, fabric_number: this.fabricNumber, fabric_openness_id: event.target.value };
          this.$store.dispatch('shadeUnitConfigs/setFabricColorOptions', payload);
        } else {
          var payload = { id: this.shadeUnit.id, fabric_number: this.fabricNumber, fabric_openness_id: event.target.value };
          this.$store.dispatch('shadeLineItems/setFabricColorOptions', payload);
        }
      }
    }
  }
</script>
