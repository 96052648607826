import { TOGGLE_SIDEBAR } from '../mutation-types'

export default {
  namespaced: true,


  // initial state
  state: {
    sidebarOpen: false
  },


  // getters
  getters: {
    sidebarOpen: state => state.sidebarOpen
  },


  // actions
  actions: {
    toggleSidebar ({ commit, state }) {
      commit(TOGGLE_SIDEBAR)
    }
  },


  // mutations
  mutations: {
    [TOGGLE_SIDEBAR] (state) {
      state.sidebarOpen = !state.sidebarOpen
    }
  }
}
