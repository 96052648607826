var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "status-notifications-list-container" },
    [
      _c(
        "draggable",
        {
          attrs: {
            draggable: ".status-notifications-list-item",
            filter: ".not-sortable",
            preventOnFilter: false,
            move: _vm.updateSortOrder,
          },
          model: {
            value: _vm.orderTypeStatusNotificationList,
            callback: function ($$v) {
              _vm.orderTypeStatusNotificationList = $$v
            },
            expression: "orderTypeStatusNotificationList",
          },
        },
        [
          _vm._l(
            _vm.orderTypeStatusNotificationList,
            function (statusNotification, index) {
              return [
                _c("order-type-status-notification", {
                  key: statusNotification.id,
                  attrs: {
                    "parent-project-order-type": _vm.parentProjectOrderType,
                    "parent-order-type-status-notification": statusNotification,
                    index: index,
                  },
                }),
              ]
            }
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "form-group col-sm-12" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-secondary",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.addStatusRecord($event)
                },
              },
            },
            [_vm._v("\n         Add Status\n      ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }