var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: _vm.modalId,
          attrs: {
            id: _vm.modalId,
            size: _vm.modalSize,
            title: _vm.modalTitle,
            "ok-title": _vm.confirmButtonText,
            "cancel-title": _vm.dismissButtonText,
            "no-close-on-backdrop": true,
            "hide-header-close": _vm.hideHeaderClose,
          },
          on: { ok: _vm.emitConfirm, cancel: _vm.emitCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "div",
                    [
                      _vm.hasDismissButton
                        ? _c("b-button", {
                            attrs: { variant: "secondary" },
                            domProps: {
                              innerHTML: _vm._s(_vm.dismissButtonText),
                            },
                            on: {
                              click: function ($event) {
                                return cancel()
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasConfirmButton
                        ? _c("b-button", {
                            attrs: {
                              variant: "primary",
                              disabled: _vm.isConfirmButtonDisabled,
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.confirmButtonText),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.submitForm()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "div",
            {},
            [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-sm-12 mb-3" }, [
                  _vm._v(
                    "\n          Please select the shade configurations you would like to copy into this estimate.\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.hasErrors
                ? [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
                        _c("div", { staticClass: "alert alert-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors[0]) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "estimator-config-list" },
                [
                  !_vm.estimatorConfigs.length
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "estimator-config-item expandable-block",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "expandable-body minimized" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "title-bar-wrapper" },
                                  [
                                    _c("div", { staticClass: "title-bar" }, [
                                      _c("div", { staticClass: "summary" }, [
                                        _c("p", { staticClass: "no-results" }, [
                                          _vm._v(_vm._s(_vm.noResultsText)),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    : [
                        _vm._l(
                          _vm.estimatorConfigs,
                          function (estimatorConfig) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "estimator-config-item expandable-block small mb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "expandable-body",
                                      class:
                                        _vm.isMaximized(estimatorConfig.id) ==
                                        true
                                          ? "maximized"
                                          : "minimized",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "title-bar-wrapper" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "title-bar",
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.toggleFullView(
                                                    $event,
                                                    estimatorConfig.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "summary" },
                                                [
                                                  _c("h2", [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            estimatorConfig.is_selected,
                                                          expression:
                                                            "estimatorConfig.is_selected",
                                                        },
                                                      ],
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        type: "checkbox",
                                                        id: _vm.getInputId(
                                                          estimatorConfig.id,
                                                          "is_selected"
                                                        ),
                                                        name: _vm.getInputName(
                                                          estimatorConfig.id,
                                                          "is_selected"
                                                        ),
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          estimatorConfig.is_selected
                                                        )
                                                          ? _vm._i(
                                                              estimatorConfig.is_selected,
                                                              null
                                                            ) > -1
                                                          : estimatorConfig.is_selected,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              estimatorConfig.is_selected,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  estimatorConfig,
                                                                  "is_selected",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  estimatorConfig,
                                                                  "is_selected",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              estimatorConfig,
                                                              "is_selected",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          estimatorConfig.name
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "actions" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.isMaximized(
                                                              estimatorConfig.id
                                                            ) == false,
                                                          expression:
                                                            "isMaximized(estimatorConfig.id) == false",
                                                        },
                                                      ],
                                                      staticClass: "link show",
                                                    },
                                                    [
                                                      _c("a", {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.toggleFullView(
                                                              $event,
                                                              estimatorConfig.id
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.isMaximized(
                                                              estimatorConfig.id
                                                            ) == true,
                                                          expression:
                                                            "isMaximized(estimatorConfig.id) == true",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "link minimize",
                                                    },
                                                    [
                                                      _c("a", {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.toggleFullView(
                                                              $event,
                                                              estimatorConfig.id
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "content-wrapper" },
                                        [
                                          _c(
                                            "transition",
                                            { attrs: { name: "slide" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.isMaximized(
                                                        estimatorConfig.id
                                                      ),
                                                      expression:
                                                        "isMaximized(estimatorConfig.id)",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "transition-container",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.isMaximized,
                                                          expression:
                                                            "isMaximized",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "content-block",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-row field-section-head",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-sm-12 section-title",
                                                            },
                                                            [
                                                              _c(
                                                                "h3",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 pb-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Shade Settings"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-row field-section",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-6 col-md-4",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Drive Type:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-value capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        estimatorConfig.drive_type
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-6 col-md-4",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Mount Type:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-value capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        estimatorConfig.mount_type
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-6 col-md-4",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Hardware Color:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-value capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        estimatorConfig.hardware_color
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-6 col-md-4",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Hold Down:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-value capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        estimatorConfig.hold_down ==
                                                                          1
                                                                          ? "Yes"
                                                                          : "No"
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-row field-section-head",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-sm-12 section-title",
                                                            },
                                                            [
                                                              _c(
                                                                "h3",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 pb-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Configuration"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-row field-section",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-6 col-md-4",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Roll Type:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        estimatorConfig.roll_type
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-6 col-md-4",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Dual Shade:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        estimatorConfig.product_model_dual_shade ==
                                                                          1
                                                                          ? "Yes"
                                                                          : "No"
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-6 col-md-4",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Guide Cable:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        estimatorConfig.product_model_guided_cable ==
                                                                          ""
                                                                          ? "None"
                                                                          : estimatorConfig.product_model_guided_cable
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-6 col-md-4",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Bottom Up:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        estimatorConfig.product_model_bottom_up ==
                                                                          null
                                                                          ? "No"
                                                                          : estimatorConfig.product_model_bottom_up
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-6 col-md-4",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Control Mech:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        estimatorConfig.product_model_drive_mechanism
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      estimatorConfig.product_model_drive_mechanism ==
                                                      "motor"
                                                        ? [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-row field-section-head",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-sm-12 section-title",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h3",
                                                                      {
                                                                        staticClass:
                                                                          "mb-0 pb-1",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Motor Settings"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-row field-section",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-6 col-md-4",
                                                                  },
                                                                  [
                                                                    _c("p", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "field-name",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Motor Mfg:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "capitalize-first",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.getMotorManufacturerNameSummary(
                                                                                estimatorConfig.id
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-6 col-md-4",
                                                                  },
                                                                  [
                                                                    _c("p", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "field-name",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Motor Type:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "capitalize-first",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              estimatorConfig.motor_type
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-6 col-md-4",
                                                                  },
                                                                  [
                                                                    _c("p", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "field-name",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Motor Power:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "capitalize-first",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              estimatorConfig.motor_power
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-6 col-md-4",
                                                                  },
                                                                  [
                                                                    _c("p", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "field-name",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Motor Sound:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "capitalize-first",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              estimatorConfig.motor_sound
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-row field-section-head",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-sm-12 section-title",
                                                            },
                                                            [
                                                              _c(
                                                                "h3",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 pb-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Fabric"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-row field-section",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-12",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Fabric 1:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.getFabricSummary(
                                                                          estimatorConfig.id
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          estimatorConfig.dual_fabric_attribute_id
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-12",
                                                                },
                                                                [
                                                                  _c("p", [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "field-name",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Fabric 2:"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "capitalize-first",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.getDualFabricSummary(
                                                                              estimatorConfig.id
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-row field-section-head",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-sm-12 section-title",
                                                            },
                                                            [
                                                              _c(
                                                                "h3",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 pb-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Hembar"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-row field-section",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-12",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Hembar Style:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        estimatorConfig.hembar_style
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-6 col-md-4",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Hembar Color:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        estimatorConfig.hembar_color
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          estimatorConfig.hembar_endcap_color
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-6 col-md-4",
                                                                },
                                                                [
                                                                  _c("p", [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "field-name",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Endcap Color:"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "capitalize-first",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            estimatorConfig.hembar_endcap_color
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-row field-section-head",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-sm-12 section-title",
                                                            },
                                                            [
                                                              _c(
                                                                "h3",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 pb-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Fascia"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-row field-section",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-12",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Fascia Type:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        estimatorConfig.fascia_type
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          estimatorConfig.fascia_type !=
                                                            "No Fascia" &&
                                                          estimatorConfig.fascia_type !=
                                                            "No Fascia w/Bracket Covers"
                                                            ? [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-6 col-md-4",
                                                                  },
                                                                  [
                                                                    _c("p", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "field-name",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Back Fascia:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "capitalize-first",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              estimatorConfig.back_fascia ==
                                                                                1
                                                                                ? "Yes"
                                                                                : "No"
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-6 col-md-4",
                                                                  },
                                                                  [
                                                                    _c("p", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "field-name",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Pref. Fascia Size:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "capitalize-first",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              estimatorConfig.preferred_fascia_size
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-12",
                                                                  },
                                                                  [
                                                                    _c("p", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "field-name",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Fascia Color:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "capitalize-first",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.getFasciaColorSummary(
                                                                                estimatorConfig.id
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-row field-section-head",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-sm-12 section-title",
                                                            },
                                                            [
                                                              _c(
                                                                "h3",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 pb-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Side Channel"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-row field-section",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-12",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Side Channel:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        estimatorConfig.has_side_channel ==
                                                                          "1"
                                                                          ? "Yes"
                                                                          : "No"
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          estimatorConfig.has_side_channel
                                                            ? [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-6 col-md-4",
                                                                  },
                                                                  [
                                                                    _c("p", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "field-name",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Side Channel Color:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "capitalize-first",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.getSideChannelColorSummary(
                                                                                estimatorConfig.id
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-row field-section-head",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-sm-12 section-title",
                                                            },
                                                            [
                                                              _c(
                                                                "h3",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 pb-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Trim Information"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-row field-section",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-6 col-md-4",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "field-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Has Trim:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "capitalize-first",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        estimatorConfig.has_trim ==
                                                                          "1"
                                                                          ? "Yes"
                                                                          : "No"
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          estimatorConfig.has_trim
                                                            ? [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-6 col-md-4",
                                                                  },
                                                                  [
                                                                    _c("p", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "field-name",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Trim Type:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "capitalize-first",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              estimatorConfig.trim_type
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-12",
                                                                  },
                                                                  [
                                                                    _c("p", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "field-name",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Trim Color:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "capitalize-first",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.getTrimColorSummary(
                                                                                estimatorConfig.id
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _vm.hasPocket
                                                                  ? [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "form-group col-6 col-md-4",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "p",
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "field-name",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Pocket Height:"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "capitalize-first",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      estimatorConfig.pocket_height
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "form-group col-6 col-md-8",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "p",
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "field-name",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Pocket Depth:"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "capitalize-first",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      estimatorConfig.pocket_depth
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-6 col-md-4",
                                                                  },
                                                                  [
                                                                    _c("p", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "field-name",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Include Closure:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "capitalize-first",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              estimatorConfig.pocket_include_closure ==
                                                                                1
                                                                                ? "Yes"
                                                                                : "No"
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                estimatorConfig.pocket_include_closure
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group col-6 col-md-8",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "field-name",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Closure Size:"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "capitalize-first",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    estimatorConfig.pocket_closure_size
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          }
                        ),
                      ],
                ],
                2
              ),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }