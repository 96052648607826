var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shadeLineItem.isNew === false
    ? _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.sort_order_number_textual))]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.quantity))]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.actual_width_formatted))]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.actual_length_formatted))]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.product_model_name))]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.square_feet))]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.square_yards))]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.unit_hardware_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.unit_chain_cost_formatted))]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.unit_aluminum_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.total_aluminum_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.unit_motorized_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.total_motorized_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.unit_fabric_1_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.unit_fabric_2_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.total_fabric_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.unit_material_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.total_material_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.shade_mfg_unit_hours))]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.shade_mfg_labor_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.trim_mfg_unit_hours))]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.trim_mfg_labor_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.side_channel_mfg_unit_hours)),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(_vm.shadeLineItem.side_channel_unit_labor_cost_formatted)
          ),
        ]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.mfg_unit_hours))]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.mfg_unit_labor_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.total_mfg_hours))]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.total_mfg_labor_cost_formatted)),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }