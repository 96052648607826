<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'currentOrderProposal'
    ],

    mixins: [proposalMixin],

    components: {},

    mounted() {
      /*
        console.log('LaborSummary.vue - Mounted');
        console.log(this.orderProposal);
        console.log('');
      */
    },

    computed: {
      orderProposal() {
        return this.currentOrderProposal;
      }
    },

    methods: {

    }
  };
</script>

<template>
  <table class="table">
    <thead>
      <tr>
        <th></th>
        <th>Hours</th>
        <th>Cost ($)</th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td>Field Measure</td>
        <td>{{ orderProposal.fm_labor_hours }}</td>
        <td>{{ orderProposal.labor_summary_field_measure_formatted }}</td>
      </tr>

      <tr>
        <td>Installation</td>
        <td>{{ orderProposal.install_labor_hours }}</td>
        <td>{{ orderProposal.labor_summary_install_formatted }}</td>
      </tr>

      <tr>
        <td>Coordination</td>
        <td>{{ orderProposal.labor_summary_coordination_hours }}</td>
        <td>{{ orderProposal.labor_summary_coordination_formatted }}</td>
      </tr>

      <tr>
        <td>Travel</td>
        <td>{{ orderProposal.travel_total_hours }}</td>
        <td>{{ orderProposal.labor_summary_travel_formatted }}</td>
      </tr>
    </tbody>
  </table>
</template>
