<script>
  export default {
    props: [
      'shadeUnit',
      'unitType',
      'containerClass',
      'errors',
      'disabled'
    ],


    mounted() {
      /*
        console.log('shade back fascia - mounted');
        console.log(this.shadeUnit);
        console.log('');
      */
    },

    computed: {
      parentContainerClass() {
        return this.containerClass;
      },

      hasFascia() {
        if( this.unitType == 'shadeConfigUnit' ) {
          var hasFascia = this.$store.getters['shadeUnitConfigs/hasFascia'](this.shadeUnit.id);
        } else {
          var hasFascia = this.$store.getters['shadeLineItems/hasFascia'](this.shadeUnit.id);
        }

        if( hasFascia == false ) {
          this.shadeUnit.back_fascia = 0;
        }

        return hasFascia;
      },

      backFasciaSelectOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.backFasciaOptions;
      },

      canHaveBackFascia() {
        let canHaveBackFascia = true;
        let backFasciaAvailableForFasciaTypes = ['Fascia', 'Fabric Wrapped Fascia'];
        let canHaveBackFasciaForFasciaTypes = backFasciaAvailableForFasciaTypes.includes(this.shadeUnit.fascia_type);
        let backFasciaAvailableForMountTypes = ['top', 'sill'];
        let canHaveBackFasciaFormMountTypes = backFasciaAvailableForMountTypes.includes(this.shadeUnit.mount_type);

        if( canHaveBackFasciaForFasciaTypes == false || canHaveBackFasciaFormMountTypes == false ) {
          this.shadeUnit.back_fascia = 0;
          canHaveBackFascia = false;
        }

        return canHaveBackFascia;
      },

      hasErrors() {
        if( typeof errors.back_fascia != 'undefined' ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return this.$parent.getInputName(id, field);
      },

      getInputId(id, field) {
        return this.$parent.getInputId(id, field);
      },
    }
  }
</script>
