<script>
  export default {
    props: [
      'parentPart',
      'partDependencyIndex'
    ],

    data() {
      return {
        partDependency: {
          id: null,
          parent_part_id: null,
          child_part_id: null,
          part: {
            name: null,
            part_number: null,
            has_colors: false,
            part_type: {
              name: null
            }
          },
          isNew: false
        },

        isMaximized: false
      }
    },

    mounted() {
      this.partDependency = { ...this.partDependency, ...this.parentPart };

      if( this.isNew ) {
        this.isMaximized = true;
      }
    },

    computed: {
      endpointDestroy() {
        return '/admin/settings/products/parts/part-dependency/' + this.partDependency.id;
      },

      isNew() {
        return ( this.partDependency.isNew ) ? true : false;
      },

      partName() {
        return ( this.isNew == true && this.partDependency.part.name == null) ? 'New Part' : this.partDependency.part.name;
      },

      partType() {
        return ( this.isNew == true && this.partDependency.part.part_type.name == null) ? '' : this.partDependency.part.part_type.name;
      },

      partNumber() {
        if( this.partDependency.part.has_colors == true ) {
          var colorPartIdArr = this.partDependency.part.part_colors.map(partColor => partColor.color + ': ' + partColor.part_number);
          return colorPartIdArr.join(", ");

        } else {
          return this.partDependency.part.part_number;
        }
      },

      selectOptions() {
        return this.$parent.$parent.selectOptions;
      },

      errors() {
        if( this.$parent.$parent.errors.hasOwnProperty( this.partDependency.id ) ) {
          return this.$parent.$parent.errors[this.partDependency.id];
        }
        return [];
      },

      hasErrors() {
        if( Object.keys(this.errors).length ) {
          this.isMaximized = true;
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'part_dependency[' + id + '][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      toggleFullView(event) {
        event.preventDefault();
        this.isMaximized = ( this.isMaximized == true ) ? false : true;
      },

      setPartDependency(event) {
        var $selectedOption = $(event.target).find(':selected');
        var selectedPart = {};
        var that = this;

        if( event.target.value != '' ) {
          loop1:
          for(var index in this.selectOptions) {
            for(var partIndex in this.selectOptions[index].parts) {
              if( this.selectOptions[index].parts[partIndex].id == event.target.value ) {
                selectedPart = this.selectOptions[index].parts[partIndex];
                break loop1;
              }
            }
          }
        }

        if( Object.keys(selectedPart).length > 0 ) {
          this.partDependency.part = selectedPart;
          this.partDependency.child_part_id = selectedPart.id;
        }
        this.$parent.$parent.partDependencyList.splice(this.partDependencyIndex, 1, this.partDependency);
      },

      destroy() {
        if( this.isNew === true ) {
          this.$parent.$parent.deleteDependencyOption( this.partDependency );

        } else {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            this.$parent.$parent.deleteDependencyOption( this.partDependency );
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      }
    }
  };
</script>

<template>
  <div
    class="part-dependency-item expandable-block small"
    v-bind:id="'sort_record_id_' + partDependency.id"
    v-bind:data-sorted-record-id="partDependency.id">

    <div class="sort-handle"></div>
    <div
      class="expandable-body"
      v-bind:class="(isMaximized == true) ? 'maximized' : 'minimized'">

      <div class="title-bar-wrapper"><!-- start: title-bar-wrapper -->
        <div class="title-bar" @dblclick="toggleFullView($event)">
          <div class="summary">
            <h2>
              {{ partName }} <span class="hint" v-if="partType">({{ partType }})</span>
            </h2>
            <span class="setting-text" v-if="partNumber">Part Number: {{ partNumber }}</span>
          </div>
          <div class="actions">
            <div class="link edit" v-show="isMaximized == false">
              <a @click="toggleFullView($event)"></a>
            </div>
            <div class="link minimize" v-show="isMaximized == true">
              <a @click="toggleFullView($event)"></a>
            </div>

            <div class="link delete">
              <a @click="destroy()"></a>
            </div>
          </div>
        </div><!-- end: title-bar -->
      </div><!-- end: title-bar-wrapper -->

      <div class="content-wrapper"><!-- start: content-wrapper -->
        <transition name="slide">
          <div class="transition-container" v-show="isMaximized">
            <div class="content-block" v-show="isMaximized">
              <input type="hidden" :name="getInputName(partDependency.id, 'id')" v-model="partDependency.id">

              <div class="form-row">
                <div class="form-group col-sm-12 col-md-4">
                  <label
                    v-bind:for="getInputId(partDependency.id, 'child_part_id')"
                    data-toggle="tooltip"
                    data-html="true"
                    data-original-title="Select the dependent parts.">
                    Parts
                  </label>

                  <select
                    class="form-control"
                    v-bind:class="errors['child_part_id'] ? 'is-invalid' : ''"
                    v-bind:name="getInputName(partDependency.id, 'child_part_id')"
                    v-bind:id="getInputId(partDependency.id, 'child_part_id')"
                    v-model="partDependency.child_part_id"
                    @change="setPartDependency($event)">

                      <option :value="null">--Choose One--</option>
                      <optgroup v-for="partType in selectOptions" :label="partType.name">
                        <template v-for="part in partType.parts">
                          <option v-if="part.isSelected != true || partDependency.child_part_id == part.id" :value="part.id" :data-part-name="part.name" :data-part-type="partType.name">
                            {{ part.name }}
                          </option>
                        </template>
                      </optgroup>
                  </select>

                  <span class="invalid-feedback" role="alert" v-if="errors['child_part_id']">
                    {{ errors['child_part_id'][0] }}
                  </span>
                </div>
              </div>
            </div><!--end: content-block -->
          </div><!-- end: transition-container -->
        </transition>

      </div><!-- end: content-wrapper -->
    </div><!-- end: part-dependency-item -->
  </div>
</template>
