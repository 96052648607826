<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'currentOrderProposal',
    ],

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('FoodAndLodging.vue - Mounted');
        console.log(this.orderProposal);
        console.log('');
      */
    },

    computed: {
      orderProposal() {
        return this.currentOrderProposal;
      },

      errors() {
        return [];
      },
    },

    methods: {
      getInputName(field) {
        return this.$parent.getOrderProposalInputName(field);
      },

      getInputId(field) {
        return this.$parent.getOrderProposalInputId(field);
      },
    }
  };
</script>

<template>
  <div class="form-row estimate-widget food-and-lodging-expenses">
    <div class="form-group col-sm-12 mb-0">
      <h2>Food & Lodging Expense</h2>
    </div>

    <div class="form-group col-sm-12 table-responsive">
      <table class="form">
        <thead>
          <tr>
            <th></th>
            <th colspan="3">Lodging</th>
            <th colspan="3">Food</th>
            <th></th>
          </tr>
          <tr>
            <th># Techs</th>
            <th># Nights</th>
            <th>Nightly Rate ($)</th>
            <th>Total ($)</th>
            <th># Days</th>
            <th>Per Diem Rate ($)</th>
            <th>Total ($)</th>
            <th>Total Cost ($)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{{ orderProposal.install_techs }}</td>
            <td>
              <input type="text"
                     class="form-control min-width-field"
                     v-bind:class="errors['install_nights'] ? 'is-invalid' : ''"
                     placeholder="1"
                     v-bind:name="getInputName('install_nights')"
                     v-bind:id="getInputId('install_nights')"
                     v-model="orderProposal.install_nights"
                     v-proposal-field-directive>
            </td>
            <td>
              <input type="text"
                     class="form-control min-width-field"
                     v-bind:class="errors['install_overnight_lodging_rate'] ? 'is-invalid' : ''"
                     placeholder="1"
                     v-bind:name="getInputName('install_overnight_lodging_rate')"
                     v-bind:id="getInputId('install_overnight_lodging_rate')"
                     v-model="orderProposal.install_overnight_lodging_rate"
                     v-proposal-field-directive>
            </td>
            <td>{{ orderProposal.fl_lodging_total_formatted }}</td>
            <td>
              <input type="text"
                     class="form-control min-width-field"
                     v-bind:class="errors['install_days'] ? 'is-invalid' : ''"
                     placeholder="1"
                     v-bind:name="getInputName('install_days')"
                     v-bind:id="getInputId('install_days')"
                     v-model="orderProposal.install_days"
                     v-proposal-field-directive>
            </td>
            <td>{{ orderProposal.per_diem_food_rate_formatted }}</td>
            <td>{{ orderProposal.fl_food_total_formatted }}</td>
            <td>{{ orderProposal.fl_total_formatted }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
