<script>
  import { mapFields, mapMultiRowFields } from 'vuex-map-fields';
  import { proposalMixin } from '../mixins/proposalMixin';
  import { shadeOrderMixin } from '../mixins/shadeOrderMixin';

  import FieldMeasureLabor from './fieldMeasureLabor/FieldMeasureLabor';
  import DistanceToJobsite from './distanceToJobsite/DistanceToJobsite';
  import TravelExpense from './travelExpense/TravelExpense';
  import FoodAndLodging from './foodAndLodging/FoodAndLodging';
  import InstallationLabor from './installationLabor/InstallationLabor';
  import SalesTax from './salesTax/SalesTax';
  import Margin from './costAdjustmentRate/Margin';
  import UnitCostAndPrice from './unitCost/UnitCostAndPrice';
  import CostSummary from './costSummary/CostSummary';
  import LaborSummary from './laborSummary/LaborSummary';
  import EstimateSummary from './estimateSummary/EstimateSummary';
  import MarkupCalculation from './markupCalculation/MarkupCalculation';
  import ProfitCheck from './profitCheck/ProfitCheck';
  import ProposalCoordinationLabors from './coordinationLabor/ProposalCoordinationLabors';
  import ProposalMiscExpenses from './miscExpense/ProposalMiscExpenses';
  import OrderItemAdditions from './additionalPartsNonmotorized/OrderItemAdditions';
  import MotorizedOrderItemAdditions from './additionalPartsMotorized/MotorizedOrderItemAdditions';
  import Takeoff from './takeoffShadeLineItems/Takeoff';
  import ProposalProjectExpenses from './projectExpense/ProposalProjectExpenses';
  import CustomColorEstimate from './customColor/CustomColorEstimate';
  import NotificationMessage from '../../notifications/Flash';
  import ModalConfirm from '../../notifications/ModalConfirm.vue';
  import ShadeOrderProjectNotifications from '../estimate/ShadeOrderProjectNotifications';

  export default {
    props: [
      'masterOrderProposal',
      'masterShadeOrder',
      'masterProject',
      'masterProjectCompany',
      'masterShadeGroups',
      'masterOrderProposalSelectOptions',
      'masterPermissionsLock'
    ],

    mixins: [proposalMixin, shadeOrderMixin],

    components: {
      FieldMeasureLabor,
      DistanceToJobsite,
      TravelExpense,
      FoodAndLodging,
      InstallationLabor,
      SalesTax,
      Margin,
      UnitCostAndPrice,
      CostSummary,
      LaborSummary,
      EstimateSummary,
      MarkupCalculation,
      ProfitCheck,
      ProposalCoordinationLabors,
      ProposalMiscExpenses,
      OrderItemAdditions,
      MotorizedOrderItemAdditions,
      Takeoff,
      ProposalProjectExpenses,
      CustomColorEstimate,
      NotificationMessage,
      ShadeOrderProjectNotifications,
      ModalConfirm
    },

    data() {
      return {
        projectCompany: {
          name: null,
          location: {
            full_address: "",
          }
        },
        showEditLanguageDeclarationSelect: false,
        showEditProductQuotedAsSelect: false,
        showEditProductText: false,
        showEditFabricText: false,
        showEditHardwareColorText: false,
        showEditFasciaText: false,
        showProductSummaryTable: false,
        fileUploadStatus: null,
      }
    },

    created() {
      //load orderProposal Vuex
      this.$store.dispatch('orderProposal/initOrderProposal', {
          selectOptions: this.masterOrderProposalSelectOptions,
          orderProposal: this.masterOrderProposal,
          userCanEdit: this.masterPermissionsLock
        });

      this.$store.dispatch('shadeOrder/applyShadeOrder', {
          shadeOrder: this.masterShadeOrder,
          userCanEdit: this.masterUserCanEditEstimate
        });

      //load the project
      this.$store.dispatch('orderProposalProject/applyProject', this.masterProject);

      //load ShadeGroups into Vuex
      this.$store.dispatch('shadeGroups/applyShadeGroups', this.masterShadeGroups);
    },

    mounted() {
      this.projectCompany = {...this.projectCompany, ...this.masterProjectCompany};
    },

    computed: {
      ...mapMultiRowFields('orderProposal', ['orderProposals']),
      ...mapMultiRowFields('orderProposalProject', ['projects']),
      ...mapFields('shadeOrder', ['shadeOrder']),

      orderProposal() {
        return this.orderProposals[0];
      },

      project() {
        return this.projects[0];
      },

      shadeOrderPoNumber: {
        get() {
          return this.$store.state.shadeOrder.shadeOrder.po_number;
        },
        set(value) {
          this.$store.commit('shadeOrder/updateField', {fieldName: 'po_number', value: value});
        }
      },

      shadeOrderGblProjectOrderTypeId: {
        get() {
          return this.$store.state.shadeOrder.shadeOrder.gbl_project_order_type_id;
        },
        set(value) {
          this.$store.commit('shadeOrder/updateField', {fieldName: 'gbl_project_order_type_id', value: value});
        }
      },

      shadeOrderGblProjectOrderType() {
        let orderTypeOptions = this.selectOptions.shadeOrderGblProjectOrderTypeOptions,
            currentOrderType = orderTypeOptions.find(option => option.id == this.shadeOrder.gbl_project_order_type_id);
        return ( currentOrderType ) ? currentOrderType.name : '';
      },

      selectOptions() {
        return this.$store.state.orderProposal.selectOptions;
      },

      isAccepted() {
        return ( this.orderProposal.is_accepted == true ) ? true : false;
      },

      isCancelled() {
        return this.shadeOrder.is_cancelled;
      },

      hasPermissionsLock() {
        return this.masterPermissionsLock;
      },

      canEditProposalLanguage() {
        if( this.hasPermissionsLock == true || this.isLockedForEdits == true ) {
          return false;
        }
        return true;
      },

      isLockedForEdits() {
        if( this.isAccepted === true ) {
          return true;
        } else {
          return ( this.orderProposal.is_locked_for_edits == true ) ? true : false;
        }
      },

      canAddSignature() {
        if( this.isAccepted === false && this.isLockedForEdits === true ) {
          return true;
        }
        return false;
      },

      projectNotifications() {
        let notificationList = [];
        let shadeOrderNotifications = this.$store.getters['shadeOrder/projectNotifications'];
        notificationList = notificationList.concat(shadeOrderNotifications);
        return notificationList;
      },

      isOrderTrimCutRequired() {
        return ( this.orderProposal.is_order_trim_cut_required == true ) ? true : false;
      },

      hasOrderTrimCuts() {
        return ( this.orderProposal.has_order_trim_cuts == true ) ? true : false;
      },

      errors() {
        return this.$store.state.orderProposal.errors;
      },

      shadeOrderErrors() {
        return this.$store.state.shadeOrder.shadeOrderErrors;
      },

      hasErrors() {
        if( (Object.keys(this.errors).length > 0) || (Object.keys(this.shadeOrderErrors).length > 0) ) {
          return true;
        }
        return false;
      },

      editProductTextLinkText() {
        return ( this.showEditProductText == false ) ? '(Edit)' : '(Done)';
      },

      editFabricTextLinkText() {
        return ( this.showEditFabricText == false ) ? '(Edit)' : '(Done)';
      },

      editHardwareColorTextLinkText() {
        return ( this.showEditHardwareColorText == false ) ? '(Edit)' : '(Done)';
      },

      editFasciaTextLinkText() {
        return ( this.showEditFasciaText == false ) ? '(Edit)' : '(Done)';
      },

      productSummaryToggleLinkText() {
        return ( this.showProductSummaryTable == true ) ? '(Hide)' : '(Show)';
      },

      modalLockHtmlId() {
        return '_lock_' + this.orderProposal.id;
      },

      modalLockTitleText() {
        return 'Are you sure?';
      },

      modalLockBodyText() {
        if( this.orderProposal.is_locked_for_edits ) {
          return 'This action will unlock the proposal, allowing for additional changes to be made to the underlying estimate.<br><br>Are you sure you would like to proceed?';
        } else {
          return 'This action will lock the proposal, prohibiting any additional changes to the underlying estimate. This should only be when you are ready to issue the proposal to the customer.<br><br>Are you sure you would like to proceed?';
        }
      },

      modalLockCancelButtonAction() {
        if( this.orderProposal.is_locked_for_edits ) {
          return 'cancelModalAction';
        } else {
          return 'cancelModalAction';
        }
      },

      modalLockConfirmButtonAction() {
        if( this.orderProposal.is_locked_for_edits ) {
          return 'unlockProposalAction';
        } else {
          return 'lockProposalAction';
        }
      },

      modalPreviewHtmlId() {
        return '_preview_' + this.orderProposal.id;
      },

      modalPreviewTitleText() {
        return 'Proposal Preview'
      },

      modalPreviewBodyText() {
        var iframeSrc = "/projects/proposal/download/" + this.orderProposal.id + '?preview=y'
        return '<iframe src="' + iframeSrc + '" width="100%" height="600"></iframe>';
      },

      modalPreviewCancelButtonAction() {
        return 'cancelModalAction';
      },

      canSubmitOrder() {
        if( this.isLockedForEdits == false ) {
          return false;
        }

        if( this.isOrderTrimCutRequired == true && this.hasOrderTrimCuts == false ) {
          return false;
        }

        if( this.isLockedForEdits != false && this.orderProposal.customer_signature_printed_name != null
            && this.orderProposal.customer_signature_date != null ) {
          return true;
        }

        return false;
      },

      //
      // For the File Upload Component
      //
      hasSignedProposalAttachment() {
        if( this.orderProposal.signed_proposal_attachment_path == null ) {
          return false;
        }
        return true;
      },

      isFileUploadSaving() {
        return this.fileUploadStatus === 'saving';
      },

      hasFileUploadError() {
        this.fileUploadStatus = null;

        if( this.errors.hasOwnProperty('signed_proposal_attachment_path') ) {
          return true;
        }

        return false;
      }
    },

    methods: {
      getOrderProposalInputName(field) {
        return 'order_proposal[' + field + ']';
      },

      getOrderProposalInputId(field) {
        var inputName = this.getOrderProposalInputName(field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      editLanguageDeclarationSelect(event) {
        event.preventDefault();
        this.showEditLanguageDeclarationSelect = true;
      },

      editProductQuotedAsSelect(event) {
        event.preventDefault();
        this.showEditProductQuotedAsSelect = true;
      },

      editProductText(event) {
        event.preventDefault();
        this.showEditProductText = (this.showEditProductText == false) ? true : false;
      },

      editFabricText(event) {
        event.preventDefault();
        this.showEditFabricText = (this.showEditFabricText == false) ? true : false;
      },

      editHardwareColorText(event) {
        event.preventDefault();
        this.showEditHardwareColorText = (this.showEditHardwareColorText == false) ? true : false;
      },

      editFasciaText(event) {
        event.preventDefault();
        this.showEditFasciaText = (this.showEditFasciaText == false) ? true : false;
      },

      doneEditing(event, editingOption) {
        event.preventDefault();
        this[editingOption] = false;
      },

      toggleProductSummaryTable(event) {
        event.preventDefault();
        this.showProductSummaryTable = ( this.showProductSummaryTable == true ) ? false : true;
      },

      saveProposal(event) {
        event.preventDefault();
        this.$store.dispatch('orderProposal/updateOrderProposal');
      },

      //
      // Lock/Unlock Proposal Methods
      //
      lockProposalClick(event) {
        event.preventDefault();
        this.$bvModal.show('modal-' + this.modalLockHtmlId);
      },

      lockProposalAction() {
        this.$store.dispatch('orderProposal/lockOrderProposal');
      },

      unlockProposalClick(event) {
        event.preventDefault();
        this.$bvModal.show('modal-' + this.modalLockHtmlId);
      },

      unlockProposalAction() {
        this.$store.dispatch('orderProposal/unlockOrderProposal');
      },

      //
      // Trim Cut Button Event
      //
      applyTrimCuts(event) {
        event.preventDefault();
        location.href = '/projects/order/trim-cuts/' + this.orderProposal.shade_order_id;
      },

      //
      // Accept Order Proposal
      //
      acceptOrderProposal(event) {
        event.preventDefault();
        this.$store.dispatch('orderProposal/acceptOrderProposal');
      },

      //
      // Modal Action Handler
      //
      handleFunctionCall(functionName) {
        this[functionName]();
      },

      cancelModalAction() {
        return false;
      },

      //
      // Preview/Download Methods
      //
      previewProposalClick(event) {
        event.preventDefault();
        this.$bvModal.show('modal-' + this.modalPreviewHtmlId);
      },

      //
      // File Upload Methods
      //
      triggerFileUpload(event) {
        this.fileUploadStatus = 'saving';

        const formData = new FormData();
        formData.append('order_proposal[signed_proposal_attachment_path]', event.target.files[0], event.target.files[0].name);

        this.$store.dispatch('orderProposal/uploadProposalAttachment', { formData: formData });
      },

      downloadSignedProposal(event) {
        event.preventDefault();
        this.$store.dispatch('orderProposal/downloadProposalAttachment');
      },

      deleteSignedProposal(event) {
        event.preventDefault();
        this.$store.dispatch('orderProposal/deleteProposalAttachment');
      }
    },

    watch: {
      'orderProposal.include_product_summary_in_pdf': function (newVal, oldVal) {
        if( typeof oldVal != 'undefined' && newVal != oldVal ) {
          this.$store.dispatch('orderProposal/updateOrderProposal');
        }
      },

      'orderProposal.show_custom_color_in_pdf': function (newVal, oldVal) {
        if( typeof oldVal != 'undefined' && newVal != oldVal ) {
          this.$store.dispatch('orderProposal/updateOrderProposal');
        }
      },
    }
  }
</script>
