var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.modalIdAttr,
            size: _vm.modalSizeAttr,
            title: _vm.modalTitleText,
            "ok-title": _vm.confirmButtonText,
            "cancel-title": _vm.dismissButtonText,
            "no-close-on-backdrop": true,
            "hide-header-close": _vm.hideHeaderCloseButton,
          },
          on: { ok: _vm.emitConfirm, cancel: _vm.emitCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "div",
                    [
                      _vm.hasDismissButton
                        ? _c("b-button", {
                            attrs: { variant: "secondary" },
                            domProps: {
                              innerHTML: _vm._s(_vm.dismissButtonText),
                            },
                            on: {
                              click: function ($event) {
                                return cancel()
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasConfirmButton
                        ? _c("b-button", {
                            attrs: { variant: "primary" },
                            domProps: {
                              innerHTML: _vm._s(_vm.confirmButtonText),
                            },
                            on: {
                              click: function ($event) {
                                return ok()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("p", {
            staticClass: "my-4",
            domProps: { innerHTML: _vm._s(_vm.modalBodyText) },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }