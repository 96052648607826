//import { TOGGLE_SIDEBAR } from '../mutation-types'

import { mapGetters, mapActions } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
//import { ENDPOINT_ORDER_PROPOSAL_UPDATE } from '../../endpoints'

export default {
  namespaced: true,


  //state
  state: {
    projects: {},

    errors: [],

    selectOptions: [],
  },


  //getters
  getters: {
    projects: state => state.projects,
    currentProject: state => state.projects[0],
    errors: state => state.errors,
    selectOptions: state => state.selectOptions,

    // vuex-map-fields plugin
    getField,
  },


  //actions
  actions: {
    applyProject({commit, dispatch}, project) {
      commit('SET_ORDER_PROPOSAL_PROJECT', [project]);
    },

  },


  //mutations
  mutations: {
    SET_ORDER_PROPOSAL_PROJECT(state, projects) {
      state.projects = projects;
    },

    // vuex-map-fields plugin
    updateField,
  }
}
