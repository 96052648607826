export const shadeUnitConfigMixin = {

  methods: {
    save(event) {
      event.preventDefault();
      this.checkForChanges(event);
    }
  },

  directives: {
    clickOutsideDirective: {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if( $(event.target).closest(el).length <= 0 && vnode.context.inFocus === true ) {
            vnode.context['save'](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    }
  }
}
