var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "table order-item-addition-cuts" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "tbody",
      [
        _vm._l(_vm.motorizedOrderItemAdditionCuts, function (cut, index) {
          return _vm.motorizedOrderItemAdditionCuts
            ? [
                _c("motorized-order-item-addition-cut", {
                  attrs: { "additional-cut-item": cut },
                }),
              ]
            : _vm._e()
        }),
        _vm._v(" "),
        _c("tr", [
          _c(
            "td",
            { staticClass: "colored-bg text-center", attrs: { colspan: "4" } },
            [
              _c(
                "a",
                {
                  class: _vm.orderProposal.userCanEdit ? "" : "disabled",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.addOrderItemAdditionCutRecord($event)
                    },
                  },
                },
                [_vm._v("\n            Add Cut\n        ")]
              ),
            ]
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Cut #")]),
        _vm._v(" "),
        _c("th", [_vm._v("Cut Length (In)")]),
        _vm._v(" "),
        _c("th", [_vm._v("Quantity")]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }