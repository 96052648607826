<script>
  import draggable from 'vuedraggable';
  import DocumentResource from './DocumentResource';

  export default {
    props: [
      'parentResources',
      'parentCategory',
      'parentSelectOptions',
    ],

    components: {
      DocumentResource
    },

    data() {
      return {
        resources: []
      }
    },

    mounted() {
      this.resources = this.parentResources;
    },

    computed: {
      endpointAddRow() {
        return '/admin/document-library/resource/create/' + this.parentCategory.id;
      },

      endpointSort() {
        return '/admin/document-library/resource/sort/' + this.parentCategory.id;
      }
    },

    methods: {
      addRow(event) {
        event.preventDefault();
        this.$parent.submitForm(event, false)
        .then((response) => {
          axios.post(this.endpointAddRow)
          .then((response) => {
            let resource = response.data.data.resource;
            resource.isNew = true;
            this.resources.push(resource);
          })
          .catch(error => {
            flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
          });
        })
        .catch(error => {
          flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
        });
      },

      saveSortOrder(event) {
        let endpoint = this.endpointSort,
            sort_order = this.resources.map(resource => resource.id),
            submitData = {
              _method: 'PUT',
              sort_order: sort_order
            };

        axios.post(endpoint, submitData)
        .then((response) => {
          //
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status); //show the error message
        });
      },

      applySavedResource(oldResourceId, newResource) {
        newResource.isNew = false;
        let index = this.resources.map(resource => resource.id).indexOf(oldResourceId);
        this.resources.splice(index, 1, newResource);
      },

      destroyResource(resource) {
        let i = this.resources.map(resource => resource.id).indexOf(resource.id);
        this.resources.splice(i, 1) // remove it from array
      }
    }
  }
</script>
