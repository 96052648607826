<script>
  import { mapMultiRowFields } from 'vuex-map-fields';

  export default {
    props: [],

    data() {
      return {
        showModal: false,
        modalId: 'modal-addEstimatorConfigs',
        modalSize: 'lg',
        modalTitle: 'Select Estimator Shade Configurations',
        dismissButtonText: 'Cancel',
        confirmButtonText: 'Add Configurations',
        noResultsText: 'No global configurations have been defined for this estimator.',
        hideHeaderClose: false,
      }
    },

    mounted() {
      //
    },

    computed: {
      ...mapMultiRowFields('estimatorShadeUnitConfigs', ['byId']),
      estimatorConfigs() {
        return this.byId;
      },

      hasDismissButton() {
        return (this.dismissButtonText) ? true : false;
      },

      hasConfirmButton() {
        return (this.confirmButtonText) ? true : false;
      },

      hasSelectedShadeConfigs() {
        let selectedConfigs = this.$store.getters['estimatorShadeUnitConfigs/getSelectedEstimatorConfigs'];
        return ( selectedConfigs.length > 0 ) ? true : false;
      },

      isConfirmButtonDisabled() {
        return false;
        //return (this.hasSelectedShadeConfigs == true) ? false : true;
      },

      errors() {
        var errors = this.$store.getters['estimatorShadeUnitConfigs/errors'];
        return errors;
      },

      hasErrors() {
        if( Object.keys(this.errors).length ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'estimator_shade_unit_configs['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      getConfigUnit(id) {
        return this.$store.getters['estimatorShadeUnitConfigs/find'](id);
      },

      getMotorManufacturerNameSummary(id) {
        let configUnit = this.getConfigUnit(id);

        if( configUnit.product_model_drive_mechanism != 'motor' ) {
          return 'None';
        }

        let motorManufacturers = this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.motorManufacturerOptions;
        let selectedMfg = motorManufacturers.find(function(mfg, index){
          return mfg.id === configUnit.motor_manufacturer_id;
        });

        return ( selectedMfg ) ? selectedMfg.name : '';
      },

      getFabricSummary(id) {
        let configUnit = this.getConfigUnit(id);
        return this.$store.getters['estimatorShadeUnitConfigs/fabricSummaryText'](id);
      },

      getDualFabricSummary(id) {
        let configUnit = this.getConfigUnit(id);
        return this.$store.getters['estimatorShadeUnitConfigs/dualFabricSummaryText'](id);
      },

      getFasciaColorSummary(id) {
        let configUnit = this.getConfigUnit(id);
        if( configUnit.fascia_color == 'custom' ) {
          return configUnit.fascia_color_custom_manufacturer + ' / ' + configUnit.fascia_color_custom_name;
        } else {
          return configUnit.fascia_color;
        }
      },

      getSideChannelColorSummary(id) {
        let configUnit = this.getConfigUnit(id);
        if( configUnit.side_channel_color == 'custom' ) {
          return configUnit.side_channel_color_custom_manufacturer + ' / ' + configUnit.side_channel_color_custom_name;
        } else {
          return configUnit.side_channel_color;
        }
      },

      getTrimColorSummary(id) {
        let configUnit = this.getConfigUnit(id);
        if( configUnit.trim_color == 'custom' ) {
          return configUnit.trim_color_custom_manufacturer + ' / ' + configUnit.trim_color_custom_name;
        } else {
          return configUnit.trim_color;
        }
      },

      hasPocket(id) {
        let configUnit = this.getConfigUnit(id),
            nonPocketOptions = ['Tile Lip', 'Closure Mount'];
        return ( nonPocketOptions.includes(configUnit.trim_type) === false ) ? true : false;
      },

      isMaximized(id) {
        let configUnit = this.getConfigUnit(id);
        return configUnit.isMaximized;
      },

      toggleFullView(event, id) {
        event.preventDefault();
        this.$store.dispatch('estimatorShadeUnitConfigs/toggleMaximizedState', id)
      },

      submitForm() {
        this.$store.dispatch('estimatorShadeUnitConfigs/saveSelectedEstimatorConfigs')
        .then(response => {
          this.emitConfirm();
          this.$refs[this.modalId].hide();

        }, error => {
          //Display the error
        });
      },

      //
      // Modal Emitters
      //
      emitConfirm() {
        this.$emit('confirmed');
      },

      emitCancel() {
        this.$emit('cancel');
      },
    }
  };
</script>

<template>
  <div>
    <b-modal
      :ref="modalId"
      :id="modalId"
      :size="modalSize"
      :title="modalTitle"
      :ok-title="confirmButtonText"
      :cancel-title="dismissButtonText"
      :no-close-on-backdrop="true"
      :hide-header-close="hideHeaderClose"
      @ok="emitConfirm"
      @cancel="emitCancel">

      <div class="">
        <div class="form-row">
          <div class="form-group col-sm-12 mb-3">
            Please select the shade configurations you would like to copy into this estimate.
          </div>
        </div>

        <template v-if="hasErrors">
          <div class="form-row">
            <div class="form-group col-sm-12 mb-0">
              <div class="alert alert-danger">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </template>

        <div class="estimator-config-list">
          <template v-if="!estimatorConfigs.length">
            <div class="estimator-config-item expandable-block">
              <div class="expandable-body minimized">
                <div class="title-bar-wrapper">
                  <div class="title-bar">
                    <div class="summary">
                      <p class="no-results">{{ noResultsText }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <template v-for="estimatorConfig in estimatorConfigs">
              <div class="estimator-config-item expandable-block small mb-2">
                <div
                  class="expandable-body"
                  v-bind:class="(isMaximized(estimatorConfig.id) == true) ? 'maximized' : 'minimized'">

                  <div class="title-bar-wrapper"><!-- start: title-bar-wrapper -->
                    <div class="title-bar" @dblclick="toggleFullView($event, estimatorConfig.id)">
                      <div class="summary">
                        <h2>
                          <input type="checkbox"
                            class="mr-2"
                            :id="getInputId(estimatorConfig.id, 'is_selected')"
                            :name="getInputName(estimatorConfig.id, 'is_selected')"
                            v-model="estimatorConfig.is_selected">
                          {{ estimatorConfig.name }}
                        </h2>
                        <!-- <span class="setting-text" v-if="partNumber">Part Number: {{ partNumber }}</span> -->
                      </div>
                      <div class="actions">
                        <div class="link show" v-show="isMaximized(estimatorConfig.id) == false">
                          <a @click="toggleFullView($event, estimatorConfig.id)"></a>
                        </div>
                        <div class="link minimize" v-show="isMaximized(estimatorConfig.id) == true">
                          <a @click="toggleFullView($event, estimatorConfig.id)"></a>
                        </div>
                      </div>
                    </div><!-- end: title-bar -->
                  </div><!-- end: title-bar-wrapper -->

                  <div class="content-wrapper"><!-- start: content-wrapper -->
                    <transition name="slide">
                      <div class="transition-container" v-show="isMaximized(estimatorConfig.id)">
                        <div class="content-block" v-show="isMaximized">

                          <div class="form-row field-section-head">
                            <div class="form-group col-sm-12 section-title">
                              <h3 class="mb-0 pb-1">Shade Settings</h3>
                            </div>
                          </div>
                          <div class="form-row field-section">
                            <div class="form-group col-6 col-md-4">
                              <p>
                                <span class="field-name">Drive Type:</span>
                                <span class="field-value capitalize-first">{{ estimatorConfig.drive_type }}</span>
                              </p>
                            </div>
                            <div class="form-group col-6 col-md-4">
                              <p>
                                <span class="field-name">Mount Type:</span>
                                <span class="field-value capitalize-first">{{ estimatorConfig.mount_type }}</span>
                              </p>
                            </div>
                            <div class="form-group col-6 col-md-4">
                              <p>
                                <span class="field-name">Hardware Color:</span>
                                <span class="field-value capitalize-first">{{ estimatorConfig.hardware_color }}</span>
                              </p>
                            </div>
                            <div class="form-group col-6 col-md-4">
                              <p>
                                <span class="field-name">Hold Down:</span>
                                <span class="field-value capitalize-first">{{ (estimatorConfig.hold_down == 1) ? 'Yes' : 'No' }}</span>
                              </p>
                            </div>
                          </div>

                          <div class="form-row field-section-head">
                            <div class="form-group col-sm-12 section-title">
                              <h3 class="mb-0 pb-1">Configuration</h3>
                            </div>
                          </div>
                          <div class="form-row field-section">
                            <div class="form-group col-6 col-md-4">
                              <p>
                                <span class="field-name">Roll Type:</span>
                                <span class="capitalize-first">{{ estimatorConfig.roll_type }}</span>
                              </p>
                            </div>
                            <div class="form-group col-6 col-md-4">
                              <p>
                                <span class="field-name">Dual Shade:</span>
                                <span class="capitalize-first">{{ (estimatorConfig.product_model_dual_shade == 1) ? 'Yes' : 'No' }}</span>
                              </p>
                            </div>
                            <div class="form-group col-6 col-md-4">
                              <p>
                                <span class="field-name">Guide Cable:</span>
                                <span class="capitalize-first">{{ (estimatorConfig.product_model_guided_cable == '') ? 'None' : estimatorConfig.product_model_guided_cable }}</span>
                              </p>
                            </div>
                            <div class="form-group col-6 col-md-4">
                              <p>
                                <span class="field-name">Bottom Up:</span>
                                <span class="capitalize-first">{{ (estimatorConfig.product_model_bottom_up == null) ? 'No' : estimatorConfig.product_model_bottom_up }}</span>
                              </p>
                            </div>
                            <div class="form-group col-6 col-md-4">
                              <p>
                                <span class="field-name">Control Mech:</span>
                                <span class="capitalize-first">{{ estimatorConfig.product_model_drive_mechanism }}</span>
                              </p>
                            </div>
                          </div>

                          <template v-if="estimatorConfig.product_model_drive_mechanism == 'motor'">
                            <div class="form-row field-section-head">
                              <div class="form-group col-sm-12 section-title">
                                <h3 class="mb-0 pb-1">Motor Settings</h3>
                              </div>
                            </div>
                            <div class="form-row field-section">
                              <div class="form-group col-6 col-md-4">
                                <p>
                                  <span class="field-name">Motor Mfg:</span>
                                  <span class="capitalize-first">{{ getMotorManufacturerNameSummary(estimatorConfig.id) }}</span>
                                </p>
                              </div>
                              <div class="form-group col-6 col-md-4">
                                <p>
                                  <span class="field-name">Motor Type:</span>
                                  <span class="capitalize-first">{{ estimatorConfig.motor_type }}</span>
                                </p>
                              </div>
                              <div class="form-group col-6 col-md-4">
                                <p>
                                  <span class="field-name">Motor Power:</span>
                                  <span class="capitalize-first">{{ estimatorConfig.motor_power }}</span>
                                </p>
                              </div>
                              <div class="form-group col-6 col-md-4">
                                <p>
                                  <span class="field-name">Motor Sound:</span>
                                  <span class="capitalize-first">{{ estimatorConfig.motor_sound }}</span>
                                </p>
                              </div>
                            </div>
                          </template>

                          <div class="form-row field-section-head">
                            <div class="form-group col-sm-12 section-title">
                              <h3 class="mb-0 pb-1">Fabric</h3>
                            </div>
                          </div>
                          <div class="form-row field-section">
                            <div class="form-group col-12">
                              <p>
                                <span class="field-name">Fabric 1:</span>
                                <span class="capitalize-first">{{ getFabricSummary(estimatorConfig.id) }}</span>
                              </p>
                            </div>
                            <div class="form-group col-12" v-if="estimatorConfig.dual_fabric_attribute_id">
                              <p>
                                <span class="field-name">Fabric 2:</span>
                                <span class="capitalize-first">{{ getDualFabricSummary(estimatorConfig.id) }}</span>
                              </p>
                            </div>
                          </div>

                          <div class="form-row field-section-head">
                            <div class="form-group col-sm-12 section-title">
                              <h3 class="mb-0 pb-1">Hembar</h3>
                            </div>
                          </div>
                          <div class="form-row field-section">
                            <div class="form-group col-12">
                              <p>
                                <span class="field-name">Hembar Style:</span>
                                <span class="capitalize-first">{{ estimatorConfig.hembar_style }}</span>
                              </p>
                            </div>
                            <div class="form-group col-6 col-md-4">
                              <p>
                                <span class="field-name">Hembar Color:</span>
                                <span class="capitalize-first">{{ estimatorConfig.hembar_color }}</span>
                              </p>
                            </div>
                            <div class="form-group col-6 col-md-4" v-if="estimatorConfig.hembar_endcap_color">
                              <p>
                                <span class="field-name">Endcap Color:</span>
                                <span class="capitalize-first">{{ estimatorConfig.hembar_endcap_color }}</span>
                              </p>
                            </div>
                          </div>

                          <div class="form-row field-section-head">
                            <div class="form-group col-sm-12 section-title">
                              <h3 class="mb-0 pb-1">Fascia</h3>
                            </div>
                          </div>
                          <div class="form-row field-section">
                            <div class="form-group col-12">
                              <p>
                                <span class="field-name">Fascia Type:</span>
                                <span class="capitalize-first">{{ estimatorConfig.fascia_type }}</span>
                              </p>
                            </div>
                            <template v-if="estimatorConfig.fascia_type != 'No Fascia' && estimatorConfig.fascia_type != 'No Fascia w/Bracket Covers'">
                              <div class="form-group col-6 col-md-4">
                                <p>
                                  <span class="field-name">Back Fascia:</span>
                                  <span class="capitalize-first">{{ (estimatorConfig.back_fascia == 1) ? 'Yes' : 'No' }}</span>
                                </p>
                              </div>
                              <div class="form-group col-6 col-md-4">
                                <p>
                                  <span class="field-name">Pref. Fascia Size:</span>
                                  <span class="capitalize-first">{{ estimatorConfig.preferred_fascia_size }}</span>
                                </p>
                              </div>
                              <div class="form-group col-12">
                                <p>
                                  <span class="field-name">Fascia Color:</span>
                                  <span class="capitalize-first">{{ getFasciaColorSummary(estimatorConfig.id) }}</span>
                                </p>
                              </div>
                            </template>
                          </div>

                          <div class="form-row field-section-head">
                            <div class="form-group col-sm-12 section-title">
                              <h3 class="mb-0 pb-1">Side Channel</h3>
                            </div>
                          </div>
                          <div class="form-row field-section">
                            <div class="form-group col-12">
                              <p>
                                <span class="field-name">Side Channel:</span>
                                <span class="capitalize-first">{{ (estimatorConfig.has_side_channel == '1') ? 'Yes' : 'No' }}</span>
                              </p>
                            </div>
                            <template v-if="estimatorConfig.has_side_channel">
                              <div class="form-group col-6 col-md-4">
                                <p>
                                  <span class="field-name">Side Channel Color:</span>
                                  <span class="capitalize-first">{{ getSideChannelColorSummary(estimatorConfig.id) }}</span>
                                </p>
                              </div>
                            </template>
                          </div>

                          <div class="form-row field-section-head">
                            <div class="form-group col-sm-12 section-title">
                              <h3 class="mb-0 pb-1">Trim Information</h3>
                            </div>
                          </div>
                          <div class="form-row field-section">
                            <div class="form-group col-6 col-md-4">
                              <p>
                                <span class="field-name">Has Trim:</span>
                                <span class="capitalize-first">{{ (estimatorConfig.has_trim == '1') ? 'Yes' : 'No' }}</span>
                              </p>
                            </div>
                            <template v-if="estimatorConfig.has_trim">
                              <div class="form-group col-6 col-md-4">
                                <p>
                                  <span class="field-name">Trim Type:</span>
                                  <span class="capitalize-first">{{ estimatorConfig.trim_type }}</span>
                                </p>
                              </div>
                              <div class="form-group col-12">
                                <p>
                                  <span class="field-name">Trim Color:</span>
                                  <span class="capitalize-first">{{ getTrimColorSummary(estimatorConfig.id) }}</span>
                                </p>
                              </div>

                              <template v-if="hasPocket">
                                <div class="form-group col-6 col-md-4">
                                  <p>
                                    <span class="field-name">Pocket Height:</span>
                                    <span class="capitalize-first">{{ estimatorConfig.pocket_height }}</span>
                                  </p>
                                </div>
                                <div class="form-group col-6 col-md-8">
                                  <p>
                                    <span class="field-name">Pocket Depth:</span>
                                    <span class="capitalize-first">{{ estimatorConfig.pocket_depth }}</span>
                                  </p>
                                </div>
                              </template>

                              <div class="form-group col-6 col-md-4">
                                <p>
                                  <span class="field-name">Include Closure:</span>
                                  <span class="capitalize-first">{{ (estimatorConfig.pocket_include_closure == 1) ? 'Yes' : 'No' }}</span>
                                </p>
                              </div>
                              <div class="form-group col-6 col-md-8" v-if="estimatorConfig.pocket_include_closure">
                                <p>
                                  <span class="field-name">Closure Size:</span>
                                  <span class="capitalize-first">{{ estimatorConfig.pocket_closure_size }}</span>
                                </p>
                              </div>
                            </template>
                          </div>
                        </div><!--end: content-block -->
                      </div><!-- end: transition-container -->
                    </transition>
                  </div><!-- end: content-wrapper -->
                </div><!-- end: expandable-body -->
              </div><!-- end: estimator-config-item -->
            </template>
          </template>
        </div><!-- end: estimator-config-list -->
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="secondary"
            v-html="dismissButtonText"
            @click="cancel()"
            v-if="hasDismissButton">
          </b-button>

          <b-button
            variant="primary"
            v-html="confirmButtonText"
            @click="submitForm()"
            v-if="hasConfirmButton"
            :disabled="isConfirmButtonDisabled">
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
