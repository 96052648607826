export const motorizedOrderItemAdditionMixin = {

  methods: {
    triggerSaveOrderItemAddition(event) {
      event.preventDefault();

      if( this.additionalItem.quantity > 0 ) {
        this.saveOrderItemAddition(event);
      }
    }
  },

  directives: {
    motorizedOrderItemAdditionFieldDirective: {
      bind: function (el, binding, vnode) {
        var checkInputs = function(e) {
          var allInputsFilled = true,
              $el = $(el),
              $parentTableRow = $(el).closest('tr');

          if( $parentTableRow.length > 0 && $parentTableRow.hasClass('require-all') ) {
            var $rowInputs = $parentTableRow.find(':input:not([type="hidden"])').not(':input[disabled]', ':input[readonly]');

            $.each($rowInputs, function(index, item) {
              if( $(item).val() === null || $(item).val() == '' ) {
                allInputsFilled = false;
              }
            });
          }

          if( allInputsFilled === true ) {
            vnode.context['triggerSaveOrderItemAddition'](e);
          }
        }

        //
        // Add the event listeners
        //
        if( $(el).is(':checkbox') ) {
          el.addEventListener('change', checkInputs, true);
        } else {
          el.addEventListener('blur', checkInputs, true);
        }
      }
    }
  }
}

