<script>
  import FieldMeasureNotes from './FieldMeasureNotes';
  import InstallationEquipmentCategories from './InstallationEquipmentCategories';

  export default {
    props: [
      'parentInstallerInformation',
      'parentSelectOptions',
      'parentIsNew'
    ],

    components: {
      FieldMeasureNotes,
      InstallationEquipmentCategories
    },

    data() {
      return {
        installerInformation: {
          project_id: null,
          installer_contact_id: null,
          starting_location: null,
          mounting_surface: null,
          parking_notes: null,
          building_access_notes: null,
          additional_notes: null,
        },
        selecteOptions: [],
        isNew: false,
        errors: []
      }
    },

    mounted() {
      this.installerInformation = { ...this.installerInformation, ...this.parentInstallerInformation };
      this.isNew = ( this.parentIsNew == true ) ? true : false;
    },

    computed: {
      endpointCreate() {
        return '/projects/' + this.installerInformation.project_id + '/install';
      },

      endpointUpdate() {
        return '/projects/install/' + this.installerInformation.id;
      },

      isDisabled() {
        return false;
      },

      installationManagerOptions() {
        return this.parentSelectOptions.installationManagerOptions;
      },

      hasErrors() {
        if( Object.keys(this.errors).length ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'installer_information[' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      save(event) {
        event.preventDefault();
        var endpoint = ( this.isNew ) ? this.endpointCreate : this.endpointUpdate;
        var installerInformation = {};
            installerInformation = _.clone(this.installerInformation);
        var fmNote = {};
          fmNote = this.$refs['fieldMeasureNotes'].getSaveData();

        var installerEquipment = {};
          installerEquipment = this.$refs.installationEquipmentCategories.getSaveData();

        var submitData = {
              _method: ( this.isNew ) ? 'POST' : 'PUT',
              installer_information: installerInformation,
              fm_note: fmNote,
              installer_dependency: installerEquipment
            };

        axios.post(endpoint, submitData)
        .then((response) => {
          this.setErrors({});
          this.installerInformation = response.data.data.installerInformation;
          this.isNew = false;
          flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          this.setErrors(error.response.data.errors);
          flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
        });
      },

      setErrors(errors) {
        if( errors.hasOwnProperty('installer_information') ) {
          this.errors = errors.installer_information;
        } else {
          this.errors = errors;
        }

        if( errors.hasOwnProperty('fm_note') ) {
          this.$refs.fieldMeasureNotes.setErrors(errors.fm_note);
        } else {
          this.$refs.fieldMeasureNotes.setErrors({});
        }

        if( errors.hasOwnProperty('installer_dependency') ) {
          this.$refs.installationEquipmentCategories.setErrors(errors.installer_dependency);
        } else {
          this.$refs.installationEquipmentCategories.setErrors({});
        }
      }
    }
  };
</script>
