var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.modalIdAttr,
            size: _vm.modalSizeAttr,
            title: _vm.modalTitleText,
            "ok-title": _vm.confirmButtonText,
            "cancel-title": _vm.dismissButtonText,
            "hide-header-close": _vm.hideHeaderCloseButton,
            "hide-header": _vm.hideHeader,
            "hide-footer": _vm.hideFooter,
            centered: true,
            "no-close-on-backdrop": _vm.noCloseOnBgClick,
          },
          on: { ok: _vm.emitConfirm, cancel: _vm.emitCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "embed-responsive embed-responsive-16by9 modal-content",
              },
              [
                _c("iframe", {
                  attrs: {
                    scrolling: "no",
                    border: "0",
                    frameborder: "no",
                    framespacing: "0",
                    allowfullscreen: "true",
                    src: _vm.videoIframeSrc,
                    allow: "autoplay; fullscreen;",
                  },
                }),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }