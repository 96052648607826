<script>
  import DocumentResources from './DocumentResources';

  export default {
    props: [
      'parentCategory',
      'formMethod',
      'parentSelectOptions',
      'publishCategoryLanguage',
      'hideCategoryLanguage'
    ],

    components: {
      DocumentResources
    },

    data() {
      return {
        category: {
          name: null,
          url_title: null,
          description: null,
          is_published: false,
          published_state: null,
        },
        errors: {},
      }
    },

    mounted() {
      this.category = { ...this.category, ...this.parentCategory };
    },

    computed: {
      endpointCreate() {
        return '/admin/document-library/category';
      },

      endpointUpdate() {
        return '/admin/document-library/category/' + this.category.id;
      },

      endpointPublish() {
        return '/admin/document-library/category/publish/' + this.category.id;
      },

      isNew() {
        return ( this.formMethod == 'POST' ) ? true : false;
      },

      isPublished() {
        return ( this.category.is_published ) ? true : false;
      },

      categoryVisibilityDescriptionText() {
        return ( this.category.is_published ) ? this.hideCategoryLanguage : this.publishCategoryLanguage;
      },

      categoryVisibilityButtonText() {
        return ( this.category.is_published ) ? 'Hide Category' : 'Publish Category';
      },


      hasErrors() {
        if( Object.keys(this.errors).length ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'category[' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      formatUrlTitle(str) {
        this.category.url_title = str.replace(/[\W_]+/g,"-").replace(/-$/, '').toLowerCase();
      },

      submitForm(event, doRedirect = true) {
        event.preventDefault();
        let endpoint = ( this.isNew ) ? this.endpointCreate : this.endpointUpdate,
            submitData = {
              _method: this.formMethod,
              category: this.category,
            };

        return new Promise((resolve, reject) => {
          axios.post(endpoint, submitData)
          .then((response) => {
            if( doRedirect == true && response.data.redirectTo != '' ) {
              window.location.href = response.data.redirectTo;
            }
            resolve(response); //resolve the promise
          })
          .catch(error => {
            this.setErrors(error.response.data.errors);
            flash(error.response.data.status_type, error.response.data.status); //show the error message
            reject(error.response.data); //reject the promise
          });
        });
      },

      togglePublishedState(event) {
        event.preventDefault();
        let endpoint = this.endpointPublish,
            publishing = ( this.isPublished ) ? 0 : 1, //If it's currently published, unpublish it and vice versa
            submitData = {
              _method: 'PUT',
              is_published: publishing,
            };

        axios.post(endpoint, submitData)
        .then((response) => {
          this.category = response.data.data.category;
          flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          this.setErrors(error.response.data.errors);
          flash(error.response.data.status_type, error.response.data.status); //show the error message
        });
      },

      setErrors(errors, form) {
        if( Object.entries(errors).length ) {
          if( typeof errors.category != 'undefined' ) {
            this.errors = errors.category;
          }
        }
        this.hasErrors;
      }
    },

    watch: {
      'category.name': function (newVal, oldVal) {
        if( this.isNew ) {
          this.formatUrlTitle(newVal);
        }
      }
    }
  }
</script>
