var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "trim-cuts-container" }, [
    _c("div", { staticClass: "table-responsive" }, [
      _c(
        "table",
        { staticClass: "table" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("trim-cut-line-items", {
            ref: "trimCutLineItems",
            attrs: {
              "master-trim-cut-line-items": _vm.trimCutLineItemsList,
              "master-select-options": _vm.masterSelectOptions,
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-sm-12 text-center mb-0" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function ($event) {
                return _vm.saveAndCompleteTrimCuts($event)
              },
            },
          },
          [_vm._v("Finished")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", [_vm._v("Room")]),
      _vm._v(" "),
      _c("th", [_vm._v("Product Model")]),
      _vm._v(" "),
      _c("th", [_vm._v("Trim Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Trim Color")]),
      _vm._v(" "),
      _c("th", [_vm._v("Trim Width (in)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Closure Size")]),
      _vm._v(" "),
      _c("th", [_vm._v("Pocket Height")]),
      _vm._v(" "),
      _c("th", [_vm._v("Pocket Depth")]),
      _vm._v(" "),
      _c("th", [_vm._v("Notes")]),
      _vm._v(" "),
      _c("th", [_vm._v(" ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group col-sm-12" }, [
      _c("p", { staticClass: "text-center mb-0" }, [
        _vm._v(
          'When you\'re done click "Finished." You will be taken back to the proposal form where you can submit your order.'
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }