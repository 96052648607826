var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-row estimate-widget dealer-commission" },
    [
      _vm.showHeadingRow
        ? _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
            _c("h2", [_vm._v(_vm._s(_vm.headingRowHeadingText))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-sm-12 table-responsive" }, [
        _c("table", { staticClass: "table" }, [
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v("Fee %")]),
              _vm._v(" "),
              _c("td", { staticClass: "min-width-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.orderProposal.dealer_commission_pct,
                      expression: "orderProposal.dealer_commission_pct",
                    },
                    {
                      name: "proposal-field-directive",
                      rawName: "v-proposal-field-directive",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors["dealer_commission_pct"]
                    ? "is-invalid"
                    : "",
                  attrs: {
                    type: "text",
                    placeholder: "10.00%",
                    name: _vm.getInputName("dealer_commission_pct"),
                    id: _vm.getInputId("dealer_commission_pct"),
                  },
                  domProps: { value: _vm.orderProposal.dealer_commission_pct },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.orderProposal,
                        "dealer_commission_pct",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Amount")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.orderProposal.dealer_commission_cost_formatted)
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }