<script>
  export default {
    props: [
      'shadeUnit',
      'unitType',
      'containerClass',
      'errors',
      'disabled'
    ],

    mounted() {
      /*
        console.log('shade fascia color mounted...');
        console.log(this.shadeUnit);
        console.log('');
      */
    },

    computed: {
      parentContainerClass() {
        return this.containerClass;
      },

      hasFascia() {
        if( this.unitType == 'shadeConfigUnit' ) {
          var hasFascia = this.$store.getters['shadeUnitConfigs/hasFascia'](this.shadeUnit.id);
        } else {
          var hasFascia = this.$store.getters['shadeLineItems/hasFascia'](this.shadeUnit.id);
        }

        if( hasFascia == false ) {
          this.shadeUnit.fascia_color = null;
        }

        return hasFascia;
      },

      fasciaColorSelectOptions() {
        var fasciaCassetteTypes = [
          'LV Cassette w/Fabric Insert',
          'RO P95 Cassette w/o Fabric Wrap',
          'RO P95 Cassette w/Fabric Wrap'
        ];
        var selectOptions = [];

        if( $.inArray(this.shadeUnit.fascia_type, fasciaCassetteTypes) >= 0 ) {
          selectOptions = this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.fasciaColorOptions.filter(function(option) {
            return option.cassetteAvailable == true;
          });
        } else {
          selectOptions = this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.fasciaColorOptions;
        }

        return selectOptions;
      },

      fasciaEndcapColorSelectOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.fasciaEndcapColorOptions;
      },

      hasCustomColor() {
        if( this.hasFascia == true && this.shadeUnit.fascia_color == 'custom' ) {
          return true;
        }

        return false;
      },

      hasErrors() {
        return this.$parent.hasErrors;
      }
    },

    methods: {
      getInputName(id, field) {
        return this.$parent.getInputName(id, field);
      },

      getInputId(id, field) {
        return this.$parent.getInputId(id, field);
      },
    },

    watch: {
      'shadeUnit.fascia_color': function (newVal, oldVal) {
        if( newVal == null || newVal.toLowerCase() != 'custom' ) {
          this.shadeUnit.fascia_color_custom_manufacturer = null;
          this.shadeUnit.fascia_color_custom_name = null;
          this.shadeUnit.fascia_endcap_color = null;
        }
      }
    }
  }
</script>
