var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "status-notifications-list-item expandable-block small",
      class: _vm.isSortableContainerClass,
    },
    [
      _vm.statusNotification.sort_order_number != 1 && _vm.isDeletable == true
        ? _c("div", { staticClass: "sort-handle" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "expandable-body",
          class:
            _vm.isMaximized == true || _vm.hasErrors == true
              ? "maximized"
              : "minimized",
        },
        [
          _c("div", { staticClass: "title-bar-wrapper" }, [
            _c("div", { staticClass: "title-bar" }, [
              _c(
                "div",
                { staticClass: "summary" },
                [
                  _vm.statusNotification.name == "" ||
                  _vm.statusNotification.name == null
                    ? [_c("h2", [_vm._v("New Status")])]
                    : [
                        _c("h2", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.statusNotification.name) +
                              "\n              "
                          ),
                          _vm.statusNotification.display_name != "" &&
                          _vm.statusNotification.display_name != null
                            ? _c("span", { staticClass: "hint" }, [
                                _vm._v(
                                  "\n                (" +
                                    _vm._s(
                                      _vm.statusNotification.display_name
                                    ) +
                                    ")\n              "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "actions" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isMaximized == false,
                        expression: "isMaximized == false",
                      },
                    ],
                    staticClass: "link edit",
                  },
                  [
                    _c("a", {
                      on: {
                        click: function ($event) {
                          return _vm.toggleFullView($event)
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isMaximized == true,
                        expression: "isMaximized == true",
                      },
                    ],
                    staticClass: "link minimize",
                  },
                  [
                    _c("a", {
                      on: {
                        click: function ($event) {
                          return _vm.toggleFullView($event)
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "link send-email" }, [
                  _c("a", {
                    class: _vm.isTestable == false ? "disabled" : "",
                    on: {
                      click: function ($event) {
                        return _vm.sendTestEmail($event)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "link delete" }, [
                  _c("a", {
                    class: _vm.isDeletable == false ? "disabled" : "",
                    on: {
                      click: function ($event) {
                        return _vm.destroy($event)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content-wrapper" },
            [
              _c("transition", { attrs: { name: "slide" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isMaximized,
                        expression: "isMaximized",
                      },
                    ],
                    staticClass: "transition-container",
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isMaximized,
                            expression: "isMaximized",
                          },
                        ],
                        staticClass: "content-block",
                      },
                      [
                        _c("div", { staticClass: "form-row" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.statusNotification.sort_order_number,
                                expression:
                                  "statusNotification.sort_order_number",
                              },
                            ],
                            attrs: {
                              type: "hidden",
                              id: _vm.getInputId(
                                _vm.statusNotification.id,
                                "sort_order_number"
                              ),
                              name: _vm.getInputName(
                                _vm.statusNotification.id,
                                "sort_order_number"
                              ),
                            },
                            domProps: {
                              value: _vm.statusNotification.sort_order_number,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.statusNotification,
                                  "sort_order_number",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form-group col-sm-12 col-md-6 mb-0",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "form-group col-sm-12 pl-0 pr-0",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "float-left",
                                      class: _vm.errors["name"]
                                        ? "is-invalid"
                                        : "",
                                      attrs: {
                                        for: _vm.getInputId(
                                          _vm.statusNotification.id,
                                          "name"
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Status Name\n                  "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.errors["name"]
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "invalid-feedback text-right",
                                          attrs: { role: "alert" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.errors["name"][0]) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.statusNotification.name,
                                        expression: "statusNotification.name",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: _vm.errors["name"]
                                      ? "is-invalid"
                                      : "",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Status name",
                                      maxlength: "255",
                                      name: _vm.getInputName(
                                        _vm.statusNotification.id,
                                        "name"
                                      ),
                                      id: _vm.getInputId(
                                        _vm.statusNotification.id,
                                        "name"
                                      ),
                                    },
                                    domProps: {
                                      value: _vm.statusNotification.name,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.statusNotification,
                                          "name",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group col-sm-12 pl-0 pr-0",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "float-left",
                                      class: _vm.errors["display_name"]
                                        ? "is-invalid"
                                        : "",
                                      attrs: {
                                        for: _vm.getInputId(
                                          _vm.statusNotification.id,
                                          "display_name"
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Display Name\n                  "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.errors["display_name"]
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "invalid-feedback text-right",
                                          attrs: { role: "alert" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.errors["display_name"][0]
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.statusNotification.display_name,
                                        expression:
                                          "statusNotification.display_name",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: _vm.errors["display_name"]
                                      ? "is-invalid"
                                      : "",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Display name",
                                      maxlength: "255",
                                      name: _vm.getInputName(
                                        _vm.statusNotification.id,
                                        "display_name"
                                      ),
                                      id: _vm.getInputId(
                                        _vm.statusNotification.id,
                                        "display_name"
                                      ),
                                    },
                                    domProps: {
                                      value:
                                        _vm.statusNotification.display_name,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.statusNotification,
                                          "display_name",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form-group col-sm-12 col-md-6 mb-0",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group col-sm-12" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "float-left",
                                      class: _vm.errors["internal_instructions"]
                                        ? "is-invalid"
                                        : "",
                                      attrs: {
                                        for: _vm.getInputId(
                                          _vm.statusNotification.id,
                                          "internal_instructions"
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Internal Instructions\n                  "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.errors["internal_instructions"]
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "invalid-feedback text-right",
                                          attrs: { role: "alert" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.errors[
                                                  "internal_instructions"
                                                ][0]
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.statusNotification
                                            .internal_instructions,
                                        expression:
                                          "statusNotification.internal_instructions",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: _vm.errors["internal_instructions"]
                                      ? "is-invalid"
                                      : "",
                                    attrs: {
                                      rows: "5",
                                      placeholder:
                                        "Include brief list of instructions for Lutek team members",
                                      name: _vm.getInputName(
                                        _vm.statusNotification.id,
                                        "internal_instructions"
                                      ),
                                      id: _vm.getInputId(
                                        _vm.statusNotification.id,
                                        "internal_instructions"
                                      ),
                                    },
                                    domProps: {
                                      value:
                                        _vm.statusNotification
                                          .internal_instructions,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.statusNotification,
                                          "internal_instructions",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("hr"),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-sm-12 col-md-6" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "float-left",
                                  class: _vm.errors["send_internal_email"]
                                    ? "is-invalid"
                                    : "",
                                  attrs: {
                                    for: _vm.getInputId(
                                      _vm.statusNotification.id,
                                      "send_internal_email"
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  Send Internal Email\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.errors["send_internal_email"]
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "invalid-feedback text-right",
                                      attrs: { role: "alert" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.errors["send_internal_email"][0]
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.statusNotification
                                          .send_internal_email,
                                      expression:
                                        "statusNotification.send_internal_email",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: _vm.errors["send_internal_email"]
                                    ? "is-invalid"
                                    : "",
                                  attrs: {
                                    name: _vm.getInputName(
                                      _vm.statusNotification.id,
                                      "send_internal_email"
                                    ),
                                    id: _vm.getInputId(
                                      _vm.statusNotification.id,
                                      "send_internal_email"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.statusNotification,
                                        "send_internal_email",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "1" } }, [
                                    _vm._v("Yes"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "0" } }, [
                                    _vm._v("No"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.statusNotification.send_internal_email ==
                                  true,
                                expression:
                                  "statusNotification.send_internal_email == true",
                              },
                            ],
                            staticClass: "form-row",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group col-sm-12 col-md-6 mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group col-sm-12 pl-0 pr-0",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "float-left",
                                        class: _vm.errors[
                                          "internal_email_subject"
                                        ]
                                          ? "is-invalid"
                                          : "",
                                        attrs: {
                                          for: _vm.getInputId(
                                            _vm.statusNotification.id,
                                            "internal_email_subject"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Internal Email Subject\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.errors["internal_email_subject"]
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "invalid-feedback text-right",
                                            attrs: { role: "alert" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.errors[
                                                    "internal_email_subject"
                                                  ][0]
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.statusNotification
                                              .internal_email_subject,
                                          expression:
                                            "statusNotification.internal_email_subject",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: _vm.errors[
                                        "internal_email_subject"
                                      ]
                                        ? "is-invalid"
                                        : "",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Email subject",
                                        maxlength: "100",
                                        name: _vm.getInputName(
                                          _vm.statusNotification.id,
                                          "internal_email_subject"
                                        ),
                                        id: _vm.getInputId(
                                          _vm.statusNotification.id,
                                          "internal_email_subject"
                                        ),
                                      },
                                      domProps: {
                                        value:
                                          _vm.statusNotification
                                            .internal_email_subject,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.statusNotification,
                                            "internal_email_subject",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group col-sm-12 pl-0 pr-0",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "float-left",
                                        class: _vm.errors[
                                          "internal_email_send_to"
                                        ]
                                          ? "is-invalid"
                                          : "",
                                        attrs: {
                                          for: _vm.getInputId(
                                            _vm.statusNotification.id,
                                            "internal_email_send_to"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Send Internal Email To\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.errors["internal_email_send_to"]
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "invalid-feedback text-right",
                                            attrs: { role: "alert" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.errors[
                                                    "internal_email_send_to"
                                                  ][0]
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.statusNotification
                                              .internal_email_send_to,
                                          expression:
                                            "statusNotification.internal_email_send_to",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: _vm.errors[
                                        "internal_email_send_to"
                                      ]
                                        ? "is-invalid"
                                        : "",
                                      attrs: {
                                        rows: "1",
                                        placeholder: "example@lu-tek.com",
                                        name: _vm.getInputName(
                                          _vm.statusNotification.id,
                                          "internal_email_send_to"
                                        ),
                                        id: _vm.getInputId(
                                          _vm.statusNotification.id,
                                          "internal_email_send_to"
                                        ),
                                      },
                                      domProps: {
                                        value:
                                          _vm.statusNotification
                                            .internal_email_send_to,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.statusNotification,
                                            "internal_email_send_to",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group col-sm-12 pl-0 pr-0",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "float-left",
                                        class: _vm.errors[
                                          "internal_email_reply_to"
                                        ]
                                          ? "is-invalid"
                                          : "",
                                        attrs: {
                                          for: _vm.getInputId(
                                            _vm.statusNotification.id,
                                            "internal_email_reply_to"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Send Internal Reply To\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.errors["internal_email_reply_to"]
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "invalid-feedback text-right",
                                            attrs: { role: "alert" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.errors[
                                                    "internal_email_reply_to"
                                                  ][0]
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.statusNotification
                                              .internal_email_reply_to,
                                          expression:
                                            "statusNotification.internal_email_reply_to",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: _vm.errors[
                                        "internal_email_reply_to"
                                      ]
                                        ? "is-invalid"
                                        : "",
                                      attrs: {
                                        type: "text",
                                        placeholder: "noreply@lu-tek.com",
                                        maxlength: "100",
                                        name: _vm.getInputName(
                                          _vm.statusNotification.id,
                                          "internal_email_reply_to"
                                        ),
                                        id: _vm.getInputId(
                                          _vm.statusNotification.id,
                                          "internal_email_reply_to"
                                        ),
                                      },
                                      domProps: {
                                        value:
                                          _vm.statusNotification
                                            .internal_email_reply_to,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.statusNotification,
                                            "internal_email_reply_to",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.errors["internal_email_reply_to"]
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "invalid-feedback",
                                            attrs: { role: "alert" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.errors[
                                                    "internal_email_reply_to"
                                                  ][0]
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group col-sm-12 col-md-6 mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-sm-12" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "float-left",
                                        class: _vm.errors[
                                          "internal_email_message_body"
                                        ]
                                          ? "is-invalid"
                                          : "",
                                        attrs: {
                                          for: _vm.getInputId(
                                            _vm.statusNotification.id,
                                            "internal_email_message_body"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Internal Email Message Body\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.errors["internal_email_message_body"]
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "invalid-feedback text-right",
                                            attrs: { role: "alert" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.errors[
                                                    "internal_email_message_body"
                                                  ][0]
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.statusNotification
                                              .internal_email_message_body,
                                          expression:
                                            "statusNotification.internal_email_message_body",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: _vm.errors[
                                        "internal_email_message_body"
                                      ]
                                        ? "is-invalid"
                                        : "",
                                      attrs: {
                                        rows: "9",
                                        placeholder: "Enter email body content",
                                        name: _vm.getInputName(
                                          _vm.statusNotification.id,
                                          "internal_email_message_body"
                                        ),
                                        id: _vm.getInputId(
                                          _vm.statusNotification.id,
                                          "internal_email_message_body"
                                        ),
                                      },
                                      domProps: {
                                        value:
                                          _vm.statusNotification
                                            .internal_email_message_body,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.statusNotification,
                                            "internal_email_message_body",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("hr"),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-sm-12 col-md-6" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "float-left",
                                  class: _vm.errors["send_customer_email"]
                                    ? "is-invalid"
                                    : "",
                                  attrs: {
                                    for: _vm.getInputId(
                                      _vm.statusNotification.id,
                                      "send_customer_email"
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  Send Customer Email\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.errors["send_customer_email"]
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "invalid-feedback text-right",
                                      attrs: { role: "alert" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.errors["send_customer_email"][0]
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.statusNotification
                                          .send_customer_email,
                                      expression:
                                        "statusNotification.send_customer_email",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: _vm.errors["send_customer_email"]
                                    ? "is-invalid"
                                    : "",
                                  attrs: {
                                    name: _vm.getInputName(
                                      _vm.statusNotification.id,
                                      "send_customer_email"
                                    ),
                                    id: _vm.getInputId(
                                      _vm.statusNotification.id,
                                      "send_customer_email"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.statusNotification,
                                        "send_customer_email",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "1" } }, [
                                    _vm._v("Yes"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "0" } }, [
                                    _vm._v("No"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.statusNotification.send_customer_email ==
                                  true,
                                expression:
                                  "statusNotification.send_customer_email == true",
                              },
                            ],
                            staticClass: "form-row",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group col-sm-12 col-md-6 mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group keep-margin col-sm-12 pl-0 pr-0",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "float-left",
                                        class: _vm.errors[
                                          "customer_email_subject"
                                        ]
                                          ? "is-invalid"
                                          : "",
                                        attrs: {
                                          for: _vm.getInputId(
                                            _vm.statusNotification.id,
                                            "customer_email_subject"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Customer Email Subject\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.errors["customer_email_subject"]
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "invalid-feedback text-right",
                                            attrs: { role: "alert" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.errors[
                                                    "customer_email_subject"
                                                  ][0]
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.statusNotification
                                              .customer_email_subject,
                                          expression:
                                            "statusNotification.customer_email_subject",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: _vm.errors[
                                        "customer_email_subject"
                                      ]
                                        ? "is-invalid"
                                        : "",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Email subject",
                                        maxlength: "100",
                                        name: _vm.getInputName(
                                          _vm.statusNotification.id,
                                          "customer_email_subject"
                                        ),
                                        id: _vm.getInputId(
                                          _vm.statusNotification.id,
                                          "customer_email_subject"
                                        ),
                                      },
                                      domProps: {
                                        value:
                                          _vm.statusNotification
                                            .customer_email_subject,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.statusNotification,
                                            "customer_email_subject",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group col-sm-12 pl-0 pr-0",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "float-left",
                                        class: _vm.errors[
                                          "customer_email_reply_to"
                                        ]
                                          ? "is-invalid"
                                          : "",
                                        attrs: {
                                          for: _vm.getInputId(
                                            _vm.statusNotification.id,
                                            "customer_email_reply_to"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Customer Email Reply To\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.errors["customer_email_reply_to"]
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "invalid-feedback text-right",
                                            attrs: { role: "alert" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.errors[
                                                    "customer_email_reply_to"
                                                  ][0]
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.statusNotification
                                              .customer_email_reply_to,
                                          expression:
                                            "statusNotification.customer_email_reply_to",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: _vm.errors[
                                        "customer_email_reply_to"
                                      ]
                                        ? "is-invalid"
                                        : "",
                                      attrs: {
                                        type: "text",
                                        placeholder: "noreply@lu-tek.com",
                                        maxlength: "100",
                                        name: _vm.getInputName(
                                          _vm.statusNotification.id,
                                          "customer_email_reply_to"
                                        ),
                                        id: _vm.getInputId(
                                          _vm.statusNotification.id,
                                          "customer_email_reply_to"
                                        ),
                                      },
                                      domProps: {
                                        value:
                                          _vm.statusNotification
                                            .customer_email_reply_to,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.statusNotification,
                                            "customer_email_reply_to",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group col-sm-12 col-md-6 mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-sm-12" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "float-left",
                                        class: _vm.errors[
                                          "customer_email_message_body"
                                        ]
                                          ? "is-invalid"
                                          : "",
                                        attrs: {
                                          for: _vm.getInputId(
                                            _vm.statusNotification.id,
                                            "customer_email_message_body"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Customer Email Message Body\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.errors["customer_email_message_body"]
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "invalid-feedback text-right",
                                            attrs: { role: "alert" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.errors[
                                                    "customer_email_message_body"
                                                  ][0]
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.statusNotification
                                              .customer_email_message_body,
                                          expression:
                                            "statusNotification.customer_email_message_body",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: _vm.errors[
                                        "customer_email_message_body"
                                      ]
                                        ? "is-invalid"
                                        : "",
                                      attrs: {
                                        rows: "5",
                                        placeholder: "Enter email body content",
                                        name: _vm.getInputName(
                                          _vm.statusNotification.id,
                                          "customer_email_message_body"
                                        ),
                                        id: _vm.getInputId(
                                          _vm.statusNotification.id,
                                          "customer_email_message_body"
                                        ),
                                      },
                                      domProps: {
                                        value:
                                          _vm.statusNotification
                                            .customer_email_message_body,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.statusNotification,
                                            "customer_email_message_body",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }