<script>
  export default {
    props: [
      'parentResource',
      'parentSelectOptions',
      'parentNewAttachment',
    ],

    components: {},

    data() {
      return {
        attached_file_data: null,
        errors: {}
      }
    },

    mounted() {},

    computed: {
      //
      // Properties/Data Vars
      //
      attachment() {
        return this.parentNewAttachment;
      },

      resource() {
        return this.parentResource;
      },

      resourceAttachments() {
        return this.parentResource.resource_attachments;
      },


      //
      // Record Attribes
      //
      isVideo() {
        return ( this.attachment.attachment_type == 'video' ) ? true : false;
      },

      isFile() {
        return ( this.attachment.attachment_type == 'file' ) ? true : false;
      },

      isFormUploadFieldDisabled() {
        return false;
      },

      hasErrors() {
        if( Object.keys(this.errors).length ) {
          return true;
        }
        return false;
      },


      //
      //Endpoints / Form Actions
      //
      endpointCreate() {
        return '/admin/document-library/attachment/' + this.resource.id;
      },

      formMethod() {
        return 'POST';
      },


      //
      // Modal
      //
      modalIdAttr() {
        return 'resourceModal' + this.resource.id;
      },

      modalSizeAttr() {
        return 'md';
      },

      modalTitleText() {
        return 'Add Attachment to Resource';
      },

      confirmButtonText() {
        return 'Save';
      },

      dismissButtonText() {
        return 'Cancel';
      },

      hideHeaderCloseButton() {
        return true;
      },
    },

    methods: {
      //
      // Inputs
      //
      getInputName(id, field) {
        return 'resource_attachments[' + id + '][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      setAttchmentType(event) {
        if( this.isVideo ) {
          this.attached_file_data = null;
          this.new_attached_file = null;
        }
      },

      //
      // Modals
      //
      emitConfirm(bvModalEvt) {
        bvModalEvt.preventDefault();

        if( this.isFile && this.attachmentPreCheck() == false ) {
          return false;
        }

        this.saveAttachment(bvModalEvt) //Save the attachment and wait for the promise response
        .then((response) => { //Promise resoved
          if( this.hasErrors === false ) {
            this.$nextTick(() => {
              this.$bvModal.hide(this.modalIdAttr);
            });
          }
        })
        .catch((error) => { //Promise rejected
          //
        });
      },

      emitCancel() {
        this.$parent.resetNewAttachment();
        this.$emit('cancel');
      },


      //
      // Attachment Handling
      //
      setAttachedFileData(event) {
        this.attached_file_data = event.target.files[0];
      },

      saveAttachment(event) {
        if( this.isFile ) {
          return this.saveFileAttachment();
        } else {
          return this.saveVideoAttachment();
        }
      },

      attachmentPreCheck() {
        if( !this.attached_file_data ) {
          let errors = {
            resource_attachment: {
              new_attached_file: [
                'Required'
              ]
            }
          }
          this.setErrors(errors);
          return false;
        }

        return true;
      },

      saveFileAttachment() {
        let endpoint = this.endpointCreate,
            submitData = new FormData(),
            requestSettings = {
              headers: { 'content-type': 'multipart/form-data' }
            };

        submitData.append('resource_attachment[new_attached_file]', this.attached_file_data, this.attached_file_data.name);
        submitData.append('resource_attachment[document_resource_id]', this.parentResource.id);
        submitData.append('resource_attachment[attachment_type]', this.attachment.attachment_type);

        return new Promise((resolve, reject) => {
          axios.post(endpoint, submitData, requestSettings)
          .then((response) => {
            let attachment = response.data.data.attachment;
            attachment.isNew = false;
            this.$parent.applyNewResourceAttachment(attachment);
            this.setErrors({});
            flash(response.data.status_type, response.data.status);
            resolve(response); //resolve the promise
          })
          .catch(error => {
            this.setErrors(error.response.data.errors);
            flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
            reject(error.response.data); //reject the promise
          });
        });
      },

      saveVideoAttachment() {
        let endpoint = this.endpointCreate,
            submitData = {
              _method: this.formMethod,
              resource_attachment: this.attachment
            };

        return new Promise((resolve, reject) => {
          axios.post(endpoint, submitData)
          .then((response) => {
            let attachment = response.data.data.attachment;
            attachment.isNew = false;
            this.$parent.applyNewResourceAttachment(attachment);
            this.setErrors({});
            flash(response.data.status_type, response.data.status);
            resolve(response); //resolve the promise
          })
          .catch(error => {
            this.setErrors(error.response.data.errors);
            flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
            reject(error.response.data); //reject the promise
          });
        });
      },

      //
      // Errors
      //
      setErrors(errors, form) {
        if( typeof errors.resource_attachment != 'undefined' ) {
          this.errors = errors.resource_attachment;
        } else {
          this.errors = {};
        }
      },
    }
  };
</script>

<template>
  <div>
    <b-modal
      :id="modalIdAttr"
      :size="modalSizeAttr"
      :title="modalTitleText"
      :ok-title="confirmButtonText"
      :cancel-title="dismissButtonText"
      :no-close-on-backdrop="true"
      :hide-header-close="hideHeaderCloseButton"
      :ref="'resourceFormModal'"
      @ok="emitConfirm"
      @cancel="emitCancel">

      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12">
          <label :for="getInputId(attachment.id, 'attachment_type')">Attachment Type</label>
          <select
            class="form-control"
            v-bind:class="errors['attachment_type'] ? 'is-invalid' : ''"
            v-bind:name="getInputName(attachment.id, 'attachment_type')"
            v-bind:id="getInputId(attachment.id, 'attachment_type')"
            v-model="attachment.attachment_type"
            @change="setAttchmentType($event)">
              <option v-for="option in parentSelectOptions.resourceAttachmentTypeOptions" :value="option.value">{{ option.label }}</option>
          </select>
          <span class="invalid-feedback" role="alert" v-if="errors['attachment_type']">
            {{ errors['attachment_type'][0] }}
          </span>
        </div>
      </div>

      <div class="form-row">
        <template v-if="isVideo">
          <div class="form-group col-sm-12 col-md-12">
            <label :for="getInputId(attachment.id, 'attachment_type')">Video URL</label>
            <input type="text"
              placeholder="Video URL"
              class="form-control"
              v-bind:class="errors['attachment_path'] ? 'is-invalid' : ''"
              v-bind:name="getInputName(attachment.id, 'attachment_path')"
              v-bind:id="getInputId(attachment.id, 'attachment_path')"
              v-model="attachment.attachment_path">
            <span class="invalid-feedback" role="alert" v-if="errors['attachment_path']">
              {{ errors['attachment_path'][0] }}
            </span>
          </div>
        </template>
        <template v-else>
          <div class="form-group col-sm-12 col-md-12">
            <label :for="getInputId(attachment.id, 'new_attached_file')">Attachment</label>
            <input
              type="file"
              class="form-control-file vue-file-input"
              v-bind:class="errors['new_attached_file'] ? 'is-invalid' : ''"
              v-bind:id="getInputId(attachment.id, 'new_attached_file')"
              :disabled="isFormUploadFieldDisabled"
              @change="setAttachedFileData($event)">
            <span class="invalid-feedback" role="alert" v-if="errors['new_attached_file']">
              {{ errors['new_attached_file'][0] }}
            </span>
          </div>
        </template>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="secondary"
            v-html="dismissButtonText"
            @click="cancel()">
          </b-button>

          <b-button
            variant="primary"
            v-html="confirmButtonText"
            @click="ok()">
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
