<script>
  export default {
    props: [
      'shadeUnit',
      'containerClass',
      'disabled'
    ],

    data() {
      return {
        //errors: {}
      }
    },

    mounted() {
      //
    },

    computed: {
      hasTrimOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.hasTrimOptions;
      },

      trimTypeOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.trimTypeOptions;
      },

      trimColorOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.trimColorOptions;
      },

      pocketHeightOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.pocketHeightOptions;
      },

      pocketDepthOptions() {
        var allPocketDepthOptions = this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.pocketDepthOptions;
        var availablePocketDepthOptions = [];

        switch(this.shadeUnit.pocket_height) {
          case '5.00':
            availablePocketDepthOptions = allPocketDepthOptions.filter(option => {
              return option.value <= 5.00;
            });
            break;

          case '7.00':
            availablePocketDepthOptions = allPocketDepthOptions.filter(option => {
              return option.value > 5.00;
            });
            break;

          default:
            availablePocketDepthOptions = allPocketDepthOptions;
            break;

        }

        return availablePocketDepthOptions;
      },

      pocketIncludeClosureOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.pocketIncludeClosureOptions;
      },

      pocketClosureSizeOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.pocketClosureSizeOptions;
      },

      parentContainerClass() {
        return this.containerClass;
      },

      canHaveTrim() {
        var incompatibleFasciaOptions = [
          null,
          'Fascia',
          'Fabric Wrapped Fascia',
          'LV Cassette w/Fabric Insert',
          'RO P95 Cassette w/o Fabric Wrap',
          'RO P95 Cassette w/Fabric Wrap'
        ];

        var isTrimAvailable = ( incompatibleFasciaOptions.includes(this.shadeUnit.fascia_type) ) ? false : true;

        if( isTrimAvailable == false ) {
          this.shadeUnit.has_trim = 0;
        }

        return isTrimAvailable;
      },

      hasTrim() {
        var hasTrim = (this.shadeUnit.has_trim == 1) ? true : false;
        return hasTrim;
      },

      setHasTrimField() {
        if( this.canHaveTrim == false ) {
          this.shadeUnit.has_trim = 0;
        }
      },

      trimIsPocket() {
        var pocketValues = ['Pocket w/Lip', 'Pocket No Lip', '7" Pocket No Lip'];

        if( pocketValues.includes(this.shadeUnit.trim_type) ) {
          return true;
        }

        this.shadeUnit.pocket_height = null;
        this.shadeUnit.pocket_depth = null;
        return false;
      },

      canHaveTrimClosure() {
        return this.hasTrim;
      },

      canHaveTrimClosureSize() {
        if( this.hasTrim == true && this.shadeUnit.pocket_include_closure == true ) {
          return true;
        }
        return false;
      },

      pocketHeightIsLocked() {
        if( this.hasTrim == false || this.trimIsPocket == false ) {
          return true;
        }

        if( this.shadeUnit.trim_type == 'Pocket No Lip' || this.shadeUnit.trim_type == '7" Pocket No Lip' ) {
          return true;
        }

        return false;
      },

      pocketDepthIsLocked() {
        if( this.hasTrim == false || this.trimIsPocket == false ) {
          return true;
        }

        if( this.shadeUnit.pocket_height == '5.00' ) {
          return true;
        }

        return false;
      },

      hasCustomTrimColor() {
        if( this.hasTrim == true && this.shadeUnit.trim_color == 'custom' ) {
          return true;
        }

        return false;
      },

      hasErrors() {
        return this.$parent.hasErrors;
      },

      errors() {
        return this.$parent.errors;
      }
    },

    methods: {
      getInputName(id, field) {
        return this.$parent.getInputName(id, field);
      },

      getInputId(id, field) {
        return this.$parent.getInputId(id, field);
      }
    },

    watch: {
      'hasTrim': function (newVal, oldVal) {
        if( newVal === false ) {
          this.shadeUnit.has_trim = 0;
          this.shadeUnit.trim_type = null;
          this.shadeUnit.trim_color = null;
          this.shadeUnit.trim_color_custom_manufacturer = null;
          this.shadeUnit.trim_color_custom_name = null;
          this.shadeUnit.pocket_height = null;
          this.shadeUnit.pocket_depth = null;
          this.shadeUnit.pocket_include_closure = 0;
          this.shadeUnit.pocket_closure_size = null;
        }
      },

      'shadeUnit.trim_type': function (newVal, oldVal) {
        switch(newVal) {
          case 'Pocket w/Lip':
            break;

          case 'Pocket No Lip':
            this.shadeUnit.pocket_height = '5.00';
            break;

          case '7" Pocket No Lip':
            this.shadeUnit.pocket_height = '7.00';
            break;

          default:
            break;
        }
      },

      'shadeUnit.trim_color': function (newVal, oldVal) {
        if( newVal != 'custom' ) {
          this.shadeUnit.trim_color_custom_manufacturer = null;
          this.shadeUnit.trim_color_custom_name = null;
        }
      },

      'shadeUnit.pocket_height': function (newVal, oldVal) {
        switch(newVal) {
          case '5.00':
            this.shadeUnit.pocket_depth = '5.00'
            break;

          case '7.00':
            if (this.pocketDepthOptions.filter(opt => opt.value == this.shadeUnit.pocket_depth).length <= 0) {
              this.shadeUnit.pocket_depth = null;
            }
            break;

          default:
            break;
        }
      },

      'shadeUnit.pocket_include_closure': function (newVal, oldVal) {
        if( newVal == false || newVal == 0 ) {
          this.shadeUnit.pocket_closure_size = null;
        }
      },
    }
  }
</script>
