var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c("td", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.fabricAttribute.color,
            expression: "fabricAttribute.color",
          },
        ],
        staticClass: "form-control",
        class: _vm.errors["color"] ? "is-invalid" : "",
        attrs: {
          type: "text",
          maxlength: "50",
          placeholder: "Color",
          name: _vm.getInputName(_vm.fabricAttribute.id, "color"),
          id: _vm.getInputId(_vm.fabricAttribute.id, "color"),
        },
        domProps: { value: _vm.fabricAttribute.color },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.fabricAttribute, "color", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _vm.errors["color"]
        ? _c(
            "span",
            { staticClass: "invalid-feedback", attrs: { role: "alert" } },
            [_vm._v("\n      " + _vm._s(_vm.errors["color"][0]) + "\n    ")]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.fabricAttribute.color_number,
            expression: "fabricAttribute.color_number",
          },
        ],
        staticClass: "form-control",
        class: _vm.errors["color_number"] ? "is-invalid" : "",
        attrs: {
          type: "text",
          maxlength: "50",
          placeholder: "Color Number",
          name: _vm.getInputName(_vm.fabricAttribute.id, "color_number"),
          id: _vm.getInputId(_vm.fabricAttribute.id, "color_number"),
        },
        domProps: { value: _vm.fabricAttribute.color_number },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.fabricAttribute, "color_number", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _vm.errors["color_number"]
        ? _c(
            "span",
            { staticClass: "invalid-feedback", attrs: { role: "alert" } },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.errors["color_number"][0]) + "\n    "
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.fabricAttribute.cost_per_yard,
            expression: "fabricAttribute.cost_per_yard",
          },
        ],
        staticClass: "form-control",
        class: _vm.errors["cost_per_yard"] ? "is-invalid" : "",
        attrs: {
          type: "text",
          maxlength: "50",
          placeholder: "$15.00",
          name: _vm.getInputName(_vm.fabricAttribute.id, "cost_per_yard"),
          id: _vm.getInputId(_vm.fabricAttribute.id, "cost_per_yard"),
        },
        domProps: { value: _vm.fabricAttribute.cost_per_yard },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.fabricAttribute, "cost_per_yard", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _vm.errors["cost_per_yard"]
        ? _c(
            "span",
            { staticClass: "invalid-feedback", attrs: { role: "alert" } },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.errors["cost_per_yard"][0]) + "\n    "
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.fabricAttribute.max_roll_width,
            expression: "fabricAttribute.max_roll_width",
          },
        ],
        staticClass: "form-control",
        class: _vm.errors["max_roll_width"] ? "is-invalid" : "",
        attrs: {
          type: "text",
          maxlength: "50",
          placeholder: '128.00"',
          name: _vm.getInputName(_vm.fabricAttribute.id, "max_roll_width"),
          id: _vm.getInputId(_vm.fabricAttribute.id, "max_roll_width"),
        },
        domProps: { value: _vm.fabricAttribute.max_roll_width },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.fabricAttribute, "max_roll_width", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _vm.errors["max_roll_width"]
        ? _c(
            "span",
            { staticClass: "invalid-feedback", attrs: { role: "alert" } },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.errors["max_roll_width"][0]) + "\n    "
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.fabricAttribute.max_roll_length,
            expression: "fabricAttribute.max_roll_length",
          },
        ],
        staticClass: "form-control",
        class: _vm.errors["max_roll_length"] ? "is-invalid" : "",
        attrs: {
          type: "text",
          maxlength: "50",
          placeholder: "30",
          name: _vm.getInputName(_vm.fabricAttribute.id, "max_roll_length"),
          id: _vm.getInputId(_vm.fabricAttribute.id, "max_roll_length"),
        },
        domProps: { value: _vm.fabricAttribute.max_roll_length },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(
              _vm.fabricAttribute,
              "max_roll_length",
              $event.target.value
            )
          },
        },
      }),
      _vm._v(" "),
      _vm.errors["max_roll_length"]
        ? _c(
            "span",
            { staticClass: "invalid-feedback", attrs: { role: "alert" } },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.errors["max_roll_length"][0]) + "\n    "
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.fabricAttribute.weight_oz,
            expression: "fabricAttribute.weight_oz",
          },
        ],
        staticClass: "form-control",
        class: _vm.errors["weight_oz"] ? "is-invalid" : "",
        attrs: {
          type: "text",
          maxlength: "50",
          placeholder: "12.17",
          name: _vm.getInputName(_vm.fabricAttribute.id, "weight_oz"),
          id: _vm.getInputId(_vm.fabricAttribute.id, "weight_oz"),
        },
        domProps: { value: _vm.fabricAttribute.weight_oz },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.fabricAttribute, "weight_oz", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _vm.errors["weight_oz"]
        ? _c(
            "span",
            { staticClass: "invalid-feedback", attrs: { role: "alert" } },
            [_vm._v("\n      " + _vm._s(_vm.errors["weight_oz"][0]) + "\n    ")]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.fabricAttribute.thickness_in,
            expression: "fabricAttribute.thickness_in",
          },
        ],
        staticClass: "form-control",
        class: _vm.errors["thickness_in"] ? "is-invalid" : "",
        attrs: {
          type: "text",
          maxlength: "50",
          placeholder: "0.027",
          name: _vm.getInputName(_vm.fabricAttribute.id, "thickness_in"),
          id: _vm.getInputId(_vm.fabricAttribute.id, "thickness_in"),
        },
        domProps: { value: _vm.fabricAttribute.thickness_in },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.fabricAttribute, "thickness_in", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _vm.errors["thickness_in"]
        ? _c(
            "span",
            { staticClass: "invalid-feedback", attrs: { role: "alert" } },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.errors["thickness_in"][0]) + "\n    "
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.fabricAttribute.stock,
            expression: "fabricAttribute.stock",
          },
        ],
        staticClass: "form-control",
        class: _vm.errors["stock"] ? "is-invalid" : "",
        attrs: {
          type: "text",
          maxlength: "50",
          placeholder: "0.027",
          name: _vm.getInputName(_vm.fabricAttribute.id, "stock"),
          id: _vm.getInputId(_vm.fabricAttribute.id, "stock"),
        },
        domProps: { value: _vm.fabricAttribute.stock },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.fabricAttribute, "stock", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _vm.errors["stock"]
        ? _c(
            "span",
            { staticClass: "invalid-feedback", attrs: { role: "alert" } },
            [_vm._v("\n      " + _vm._s(_vm.errors["stock"][0]) + "\n    ")]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c("button", {
          staticClass: "btn icon-only btn-delete",
          attrs: { type: "button", title: "Delete" },
          on: {
            click: function ($event) {
              return _vm.destroyClick($event)
            },
          },
        }),
        _vm._v(" "),
        _c("modal-confirm", {
          attrs: {
            "modal-id": _vm.fabricAttribute.id,
            "modal-title": "Are you sure?",
            "modal-body":
              "Are you sure you want to delete this fabric color? This action cannot be undone.",
            "dismiss-button": "Cancel",
            "confirm-button": "Delete",
            "hide-header-close": true,
          },
          on: {
            confirmed: function ($event) {
              return _vm.handleFunctionCall("destroy")
            },
            cancel: function ($event) {
              return _vm.handleFunctionCall("cancelModalAction")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }