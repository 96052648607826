<script>
  import ResourceAttachment from './ResourceAttachment';
  import ResourceAttachmentModal from './ResourceAttachmentModal';

  export default {
    props: [
      'parentResource',
      'parentResourceAttachments',
      'parentSelectOptions',
    ],

    components: {
      ResourceAttachment,
      ResourceAttachmentModal
    },

    data() {
      return {
        resourceAttachments: [],
        newAttachment: {},
        maxAttachments: 5,
        errors: {},
      }
    },

    mounted() {
      if( typeof this.parentResourceAttachments === 'undefined' ) {
        this.resourceAttachments = [];
      } else {
        this.resourceAttachments = this.parentResourceAttachments;
      }
    },

    computed: {
      endpointAddRow() {
        return '/admin/document-library/attachment/create/' + this.parentResource.id;
      },

      endpointSort() {
        return '/admin/document-library/attachment/sort/' + this.parentResource.id;
      },

      isNew() {
        return ( this.resource.isNew === true ) ? true : false;
      },

      canAddAttachment() {
        return ( this.resourceAttachments.length < this.maxAttachments ) ? true : false;
      },

      hasErrors() {
        if( Object.keys(this.errors).length ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      //
      // Inputs
      //
      getInputName(id, field) {
        return 'resource_attachments[' + id + '][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },


      //
      // Existing Attachments
      //
      getAllAttachments() {
        if( this.$children.length <= 0 ) {
          return [];
        } else {
          let attachments = [];
          for (var i = 0; i < this.$children.length; i++) {
            attachments.push( this.$children[i].attachment );
          }

          return attachments;
        }
      },

      destroyAttachment(attachment) {
        let i = this.resourceAttachments.map(attachment => attachment.id).indexOf(attachment.id);
        this.resourceAttachments.splice(i, 1) // remove it from array
      },


      //
      // New Attachments
      //
      addRow(event) {
        event.preventDefault();

        //First we save the resource
        this.$parent.save(false, false)
        .then((response) => {
          //Now make a request to get the new resource attachment record
          axios.post(this.endpointAddRow)
          .then((response) => {
            let attachment = response.data.data.attachment;
            attachment.isNew = true;
            attachment.attachment_type = 'file';
            this.newAttachment = attachment;
            this.showResoureAttachmentModal(event);
          })
          .catch(error => {
            this.setErrors(error.response.data.errors);
            flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
          });
        })
        .catch((error) => {
          //
        });
      },

      showResoureAttachmentModal(event) {
        event.preventDefault();
        this.$bvModal.show('resourceModal' + this.parentResource.id);
      },

      applyNewResourceAttachment(newAttachment) {
        this.resourceAttachments.push(newAttachment);
        this.resetNewAttachment();
      },

      resetNewAttachment() {
        this.newAttachment = {};
      },

      saveSortOrder(event) {
        let endpoint = this.endpointSort,
            sort_order = this.resourceAttachments.map(attachment => attachment.id),
            submitData = {
              _method: 'PUT',
              sort_order: sort_order
            };

        axios.post(endpoint, submitData)
        .then((response) => {
          //
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status); //show the error message
        });
      },

      //
      //Errors
      //
      setErrors(errors, form) {
        if( typeof errors.resource_attachment != 'undefined' ) {
          this.errors = errors.resource_attachment;
        } else {
          this.errors = {};
        }
      }
    }
  };
</script>
<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>Type</th>
          <th>File Name</th>
          <th>&nbsp;</th>
        </tr>
      </thead>

      <template v-if="resourceAttachments.length">
        <draggable
          :class="'resource-attachments'"
          :element="'tbody'"
          v-model="resourceAttachments"
          draggable=".draggable-resource-attachments"
          :preventOnFilter="false"
          @end="saveSortOrder">

          <template v-for="(attachment) in resourceAttachments">
            <resource-attachment
              :parent-resource="parentResource"
              :parent-attachment="attachment"
              :parent-select-options="parentSelectOptions"
              :ref="'attachment'">
            </resource-attachment>
          </template>

        </draggable>
      </template>

      <tbody>
        <tr>
          <td colspan="4" class="colored-bg text-center">
            <a href="#"
               v-bind:class="(canAddAttachment == false) ? 'disabled' : ''"
               :disabled="(canAddAttachment == false) ? true : false"
               @click="addRow($event)">
              Add Attachment
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <resource-attachment-modal
      :parent-resource="parentResource"
      :parent-select-options="parentSelectOptions"
      :parent-new-attachment="newAttachment"
      :ref="'documentResourceForm'">
    </resource-attachment-modal>
  </div>
</template>
