<script>
  import EquipmentCategoryOptions from './EquipmentCategoryOptions';

  export default {
    props: [
      'parentEquipmentCategory',
      'parentCategoryOptions',
    ],

    components: {
      EquipmentCategoryOptions,
    },

    data() {
      return {
        equipmentCategoryName: null,
        categoryOptionList: [],
      }
    },

    mounted() {
      this.equipmentCategoryName = this.parentEquipmentCategory;
      this.categoryOptionList = this.parentCategoryOptions;
    },

    computed: {
      categoryNameFormatted() {
        return this.equipmentCategoryName;
      }
    }
  };
</script>
