export const shadeOrderSettingsMixin = {

  methods: {
    save(event) {
      if( !this.isNew ) {
        this.triggerSubmit(event);
      }
    }
  },

  directives: {
    settingsFieldDirective: {
      bind: function (el, binding, vnode) {
        var checkInputs = function(e) {
          //This is a new estimate - do not attempt to save automatically
          if( vnode.context['isNew'] ) {
            return;
          }

          var allInputsFilled = true,
              $el = $(el),
              $parentTableRow = $(el).closest('tr');

          if( $parentTableRow.length > 0 && $parentTableRow.hasClass('require-all') ) {
            var $rowInputs = $parentTableRow.find(':input:not([type="hidden"])').not(':input[disabled]', ':input[readonly]');
            $.each($rowInputs, function(index, item) {
              if( $(item).val() === null || $(item).val() == '' ) {
                allInputsFilled = false;
              }
            });
          }

          if( allInputsFilled === true ) {
            vnode.context['save'](e);
          }
        }

        //
        // Add the event listeners
        //
        if( $(el).is(':checkbox') ) {
          el.addEventListener('change', checkInputs, true);
        } else {
          el.addEventListener('blur', checkInputs, true);
        }
      }
    }
  }
}
