<script>
  import ModalConfirm from '../../notifications/ModalConfirm.vue';
  import VideoModal from '../../global/VideoModal.vue';

  export default {
    props: [
      'parentResource',
      'parentAttachment',
      'parentSelectOptions',
    ],

    components: {
      ModalConfirm,
      VideoModal
    },

    data() {
      return {
        deleteAttachmentModalId: 'deleteAttachment'
      }
    },

    mounted() {},

    computed: {
      attachment() {
        return this.parentAttachment;
      },

      endpointDestroy() {
        return '/admin/document-library/attachment/' + this.attachment.id;
      },

      endpointDownload() {
        return '/admin/document-library/attachment/download/' + this.attachment.id;
      },

      isNew() {
        return ( this.attachment.isNew === true ) ? true : false;
      },

      isVideo() {
        return ( this.attachment.attachment_type == 'video' ) ? true : false;
      },

      isFile() {
        return ( this.attachment.attachment_type == 'file' ) ? true : false;
      },

      attachmentTypeText() {
        let attachmentType = this.attachment.attachment_type;
        return attachmentType.charAt(0).toUpperCase() + attachmentType.slice(1);
      },

      attachmentFileNameText() {
        return this.attachment.file_name;
      },

      viewAttachmentLinkText() {
        return ( this.isVideo ) ? 'Watch' : 'Download';
      },


      //
      //Modal (Delete)
      //
      modalCancelAction() {
        return 'cancelModalAction';
      },

      modalDestroyConfirmButtonAction() {
        return 'destroy';
      },

    },

    methods: {
      //
      // Inputs
      //
      getInputName(id, field) {
        return 'resource_attachments[' + id + '][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },


      //
      // Attachment Form Actions
      //
      destroy() {
        if( this.isNew === true ) {
          this.$parent.destroyAttachment( this.attachment );

        } else {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            this.$parent.$parent.destroyAttachment( this.attachment );
            flash(response.data.status_type, response.data.status);
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      },

      //
      // View Attachments
      //
      viewAttachment(event) {
        event.preventDefault();

        if( this.isFile ) {
          this.downloadFile(event);
        } else {
          this.watchVideo(event);
        }
      },

      downloadFile(event) {
        event.preventDefault();
        let endpoint = this.endpointDownload;

        axios.post(endpoint)
        .then((response) => {
          var fileLink = document.createElement('a');
          fileLink.href = response.data.data.downloadUrl;
          fileLink.setAttribute('download', 'file.pdf');
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status); //show the error message
        });
      },

      watchVideo(event) {
        event.preventDefault();
        this.$bvModal.show('videoModal-' + this.attachment.id);
      },

      //
      // Modals (Delete)
      //
      destroyAttachmentClick(event) {
        event.preventDefault();
        this.$bvModal.show(this.deleteAttachmentModalId + this.attachment.id);
      },

      //Handle modal function call
      handleFunctionCall(functionName) {
        this[functionName]();
      },

      //Cancel out of an open/active modal without completeing action
      cancelModalAction() {
        return false;
      },
    }
  };
</script>
<template>
  <tr class="draggable-resource-attachments">
    <td><div class="sort-handle"></div></td>
    <td>{{ attachmentTypeText }}</td>
    <td>
      <a @click="viewAttachment($event)" href="#">{{ attachmentFileNameText }}</a>
      <video-modal
        :modal-id="attachment.id"
        :video-url="attachment.attachment_path"
        :auto-play="true"
        :modal-size="'lg'"
        :modal-title="'Are you sure?'"
        :modal-body="'Are you sure you want to delete this attachment? This action cannot be undone.'"
        :dismiss-button="'Cancel'"
        :confirm-button="'Delete'"
        :hide-header-close="false"
        @confirmed="handleFunctionCall(modalDestroyConfirmButtonAction)"
        @cancel="handleFunctionCall(modalCancelAction)">
      </video-modal>
    </td>
    <td>
      <a @click="destroyAttachmentClick($event)" href="#" class="btn icon-only btn-delete"></a>
      <modal-confirm
        :modal-id-attr-override="deleteAttachmentModalId"
        :modal-id="attachment.id"
        :modal-title="'Are you sure?'"
        :modal-body="'Are you sure you want to delete this attachment? This action cannot be undone.'"
        :dismiss-button="'Cancel'"
        :confirm-button="'Delete'"
        :hide-header-close="true"
        @confirmed="handleFunctionCall(modalDestroyConfirmButtonAction)"
        @cancel="handleFunctionCall(modalCancelAction)">
      </modal-confirm>
    </td>
  </tr>
</template>
