var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-row estimate-widget field-measure-labor-expenses" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-sm-12 table-responsive" }, [
        _c("table", { staticClass: "table" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.orderProposal.field_measure_trips,
                      expression: "orderProposal.field_measure_trips",
                    },
                    {
                      name: "proposal-field-directive",
                      rawName: "v-proposal-field-directive",
                    },
                  ],
                  staticClass: "form-control min-width-field",
                  class: _vm.errors["field_measure_trips"] ? "is-invalid" : "",
                  attrs: {
                    type: "text",
                    placeholder: "1",
                    name: _vm.getInputName("field_measure_trips"),
                    id: _vm.getInputId("field_measure_trips"),
                  },
                  domProps: { value: _vm.orderProposal.field_measure_trips },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.orderProposal,
                        "field_measure_trips",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.orderProposal.field_measure_labor_hours,
                      expression: "orderProposal.field_measure_labor_hours",
                    },
                    {
                      name: "proposal-field-directive",
                      rawName: "v-proposal-field-directive",
                    },
                  ],
                  staticClass: "form-control min-width-field",
                  class: _vm.errors["field_measure_labor_hours"]
                    ? "is-invalid"
                    : "",
                  attrs: {
                    type: "text",
                    placeholder: "1",
                    name: _vm.getInputName("field_measure_labor_hours"),
                    id: _vm.getInputId("field_measure_labor_hours"),
                  },
                  domProps: {
                    value: _vm.orderProposal.field_measure_labor_hours,
                  },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.orderProposal,
                        "field_measure_labor_hours",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.orderProposal.fm_total_hours))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.fm_total_cost_formatted)),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
      _c("h2", [_vm._v("Field Measure Labor")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("# Trips")]),
        _vm._v(" "),
        _c("th", [_vm._v("Labor Time (Hours)")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total Labor Time (Hours)")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total Labor Cost ($)")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }