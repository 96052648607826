var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "resourceFormModal",
          attrs: {
            id: _vm.modalIdAttr,
            size: _vm.modalSizeAttr,
            title: _vm.modalTitleText,
            "ok-title": _vm.confirmButtonText,
            "cancel-title": _vm.dismissButtonText,
            "no-close-on-backdrop": true,
            "hide-header-close": _vm.hideHeaderCloseButton,
          },
          on: { ok: _vm.emitConfirm, cancel: _vm.emitCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "div",
                    [
                      _c("b-button", {
                        attrs: { variant: "secondary" },
                        domProps: { innerHTML: _vm._s(_vm.dismissButtonText) },
                        on: {
                          click: function ($event) {
                            return cancel()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("b-button", {
                        attrs: { variant: "primary" },
                        domProps: { innerHTML: _vm._s(_vm.confirmButtonText) },
                        on: {
                          click: function ($event) {
                            return ok()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-sm-12 col-md-12" }, [
              _c(
                "label",
                {
                  attrs: {
                    for: _vm.getInputId(_vm.attachment.id, "attachment_type"),
                  },
                },
                [_vm._v("Attachment Type")]
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.attachment.attachment_type,
                      expression: "attachment.attachment_type",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors["attachment_type"] ? "is-invalid" : "",
                  attrs: {
                    name: _vm.getInputName(
                      _vm.attachment.id,
                      "attachment_type"
                    ),
                    id: _vm.getInputId(_vm.attachment.id, "attachment_type"),
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.attachment,
                          "attachment_type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.setAttchmentType($event)
                      },
                    ],
                  },
                },
                _vm._l(
                  _vm.parentSelectOptions.resourceAttachmentTypeOptions,
                  function (option) {
                    return _c("option", { domProps: { value: option.value } }, [
                      _vm._v(_vm._s(option.label)),
                    ])
                  }
                ),
                0
              ),
              _vm._v(" "),
              _vm.errors["attachment_type"]
                ? _c(
                    "span",
                    {
                      staticClass: "invalid-feedback",
                      attrs: { role: "alert" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.errors["attachment_type"][0]) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-row" },
            [
              _vm.isVideo
                ? [
                    _c(
                      "div",
                      { staticClass: "form-group col-sm-12 col-md-12" },
                      [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: _vm.getInputId(
                                _vm.attachment.id,
                                "attachment_type"
                              ),
                            },
                          },
                          [_vm._v("Video URL")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.attachment.attachment_path,
                              expression: "attachment.attachment_path",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.errors["attachment_path"]
                            ? "is-invalid"
                            : "",
                          attrs: {
                            type: "text",
                            placeholder: "Video URL",
                            name: _vm.getInputName(
                              _vm.attachment.id,
                              "attachment_path"
                            ),
                            id: _vm.getInputId(
                              _vm.attachment.id,
                              "attachment_path"
                            ),
                          },
                          domProps: { value: _vm.attachment.attachment_path },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.attachment,
                                "attachment_path",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors["attachment_path"]
                          ? _c(
                              "span",
                              {
                                staticClass: "invalid-feedback",
                                attrs: { role: "alert" },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.errors["attachment_path"][0]) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "form-group col-sm-12 col-md-12" },
                      [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: _vm.getInputId(
                                _vm.attachment.id,
                                "new_attached_file"
                              ),
                            },
                          },
                          [_vm._v("Attachment")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control-file vue-file-input",
                          class: _vm.errors["new_attached_file"]
                            ? "is-invalid"
                            : "",
                          attrs: {
                            type: "file",
                            id: _vm.getInputId(
                              _vm.attachment.id,
                              "new_attached_file"
                            ),
                            disabled: _vm.isFormUploadFieldDisabled,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.setAttachedFileData($event)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors["new_attached_file"]
                          ? _c(
                              "span",
                              {
                                staticClass: "invalid-feedback",
                                attrs: { role: "alert" },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.errors["new_attached_file"][0]) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }