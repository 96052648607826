var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tab-pane fade",
      class: _vm.activeTabClass,
      attrs: {
        id: _vm.tabContainerId,
        role: "tabpanel",
        "aria-labelledby": _vm.tabContainerAriaLabelledby,
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", {
          staticClass: "group col-sm-12",
          domProps: { innerHTML: _vm._s(_vm.tabDescriptionText) },
        }),
      ]),
      _vm._v(" "),
      _vm.orderTypeStatusNotifications.length
        ? [
            _c("order-type-status-notifications", {
              ref: "orderTypeStatusNotifications",
              attrs: {
                "parent-project-order-type": _vm.projectOrderType,
                "parent-order-type-status-notifications":
                  _vm.orderTypeStatusNotifications,
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "form-group col-sm-12" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-primary",
              on: {
                click: function ($event) {
                  return _vm.saveNotifications($event)
                },
              },
            },
            [_vm._v("Submit")]
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }