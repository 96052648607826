var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-row estimate-widget coord-labor-expenses" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-sm-12 table-responsive" }, [
        _c("table", { staticClass: "table" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm.proposalCoordinationLabors.length > 0
                ? [
                    _vm._l(
                      _vm.proposalCoordinationLabors,
                      function (coordinationLabor, index) {
                        return [
                          _c("proposal-coordination-labor", {
                            attrs: {
                              "coordination-labor": coordinationLabor,
                              "coordination-labor-id": coordinationLabor.id,
                            },
                          }),
                        ]
                      }
                    ),
                    _vm._v(" "),
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "colored-bg text-center",
                          attrs: { colspan: "5" },
                        },
                        [
                          _c(
                            "a",
                            {
                              class:
                                _vm.orderProposal.userCanEdit == false
                                  ? "disabled"
                                  : "",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.addProposalCoordinationLabor(
                                    $event
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                Add Coordination Labor\n              "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                : [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "colored-bg text-center",
                          attrs: { colspan: "5" },
                        },
                        [
                          _c(
                            "a",
                            {
                              class:
                                _vm.orderProposal.userCanEdit == false
                                  ? "disabled"
                                  : "",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.addProposalCoordinationLabor(
                                    $event
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                  Add Coordination Labor\n              "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ],
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
      _c("h2", [_vm._v("Coordination Labor Expense")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Position")]),
        _vm._v(" "),
        _c("th", [_vm._v("HR")]),
        _vm._v(" "),
        _c("th", [_vm._v("$/HR")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total")]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }