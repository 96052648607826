var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    directives: [
      { name: "show", rawName: "v-show", value: _vm.show, expression: "show" },
    ],
    staticClass: "alert fixed-alert alert-flash",
    class: _vm.type,
    attrs: { role: "alert" },
    domProps: { innerHTML: _vm._s(_vm.message) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }