//import { TOGGLE_SIDEBAR } from '../mutation-types'

/*
  import { ENDPOINT_NEW_COUPLED_SHADE,
            ENDPOINT_DESTROY_COUPLED_SHADE_LINE_ITEM,
            ENDPOINT_COPY_COUPLED_SHADE_LINE_ITEM,
            ENDPOINT_GET_FABRIC_OPENNESS_OPTIONS,
            ENDPOINT_GET_FABRIC_ATTRIBUTE_OPTIONS,
            ENDPOINT_GET_MOTOR_TYPE_OPTIONS,
            ENDPOINT_GET_MOTOR_POWER_OPTIONS,
            ENDPOINT_GET_MOTOR_SOUND_OPTIONS
          } from '../endpoints'
*/

import { mapGetters, mapActions } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import { v4 as uuidv4 } from 'uuid'


export const SHADE_LINE_ITEM_DEFAULT_VALUES = {
  back_fascia: 0,
  drive_type: "M-I",
  errors: {},
  handedness: 'left',
  hardware_color: 'black',
  has_trim: 0,
  hasChanges: false,
  hold_down: 1,
  is_door: 0,
  isNew: false,
  mount_type: "wall",
  order_item_assembly_hembar_composition: "A",
  product_model_dual_shade: 0,
  product_model_guided_cable: 0,
  roll_type: 'standard'
};


//
//  TODO Before this is Complete...
//
//  1) Submit Form. We need to think about this and plan out what the return data should be...
//    Should this submit ALL line items?
//    Should we do this as a "per line" submission process? (i.e. 1 line, auto submit & show errors?)
//    We're going to need to "commit" the changes, so what should the response data look like?
//      Just an array of shade line items? The individual shade line item? Something else?
//
//  2) Errors... (take a look at the ERROR RESPONSE in the submitForm Action...)
//     Error handling is an absolute must. The Vuex changes have disabled this. We need to hook it back up...
//
export default {
  namespaced: true,

  //
  // State
  //
  state: {

    byId: {},
    byGroupId: {},
    allIds: [],
    shadeLineItems: [],
    errors: {},

    shadeLineItemsInitialState: [],
    initialStateById: {}
  },

  //
  // Getters
  //
  getters: {
    //
    // List/multiple object getters
    //
    shadeLineItems: state => state.shadeLineItems,

    //
    // Singular object getters
    //
    shadeLineItem: (state) => (id) => {
      return state.shadeLineItems.find(shadeLineItem => shadeLineItem.id == id)
    },

    errorsByRecordId: (state) => (id) => {
      if( state.errors.hasOwnProperty(id) ) {
        return state.errors[id];
      } else {
        return {};
      }
    },


    //
    // Flattened Data Approach
    //
    find: state => id => state.byId[id],

    list: (state, getters) => {
      return state.allIds.map(id => getters.find(id));
    },

    getInitialStateById: state => id => state.initialStateById[id],

    fabricSummaryText: (state, rootState, store, getters) => (id) => {
      var shadeUnit = getters['proposalShadeLineItems/find'](id);
      var ret = '';

      var fabricColor = ( shadeUnit.fabric_attribute ) ? shadeUnit.fabric_attribute : null;
      var fabricOpenness = ( shadeUnit.fabric_attribute && shadeUnit.fabric_attribute.fabric_openness_factor ) ? shadeUnit.fabric_attribute.fabric_openness_factor : null;
      var fabric = ( shadeUnit.fabric_attribute && shadeUnit.fabric_attribute.fabric_openness_factor && shadeUnit.fabric_attribute.fabric_openness_factor.fabric ) ? shadeUnit.fabric_attribute.fabric_openness_factor.fabric : null;

      ret += ( fabric ) ? fabric.name + ' ' : '';
      ret += ( fabricOpenness ) ? fabricOpenness.name + ' ' : '';
      ret += ( fabricColor ) ? fabricColor.color : '';

      return ret;
    },

    dualFabricSummaryText: (state, rootState, store, getters) => (id) => {
      var shadeUnit = getters['proposalShadeLineItems/find'](id);
      var ret = '';

      var fabricColor = ( shadeUnit.dual_fabric_attribute ) ? shadeUnit.dual_fabric_attribute : null;
      var fabricOpenness = ( shadeUnit.dual_fabric_attribute && shadeUnit.dual_fabric_attribute.fabric_openness_factor ) ? shadeUnit.dual_fabric_attribute.fabric_openness_factor : null;
      var fabric = ( shadeUnit.dual_fabric_attribute && shadeUnit.dual_fabric_attribute.fabric_openness_factor && shadeUnit.dual_fabric_attribute.fabric_openness_factor.fabric ) ? shadeUnit.dual_fabric_attribute.fabric_openness_factor.fabric : null;

      ret += ( fabric ) ? fabric.name + ' ' : '';
      ret += ( fabricOpenness ) ? fabricOpenness.name + ' ' : '';
      ret += ( fabricColor ) ? fabricColor.color : '';

      return ret;
    },

    //
    // vuex-map-fields plugin
    //
    getField,
  },


  //
  // Actions
  //
  actions: {
    //
    // Add Shade Line Items to the store (initial load)
    //
    applyShadeLineItems({commit}, shadeLineItems) {
      if( shadeLineItems ) {
        shadeLineItems.forEach(function(shadeLineItem, index) {
          shadeLineItem = _.mergeWith(shadeLineItem, SHADE_LINE_ITEM_DEFAULT_VALUES, function(sliValue, defaultValue) {
            if( (_.isNull(sliValue) || _.isUndefined(sliValue)) && (!_.isNull(defaultValue) && !_.isUndefined(defaultValue)) ) {
              return defaultValue;
            } else {
              return sliValue;
            }
          });

          //flatten the object for easier use with vuex
          shadeLineItem.order_item_assembly_hembar_composition = shadeLineItem.order_item_assembly.hembar_composition;
          shadeLineItem.order_item_assembly_spring_position = shadeLineItem.order_item_assembly.spring_position;
          shadeLineItem.order_item_assembly_notes = shadeLineItem.order_item_assembly.notes;

          commit('ADD_PROPOSAL_SHADE_LINE_ITEM', shadeLineItem);
          commit('SET_PROPOSAL_SHADE_LINE_ITEMS', shadeLineItem);
        });
      }
    },

  },


  //
  // Mutations
  //
  mutations: {
    ADD_PROPOSAL_SHADE_LINE_ITEM(state, item) {
      //Set state for shade line items byId
      Vue.set(state.byId, item.id, item);

      //Set the initial state (so we can easily revert to previous object if necessary)
      Vue.set(state.initialStateById, item.id, _.cloneDeep(item));

      if( typeof state.byGroupId[item.shade_group_id] == 'undefined' ) {
        state.byGroupId = Object.assign({}, state.byGroupId, { [item.shade_group_id]: { shadeLineItems: [] } });
      }
      state.byGroupId[item.shade_group_id].shadeLineItems.push(item);

      //Set state for all shade line item ids
      if (state.allIds.includes(item.id)) return;
      state.allIds.push(item.id);

      //
    },

    SET_PROPOSAL_SHADE_LINE_ITEMS(state, shadeLineItem) {
      state.shadeLineItems.push(shadeLineItem);
    },



    //
    // vuex-map-fields plugin
    //
    updateField,

    //
    // This is an override of the updateField method from vuex...
    //
/*
    updateField(state, { path, value }) {
      var splitPath = path.split(/[.[\]]+/),
          subjectShade = state.byGroupId[splitPath[1]].shadeLineItems[splitPath[3]],
          subjectShadeInitialState = null;

      if( typeof subjectShade !== 'undefined' && subjectShade.hasOwnProperty('id') ) {
        subjectShadeInitialState = state.initialStateById[subjectShade.id];
      }

      path.split(/[.[\]]+/).reduce((prev, key, index, array) => {
        if (array.length === index + 1) {
          // eslint-disable-next-line no-param-reassign
          prev[key] = value;

          //If the new value is not equal to the value stored in initial state, then set the 'hasChanges' property to true
          //so we can be sure to trigger the save/update method on this shade.
          if( value != subjectShadeInitialState[key] ) {
            prev['hasChanges'] = true;
          }
        }

        return prev[key];
      }, state);
    }
*/

  }
}
