var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row estimate-widget sales-tax" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-sm-12" }, [
      _c("table", { staticClass: "form" }, [
        _c("tbody", [
          _c("tr", [
            _c("td", [_vm._v("Sales Tax")]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.orderProposal.sales_tax_pct,
                    expression: "orderProposal.sales_tax_pct",
                  },
                  {
                    name: "proposal-field-directive",
                    rawName: "v-proposal-field-directive",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors["sales_tax_pct"] ? "is-invalid" : "",
                attrs: {
                  type: "text",
                  placeholder: "1",
                  name: _vm.getInputName("sales_tax_pct"),
                  id: _vm.getInputId("sales_tax_pct"),
                },
                domProps: { value: _vm.orderProposal.sales_tax_pct },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.orderProposal,
                      "sales_tax_pct",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
      _c("h2", [_vm._v("Sales Tax")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }