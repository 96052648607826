<script>
  import UserForNewCompanyForm from '../user/UserForNewCompanyForm';

  export default {
    props: [
      'masterCompany',
      'masterSelectOptions',
      'masterFormMethod',
    ],

    components: {
      UserForNewCompanyForm,
    },

    data() {
      return {
        company: {
          id: null,
          company_type_id: null,
          distributor_id: null,
          name: null,
          gbl_payment_terms_id: null,
          credit_hold: false,
          margin_pct: null,
          use_tax_pct: null,
          dealer_commission_pct: null,
          deleted_at: null,
        },
        location: {
          id: null,
          company_id: null,
          name: null,
          address_line_one: null,
          address_line_two: null,
          address_line_three: null,
          city: null,
          state: null,
          postal_code: null,
          building: null,
          floor: null,
          is_company_location: null
        },
        errorsComp: [],
        errorsLocation: []
      }
    },

    mounted() {
      this.company = { ...this.company, ...this.masterCompany };
        this.company.margin_pct = this.company.margin_pct_formatted;
        this.company.part_cost_adjustment_pct = this.company.part_cost_adjustment_pct_formatted;
        this.company.use_tax_pct = this.company.use_tax_pct_formatted;
        this.company.dealer_commission_pct = this.company.dealer_commission_pct_formatted;

      this.location = { ...this.location, ...this.company.location };
        delete this.company.location;
    },

    computed: {
      endpointCreate() {
        return '/admin/companies/store';
      },

      endpointUpdate() {
        return '/admin/companies/update/' + this.company.id;
      },

      isNewCompany() {
        return ( this.masterFormMethod == 'POST' ) ? true : false;
      },

      companyTypeSelectOptions() {
        return this.selectOptions.companyTypeOptions;
      },

      selectOptions() {
        return this.masterSelectOptions;
      },

      globalPaymentTermsSelectOptions() {
        var pmtTermOptions = this.selectOptions.paymentTermOptions;
        var options = [];
        if( pmtTermOptions.length ) {
          switch( this.company.company_type_id ) {
            case 1: //Lutek Company Type
              options = pmtTermOptions.filter(function(opt) {
                if( opt.proposal_type == 'Commercial' || opt.proposal_type == 'Direct' ) {
                  return opt;
                }
              });
              break;

            case 2: //Dealer Company Type
              options = pmtTermOptions.filter(function(opt) {
                return opt.proposal_type == 'Dealer';
              });
              break;

            case 3: //Designer Company Type
              options = [];
              break;
          }
        }
        return options;
      },

      userSelectOptions() {
        return this.selectOptions.userSelectOptions;
      },

      distributorSelectOptions() {
        return this.selectOptions.distributors;
      },

      selectedDistributorAccount() {
        if( !this.company.distributor_id ) {
          return null;
        }

        var distributor = this.selectOptions.distributors.find(dist => {
          return dist.id == this.company.distributor_id;
        });
        return distributor;
      },

      isFieldDisabled() {
        if( this.company.deleted_at != null ) {
          return true;
        }
        return false;
      },

      disableAccountTypeField() {
        if( this.isFieldDisabled == true || this.masterFormMethod == 'PUT' ) {
          return true;
        }
        return false;
      },

      disablePaymentTypeOptionField() {
        if( this.isFieldDisabled || this.company.company_type_id == null || this.company.company_type_id == 3 ) {
          return true;
        }
        return false;
      },
    },

    methods: {
      getInputName(field) {
        return 'comp['+ field +']';
      },

      getInputId(field) {
        var inputName = this.getInputName(field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      getLocationInputName(field) {
        return 'location['+ field +']';
      },

      getLocationInputId(field) {
        var inputName = this.getLocationInputName(field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      submitForm(event) {
        event.preventDefault();
        var endpoint = ( this.masterFormMethod == 'POST' ) ? this.endpointCreate : this.endpointUpdate,
            submitData = {
              _method: this.masterFormMethod,
              comp: this.company,
              location: this.location
            };

        if( this.masterFormMethod == 'POST' ) {
          submitData.contact_info = this.$children[0].contactInfo;
          submitData.user = this.$children[0].user;
          submitData.user_role = this.$children[0].user_role;
        }

        axios.post(endpoint, submitData)
        .then((response) => {
          if( response.data.redirectTo != '' ) {
            window.location.href = response.data.redirectTo;
          }
        })
        .catch(error => {
          this.setErrors(error.response.data.errors);
          flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
        });
      },

      setErrors(errors) {
        this.errorsComp = ( errors.hasOwnProperty('comp') ) ? errors.comp : {};
        this.errorsLocation = ( errors.hasOwnProperty('location') ) ? errors.location : {};

        //Set user form errors if applicable
        this.$children[0].setErrors(errors);
      }
    },

    watch: {
      'company.distributor_id': function(newVal, oldVal) {
        if( this.isNewCompany && newVal != oldVal ) {
          if( this.selectedDistributorAccount ) {
            this.company.dealer_commission_pct = this.selectedDistributorAccount.dealer_commission_pct_formatted;
          } else {
            this.company.dealer_commission_pct = null;
          }
        }
      }
    }
  }
</script>
