<script>
  export default {
    props: [
      'parentFieldMeasureNote'
    ],

    components: {},

    data() {
      return {
        fieldMeasureNote: {
          description: null,
          selected_value: null,
        }
      }
    },

    mounted() {
      this.fieldMeasureNote = {...this.fieldMeasureNote, ...this.parentFieldMeasureNote };
      if( typeof this.fmNoteResponse != 'undefined' ) {
        this.fieldMeasureNote.selected_value = this.fmNoteResponse.selected_value;
      } else {
        this.fieldMeasureNote.selected_value = 'n/a';
      }
    },

    computed: {
      isSelected() {
        return ( this.fieldMeasureNote.selected_value != 'n/a' ) ? true : false;
      },

      fmNoteResponse() {
        return this.$parent.installerFmResponseList.find(fmResponse => {
          return fmResponse.field_measure_checklist_id == this.fieldMeasureNote.id;
        });
      },

      isDisabled() {
        return false;
      },

      errors() {
        if( this.$parent.errors.hasOwnProperty('field_measure_checklist') && this.$parent.errors.field_measure_checklist.hasOwnProperty(this.fieldMeasureNote.id) ) {
          return this.$parent.errors.field_measure_checklist[this.fieldMeasureNote.id];
        } else {
          return [];
        }
      }
    },

    methods: {
      getChecklistInputName(id, field) {
        return 'fm_note[field_measure_checklist][' + id + '][' + field + ']';
      },

      getChecklistInputId(id, field) {
        var inputName = this.getChecklistInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      }
    }
  };
</script>
