<script>
  export default {
    props: [
      'shadeUnit',
      'unitType',
      'containerClass',
      'errors',
      'disabled'
    ],

    mounted() {
      /*
        console.log('shade preferred fascia size - mounted');
        console.log(this.shadeUnit);
        console.log('');
      */
    },

    computed: {
      parentContainerClass() {
        return this.containerClass;
      },

      preferredFasciaSizeOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.preferredFasciaSizeOptions;
      },

      hasFascia() {
        if( this.unitType == 'shadeConfigUnit' ) {
          return this.$store.getters['shadeUnitConfigs/hasFascia'](this.shadeUnit.id);
        } else {
          return this.$store.getters['shadeLineItems/hasFascia'](this.shadeUnit.id);
        }
      },

      canHavePreferredFasciaSize() {
        var preferredFasciaSizeAvailableFor = ['Fascia', 'Fabric Wrapped Fascia'];
        var isAvailable = false;

        if( this.hasFascia == true ) {
          isAvailable = preferredFasciaSizeAvailableFor.includes(this.shadeUnit.fascia_type);
        }

        if( isAvailable == false ) {
          this.shadeUnit.preferred_fascia_size = null;
        }

        return isAvailable;
      },

      hasErrors() {
        if( typeof errors.preferred_fascia_size != 'undefined' ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return this.$parent.getInputName(id, field);
      },

      getInputId(id, field) {
        return this.$parent.getInputId(id, field);
      },
    }
  }
</script>
