var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-responsive" },
    [
      _c(
        "table",
        { staticClass: "table" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.resourceAttachments.length
            ? [
                _c(
                  "draggable",
                  {
                    class: "resource-attachments",
                    attrs: {
                      element: "tbody",
                      draggable: ".draggable-resource-attachments",
                      preventOnFilter: false,
                    },
                    on: { end: _vm.saveSortOrder },
                    model: {
                      value: _vm.resourceAttachments,
                      callback: function ($$v) {
                        _vm.resourceAttachments = $$v
                      },
                      expression: "resourceAttachments",
                    },
                  },
                  [
                    _vm._l(_vm.resourceAttachments, function (attachment) {
                      return [
                        _c("resource-attachment", {
                          ref: "attachment",
                          refInFor: true,
                          attrs: {
                            "parent-resource": _vm.parentResource,
                            "parent-attachment": attachment,
                            "parent-select-options": _vm.parentSelectOptions,
                          },
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "colored-bg text-center",
                  attrs: { colspan: "4" },
                },
                [
                  _c(
                    "a",
                    {
                      class: _vm.canAddAttachment == false ? "disabled" : "",
                      attrs: {
                        href: "#",
                        disabled: _vm.canAddAttachment == false ? true : false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addRow($event)
                        },
                      },
                    },
                    [_vm._v("\n            Add Attachment\n          ")]
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("resource-attachment-modal", {
        ref: "documentResourceForm",
        attrs: {
          "parent-resource": _vm.parentResource,
          "parent-select-options": _vm.parentSelectOptions,
          "parent-new-attachment": _vm.newAttachment,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(" ")]),
        _vm._v(" "),
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("File Name")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }