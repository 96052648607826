var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "part-map-item expandable-block small",
      class: _vm.isSortableContainerClass,
    },
    [
      _c("div", { staticClass: "sort-handle" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "expandable-body",
          class: _vm.isMaximized == true ? "maximized" : "minimized",
        },
        [
          _c("div", { staticClass: "title-bar-wrapper" }, [
            _c(
              "div",
              {
                staticClass: "title-bar",
                on: {
                  dblclick: function ($event) {
                    return _vm.toggleFullView($event)
                  },
                },
              },
              [
                _c("div", { staticClass: "summary" }, [
                  _c("h2", [
                    _vm._v("\n            " + _vm._s(_vm.partName) + " "),
                    _vm.partType
                      ? _c("span", { staticClass: "hint" }, [
                          _vm._v("(" + _vm._s(_vm.partType) + ")"),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.partNumber
                    ? _c("span", { staticClass: "setting-text" }, [
                        _vm._v("Part Number: " + _vm._s(_vm.partNumber)),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "actions" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isMaximized == false,
                          expression: "isMaximized == false",
                        },
                      ],
                      staticClass: "link edit",
                    },
                    [
                      _c("a", {
                        on: {
                          click: function ($event) {
                            return _vm.toggleFullView($event)
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isMaximized == true,
                          expression: "isMaximized == true",
                        },
                      ],
                      staticClass: "link minimize",
                    },
                    [
                      _c("a", {
                        on: {
                          click: function ($event) {
                            return _vm.toggleFullView($event)
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "link delete" }, [
                    _c("a", {
                      on: {
                        click: function ($event) {
                          return _vm.destroy()
                        },
                      },
                    }),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content-wrapper" },
            [
              _c("transition", { attrs: { name: "slide" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isMaximized,
                        expression: "isMaximized",
                      },
                    ],
                    staticClass: "transition-container",
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isMaximized,
                            expression: "isMaximized",
                          },
                        ],
                        staticClass: "content-block",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mappedPart.id,
                              expression: "mappedPart.id",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            name: _vm.getInputName(_vm.mappedPart.id, "id"),
                          },
                          domProps: { value: _vm.mappedPart.id },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.mappedPart,
                                "id",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-sm-12 col-md-4" },
                            [
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: _vm.getInputId(
                                      _vm.mappedPart.id,
                                      "head_part_id"
                                    ),
                                    "data-toggle": "tooltip",
                                    "data-html": "true",
                                    "data-original-title": "Select Part",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  Parts\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.mappedPart.head_part_id,
                                      expression: "mappedPart.head_part_id",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: _vm.errors["head_part_id"]
                                    ? "is-invalid"
                                    : "",
                                  attrs: {
                                    name: _vm.getInputName(
                                      _vm.mappedPart.id,
                                      "head_part_id"
                                    ),
                                    id: _vm.getInputId(
                                      _vm.mappedPart.id,
                                      "head_part_id"
                                    ),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.mappedPart,
                                          "head_part_id",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        return _vm.setMappedPart($event)
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("option", { domProps: { value: null } }, [
                                    _vm._v("--Choose One--"),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.selectOptions,
                                    function (partType) {
                                      return _c(
                                        "optgroup",
                                        { attrs: { label: partType.name } },
                                        [
                                          _vm._l(
                                            partType.parts,
                                            function (part) {
                                              return [
                                                part.isSelected != true ||
                                                _vm.mappedPart.head_part_id ==
                                                  part.id
                                                  ? _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          "data-part-name":
                                                            part.name,
                                                          "data-part-type":
                                                            partType.name,
                                                        },
                                                        domProps: {
                                                          value: part.id,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(part.name) +
                                                            "\n                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.errors["head_part_id"]
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "invalid-feedback",
                                      attrs: { role: "alert" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.errors["head_part_id"][0]
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }