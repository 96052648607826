<script>
  import { mapMultiRowFields } from 'vuex-map-fields';
  import { proposalMixin } from '../../mixins/proposalMixin';
  import ProposalProjectExpense from './ProposalProjectExpense';

  export default {
    props: [
      'currentOrderProposal',
      'canEdit'
    ],

    mixins: [proposalMixin],

    components: {
      ProposalProjectExpense,
    },

    mounted() {
      /*
        console.log('ProposalProjectExpenses.vue (PLURAL) - Mounted');
        console.log(this.orderProposal);
        console.log('');
      */
    },

    computed: {
      orderProposal() {
        return this.currentOrderProposal;
      },

      ...mapMultiRowFields('proposalProjectExpense', ['proposalProjectExpenses']),

      errors() {
        return [];
      }
    },

    methods: {
      getInputName(id, field) {
        return 'proposal_project_expenses[' + id + '][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },
    }
  };
</script>

<template>
  <div class="table-responsive estimate-widget project-expenses">
    <table class="table">
      <thead>
        <tr>
          <th>Description</th>
          <th>%</th>
          <th>Cost ($)</th>
        </tr>
      </thead>

      <tbody>
        <template v-if="proposalProjectExpenses.length <= 0">
          <tr>
            <td colspan="3" class="colored-bg text-center">No Project Expenses</td>
          </tr>
        </template>

        <template v-else v-for="(projectExpense, index) in proposalProjectExpenses">
          <proposal-project-expense
            :project-expense="projectExpense"
            :project-expense-id="projectExpense.id"
            :can-edit="canEdit">
          </proposal-project-expense>
        </template>

        <template v-if="proposalProjectExpenses.length > 0">
          <tr>
            <td colspan="2">Subtotal</td>
            <td>{{ orderProposal.project_expense_subtotal_formatted }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
