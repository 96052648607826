<script>
  import draggable from 'vuedraggable'
  import ProductModelPart from './ProductModelPart'

  export default {
    props: [
      'productModel',
      'selectOptions',
    ],

    components: {
      draggable,
      ProductModelPart
    },

    data() {
      return {
        productModelParts: [],
        errors: {}
      }
    },

    mounted() {
      this.productModelParts = ( this.productModel.product_model_parts ) ? this.productModel.product_model_parts : [];
    },

    computed: {
      endpointAddRow() {
        return '/admin/settings/products/models/parts/add-row/' + this.productModel.id;
      },

      endpointSort() {
        return '/admin/settings/products/models/parts/sort';
      },
    },

    methods: {
      addProductModelPartRecord(record, isNew) {
        record.isNew = ( isNew === true ) ? true : false;
        this.productModelParts.push(record);
      },

      addProductModelPart(event) {
        event.preventDefault();

        axios.post(this.endpointAddRow)
        .then((response) => {
          this.addProductModelPartRecord( response.data.productModelPart, true );
        })
        .catch(error => {
          //flash(error.response.data.status_type, error.response.data.status);
        });

        return false;
      },

      deleteProductModelPart(productModelPart) {
        let i = this.productModelParts.map(pmPart => pmPart.id).indexOf(productModelPart.id);
        this.productModelParts.splice(i, 1) // remove it from array
      },

      saveSortOrder(event) {
        let endpoint = this.endpointSort,
            sort_order = this.productModelParts.map(part => part.id),
            submitData = {
              _method: 'PUT',
              sort_order: sort_order
            };
        axios.post(endpoint, submitData)
        .then((response) => {
          //
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status); //show the error message
        });
      },

      setErrors(errors) {
        var that = this;
        this.errors = errors;
      }
    }
  }
</script>
