<script>
  export default {
    props: [
      'shadeUnit',
      'containerClass',
      'guideCableLabel',
      'errors',
      'disabled'
    ],

    computed: {
      parentContainerClass() {
        return this.containerClass;
      },

      guidedCableSelectOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.guidedCableOptions;
      },

      hasGuideCable() {
        return ( this.shadeUnit.product_model_guided_cable == 1 ) ? true : false;
      },

      isExteriorShade() {
        return this.$parent.isExteriorShade;
      }
    },

    methods: {
      getInputName(id, field) {
        return this.$parent.getInputName(id, field);
      }
    }
  };
</script>

<template>
  <div :class="parentContainerClass" v-if="!isExteriorShade">
    <label v-bind:for="getInputName(shadeUnit.id, 'product_model_guided_cable')">
      {{ guideCableLabel }}
    </label>

    <select
      class="form-control"
      :class="errors['product_model_guided_cable'] ? 'is-invalid' : ''"
      v-bind:name="getInputName(shadeUnit.id, 'product_model_guided_cable')"
      v-bind:id="getInputName(shadeUnit.id, 'product_model_guided_cable')"
      v-model="shadeUnit.product_model_guided_cable"
      :disabled="disabled">

      <option v-for="option in guidedCableSelectOptions" :value="option.value">
        {{ option.label }}
      </option>
    </select>

    <span
      class="invalid-feedback"
      role="alert"
      v-if="errors['product_model_guided_cable']">

      {{ errors['product_model_guided_cable'][0] }}
    </span>
  </div>
</template>
