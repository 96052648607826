var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.partComponent.isMotor || _vm.partComponent.isTube,
          expression: "partComponent.isMotor || partComponent.isTube",
        },
      ],
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "part-dependencies-container" },
        [
          _vm.hasPartDependencies == false
            ? [
                _c(
                  "div",
                  { staticClass: "dependent-part-item expandable-block" },
                  [
                    _c("div", { staticClass: "expandable-body minimized" }, [
                      _c("div", { staticClass: "title-bar-wrapper" }, [
                        _c("div", { staticClass: "title-bar" }, [
                          _c("div", { staticClass: "summary" }, [
                            _c("p", { staticClass: "no-results" }, [
                              _vm._v("No dependent parts found. "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addPartDependencyOption($event)
                                    },
                                  },
                                },
                                [_vm._v("Add a Part")]
                              ),
                              _vm._v(" to get started..."),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]
            : [
                _c(
                  "draggable",
                  {
                    attrs: {
                      draggable: ".part-dependency-item",
                      filter: ".not-sortable",
                      preventOnFilter: false,
                    },
                    on: { end: _vm.saveSortOrder },
                    model: {
                      value: _vm.partDependencyList,
                      callback: function ($$v) {
                        _vm.partDependencyList = $$v
                      },
                      expression: "partDependencyList",
                    },
                  },
                  [
                    _vm._l(
                      _vm.partDependencyList,
                      function (partDependency, index) {
                        return [
                          _c("part-dependency", {
                            key: partDependency.id,
                            ref: "partDependency",
                            refInFor: true,
                            attrs: {
                              "parent-part": partDependency,
                              "part-dependency-index": index,
                            },
                          }),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "form-group col-sm-12" }, [
          _c("p", [
            _c(
              "a",
              {
                staticClass: "btn btn-secondary",
                on: {
                  click: function ($event) {
                    return _vm.addPartDependencyOption($event)
                  },
                },
              },
              [_vm._v("Add Part")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "col-sm-12 heading w-bdr-btm bdr-btm-md" }, [
        _c("h1", [_vm._v("Part Dependencies")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }