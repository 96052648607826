<script>
  import PartType from './PartType';

  export default {
    props: [
      'parentPartTypes',
    ],

    components: {
      PartType,
    },

    data() {
      return {
        partTypeList: [],
        errors: {}
      }
    },

    mounted() {
      this.partTypeList = this.parentPartTypes;
    },

    computed: {
      endpointAddRow() {
        return '/admin/settings/products/part-types/add-row';
      },

      endpointSavePartTypes() {
        return '/admin/settings/products/part-types';
      },

      hasErrors() {
        if( Object.keys(this.errors).length ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'part_type['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      addPartType(event) {
        event.preventDefault();
        axios.post(this.endpointAddRow)
        .then((response) => {
          let newPartType = response.data.partType;
            newPartType.isNew = response.data.isNew;
          this.partTypeList.push( newPartType );
        })
        .catch(error => {
          //flash(error.response.data.status_type, error.response.data.status);
        });
      },

      submitForm(event) {
        event.preventDefault();
        var newPartTypes = this.$children.filter(partType => partType.isNew == true);
        var newPartTypesMapped = {};
        newPartTypes.forEach(function(partType) {
          let pt = _.clone(partType.partType);
          newPartTypesMapped[pt.id] = pt;
        });
        var submitData = {
          _method: 'PUT',
          part_type: newPartTypesMapped
        };

        axios.post(this.endpointSavePartTypes, submitData)
        .then((response) => {
          //flash(response.data.status_type, response.data.status);
          if( response.data.redirectTo != '' ) {
            window.location.href = response.data.redirectTo;
          }
        })
        .catch(error => {
          this.setErrors(error.response.data.errors);
          flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
        });
      },

      deletePartType(partType) {
        let i = this.partTypeList.map(pt => pt.id).indexOf(partType.id);
        this.partTypeList.splice(i, 1) // remove it from array
      },

      setErrors(errors) {
        this.errors = errors.part_type;
      }
    }
  };
</script>
