<script>
  import { mapMultiRowFields } from 'vuex-map-fields';
  import { proposalMixin } from '../../mixins/proposalMixin';
  import ProposalMiscExpense from './ProposalMiscExpense';

  export default {
    props: [
      'currentOrderProposal',
    ],

    mixins: [proposalMixin],

    components: {
      ProposalMiscExpense,
    },

    mounted() {
      /*
        console.log('ProposalMiscExpenses.vue (PLURAL) - Mounted');
        console.log(this.orderProposal);
        console.log('');
      */
    },

    computed: {
      ...mapMultiRowFields('proposalMiscExpense', ['proposalMiscExpenses']),

      orderProposal() {
        return this.currentOrderProposal;
      },

      errors() {
        return this.$store.state.proposalMiscExpense.errors;
      },
    },

    methods: {
      addProposalMiscExpense(event) {
        event.preventDefault();
        this.$store.dispatch('proposalMiscExpense/addNewProposalMiscExpense');
      }
    }
  };
</script>

<template>
  <div class="form-row estimate-widget misc-expenses">
    <div class="form-group col-sm-12 mb-0">
      <h2>Misc Charges</h2>
    </div>

    <div class="form-group col-sm-12">
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Cost</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <template v-if="proposalMiscExpenses.length > 0">
            <template v-for="(miscExpense, index) in proposalMiscExpenses">
              <proposal-misc-expense
                :misc-expense="miscExpense"
                :misc-expense-id="miscExpense.id">
              </proposal-misc-expense>
            </template>
          </template>
          <tr>
            <td colspan="3" class="colored-bg text-center">
              <a
                href="#"
                v-bind:class="orderProposal.userCanEdit == false ? 'disabled' : ''"
                @click="addProposalMiscExpense($event)">
                Add Misc Charge
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
