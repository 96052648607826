import { render, staticRenderFns } from "./Flash.vue?vue&type=template&id=48a59f97&"
import script from "./Flash.vue?vue&type=script&lang=js&"
export * from "./Flash.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/sam/lutek/Portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('48a59f97')) {
      api.createRecord('48a59f97', component.options)
    } else {
      api.reload('48a59f97', component.options)
    }
    module.hot.accept("./Flash.vue?vue&type=template&id=48a59f97&", function () {
      api.rerender('48a59f97', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/vue/components/notifications/Flash.vue"
export default component.exports