<script>
  import draggable from 'vuedraggable'
  import OrderTypeStatusNotification from './OrderTypeStatusNotification';

  export default {
    props: [
      'parentProjectOrderType',
      'parentOrderTypeStatusNotifications'
    ],

    components: {
      draggable,
      OrderTypeStatusNotification,
    },

    data() {
      return {
        orderTypeStatusNotificationList: [],
        errors: []
      }
    },

    mounted() {
      this.orderTypeStatusNotificationList = this.parentOrderTypeStatusNotifications;

      /*
        console.log('OrderTypeStatusNotifications.vue (PLURAL) - Mounted');
        //console.log('this.parentProjectOrderType');
        //console.log(this.parentProjectOrderType);
        console.log('this.orderTypeStatusNotificationList');
        console.log(this.orderTypeStatusNotificationList);
        console.log('');
      */
    },

    computed: {
      endpointAddRow() {
        return '/admin/settings/app-config/order-notifications/add-row/' + this.parentProjectOrderType.id;
      }
    },

    methods: {
      addStatusRecord(event) {
        event.preventDefault();
        axios.post(this.endpointAddRow)
        .then((response) => {
          const newOrderNotification = response.data.data.globalOrderNotification;
          newOrderNotification.isNew = true;

          this.orderTypeStatusNotificationList.push(newOrderNotification);
          //flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status);
        });
      },

      updateSortOrder(event) {
        if( event.draggedContext.futureIndex == 0 && event.draggedContext.index != 0 ) {
          return false;
        }
      },

      deleteStatusRecord(orderTypeStatusNotification) {
        let i = this.orderTypeStatusNotificationList.map(status => status.id).indexOf(orderTypeStatusNotification.id);
        this.orderTypeStatusNotificationList.splice(i, 1) // remove it from array
      },

      setErrors(errors) {
        this.errors = errors;
      }
    }

  };
</script>

<template>
  <div class="status-notifications-list-container">
    <draggable
      v-model="orderTypeStatusNotificationList"
      draggable=".status-notifications-list-item"
      filter=".not-sortable"
      :preventOnFilter="false"
      :move="updateSortOrder">

      <template v-for="(statusNotification, index) in orderTypeStatusNotificationList">
        <order-type-status-notification
          :parent-project-order-type="parentProjectOrderType"
          :parent-order-type-status-notification="statusNotification"
          :key="statusNotification.id"
          :index="index">
        </order-type-status-notification>
      </template>
    </draggable>

    <div class="form-row">
      <div class="form-group col-sm-12">
        <a href="#"
           class="btn btn-secondary"
           @click="addStatusRecord($event)">
           Add Status
        </a>
      </div>
    </div>
  </div>
</template>
