<script>
  export default {
    props: [
      'modalId',
      'modalIdAttrOverride',
      'modalSize',
      'modalTitle',
      'modalBody',
      'dismissButton',
      'confirmButton',
      'hideHeaderClose',
      'triggerModalElementType',
      'triggerModalElementText',
      'triggerModalElementCssClass',
      'triggerModalElementExcludeIcon',
      'recordId',
      'formEndpoint',
      'postDeleteParentMethodName',
      'deleteContainerIdAttr',
      'followDeleteResponseRedirect',
    ],

    data() {
      return {
        showModal: false,
        modalElementType: 'link',
        modalElementText: null,
        modalElementCssClass: null,
        modalElementExcludeIcon: false,
        postDeleteParentMethod: null,
        followDeleteRedirect: false
      }
    },

    mounted() {
      this.modalElementType = ( this.triggerModalElementType != 'button' ) ? 'link' : 'button';
      this.modalElementText = this.triggerModalElementText;
      this.modalElementCssClass = ( this.triggerModalElementCssClass != '' ) ? this.triggerModalElementCssClass : this.modalElementCssClass;
      this.postDeleteParentMethod = ( this.postDeleteParentMethodName == '' ) ? null : this.postDeleteParentMethodName;
      this.followDeleteRedirect = ( this.followDeleteResponseRedirect == true ) ? true : false;
      this.modalElementExcludeIcon = ( this.triggerModalElementExcludeIcon == true ) ? true : false;
    },

    computed: {
      endpointDestroy() {
        if( this.formEndpoint == '' || this.recordId == '' ) {
          return '';
        }
        return this.formEndpoint + '/' + this.recordId;
      },

      modalIdAttr() {
        if( this.modalIdAttrOverride ) {
          return this.modalIdAttrOverride + this.modalId;
        }
        return 'modal-' + this.modalId;
      },

      modalSizeAttr() {
        if( this.modalSize == null ) {
          return;
        }

        return this.modalSize;
      },

      modalTitleText() {
        return ( this.modalTitle != null ) ? this.modalTitle : 'Are you sure?';
      },

      modalBodyText() {
        return ( this.modalBody != null ) ? this.modalBody : 'Are you sure you want to delete this record? This action cannot be undone';
      },

      dismissButtonText() {
        return ( this.dismissButton != null ) ? this.dismissButton : 'Cancel';
      },

      confirmButtonText() {
        return ( this.confirmButton != null ) ? this.confirmButton : 'Approve';
      },

      hasDismissButton() {
        return ( this.dismissButton == null ) ? false : true;
      },

      hasConfirmButton() {
        return ( this.confirmButton == null ) ? false : true;
      },

      hideHeaderCloseButton() {
        return ( this.hideHeaderClose == true ) ? true : false;
      }
    },

    methods: {
      displayModal(event) {
        event.preventDefault();
        this.$bvModal.show(this.modalIdAttr);
      },

      emitConfirm() {
        //this.$emit('confirmed');
        if( this.endpointDestroy != '' ) {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            if( this.postDeleteParentMethod != null ) {
              this.$parent[this.postDeleteParentMethod]();
            } else if( this.deleteContainerIdAttr != null ) {
              document.getElementById(this.deleteContainerIdAttr).remove();
            }

            if( this.followDeleteRedirect == true && response.data.redirectTo != null ) {
              window.location.href = response.data.redirectTo;
            }

            if( response.data.status_type != null && response.data.status != null ) {
              flash(response.data.status_type, response.data.status);
            }
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      },

      emitCancel() {
        this.$emit('cancel');
      },
    }
  };
</script>

<template>
  <div>
    <template v-if="modalElementType == 'link'">
      <a href="#" v-bind:class="modalElementCssClass" @click="displayModal($event)" title="Delete">
        {{ modalElementText }}
        <template v-if="modalElementExcludeIcon == false">
          <i class="fa fa-trash-o"></i>
        </template>
      </a>
    </template>
    <template v-else>
      <button type="button" v-bind:class="modalElementCssClass" @click="displayModal($event)" title="Delete">
        {{ modalElementText }}
        <template v-if="modalElementExcludeIcon == false">
          <i class="fa fa-trash-o"></i>
        </template>
      </button>
    </template>

    <b-modal
      :id="modalIdAttr"
      :size="modalSizeAttr"
      :title="modalTitleText"
      :ok-title="confirmButtonText"
      :cancel-title="dismissButtonText"
      :no-close-on-backdrop="true"
      :hide-header-close="hideHeaderCloseButton"
      @ok="emitConfirm"
      @cancel="emitCancel">

      <p class="mb-0" v-html="modalBodyText"></p>

      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="secondary"
            v-html="dismissButtonText"
            @click="cancel()"
            v-if="hasDismissButton">
          </b-button>

          <b-button
            variant="primary"
            v-html="confirmButtonText"
            @click="ok()"
            v-if="hasConfirmButton">
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
