<script>
  import ModalConfirm from '../../../notifications/ModalConfirm.vue';

  export default {
    props: [
      'parentFieldMeasureQuestion',
    ],

    components: {
      ModalConfirm
    },

    data() {
      return {
        fieldMeasureQuestion: [],
        errors: []
      }
    },

    mounted() {
      this.fieldMeasureQuestion = this.parentFieldMeasureQuestion;
    },

    computed: {
      endpointCreate() {
        return '/admin/settings/field-measure';
      },

      endpointUpdate() {
        return '/admin/settings/field-measure/' + this.fieldMeasureQuestion.id;
      },

      endpointDestroy() {
        return '/admin/settings/field-measure/' + this.fieldMeasureQuestion.id;
      },

      isNew() {
        return ( this.fieldMeasureQuestion.isNew == true ) ? true : false;
      },

      isDisabled() {
        return false;
      },

      hasErrors() {
        if( Object.keys(this.errors).length ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'field_measure_question['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      save(event) {
        event.preventDefault();
        var endpoint = ( this.isNew ) ? this.endpointCreate : this.endpointUpdate;
        var fmQuestionData = {};
            fmQuestionData[this.fieldMeasureQuestion.id] = _.clone(this.fieldMeasureQuestion);
        var submitData = {
              _method: ( this.isNew ) ? 'POST' : 'PUT',
              field_measure_question: fmQuestionData
            };

        axios.post(endpoint, submitData)
        .then((response) => {
          let updatedQuestion = response.data.data.fieldMeasureOption;
          this.setErrors({});
          this.$parent.$parent.applySavedFieldMeasureQuestion(this.fieldMeasureQuestion.id, updatedQuestion);
          flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          this.setErrors(error.response.data.errors);
          flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
        });
      },

      destroyClick(event) {
        event.preventDefault();
        if( this.isNew == true ) {
          this.destroy();
        } else {
          this.$bvModal.show('modal-' + this.fieldMeasureQuestion.id);
        }
      },

      //Modal - Handle modal function call
      handleFunctionCall(functionName) {
        this[functionName]();
      },

      //Modal - Cancel out of an open/active modal without completeing action
      cancelModalAction() {
        return false;
      },

      destroy() {
        if( this.isNew === true ) {
          this.$parent.$parent.deleteFieldMeasureQuestion( this.fieldMeasureQuestion );
          flash('alert-success', 'Field measure question deleted');

        } else {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            this.$parent.$parent.deleteFieldMeasureQuestion( this.fieldMeasureQuestion );
            flash(response.data.status_type, response.data.status);
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      },

      setErrors(errors) {
        if( errors.hasOwnProperty('field_measure_question') && errors.field_measure_question.hasOwnProperty(this.fieldMeasureQuestion.id) ) {
          this.errors = errors.field_measure_question[this.fieldMeasureQuestion.id];
        } else {
          this.errors = errors;
        }
      }
    }
  };
</script>
