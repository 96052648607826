<script>
  import draggable from 'vuedraggable'
  import PartMap from './PartMap';

  export default {
    props: [
      'parentPart',
      'partMapParts'
    ],

    components: {
      draggable,
      PartMap
    },

    data() {
      return {
        mappedPartList: [],
        partComponent: [],
        part: [],
        errors: {},
        //selectOptions: []
      }
    },

    mounted() {
      this.mappedPartList = this.partMapParts;
      this.partComponent = this.$parent;
      this.part = this.partComponent.part;
    },

    computed: {
      endpointAddRow() {
        return '/admin/settings/products/parts/part-maps/add-row';
      },

      endpointSort() {
        return '/admin/settings/products/parts/part-maps/sort';
      },

      canHavePartMaps() {
        return ( this.partComponent.isIdleBracket == true || this.partComponent.isMotor == true ) ? true : false;
      },

      sectionTitle() {
        if( this.partComponent.isIdleBracket == true ) {
          return 'Idle Parts'
        } else if ( this.partComponent.isMotor == true ) {
          return 'Motor Brackets';
        }
      },

      hasPartMaps() {
        return ( this.mappedPartList && this.mappedPartList.length > 0 ) ? true : false;
      },

      partMapIdList() {
        return this.mappedPartList.map(part => {
          return part.head_part_id;
        });
      },

      selectOptions() {
        var that = this;

        if( this.partComponent.isIdleBracket == true ) {
          var options = this.$parent.selectOptions.idleBracketPartOptions;
        } else if( this.partComponent.isMotor == true ) {
          var options = this.$parent.selectOptions.motorBracketDependencyOptions;
        }

        options.map(function(category) {
          category.parts.map(function(part) {
            if( that.partMapIdList.indexOf(part.id) != -1 ) {
              part.isSelected = true;
            } else {
              part.isSelected = false;
            }
          });
        });
        return options;
      },

      canAddPartMaps() {
        var selectableParts = [];

        this.selectOptions.forEach(function(category) {
          category.parts.forEach(function(part){
            if( part.isSelected != true ) {
              selectableParts.push(part.id);
            }
          });
        });

        return ( selectableParts.length > 0 ) ? true : false;
      },

      disableAddPartMapsButton() {
        return ( this.canAddPartMaps === true ) ? false : true;
      }
    },

    methods: {
      deletePartMapPart(targetPart) {
        let i = this.mappedPartList.map(part => part.id).indexOf(targetPart.id);
        this.mappedPartList.splice(i, 1) // remove it from array
      },

      addPartMapPartRecord(record, isNew) {
        record.isNew = ( isNew === true ) ? true : false;
        this.mappedPartList.push(record);
      },

      addPartMapOption(event) {
        event.preventDefault();
        axios.post(this.endpointAddRow)
        .then((response) => {
          this.addPartMapPartRecord( response.data.data.partMap, true );
        })
        .catch(error => {
          //flash(error.response.data.status_type, error.response.data.status);
        });

        return false;
      },

      saveSortOrder(event) {
        let endpoint = this.endpointSort,
            sort_order = this.mappedPartList.map(part => part.id),
            submitData = {
              _method: 'PUT',
              sort_order: sort_order
            };
        axios.post(endpoint, submitData)
        .then((response) => {
          //
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status); //show the error message
        });
      },

      getSubmitData() {
        var partMaps = {};
        if( this.$refs.mappedPart ) {
          this.$refs.mappedPart.forEach(function(obj) {
            let part = _.clone(obj.mappedPart);
              delete part.part;
            partMaps[obj.mappedPart.id] = part;
          });
        }
        return partMaps;
      },

      setErrors(errors) {
        var that = this;
        this.errors = errors;
      }
    }
  };
</script>

<template>
  <div v-if="canHavePartMaps">
    <div class="form-row">
      <div class="col-sm-12 heading w-bdr-btm bdr-btm-md">
        <h1>{{ sectionTitle }}</h1>
      </div>
    </div>

    <div class="part-maps-container">
      <template v-if="hasPartMaps == false">
        <div class="part-maps-list">
          <div class="part-map-item expandable-block">
            <div class="expandable-body minimized">
              <div class="title-bar-wrapper">
                <div class="title-bar">
                  <div class="summary">
                    <p class="no-results">No {{ sectionTitle }} found. <a href="#" @click="addPartMapOption($event)" >Add a Part</a> to get started...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <draggable
          v-model="mappedPartList"
          draggable=".part-map-item"
          filter=".not-sortable"
          :preventOnFilter="false"
          @end="saveSortOrder">

          <template v-for="(mappedPart, index) in mappedPartList">
            <part-map
              :parent-part="mappedPart"
              :part-maps-index="index"
              :key="mappedPart.id"
              ref="mappedPart">
            </part-map>
          </template>

        </draggable>
      </template>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-12">
        <p>
          <button class="btn btn-secondary" @click="addPartMapOption($event)" :disabled="disableAddPartMapsButton">Add Part</button>
        </p>
      </div>
    </div>
  </div>
</template>
