<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'currentOrderProposal',
      'includeHeadingRow',
      'headingText',
      'permissionsLock'
    ],

    mixins: [proposalMixin],

    data() {
      return {
        defaultHeadingText: 'Estimate Summary'
      }
    },

    mounted() {},

    computed: {
      orderProposal() {
        return this.currentOrderProposal;
      },

      isLockedForEdits() {
        if( this.$parent.isLockedForEdits || this.permissionsLock == true ) {
          return true;
        }
        return false;
      },

      project() {
        return this.$parent.project;
      },

      selectOptions() {
        return this.$store.state.orderProposal.selectOptions;
      },

      errors() {
        return [];
      },

      showHeadingRow() {
        return ( this.includeHeadingRow == false ) ? false : true;
      },

      headingRowHeadingText() {
        var heading = '';

        if( this.showHeadingRow == true ) {
          heading = ( typeof this.headingText != 'undefined' && this.headingText != '' ) ? this.headingText : this.defaultHeadingText;
        }

        return heading;
      }
    },

    methods: {
      getInputName(field) {
        return this.$parent.getOrderProposalInputName(field);
      },

      getInputId(field) {
        return this.$parent.getOrderProposalInputId(field);
      },
    }
  };
</script>

<template>
  <div class="form-row">
    <div class="form-group col-sm-12 mb-0" v-if="showHeadingRow">
      <h2>{{ headingRowHeadingText }}</h2>
    </div>

    <div class="form-group col-sm-12 mb-0">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Cost ($)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colspan="2">Labor Total</td>
            <td>{{ orderProposal.cs_labor_cost_formatted }}</td>
          </tr>

          <tr>
            <td colspan="2">Project Expense</td>
            <td>{{ orderProposal.cs_job_cost_formatted }}</td>
          </tr>

          <tr>
            <td colspan="2">Material Cost</td>
            <td>{{ orderProposal.cs_material_cost_formatted }}</td>
          </tr>

          <tr>
            <td>Sales Tax</td>
            <td>
              <template v-if="isLockedForEdits">
                {{ project.type }}
              </template>
              <template v-else>
                <select
                  class="form-control"
                  v-bind:class="errors['type'] ? 'is-invalid' : ''"
                  v-bind:name="getInputName('type')"
                  v-bind:id="getInputId('type')"
                  v-model="project.type"
                  v-proposal-field-directive>
                  <option :value="null">--Choose One--</option>
                  <template v-for="option in selectOptions.proposalProjectTypeOptions">
                    <option :value="option.id">{{ option.value }}</option>
                  </template>
                </select>
              </template>
            </td>
            <td>{{ orderProposal.cs_sales_tax_cost_formatted }}</td>
          </tr>

          <tr>
            <td>Freight</td>
            <td>
              <template v-if="isLockedForEdits">
                {{ orderProposal.freight_pct_formatted }}
              </template>
              <template v-else>
                <select
                  class="form-control"
                  v-bind:class="errors['freight_pct'] ? 'is-invalid' : ''"
                  v-bind:name="getInputName('freight_pct')"
                  v-bind:id="getInputId('freight_pct')"
                  v-model="orderProposal.freight_pct"
                  v-proposal-field-directive>
                  <option :value="null">--Choose One--</option>
                  <template v-for="option in selectOptions.proposalFreightExpenseOptions">
                    <option :value="option.value">{{ option.label }}</option>
                  </template>
                </select>
              </template>
            </td>
            <td>{{ orderProposal.cs_freight_cost_formatted }}</td>
          </tr>

          <tr>
            <td colspan="2">Total Direct Cost</td>
            <td>{{ orderProposal.es_total_direct_cost_formatted }}</td>
          </tr>

          <tr>
            <td>Contingency</td>
            <td>
              <template v-if="isLockedForEdits">
                {{ orderProposal.contingency_pct_formatted }}
              </template>
              <template v-else>
                <select
                  class="form-control"
                  v-bind:class="errors['contingency_pct'] ? 'is-invalid' : ''"
                  v-bind:name="getInputName('contingency_pct')"
                  v-bind:id="getInputId('contingency_pct')"
                  v-model="orderProposal.contingency_pct"
                  v-proposal-field-directive>
                  <option :value="null">--Choose One--</option>
                  <template v-for="option in selectOptions.proposalContingencyExpenseOptions">
                    <option :value="option.value">{{ option.label }}</option>
                  </template>
                </select>
              </template>
            </td>
            <td>{{ orderProposal.cs_contingency_cost_formatted }}</td>
          </tr>

          <tr>
            <td colspan="2">Subtotal</td>
            <td>{{ orderProposal.es_contingency_subtotal_formatted }}</td>
          </tr>

          <tr>
            <td>Overhead</td>
            <td>
              <template v-if="isLockedForEdits">
                {{ orderProposal.overhead_pct_formatted }}
              </template>
              <template v-else>
                <select
                  class="form-control"
                  v-bind:class="errors['overhead_pct'] ? 'is-invalid' : ''"
                  v-bind:name="getInputName('overhead_pct')"
                  v-bind:id="getInputId('overhead_pct')"
                  v-model="orderProposal.overhead_pct"
                  v-proposal-field-directive>
                  <option :value="null">--Choose One--</option>
                  <template v-for="option in selectOptions.proposalOverheadExpenseOptions">
                    <option :value="option.value">{{ option.label }}</option>
                  </template>
                </select>
              </template>
            </td>
            <td>{{ orderProposal.cs_overhead_cost_formatted }}</td>
          </tr>

          <tr>
            <td colspan="2">Subtotal</td>
            <td>{{ orderProposal.es_overhead_subtotal_formatted }}</td>
          </tr>

          <tr>
            <td>Profit</td>
            <td>
              <template v-if="isLockedForEdits">
                {{ orderProposal.profit_pct_formatted }}
              </template>
              <template v-else>
                <select
                  class="form-control"
                  v-bind:class="errors['profit_pct'] ? 'is-invalid' : ''"
                  v-bind:name="getInputName('profit_pct')"
                  v-bind:id="getInputId('profit_pct')"
                  v-model="orderProposal.profit_pct"
                  v-proposal-field-directive>
                  <option :value="null">--Choose One--</option>
                  <template v-for="option in selectOptions.proposalProfitOptions">
                    <option :value="option.value">{{ option.label }}</option>
                  </template>
                </select>
              </template>
            </td>
            <td>{{ orderProposal.cs_profit_formatted }}</td>
          </tr>

          <tr>
            <td colspan="2">Subtotal</td>
            <td>{{ orderProposal.es_profit_subtotal_formatted }}</td>
          </tr>

          <tr>
            <td>Performance Bond</td>
            <td>
              <template v-if="isLockedForEdits">
                {{ orderProposal.performance_bond_pct_formatted }}
              </template>
              <template v-else>
                <select
                  class="form-control"
                  v-bind:class="errors['performance_bond_pct'] ? 'is-invalid' : ''"
                  v-bind:name="getInputName('performance_bond_pct')"
                  v-bind:id="getInputId('performance_bond_pct')"
                  v-model="orderProposal.performance_bond_pct"
                  v-proposal-field-directive>
                  <option :value="null">--Choose One--</option>
                  <template v-for="option in selectOptions.proposalPerformanceBondOptions">
                    <option :value="option.value">{{ option.label }}</option>
                  </template>
                </select>
              </template>
            </td>
            <td>{{ orderProposal.cs_bond_cost_formatted }}</td>
          </tr>

          <tr>
            <td colspan="2">Total</td>
            <td>{{ orderProposal.cs_total_cost_formatted }}</td>
          </tr>

          <tr v-if="orderProposal.override_price_formatted !== null">
            <td colspan="2">Discount Total</td>
            <td>{{ orderProposal.override_price_formatted }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
