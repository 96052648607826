<script>
  //import { mapMultiRowFields } from 'vuex-map-fields';
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'shadeUnit',
      'shadeUnitId',
    ],

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('TakeoffShadeLineItem.vue - Mounted');
        console.log('shadeUnit');
        console.log(this.shadeUnit);
        console.log('shadeLineItem');
        console.log(this.shadeLineItem);
        console.log('');
      */
    },

    computed: {
      shadeLineItem() {
        return this.shadeUnit;
      },

      afterHoursLaborSelectOptions() {
        return this.$store.state.orderProposal.selectOptions.takeoffAfterHoursLaborOptions;
      },

      extraLaborSelectOptions() {
        return this.$store.state.orderProposal.selectOptions.takeoffExtraLaborOptions;
      },

      supplyOnlySelectOptions() {
        return this.$store.state.orderProposal.selectOptions.takeoffSupplyOnlyOptions;
      },

      fabricName() {
        return this.$store.getters['shadeLineItems/fabricSummaryText'](this.shadeLineItem.id);
      },

      dualFabricName() {
        return this.$store.getters['shadeLineItems/dualFabricSummaryText'](this.shadeLineItem.id);
      },

      errors() {
        return this.$parent.errors;
      },
    },

    methods: {
      getInputName(id, field) {
        return this.$parent.getInputName(id, field);
      },

      getInputId(id, field) {
        return this.$parent.getInputId(id, field);
      },
    }
  };
</script>

<template>
  <tr>
    <td>{{ shadeLineItem.sort_order_number }}</td>
    <td>{{ shadeLineItem.room }}</td>
    <td>{{ shadeLineItem.quantity }}</td>
    <td>{{ shadeLineItem.actual_width_formatted }}</td>
    <td>{{ shadeLineItem.actual_length_formatted }}</td>
    <td>{{ shadeLineItem.product_model_name }}</td>
    <td>{{ shadeLineItem.fascia_type }}</td>
    <td>{{ (shadeLineItem.is_coupled) ? 'Yes' : 'No' }}</td>
    <td>{{ (shadeLineItem.has_side_channel) ? 'Yes' : 'No' }}</td>
    <td>{{ fabricName }}</td>
    <td>{{ dualFabricName }}</td>
    <td>
      <template v-if="shadeLineItem.motor">
        {{ shadeLineItem.motor_name }}
      </template>
    </td>
    <td>
      <select
        class="form-control"
        v-bind:class="errors['extra_labor_minutes'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(shadeLineItem.id, 'extra_labor_minutes')"
        v-bind:id="getInputId(shadeLineItem.id, 'extra_labor_minutes')"
        v-model="shadeLineItem.extra_labor_minutes"
        v-proposal-field-directive>
        <option :value="null">--None--</option>
        <option v-for="option in extraLaborSelectOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
    <td>
      <select
        class="form-control"
        v-bind:class="errors['after_hours'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(shadeLineItem.id, 'after_hours')"
        v-bind:id="getInputId(shadeLineItem.id, 'after_hours')"
        v-model="shadeLineItem.after_hours"
        v-proposal-field-directive>
        <option v-for="option in afterHoursLaborSelectOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
    <td>
      <select
        class="form-control"
        v-bind:class="errors['supply_only'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(shadeLineItem.id, 'supply_only')"
        v-bind:id="getInputId(shadeLineItem.id, 'supply_only')"
        v-model="shadeLineItem.supply_only"
        v-proposal-field-directive>
        <option v-for="option in supplyOnlySelectOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
  </tr>
</template>
