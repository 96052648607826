<script>
  export default {
    props: [
      'record',
      'recordType'
    ],

    data() {
      return {
        active: this.record.is_favorited
      }
    },

    computed: {
      classes() {
        return [
          'btn',
          this.active ? 'btn-favorited' : 'btn-not-favorited'
        ];
      },

      title() {
        return [
          this.active ? 'Remove from watch list' : 'Add to watch list'
        ];
      },

      endpoint() {
        return '/favorite/' + this.recordType + '/' + this.record.id;
      }
    },

    methods: {
      toggle() {
        this.active ? this.destroy() : this.create();
      },

      create() {
        axios.post(this.endpoint)
        .then((response) => {
          //flash(response.data.status_type, response.data.status);
          location.reload();
          this.active = true;
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status);
        });
      },

      destroy() {
        axios.delete(this.endpoint)
        .then((response) => {
          //flash(response.data.status_type, response.data.status);
          location.reload();
          this.active = false;
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status);
        });
      }
    }
  }
</script>
