var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shadeLineItem.isNew === false
    ? _c(
        "tr",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.checkForChanges,
              expression: "checkForChanges",
            },
          ],
          on: { click: _vm.hasFocus },
        },
        [
          _c("td", [
            _c("input", {
              staticClass: "checkbox-installation-class",
              attrs: { type: "checkbox" },
              domProps: { value: _vm.shadeLineItem.id },
            }),
          ]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.shadeLineItem.sort_order_number_textual)),
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.quantity))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.product_model_name))]),
          _vm._v(" "),
          _c("td", [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.shadeLineItem.supply_only,
                    expression: "shadeLineItem.supply_only",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors["supply_only"] ? "is-invalid" : "",
                attrs: {
                  name: _vm.getInputName(_vm.shadeLineItem.id, "supply_only"),
                  id: _vm.getInputId(_vm.shadeLineItem.id, "supply_only"),
                  disabled:
                    _vm.shadeLineItem.isInnerCoupledShade ||
                    _vm.orderProposal.userCanEdit == false,
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.shadeLineItem,
                      "supply_only",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(_vm.supplyOnlySelectOptions, function (option) {
                return _c("option", { domProps: { value: option.value } }, [
                  _vm._v(_vm._s(option.label)),
                ])
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("td", [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.shadeLineItem.after_hours,
                    expression: "shadeLineItem.after_hours",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors["after_hours"] ? "is-invalid" : "",
                attrs: {
                  name: _vm.getInputName(_vm.shadeLineItem.id, "after_hours"),
                  id: _vm.getInputId(_vm.shadeLineItem.id, "after_hours"),
                  disabled:
                    _vm.shadeLineItem.isInnerCoupledShade ||
                    _vm.orderProposal.userCanEdit == false,
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.shadeLineItem,
                      "after_hours",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(_vm.afterHoursLaborSelectOptions, function (option) {
                return _c("option", { domProps: { value: option.value } }, [
                  _vm._v(_vm._s(option.label)),
                ])
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.shade_unit_hours))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.shadeLineItem.shade_unit_labor_cost_formatted)),
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.trim_unit_hours))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.shadeLineItem.trim_unit_labor_cost_formatted)),
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.side_channel_unit_hours))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              _vm._s(_vm.shadeLineItem.side_channel_unit_labor_cost_formatted)
            ),
          ]),
          _vm._v(" "),
          _c("td", [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.shadeLineItem.extra_labor_minutes,
                    expression: "shadeLineItem.extra_labor_minutes",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors["extra_labor_minutes"] ? "is-invalid" : "",
                attrs: {
                  name: _vm.getInputName(
                    _vm.shadeLineItem.id,
                    "extra_labor_minutes"
                  ),
                  id: _vm.getInputId(
                    _vm.shadeLineItem.id,
                    "extra_labor_minutes"
                  ),
                  disabled:
                    _vm.shadeLineItem.isInnerCoupledShade ||
                    _vm.orderProposal.userCanEdit == false,
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.shadeLineItem,
                      "extra_labor_minutes",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v("--None--"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.extraLaborSelectOptions, function (option) {
                  return _c("option", { domProps: { value: option.value } }, [
                    _vm._v(_vm._s(option.label)),
                  ])
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.shadeLineItem.extra_labor_cost_formatted)),
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.install_unit_hours))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.shadeLineItem.install_unit_cost_formatted)),
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.install_total_hours))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.shadeLineItem.install_total_cost_formatted)),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }