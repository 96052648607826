var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.clearSearch,
          expression: "clearSearch",
        },
      ],
      staticClass: "search-container",
    },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.queryString,
              expression: "queryString",
            },
          ],
          staticClass: "form-control search-input",
          attrs: {
            placeholder: "Company, Project, Part, etc.",
            autocomplete: "no",
          },
          domProps: { value: _vm.queryString },
          on: {
            keyup: _vm.startSearch,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.queryString = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showResults,
              expression: "showResults",
            },
          ],
          staticClass: "search-results",
        },
        [
          _vm.hasResults() == false
            ? [_vm._m(0)]
            : [
                _vm._l(_vm.results.companies, function (record) {
                  return _vm.results.companies.length
                    ? _c("div", { staticClass: "result" }, [
                        _c(
                          "a",
                          {
                            staticClass: "record",
                            attrs: { href: _vm.companyUrl(record.id) },
                          },
                          [
                            _c("span", { staticClass: "record-type" }, [
                              _vm._v("Company:"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "record-text" }, [
                              _vm._v(_vm._s(record.name)),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.results.projects, function (record) {
                  return _vm.results.projects.length
                    ? _c("div", { staticClass: "result" }, [
                        _c(
                          "a",
                          {
                            staticClass: "record",
                            attrs: { href: _vm.projectUrl(record.id) },
                          },
                          [
                            _c("span", { staticClass: "record-type" }, [
                              _vm._v("Project:"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "record-text" }, [
                              _vm._v(_vm._s(record.name)),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.results.productModels, function (record) {
                  return _vm.results.productModels.length
                    ? _c("div", { staticClass: "result" }, [
                        _c(
                          "a",
                          {
                            staticClass: "record",
                            attrs: { href: _vm.productModelUrl(record.id) },
                          },
                          [
                            _c("span", { staticClass: "record-type" }, [
                              _vm._v("Product Model:"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "record-text" }, [
                              _vm._v(_vm._s(record.name)),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.results.parts, function (record) {
                  return _vm.results.parts.length
                    ? _c("div", { staticClass: "result" }, [
                        _c(
                          "a",
                          {
                            staticClass: "record",
                            attrs: { href: _vm.partUrl(record.id) },
                          },
                          [
                            _c("span", { staticClass: "record-type" }, [
                              _vm._v("Part:"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "record-text" }, [
                              _vm._v(_vm._s(record.name)),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e()
                }),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "result no-results" }, [
      _c("p", { staticClass: "record" }, [_vm._v("No Results.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }