<script>
  import { fieldFormattingMixin } from '../../../mixins/fieldFormattingMixin'
  import { createHelpers, mapMultiRowFields } from 'vuex-map-fields';

  import DualShade from './dualShade/DualShade';
  import GuideCable from './guideCable/GuideCable';
  import ShadeBackFascia from './shadeFascia/ShadeBackFascia';
  import ShadeFabric from './shadeFabric/ShadeFabric';
  import ShadeFasciaColor from './shadeFascia/ShadeFasciaColor';
  import ShadeFasciaType from './shadeFascia/ShadeFasciaType';
  import ShadeHembarStyle from './shadeHembarStyle/ShadeHembarStyle';
  import ShadeHembarColor from './shadeHembarColor/ShadeHembarColor';
  import ShadeHembarEndcapColor from './shadeHembarEndcapColor/ShadeHembarEndcapColor';
  import ShadeLineItemErrorPopover from './shadeLineItemErrorPopover/ShadeLineItemErrorPopover';
  import ShadeMotor from './shadeMotor/ShadeMotor.vue';
  import ShadePocket from './shadePocket/ShadePocket';
  import ShadePreferredFasciaSize from './shadeFascia/ShadePreferredFasciaSize';
  import WrappedFasciaFabric from './wrappedFasciaFabric/WrappedFasciaFabric';

  const { mapFields } = createHelpers({
    getterType: 'shadeLineItems/getField',
    mutationType: 'shadeLineItems/updateField',
  });

  export default {
    mixins: [fieldFormattingMixin],

    props: [
      'shadeUnit',
      'shadeUnitId',
      'shadeGroupId',
      'shadeLineItemsIndex',
      'userCanEditAdvancedSettings',
    ],

    components: {
      DualShade,
      GuideCable,
      ShadeBackFascia,
      ShadeFabric,
      ShadeFasciaColor,
      ShadeFasciaType,
      ShadeHembarStyle,
      ShadeHembarColor,
      ShadeHembarEndcapColor,
      ShadeLineItemErrorPopover,
      ShadeMotor,
      ShadePocket,
      ShadePreferredFasciaSize,
      WrappedFasciaFabric
    },

    data() {
      return {
        showShadeSettings: false,
        isMaximized: false,
        disabledInnerCoupledShadeFields: [],
        permittedFabricPanelOnlyFields: [],
      }
    },

    created() {
      if( this.shadeLineItem.isNew == true || this.shadeLineItem.isMaximized == true) {
        this.isMaximized = true;
      }

      if( this.shadeLineItem.isNew == true && this.shadeLineItem.shade_unit_config_id == null ) {
        this.showShadeSettings = true;
      }

      this.$eventHub.$on('minimizeOpenShades', this.minimizeContainer);

      //set disabled inner coupled shade field list
      this.disabledInnerCoupledShadeFields = this.$store.getters['shadeLineItems/coupledShadeChildLockedProps'];

      //set permitted fabric panel only field list
      this.permittedFabricPanelOnlyFields = this.$store.getters['shadeLineItems/fabricPanelOnlyPermittedFields'];
    },

    beforeDestroy() {
      this.$eventHub.$off('minimizeOpenShades', this.minimizeContainer );
    },

    mounted() {
      if( this.shadeLineItem.isNew == true ) {
        var container = this.$el.getElementsByClassName("line-item")[0];
        container.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    },

    computed: {
      shadeLineItem() {
        return this.shadeUnit;
      },

      isExteriorShade() {
        return this.$store.getters['shadeLineItems/isExteriorShade'](this.shadeLineItem.id);
      },

      sortOrderNumber() {
        return this.shadeGroup.sort_order_number;
      },

      shadeGroup() {
        return this.$store.getters['shadeGroups/find'](this.shadeGroupId);
      },

      shadeOrder() {
        return this.$store.state.shadeOrder.shadeOrder;
      },

      orderProposal() {
        return this.$store.getters['orderProposal/currentOrderProposal'];
      },

      shadeUnitConfigs() {
        return this.$store.getters['shadeUnitConfigs/appliedShadeUnitConfigs'];
      },

      selectOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions;
      },

      hardwareColorSelectOptions() {
        return this.$store.getters['estimationSelectOptions/hardwareColorOptionsByShadeType'](this.shadeLineItem.product_model_shade_type);
      },

      handednessSelectOptions() {
        let handednessSelectOptions = this.$store.getters['estimationSelectOptions/handednessOptionsByShadeType'](this.shadeLineItem.product_model_shade_type, this.shadeLineItem.product_model_exterior_mfg);
        return handednessSelectOptions
      },

      productModelDriveMechanismSelectOptions() {
        let driveMechOptions = this.selectOptions.driveMechanismOptions;

        //Fabric Only shades can not be configured for coupled shades
        if( this.isInnerCoupledShade || this.hasSiblingShades ) {
          driveMechOptions = driveMechOptions.filter(function(obj){
            return obj.value != 'fabric_only';
          });
        }

        return driveMechOptions;
      },

      showShadeSettingsButtonText() {
        return ( this.showShadeSettings == true ) ? 'Hide' : 'Expand';
      },

      userCanEdit() {
        if( this.orderProposal && this.orderProposal.userCanEdit == false ) {
          return false;
        }
        return true;
      },

      canEditAdvancedSettings(field) {
        return ( this.userCanEditAdvancedSettings == true ) ? true : false;
      },

      hasCustomSideChannelColor() {
        if( this.shadeLineItem.has_side_channel == true && this.shadeLineItem.side_channel_color == 'custom' ) {
          return true;
        }

        return false;
      },

      hasMotor() {
        var hasMotor = false;
        if( this.shadeLineItem.motor !== null && this.shadeLineItem.motor !== undefined ) {
          if( this.shadeLineItem.motor.hasOwnProperty('name') && this.shadeLineItem.motor.name !== null ) {
            hasMotor = true;
          }
        }

        return hasMotor;
      },

      isNew() {
        return ( this.shadeLineItem.isNew == true ) ? true : false;
      },

      isInnerCoupledShade() {
        return ( this.shadeLineItem.isInnerCoupledShade === true ) ? true : false;
      },

      isLastShadeInGroup() {
        return ( (this.$parent.$parent.shadeLineItems.length - 1) == this.shadeLineItemsIndex ) ? true : false;
      },

      isFabricPanelOnlyShade() {
        return ( this.shadeLineItem.product_model_drive_mechanism == 'fabric_only' ) ? true : false;
      },

      isManualCordlessShade() {
        return ( this.shadeLineItem.product_model_drive_mechanism == 'manual_cordless' ) ? true : false;
      },

      canAddInnerShade() {
        if( !this.shadeLineItem.is_door && this.isLastShadeInGroup && !this.hasErrors && this.$parent.$parent.canAddInnerShade && !this.orderProposal.is_locked_for_edits && !this.isFabricPanelOnlyShade &&!this.isExteriorShade ) {
          return true;
        }

        return false;
      },

      canSetHembarComposition() {
        if( this.isInnerCoupledShadeFieldDisabled('order_item_assembly_hembar_composition') == true ) {
          return false;
        } else if( this.shadeLineItem.hembar_style == 'Sealed Hembar' ) {
          return true;
        }
        this.shadeLineItem.order_item_assembly_hembar_composition = 'A';
        return false;
      },

      hasSiblingShades() {
        return ( this.$parent.$parent.shadeLineItems.length > 1 ) ? true : false;
      },

      parentContainerClass() {
        var cssClass = 'expandable-block small';

        if( this.isNew == false ) {
          cssClass += ' draggable-block';
        } else {
          cssClass += ' not-sortable';
        }

        if( this.isInnerCoupledShade === true ) {
          cssClass += ' ml-4 inner-coupled-shade small';
        }

        return cssClass;
      },

      shadeLineItemContainerClass() {
        var cssClass = [];

        var minimizedOrMaximized = ( this.isMaximized == true ) ? 'maximized' : 'minimized';
        cssClass.push(minimizedOrMaximized);

        if( this.hasErrors || this.hasOrderItemAssemblyErrors ) {
          cssClass.push('error');
        }

        return cssClass.join(' ');
      },

      getFabricSummary() {
        return this.$store.getters['shadeLineItems/fabricSummaryText'](this.shadeLineItem.id);
      },

      getDualFabricSummary() {
        return this.$store.getters['shadeLineItems/dualFabricSummaryText'](this.shadeLineItem.id);
      },

      getFasciaCutWidth() {
        return ( this.shadeLineItem.has_fascia ) ? this.shadeGroup.fascia_cut_width_formatted : 'N/A';
      },

      getFasciaColorSummary() {
        return ( this.shadeLineItem.has_fascia ) ? this.shadeLineItem.fascia_color_concatenated : 'N/A';
      },

      getBottomUpShadeSummary() {
        return ( this.shadeLineItem.product_model_bottom_up ) ? this.shadeLineItem.product_model_bottom_up : 'No';
      },

      getDriveMechanismSummary() {
        return ( this.shadeLineItem.product_model_drive_mechanism ) ? this.shadeLineItem.product_model_drive_mechanism.replace('_', ' ') : '';
      },

      getMotorManufacturerNameSummary() {
        if( this.shadeLineItem.product_model_drive_mechanism != 'motor' ) {
          return 'N/A';
        }

        let motorManufacturers = this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.motorManufacturerOptions;
        let shadeLineItem = this.shadeLineItem;
        let selectedMfg = motorManufacturers.find(function(mfg, index){
          return mfg.id === shadeLineItem.motor_manufacturer_id;
        });

        return ( selectedMfg ) ? selectedMfg.name : '';
      },

      getMotorTypeSummary() {
        return (this.shadeLineItem.product_model_drive_mechanism == 'motor') ? this.shadeLineItem.motor_type : 'N/A';
      },

      getMotorPowerTypeSummary() {
        return (this.shadeLineItem.product_model_drive_mechanism == 'motor') ? this.shadeLineItem.motor_power : 'N/A';
      },

      getMotorSoundTypeSummary() {
        return (this.shadeLineItem.product_model_drive_mechanism == 'motor') ? this.shadeLineItem.motor_sound : 'N/A';
      },

      getTrimClosureSummary() {
        if( this.shadeLineItem.pocket_include_closure == 1 ) {
          let closureSize = this.shadeLineItem.pocket_closure_size;
          return parseFloat(closureSize).toFixed(2) + '"';
        } else {
          return 'None';
        }
      },

      hasErrors() {
        if( Object.keys(this.errors).length > 0 ) {
          return true;
        }
        return false;
      },

      errors() {
        var errors = ( this.shadeLineItem.hasOwnProperty('errors') ) ? this.shadeLineItem.errors : {};
        return errors;
      },

      hasOrderItemAssemblyErrors() {
        if( this.$parent.$parent.hasErrorsInChildren ) {
          return false;
        }
        return ( this.orderItemAssemblyErrors.length > 0 ) ? true : false;
      },

      orderItemAssemblyErrors() {
        if( this.shadeLineItem.hasOwnProperty('order_item_assembly_errors') && this.shadeLineItem.order_item_assembly_errors.length > 0 ) {
          return this.shadeLineItem.order_item_assembly_errors;
        }
        return [];
      },
    },

    methods: {
      getInputName(id, field) {
        return 'line_item[shade]['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      getInputTooltipId(id, field) {
        var inputId = this.getInputId(id, field);
        return inputId + '_' + id;
      },

      getAssemblyInputName(id, field) {
        return 'line_item[shade]['+ id +'][assembly][' + field + ']';
      },

      getAssemblyInputId(id, field) {
        var inputName = this.getAssemblyInputName(id, field);
        return inputName.replace(/[]/g, "_");
      },

      getShadeGroupInputName(id, field) {
        return 'line_item[shade]['+ id +'][shade_group][' + field + ']';
      },

      getShadeGroupInputId(id, field) {
        var inputName = this.getShadeGroupInputName(id, field);
        return inputName.replace(/[]/g, "_");
      },

      isFieldDisabled(field) {
        if( this.isInnerCoupledShadeFieldDisabled(field) === true ) {
          return true;
        } else if( this.isFabricPanelOnlyShade && this.isFabricPanelOnlyFieldDisabled(field) === true ) {
          return true;
        } else {
          return false;
        }
      },

      isInnerCoupledShadeFieldDisabled(field) {
        if( this.isInnerCoupledShade === false ) {
          return false;
        }

        return this.disabledInnerCoupledShadeFields.includes(field);
      },

      isFabricPanelOnlyFieldDisabled(field) {
        if( this.isFabricPanelOnlyShade === true && this.permittedFabricPanelOnlyFields.includes(field) === false ) {
          return true;
        } else {
          return false;
        }
      },

      toggleShadeSettings() {
        if( this.showShadeSettings == true ) {
          this.showShadeSettings = false;
        } else {
          this.showShadeSettings = true;
        }
      },

      toggleFullView($event) {
        event.preventDefault();
        this.isMaximized = ( this.isMaximized == true ) ? false : true;
        if( this.isMaximized == false ) {
          this.showShadeSettings = false;
        }
      },

      minimizeContainer() {
        if( this.shadeLineItem.isNew != true ) {
          this.isMaximized = false;
        }
      },

      applyShadeUnitConfigSettings(event) {
        if( event.target.value == '' || event.target.value == null ) {
          return;
        }

        var payload = {
          shadeLineItemId: this.shadeLineItem.id,
          shadeUnitConfigId: event.target.value
        };

        this.$store.dispatch('shadeLineItems/mergeShadeUnitConfig', payload);
      },

      changeProductModelShadeType(event) {
        event.preventDefault();
        this.shadeLineItem.product_model_exterior_mfg = null;
        this.shadeLineItem.hardware_color = null;
        this.shadeLineItem.fabric_id = null;
        this.shadeLineItem.fabric_openness_factor_id = null;
        this.shadeLineItem.fabric_attribute_id = null;
        this.shadeLineItem.dual_fabric_id = null;
        this.shadeLineItem.shade_unit_config_id = null;

        if( this.shadeLineItem.product_model_shade_type && this.shadeLineItem.product_model_shade_type.toLowerCase() == 'exterior' ) {
          this.shadeLineItem.product_model_exterior_mfg = 'Veue';
          this.shadeLineItem.drive_type = 'M-I';
          this.shadeLineItem.is_door = 0;
          this.shadeLineItem.hold_down = 0;
          this.shadeLineItem.handedness = null;
          this.shadeLineItem.product_model_dual_shade = 0;
          this.shadeLineItem.product_model_guided_cable = 0;
          this.shadeLineItem.product_model_bottom_up = null;
          this.shadeLineItem.product_model_drive_mechanism = 'motor';
          this.shadeLineItem.motor_manufacturer_id = null;
          this.shadeLineItem.dual_fabric_id = null;
          this.shadeLineItem.dual_fabric_openness_factor_id = null;
          this.shadeLineItem.dual_fabric_attribute_id = null;
          this.shadeLineItem.hembar_style = null;
          this.shadeLineItem.hembar_color = null;
          this.shadeLineItem.hembar_endcap_color = null;
          this.shadeLineItem.fascia_type = null;
          this.shadeLineItem.back_fascia = 1;
          this.shadeLineItem.preferred_fascia_size = null;
          this.shadeLineItem.fascia_color = null;
          this.shadeLineItem.fascia_endcap_color = null;
          this.shadeLineItem.fascia_color_custom_manufacturer = null;
          this.shadeLineItem.fascia_color_custom_name = null;
          this.shadeLineItem.wrapped_fascia_fabric = null;
          this.shadeLineItem.has_side_channel = 0;
          this.shadeLineItem.side_channel_color = null;
          this.shadeLineItem.side_channel_color_custom_manufacturer = null;
          this.shadeLineItem.side_channel_color_custom_color = null;
          this.shadeLineItem.has_trim = 0;
          this.shadeLineItem.trim_type = null;
          this.shadeLineItem.trim_color = null;
          this.shadeLineItem.trim_color_custom_manufacturer = null;
          this.shadeLineItem.trim_color_custom_name = null;
          this.shadeLineItem.pocket_height = null;
          this.shadeLineItem.pocket_depth = null;
          this.shadeLineItem.pocket_include_closure = 0;
          this.shadeLineItem.pocket_closure_size = null;
        }
      },

      saveShadeLineItem() {
        this.$parent.$parent.$parent.saveShadeGroup();
      },

      copy() {
        if( this.isInnerCoupledShade ) {
          this.$store.dispatch('shadeLineItems/copyShadeLineItem', this.shadeLineItem.id);
        } else {
          this.$store.dispatch('shadeGroups/copyShadeGroup', this.shadeGroup.id);
        }
      },

      destroy() {
        if( this.isInnerCoupledShade ) {
          this.$store.dispatch('shadeLineItems/deleteShadeLineItem', this.shadeLineItem.id);
        } else {
          this.$store.dispatch('shadeGroups/deleteShadeGroup', this.shadeGroup.id);
        }
      },

      addCoupledShade(event) {
        event.preventDefault();
        //this.$store.dispatch('shadeLineItems/addCoupledShade', this.shadeGroupId);

        if( this.shadeGroup.isNew ) {
          this.$store.dispatch('shadeGroups/createShadeGroup', this.shadeGroupId)
          .then((response) => {
            this.$store.dispatch('shadeLineItems/addCoupledShade', this.shadeGroupId);
          })
          .catch((error) => {
            //
          });

        } else {
          this.$store.dispatch('shadeGroups/updateShadeGroup', this.shadeGroupId)
          .then((response) => {
            this.$store.dispatch('shadeLineItems/addCoupledShade', this.shadeGroupId);
          })
          .catch((error) => {
            //
          });
        }
      },
    },

    watch: {
      'hasErrors': function(newVal, oldVal) {
        if( newVal == true ) {
          this.showShadeSettings = true;
        }
      },

      'isMaximized': function(newVal, oldVal) {
        this.shadeLineItem.isMaximized = newVal;
        if( newVal == false ) {
          this.showShadeSettings = false;
        }
      },

      'shadeLineItem.isMaximized': function(newVal, oldVal) {
        if( newVal != this.isMaximized ) {
          this.isMaximized = newVal;
        }
      },

      'shadeLineItem.shade_unit_config_id': function(newVal, oldVal) {
        if( this.shadeLineItem.isNew == true && newVal != null ) {
          this.showShadeSettings = false;
        }
      },

      'shadeLineItem.product_model_exterior_mfg': function (newVal, oldVal) {
        if( newVal == null ) {
          this.shadeLineItem.motor_manufacturer_id = null;
        } else {
          this.shadeLineItem.motor_manufacturer_id = this.$store.getters['estimationSelectOptions/getExteriorMotorMfgIdByShadeMfg'](this.shadeLineItem.product_model_exterior_mfg);
        }

        if(newVal == 'Fixscreen') {
          this.shadeLineItem.has_side_channel = 1;
        } else {
          this.shadeLineItem.has_side_channel = 0;
        }
      },

      'shadeLineItem.has_side_channel': function (newVal, oldVal) {
        if( newVal == false || newVal == 0 ) {
          this.shadeLineItem.side_channel_color = null;
        }
      },

      'shadeLineItem.side_channel_color': function (newVal, oldVal) {
        if( newVal == null || newVal.toLowerCase() != 'custom' ) {
          this.shadeLineItem.side_channel_color_custom_manufacturer = null;
          this.shadeLineItem.side_channel_color_custom_name = null;
        }
      },

      'shadeLineItem.product_model_drive_mechanism': function(newVal, oldVal) {
        if( newVal == 'manual_chain' ) {
          this.shadeLineItem.hold_down = '1';
        } else {
          this.shadeLineItem.hold_down = '0';
        }

        //If changing product_model_drive_mechanism to fabric panel only, reset all non-permitted values to their defaults
        if( newVal == 'fabric_only' ) {
          this.$store.dispatch('shadeLineItems/applyDefaultShadePropsForFabricOnly', this.shadeLineItem.id);
        } else if( oldVal == 'fabric_only' && newVal != 'fabric_only' ) {
          this.$store.dispatch('shadeLineItems/removeShadePropsForFabricOnly', this.shadeLineItem.id);
        }

        if( newVal == 'manual_cordless' ) {
          this.shadeLineItem.hardware_color = 'white';
        }
      },

      'shadeLineItem.override_deflection': function(newVal, oldVal) {
        if( newVal != oldVal ) {
          if( this.userCanEditAdvancedSettings == true ) {
            this.shadeLineItem.override_deflection = newVal;
          } else {
            this.shadeLineItem.override_deflection = 0;
          }
        }
      },
    }
  };
</script>
