<script>
  import PartColor from './PartColor';

  export default {
    props: [
      'parentProductPartColors',
      'parentPart'
    ],

    components: {
      PartColor
    },

    data() {
      return {
        partComponent: [],
        part: [],
        productPartColorsList: [],
        selectOptions: []
      }
    },

    mounted() {
      this.productPartColorsList = this.parentProductPartColors;
      this.partComponent = this.$parent;
      this.part = this.partComponent.part;
      this.selectOptions = this.partComponent.selectOptions;
    },

    computed: {
      endpointColorAddRow() {
        return '/admin/settings/products/parts/part-color/add-row';
      }
    },

    methods: {
      getInputName(id, field) {
        return this.$parent.getInputName(id, field);
      },

      getInputId(id, field) {
        return this.$parent.getInputId(id, field);
      },

      deleteColorOption(productPartColor) {
        let i = this.productPartColorsList.map(partColor => partColor.id).indexOf(productPartColor.id);
        this.productPartColorsList.splice(i, 1) // remove it from array
      },

      addColorOptionRecord(colorRecord, isNew) {
        colorRecord.isNew = ( isNew === true ) ? true : false;
        this.productPartColorsList.push(colorRecord);
      },

      addColorOption(event) {
        event.preventDefault();

        axios.post(this.endpointColorAddRow)
        .then((response) => {
          this.addColorOptionRecord( response.data.partColor, true );
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status);
        });

        return false;
      },

      getSubmitData() {
        var partColors = {};
        if( this.$refs.partColor ) {
          this.$refs.partColor.forEach(function(color) {
            partColors[color.partColor.id] = _.clone(color.partColor);
          });
        }
        return partColors;
      }
    }
  }
</script>
