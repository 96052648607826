<script>
  import draggable from 'vuedraggable'
  import PartDependency from './PartDependency';

  export default {
    props: [
      'parentPart',
      'partDependencies'
    ],

    components: {
      draggable,
      PartDependency
    },

    data() {
      return {
        partDependencyList: [],
        partComponent: [],
        part: [],
        errors: {},
        //selectOptions: []
      }
    },

    mounted() {
      this.partDependencyList = this.partDependencies;
      this.partComponent = this.$parent;
      this.part = this.partComponent.part;
      //this.selectOptions = this.partComponent.selectOptions;
    },

    computed: {
      endpointAddRow() {
        return '/admin/settings/products/parts/part-dependency/add-row';
      },

      endpointSort() {
        return '/admin/settings/products/parts/part-dependency/sort';
      },

      hasPartDependencies() {
        return ( this.partDependencyList && this.partDependencyList.length > 0 ) ? true : false;
      },

      partDependencyIdList() {
        return this.partDependencyList.map(part => {
          return part.child_part_id;
        });
      },

      selectOptions() {
        var that = this,
            options = null;

        if( this.partComponent.isMotor ) {
          options = this.partComponent.selectOptions.motorPartDependencyOptions;
        } else if( this.partComponent.isTube ) {
          options = this.partComponent.selectOptions.tubePartDependencyOptions;
        }

        if( options ) {
          options.map(function(category) {
            category.parts.map(function(part) {
              if( that.partDependencyIdList.indexOf(part.id) != -1 ) {
                part.isSelected = true;
              } else {
                part.isSelected = false;
              }
            });
          });
        }

        return options;
      },
    },

    methods: {
      deleteDependencyOption(partDependency) {
        let i = this.partDependencyList.map(partDep => partDep.id).indexOf(partDependency.id);
        this.partDependencyList.splice(i, 1) // remove it from array
      },

      addPartDependencyRecord(record, isNew) {
        record.isNew = ( isNew === true ) ? true : false;
        this.partDependencyList.push(record);
      },

      addPartDependencyOption(event) {
        event.preventDefault();
        axios.post(this.endpointAddRow)
        .then((response) => {
          this.addPartDependencyRecord( response.data.partDependency, true );
        })
        .catch(error => {
          //flash(error.response.data.status_type, error.response.data.status);
        });

        return false;
      },

      saveSortOrder(event) {
        let endpoint = this.endpointSort,
            sort_order = this.partDependencyList.map(part => part.id),
            submitData = {
              _method: 'PUT',
              sort_order: sort_order
            };
        axios.post(endpoint, submitData)
        .then((response) => {
          //
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status); //show the error message
        });
      },

      getSubmitData() {
        var partDeps = {};
        if( this.$refs.partDependency ) {
          this.$refs.partDependency.forEach(function(obj) {
            let partDep = _.clone(obj.partDependency);
              delete partDep.part;
            partDeps[obj.partDependency.id] = partDep;
          });
        }
        return partDeps;
      },

      setErrors(errors) {
        var that = this;
        this.errors = errors;
      }
    }
  };
</script>

<template>
  <div v-show="partComponent.isMotor || partComponent.isTube">
    <div class="form-row">
      <div class="col-sm-12 heading w-bdr-btm bdr-btm-md">
        <h1>Part Dependencies</h1>
      </div>
    </div>

    <div class="part-dependencies-container">
      <template v-if="hasPartDependencies == false">
        <div class="dependent-part-item expandable-block">
          <div class="expandable-body minimized">
            <div class="title-bar-wrapper">
              <div class="title-bar">
                <div class="summary">
                  <p class="no-results">No dependent parts found. <a href="#" @click="addPartDependencyOption($event)">Add a Part</a> to get started...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <draggable
          v-model="partDependencyList"
          draggable=".part-dependency-item"
          filter=".not-sortable"
          :preventOnFilter="false"
          @end="saveSortOrder">

          <template v-for="(partDependency, index) in partDependencyList">
            <part-dependency
              :parent-part="partDependency"
              :part-dependency-index="index"
              :key="partDependency.id"
              ref="partDependency">
            </part-dependency>
          </template>

        </draggable>
      </template>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-12">
        <p>
          <a class="btn btn-secondary" @click="addPartDependencyOption($event)">Add Part</a>
        </p>
      </div>
    </div>
  </div>
</template>

