<script>
  import InstallationEquipmentCategory from './InstallationEquipmentCategory';

  export default {
    props: [
      'parentInstallationEquipmentCategories'
    ],

    components: {
      InstallationEquipmentCategory
    },

    data() {
      return {
        equipmentCategoryList: [],
        errors: []
      }
    },

    mounted() {
      this.equipmentCategoryList = this.parentInstallationEquipmentCategories;
    },

    computed: {
      isDisabled() {
        return false;
      },
    },

    methods: {
      getSaveData() {
        var selectedEquipment = {};
        this.$refs.installationEquipmentCategory.forEach(function(category) {
          category.$refs.installationEquipments.forEach(function(equipmentRecord) {
            equipmentRecord.$refs.installationEquipment.forEach(function(installEquipment) {
              if( installEquipment.equipmentOption.selected_value != null ) {
                selectedEquipment[installEquipment.equipmentOption.id] = {
                  quantity: installEquipment.equipmentOption.quantity
                }
              }
            });
          });
        });
        return selectedEquipment
      },

      setErrors(errors) {
        this.errors = errors;
      }
    },
  };
</script>
