<script>
  export default {
    props: [
      'parentCoordinationLabor',
    ],

    data() {
      return {
        coordinationLabor: {
          id: null,
          position: null,
          rate: null
        },
        isNew: false,
        errors: [],
      }
    },

    mounted() {
      this.coordinationLabor = { ...this.coordinationLabor, ...this.parentCoordinationLabor };

      if( this.parentCoordinationLabor.isNew == true ) {
        this.isNew = true;
      }
    },

    computed: {
      endpointDestroy(id) {
        return '/admin/settings/app-config/proposals/coordination-labors/' + this.coordinationLabor.id;
      }
    },

    methods: {
      destroy(event) {
        event.preventDefault();

        if( this.isNew === true ) {
          this.$parent.deleteCoordinationLaborRow( this.coordinationLabor );

        } else {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            this.$parent.deleteCoordinationLaborRow( this.coordinationLabor );
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      },

      setErrors(errors) {
        var that = this;

        $.each(errors, function(index, partDependencyError) {
          let i = that.partDependencyList.map(partDep => partDep.id).indexOf(parseInt(index));
          if( i >= 0 ) {
            //Create the concatenated ref name.
            //TODO - seems like there should be an easier way to do this here...
            var refName = 'partDependency_'+that.partDependencyList[i].id;
            that.$refs[refName][0].setErrors(partDependencyError);
          }
        });
      }
    }
  }
</script>
