<script>

//
// TODO Before Complete:
//  1) Add Sort Order Functionality
//  2) Add Input Hint Text
//  3) Also, properly handle error passing from parent to child.
//

  export default {
    props: [
      'parentProjectOrderType',
      'parentOrderTypeStatusNotification',
      'index',
    ],

    data() {
      return {
        statusNotification: {
          gbl_project_order_type_id: null,
          is_record_deletable: false,
          isNew: false,
          sort_order_number: null,
          name: null,
          internal_instructions: null,
          display_name: null,
          send_internal_email: false,
          internal_email_subject: null,
          internal_email_send_to: null,
          internal_email_reply_to: null,
          internal_email_message_body: null,
          send_customer_email: false,
          customer_email_subject: null,
          customer_email_reply_to: null,
          customer_email_message_body: null
        },
        isNew: false,
        isMaximized: false
      }
    },

    mounted() {
      this.statusNotification = { ...this.statusNotification, ...this.parentOrderTypeStatusNotification };

      if( this.statusNotification.isNew === true ) {
        this.isNew = true;
        this.isMaximized = true;
      }

      /*
        console.log('OrderTypeStatusNotification.vue (SINGULAR) - Mounted');
        console.log('this.parentProjectOrderType');
        console.log(this.parentProjectOrderType);
        console.log('this.parentOrderTypeStatusNotification');
        console.log(this.parentOrderTypeStatusNotification);
        //console.log('this.statusNotification');
        //console.log(this.statusNotification);
        console.log('');
      */
    },

    computed: {
      isDeletable() {
        return this.statusNotification.is_record_deletable;
      },

      isTestable() {
        if( this.isNew == true ) {
          return false;
        }

        if( this.statusNotification.send_internal_email == false && this.statusNotification.send_customer_email == false ) {
          return false;
        }

        return true;
      },

      canChangeSortOrder() {
        return ( this.index == 0 ) ? false : true;
      },

      isSortableContainerClass() {
        return ( this.canChangeSortOrder == false ) ? 'not-sortable' : 'is-sortable';
      },

      sortOrderNumber() {
        const sortOrderNumber = this.index + 1;
        return sortOrderNumber;
      },

      endpointDestroy() {
        return '/admin/settings/app-config/order-notifications/' + this.statusNotification.id
      },

      endpointEmailTest() {
        return '/admin/settings/app-config/order-notifications/email-test/' + this.statusNotification.id
      },

      errors() {
        if( this.$parent.$parent.errors.hasOwnProperty('global_order_notifications')
              && this.$parent.$parent.errors.global_order_notifications.hasOwnProperty(this.statusNotification.id) ) {
          return this.$parent.$parent.errors['global_order_notifications'][this.statusNotification.id];
        }
        return [];
      },

      hasErrors() {
        if( Object.keys(this.errors).length > 0 ) {
          this.isMaximized = true;
          return true;
        }

        return false;
      },
    },

    methods: {
      getInputName(id, field) {
        return 'global_order_notifications['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      toggleFullView(event) {
        event.preventDefault();
        this.isMaximized = ( this.isMaximized == true ) ? false : true;
      },

      destroy(event) {
        event.preventDefault();

        if( this.isNew == true ) {
          this.$parent.$parent.deleteStatusRecord( this.statusNotification );
          return;

        } else {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            this.$parent.$parent.deleteStatusRecord( this.statusNotification );
            flash(response.data.status_type, response.data.status);
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      },

      sendTestEmail(event) {
        event.preventDefault();

        const testData = {};
        testData[this.statusNotification.id] = this.statusNotification;

        const submitData = {
          global_order_notifications: testData
        };

        axios.post(this.endpointEmailTest, submitData)
        .then((response) => {
          flash(response.data.status_type, response.data.status);

        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status);
        });
      },
    },

    watch: {
      'statusNotification.send_internal_email': function (newVal, oldVal) {
        if( newVal == false ) {
          this.statusNotification.internal_email_subject = null;
          this.statusNotification.internal_email_send_to = null;
          this.statusNotification.internal_email_reply_to = null;
          this.statusNotification.internal_email_message_body = null;
        }
      },

      'statusNotification.send_customer_email': function (newVal, oldVal) {
        if( newVal == false ) {
          this.statusNotification.customer_email_subject = null;
          this.statusNotification.customer_email_reply_to = null;
          this.statusNotification.customer_email_message_body = null;
        }
      },

      'sortOrderNumber': function (newVal, oldVal) {
        if( this.statusNotification.sort_order_number != this.sortOrderNumber ) {
          this.statusNotification.sort_order_number = this.sortOrderNumber;
        }
      }

    }

  };
</script>

<template>
  <div
    class="status-notifications-list-item expandable-block small"
    v-bind:class="isSortableContainerClass">

    <div class="sort-handle" v-if="statusNotification.sort_order_number != 1 && isDeletable == true"></div>
    <div
      class="expandable-body"
      v-bind:class="(isMaximized == true || hasErrors == true) ? 'maximized' : 'minimized'">

      <div class="title-bar-wrapper"><!-- start: title-bar-wrapper -->
        <div class="title-bar">
          <div class="summary">
            <template v-if="statusNotification.name == '' || statusNotification.name == null">
              <h2>New Status</h2>
            </template>
            <template v-else>
              <h2>
                {{ statusNotification.name }}
                <span class="hint" v-if="statusNotification.display_name != '' && statusNotification.display_name != null">
                  ({{ statusNotification.display_name }})
                </span>
              </h2>
            </template>
          </div>
          <div class="actions">
            <div class="link edit" v-show="isMaximized == false">
              <a @click="toggleFullView($event)"></a>
            </div>
            <div class="link minimize" v-show="isMaximized == true">
              <a @click="toggleFullView($event)"></a>
            </div>
            <div class="link send-email">
              <a v-bind:class="(isTestable == false) ? 'disabled' : ''" @click="sendTestEmail($event)"></a>
            </div>
            <div class="link delete">
              <a v-bind:class="(isDeletable == false) ? 'disabled' : ''" @click="destroy($event)"></a>
            </div>
          </div>
        </div>
      </div><!-- end: title-bar-wrapper -->

      <div class="content-wrapper"><!-- start: content-wrapper -->
        <transition name="slide">
          <div class="transition-container" v-show="isMaximized">
            <div class="content-block" v-show="isMaximized">
              <div class="form-row">

                <!-- sort order number field -->
                <input
                  type="hidden"
                  v-bind:id="getInputId(statusNotification.id, 'sort_order_number')"
                  v-bind:name="getInputName(statusNotification.id, 'sort_order_number')"
                  v-model="statusNotification.sort_order_number">

                <div class="form-group col-sm-12 col-md-6 mb-0">
                  <div class="form-group col-sm-12 pl-0 pr-0">
                    <label
                      class="float-left"
                      v-bind:class="errors['name'] ? 'is-invalid' : ''"
                      v-bind:for="getInputId(statusNotification.id, 'name')">
                      Status Name
                    </label>
                    <span class="invalid-feedback text-right" role="alert" v-if="errors['name']">
                      {{ errors['name'][0] }}
                    </span>
                    <input type="text"
                           class="form-control"
                           v-bind:class="errors['name'] ? 'is-invalid' : ''"
                           placeholder="Status name"
                           maxlength="255"
                           v-bind:name="getInputName(statusNotification.id, 'name')"
                           v-bind:id="getInputId(statusNotification.id, 'name')"
                           v-model="statusNotification.name">
                  </div>

                  <div class="form-group col-sm-12 pl-0 pr-0">
                    <label
                      class="float-left"
                      v-bind:class="errors['display_name'] ? 'is-invalid' : ''"
                      v-bind:for="getInputId(statusNotification.id, 'display_name')">
                      Display Name
                    </label>
                    <span class="invalid-feedback text-right" role="alert" v-if="errors['display_name']">
                      {{ errors['display_name'][0] }}
                    </span>
                    <input type="text"
                           class="form-control"
                           v-bind:class="errors['display_name'] ? 'is-invalid' : ''"
                           placeholder="Display name"
                           maxlength="255"
                           v-bind:name="getInputName(statusNotification.id, 'display_name')"
                           v-bind:id="getInputId(statusNotification.id, 'display_name')"
                           v-model="statusNotification.display_name">
                  </div>
                </div>

                <div class="form-group col-sm-12 col-md-6 mb-0">
                  <div class="form-group col-sm-12">
                    <label
                      class="float-left"
                      v-bind:class="errors['internal_instructions'] ? 'is-invalid' : ''"
                      v-bind:for="getInputId(statusNotification.id, 'internal_instructions')">
                      Internal Instructions
                    </label>
                    <span class="invalid-feedback text-right" role="alert" v-if="errors['internal_instructions']">
                      {{ errors['internal_instructions'][0] }}
                    </span>

                    <textarea
                      rows="5"
                      class="form-control"
                      placeholder="Include brief list of instructions for Lutek team members"
                      v-bind:class="errors['internal_instructions'] ? 'is-invalid' : ''"
                      v-bind:name="getInputName(statusNotification.id, 'internal_instructions')"
                      v-bind:id="getInputId(statusNotification.id, 'internal_instructions')"
                      v-model="statusNotification.internal_instructions">
                    </textarea>
                  </div>
                </div>
              </div>

              <hr>

              <div class="form-row">
                <div class="form-group col-sm-12 col-md-6">
                  <label
                    class="float-left"
                    v-bind:class="errors['send_internal_email'] ? 'is-invalid' : ''"
                    v-bind:for="getInputId(statusNotification.id, 'send_internal_email')">
                    Send Internal Email
                  </label>
                  <span class="invalid-feedback text-right" role="alert" v-if="errors['send_internal_email']">
                    {{ errors['send_internal_email'][0] }}
                  </span>

                  <select
                    class="form-control"
                    v-bind:class="errors['send_internal_email'] ? 'is-invalid' : ''"
                    v-bind:name="getInputName(statusNotification.id, 'send_internal_email')"
                    v-bind:id="getInputId(statusNotification.id, 'send_internal_email')"
                    v-model="statusNotification.send_internal_email">
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
              </div>

              <div class="form-row" v-show="statusNotification.send_internal_email == true">
                <div class="form-group col-sm-12 col-md-6 mb-0">
                  <div class="form-group col-sm-12 pl-0 pr-0">
                    <label
                      class="float-left"
                      v-bind:class="errors['internal_email_subject'] ? 'is-invalid' : ''"
                      v-bind:for="getInputId(statusNotification.id, 'internal_email_subject')">
                      Internal Email Subject
                    </label>
                    <span class="invalid-feedback text-right" role="alert" v-if="errors['internal_email_subject']">
                      {{ errors['internal_email_subject'][0] }}
                    </span>

                    <input type="text"
                           class="form-control"
                           v-bind:class="errors['internal_email_subject'] ? 'is-invalid' : ''"
                           placeholder="Email subject"
                           maxlength="100"
                           v-bind:name="getInputName(statusNotification.id, 'internal_email_subject')"
                           v-bind:id="getInputId(statusNotification.id, 'internal_email_subject')"
                           v-model="statusNotification.internal_email_subject">
                  </div>

                  <div class="form-group col-sm-12 pl-0 pr-0">
                    <label
                      class="float-left"
                      v-bind:class="errors['internal_email_send_to'] ? 'is-invalid' : ''"
                      v-bind:for="getInputId(statusNotification.id, 'internal_email_send_to')">
                      Send Internal Email To
                    </label>
                    <span class="invalid-feedback text-right" role="alert" v-if="errors['internal_email_send_to']">
                      {{ errors['internal_email_send_to'][0] }}
                    </span>

                    <textarea
                      rows="1"
                      class="form-control"
                      v-bind:class="errors['internal_email_send_to'] ? 'is-invalid' : ''"
                      placeholder="example@lu-tek.com"
                      v-bind:name="getInputName(statusNotification.id, 'internal_email_send_to')"
                      v-bind:id="getInputId(statusNotification.id, 'internal_email_send_to')"
                      v-model="statusNotification.internal_email_send_to">
                    </textarea>
                  </div>

                  <div class="form-group col-sm-12 pl-0 pr-0">
                    <label
                      class="float-left"
                      v-bind:class="errors['internal_email_reply_to'] ? 'is-invalid' : ''"
                      v-bind:for="getInputId(statusNotification.id, 'internal_email_reply_to')">
                      Send Internal Reply To
                    </label>
                    <span class="invalid-feedback text-right" role="alert" v-if="errors['internal_email_reply_to']">
                      {{ errors['internal_email_reply_to'][0] }}
                    </span>

                    <input type="text"
                           class="form-control"
                           v-bind:class="errors['internal_email_reply_to'] ? 'is-invalid' : ''"
                           placeholder="noreply@lu-tek.com"
                           maxlength="100"
                           v-bind:name="getInputName(statusNotification.id, 'internal_email_reply_to')"
                           v-bind:id="getInputId(statusNotification.id, 'internal_email_reply_to')"
                           v-model="statusNotification.internal_email_reply_to">

                    <span class="invalid-feedback" role="alert" v-if="errors['internal_email_reply_to']">
                      {{ errors['internal_email_reply_to'][0] }}
                    </span>
                  </div>
                </div>

                <div class="form-group col-sm-12 col-md-6 mb-0">
                  <div class="form-group col-sm-12">
                    <label
                      class="float-left"
                      v-bind:class="errors['internal_email_message_body'] ? 'is-invalid' : ''"
                      v-bind:for="getInputId(statusNotification.id, 'internal_email_message_body')">
                      Internal Email Message Body
                    </label>
                    <span class="invalid-feedback text-right" role="alert" v-if="errors['internal_email_message_body']">
                      {{ errors['internal_email_message_body'][0] }}
                    </span>

                    <textarea
                      rows="9"
                      class="form-control"
                      placeholder="Enter email body content"
                      v-bind:class="errors['internal_email_message_body'] ? 'is-invalid' : ''"
                      v-bind:name="getInputName(statusNotification.id, 'internal_email_message_body')"
                      v-bind:id="getInputId(statusNotification.id, 'internal_email_message_body')"
                      v-model="statusNotification.internal_email_message_body">
                    </textarea>
                  </div>
                </div>
              </div>

              <hr>

              <div class="form-row">
                <div class="form-group col-sm-12 col-md-6">
                  <label
                    class="float-left"
                    v-bind:class="errors['send_customer_email'] ? 'is-invalid' : ''"
                    v-bind:for="getInputId(statusNotification.id, 'send_customer_email')">
                    Send Customer Email
                  </label>
                  <span class="invalid-feedback text-right" role="alert" v-if="errors['send_customer_email']">
                    {{ errors['send_customer_email'][0] }}
                  </span>

                  <select
                    class="form-control"
                    v-bind:class="errors['send_customer_email'] ? 'is-invalid' : ''"
                    v-bind:name="getInputName(statusNotification.id, 'send_customer_email')"
                    v-bind:id="getInputId(statusNotification.id, 'send_customer_email')"
                    v-model="statusNotification.send_customer_email">
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
              </div>

              <div class="form-row" v-show="statusNotification.send_customer_email == true">
                <div class="form-group col-sm-12 col-md-6 mb-0">
                  <div class="form-group keep-margin col-sm-12 pl-0 pr-0">
                    <label
                      class="float-left"
                      v-bind:class="errors['customer_email_subject'] ? 'is-invalid' : ''"
                      v-bind:for="getInputId(statusNotification.id, 'customer_email_subject')">
                      Customer Email Subject
                    </label>
                    <span class="invalid-feedback text-right" role="alert" v-if="errors['customer_email_subject']">
                      {{ errors['customer_email_subject'][0] }}
                    </span>

                    <input type="text"
                           class="form-control"
                           v-bind:class="errors['customer_email_subject'] ? 'is-invalid' : ''"
                           placeholder="Email subject"
                           maxlength="100"
                           v-bind:name="getInputName(statusNotification.id, 'customer_email_subject')"
                           v-bind:id="getInputId(statusNotification.id, 'customer_email_subject')"
                           v-model="statusNotification.customer_email_subject">
                  </div>

                  <div class="form-group col-sm-12 pl-0 pr-0">
                    <label
                      class="float-left"
                      v-bind:class="errors['customer_email_reply_to'] ? 'is-invalid' : ''"
                      v-bind:for="getInputId(statusNotification.id, 'customer_email_reply_to')">
                      Customer Email Reply To
                    </label>
                    <span class="invalid-feedback text-right" role="alert" v-if="errors['customer_email_reply_to']">
                      {{ errors['customer_email_reply_to'][0] }}
                    </span>

                    <input type="text"
                           class="form-control"
                           v-bind:class="errors['customer_email_reply_to'] ? 'is-invalid' : ''"
                           placeholder="noreply@lu-tek.com"
                           maxlength="100"
                           v-bind:name="getInputName(statusNotification.id, 'customer_email_reply_to')"
                           v-bind:id="getInputId(statusNotification.id, 'customer_email_reply_to')"
                           v-model="statusNotification.customer_email_reply_to">
                  </div>
                </div>

                <div class="form-group col-sm-12 col-md-6 mb-0">
                  <div class="form-group col-sm-12">
                    <label
                      class="float-left"
                      v-bind:class="errors['customer_email_message_body'] ? 'is-invalid' : ''"
                      v-bind:for="getInputId(statusNotification.id, 'customer_email_message_body')">
                      Customer Email Message Body
                    </label>
                    <span class="invalid-feedback text-right" role="alert" v-if="errors['customer_email_message_body']">
                      {{ errors['customer_email_message_body'][0] }}
                    </span>

                    <textarea
                      rows="5"
                      class="form-control"
                      placeholder="Enter email body content"
                      v-bind:class="errors['customer_email_message_body'] ? 'is-invalid' : ''"
                      v-bind:name="getInputName(statusNotification.id, 'customer_email_message_body')"
                      v-bind:id="getInputId(statusNotification.id, 'customer_email_message_body')"
                      v-model="statusNotification.customer_email_message_body">
                    </textarea>
                  </div>
                </div>
              </div>

            </div><!-- content-block -->
          </div><!-- transition-container -->
        </transition>
      </div><!-- content-wrapper -->
    </div><!-- expandable-body -->
  </div><!-- .status-notifications-list-item.expandable-block -->
</template>
