var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row" }, [
    _c(
      "div",
      { staticClass: "form-group col-sm-12 unit-cost table-responsive" },
      [
        _c("table", { staticClass: "table" }, [
          _c("tbody", [
            _c("tr", [
              _c("td", { staticClass: "text-nowrap" }, [_vm._v("Unit Cost")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.orderProposal.unit_cost_formatted))]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "text-nowrap" }, [_vm._v("Unit Price")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.unit_price_formatted)),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }