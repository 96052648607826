<script>
  export default {
    props: [
      'shadeUnit',
      'unitType',
      'containerClass',
      'errors',
      'disabled'
    ],

    mounted() {},

    computed: {
      parentContainerClass() {
        return this.containerClass;
      },

      fasciaTypeSelectOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.fasciaTypeOptions;
      },

      hasFascia() {
        if( this.unitType == 'shadeConfigUnit' ) {
          return this.$store.getters['shadeUnitConfigs/hasFascia'](this.shadeUnit.id);
        } else {
          return this.$store.getters['shadeLineItems/hasFascia'](this.shadeUnit.id);
        }
      },

      hasErrors() {
        if( typeof errors.fascia_type != 'undefined' ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return this.$parent.getInputName(id, field);
      },

      getInputId(id, field) {
        return this.$parent.getInputId(id, field);
      },
    }
  }
</script>
