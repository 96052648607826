var render, staticRenderFns
import script from "./ProductModelPart.vue?vue&type=script&lang=js&"
export * from "./ProductModelPart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/sam/lutek/Portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c32293b2')) {
      api.createRecord('c32293b2', component.options)
    } else {
      api.reload('c32293b2', component.options)
    }
    
  }
}
component.options.__file = "resources/js/vue/components/admin/settings/product/ProductModelPart.vue"
export default component.exports