<script>
  import { mapDynamicMultiRowFields } from '../../../../helpers/vuexMapFieldsOverride';
  import ShadeInstallationTableRow from './ShadeInstallationTableRow';

  export default {
    props: [
      'shadeGroupId',
    ],

    components: {
      ShadeInstallationTableRow,
    },

    mounted() {},

    computed: {
      shadeGroup() {
        return this.$store.getters['shadeGroups/find'](this.shadeGroupId);
      },

      ...mapDynamicMultiRowFields('shadeLineItems', { shadeLineItems: 'byGroupId[].shadeLineItems'}, 'indexArray'),

      indexArray() {
        return this.shadeGroupId;
      },

      errors() {
        return this.$parent.errors;
      },
    },

    methods: {

    }
  };
</script>

<template>
  <tbody v-if="shadeGroup.isNew == false">
    <template v-for="shadeLineItem in shadeLineItems">
      <shade-installation-table-row
        :shade-group-id="shadeGroupId"
        :shade-unit="shadeLineItem">
      </shade-installation-table-row>
    </template>
  </tbody>
</template>
