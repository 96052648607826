<script>
  import PartColors from './PartColors';
  import PartDependencies from './PartDependencies';
  import PartMaps from './PartMaps';
  import PartLabors from './PartLabors';

  export default {
    props: [
      'parentPart',
      'parentIsNew',
      'parentPartDimension',
      'parentPartLabors',
      'parentProductPartColors',
      'parentPartDependencies',
      'parentPartMaps',
      'selectOptions',
    ],

    components: {
      PartColors,
      PartDependencies,
      PartMaps,
      PartLabors
    },

    data() {
      return {
        part: {
          unit_price_formatted: '$0.00',
          mount_type: null,
          motor_type: null,
          motor_power: null,
          motor_sound: null,
        },
        partDimension: {
          composition: null,
        },
        isNew: false,
        errors: {},
        loaded: false
      }
    },

    mounted() {
      this.part = { ...this.part, ...this.parentPart };
        this.part.unit_price = this.part.unit_price_formatted;
      this.partDimension = { ...this.partDimension, ...this.parentPartDimension };
        this.partDimension.diameter_in = this.partDimension.diameter_in_formatted;
        this.partDimension.width_in = this.partDimension.width_in_formatted;
        this.partDimension.length_in = this.partDimension.length_in_formatted;
        this.partDimension.thickness_in = this.partDimension.thickness_in_formatted;
        this.partDimension.fabric_width_addtl_in = this.partDimension.fabric_width_addtl_in_formatted;
        this.partDimension.fabric_length_addtl_in = this.partDimension.fabric_length_addtl_in_formatted;
      this.isNew = this.parentIsNew;

      this.$nextTick(() => {
        var $descriptionEl = this.$refs.partDescription;
        $descriptionEl.style.height = `${$descriptionEl.scrollHeight}px`;
      });

/*
        console.log('ProductPart.vue Mounted...');
        console.log('this');
        console.log(this);
        console.log('');
*/
    },

    computed: {
      endpointCreate() {
        return '/admin/settings/products/parts';
      },

      endpointUpdate() {
        return '/admin/settings/products/parts/' + this.part.id;
      },

      isMotor: function() {
        if( this.part.part_type_id == null ) {
          return false;
        } else {
          let i = this.selectOptions.partTypes.map(partType => partType.id).indexOf(this.part.part_type_id);

          if( this.selectOptions.partTypes[i].name == 'Motor' ) {
            this.part.has_colors = false;
            return true;
          } else {
            this.part.has_colors = ( this.part.part_colors.length ) ? true : false;
            return false;
          }
        }
      },

      isTube: function() {
        if( this.part.part_type_id == null ) {
          return false;
        } else {
          let i = this.selectOptions.partTypes.map(partType => partType.id).indexOf(this.part.part_type_id);

          if( this.selectOptions.partTypes[i].name == 'Tube' ) {
            this.part.has_colors = ( this.part.part_colors.length ) ? true : false;
            return true;
          } else {
            this.part.has_colors = ( this.part.part_colors.length ) ? true : false;
            return false;
          }
        }
      },

      isIdleBracket: function() {
        if( this.part.part_type_id == null ) {
          return false;

        } else {
          let i = this.selectOptions.partTypes.map(partType => partType.id).indexOf(this.part.part_type_id);

          if( this.selectOptions.partTypes[i].name == 'Bracket (Idle)' ) {
            this.part.has_colors = ( this.part.part_colors.length ) ? true : false;
            return true;

          } else {
            this.part.has_colors = ( this.part.part_colors.length ) ? true : false;
            return false;
          }
        }
      },

      isTrim: function() {
        if( this.part.part_type_id == null ) {
          this.part.trim_type = null;
          return false;
        }

        var selectedPartTypeId = this.selectOptions.partTypes.map(partType => partType.id).indexOf(this.part.part_type_id);

        if( this.selectOptions.partTypes[selectedPartTypeId].name == 'Pocket Trim' ) {
          return true;
        } else {
          this.part.trim_type = null;
          return false;
        }
      },

      isCassette: function() {
        if( this.part.part_type_id == null ) {
          this.part.trim_type = null;
          return false;
        }

        var selectedPartTypeId = this.selectOptions.partTypes.map(partType => partType.id).indexOf(this.part.part_type_id);

        if( this.selectOptions.partTypes[selectedPartTypeId].name == 'Cassette' ) {
          return true;
        } else {
          this.part.trim_type = null;
          return false;
        }
      },

      isSideChannel: function() {
        if( this.part.part_type_id == null ) {
          this.part.trim_type = null;
          return false;
        }

        var selectedPartTypeId = this.selectOptions.partTypes.map(partType => partType.id).indexOf(this.part.part_type_id);

        if( this.selectOptions.partTypes[selectedPartTypeId].name == 'Side Channel' ) {
          return true;
        } else {
          this.part.trim_type = null;
          return false;
        }
      },

      isFascia: function() {
        if( this.part.part_type_id == null ) {
          return false;
        }

        var selectedPartTypeId = this.selectOptions.partTypes.map(partType => partType.id).indexOf(this.part.part_type_id);

        if( this.selectOptions.partTypes[selectedPartTypeId].name == 'Fascia' ) {
          return true;
        } else {
          return false;
        }
      },

      isCassette: function() {
        if( this.part.part_type_id == null ) {
          return false;
        }

        var selectedPartTypeId = this.selectOptions.partTypes.map(partType => partType.id).indexOf(this.part.part_type_id);

        if( this.selectOptions.partTypes[selectedPartTypeId].name == 'Cassette' ) {
          return true;
        } else {
          return false;
        }
      },

      canHaveHoldingCapacity() {
        if( this.part.part_type_id == null ) {
          return false;
        }

        var holdingCapacityPartTypes = ['Clutch', 'Cranks', 'Spring (LH)', 'Spring (RH)'];
        var selectedPartTypeId = this.selectOptions.partTypes.map(partType => partType.id).indexOf(this.part.part_type_id);

        if( holdingCapacityPartTypes.includes(this.selectOptions.partTypes[selectedPartTypeId].name) ) {
          return true;
        } else {
          return false;
        }
      },

      supportsMainPartNumber() {
        if( this.part.has_colors == true ) {
          this.part.part_number = null;
          return false;
        }
        return true;
      },

      hasErrors() {
        if( Object.keys(this.errors).length ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'part[' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      getPartDimensionInputName(id, field) {
        return 'part_dimension[' + field + ']';
      },

      getPartDimensionInputId(id, field) {
        var inputName = this.getPartDimensionInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      getPartColorInputName(id, field) {
        return 'part_color[' + id + '][' + field + ']';
      },

      getPartColorInputId(id, field) {
        var inputName = this.getPartColorInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      submitForm(event) {
        event.preventDefault();
        var endpoint = ( this.isNew ) ? this.endpointCreate : this.endpointUpdate,
            submitData = {},
            partData = this.part;
              partData['has_color_options'] = this.part.has_colors;
        var partDimensionData = this.partDimension,
            partColorData = ( this.$refs.partColors ) ? this.$refs.partColors.getSubmitData() : {},
            partDependencyData = ( this.$refs.partDependencies ) ? this.$refs.partDependencies.getSubmitData() : {},
            partMapData = ( this.$refs.partMapDependencies ) ? this.$refs.partMapDependencies.getSubmitData() : {},
            partLaborsData = ( this.$refs.partLabors ) ? this.$refs.partLabors.getSubmitData() : {},

        submitData = {
          _method: ( this.isNew ) ? 'POST' : 'PUT',
          part: partData,
          part_dimension: partDimensionData,
          part_color: partColorData,
          part_dependency: partDependencyData,
          part_map: partMapData,
          part_labors: partLaborsData,
        }

        axios.post(endpoint, submitData)
        .then((response) => {
          //flash(response.data.status_type, response.data.status);
          if( response.data.redirectTo != '' ) {
            window.location.href = response.data.redirectTo;
          }
        })
        .catch(error => {
          this.setErrors(error.response.data.errors);
          flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
        });
      },

      setErrors(errors, form) {
        if( Object.entries(errors).length ) {
          if( typeof errors.part != 'undefined' ) {
            this.errors = errors.part;
          }

          if( typeof errors.part_dependency != 'undefined' ) {
            this.$refs.partDependencies.setErrors(errors.part_dependency);
          } else {
            this.$refs.partDependencies.setErrors({});
          }

          if( typeof errors.part_map != 'undefined' ) {
            this.$refs.partMapDependencies.setErrors(errors.part_map);
          } else {
            this.$refs.partMapDependencies.setErrors({});
          }

          if( typeof errors.part_labors != 'undefined' ) {
            this.$refs.partLabors.setErrors(errors.part_labors);
          } else {
            this.$refs.partLabors.setErrors({});
          }
        }

        setTimeout( function() {
          $(form).find('.is-invalid:first').focus();
        }, 100);

        //this.errors = errors;
        this.hasErrors;
      }
    }
  }
</script>
