<script>
  import draggable from 'vuedraggable';
  import EquipmentCategoryOption from './EquipmentCategoryOption';

  export default {
    props: [
      'parentEquipmentOptions',
      'parentCategoryOptions'
    ],

    components: {
      EquipmentCategoryOption,
    },

    data() {
      return {
        categoryOptionList: [],
      }
    },

    mounted() {
      this.categoryOptionList = this.parentCategoryOptions;
    },

    computed: {
      endpointAddRow() {
        return '/admin/settings/installer-equipment/add-row';
      },

      endpointSort() {
        return '/admin/settings/installer-equipment-sort/sort';
      },

      addRowButtonText() {
        return 'Add ' + this.$parent.equipmentCategoryName;
      }
    },

    methods: {
      addCategoryOption(event) {
        event.preventDefault();
        axios.post(this.endpointAddRow, { category: this.$parent.parentEquipmentCategory })
        .then((response) => {
          let newOption = response.data.installerEquipmentOption;
            newOption.isNew = response.data.isNew;
            newOption.uom = null;
          this.categoryOptionList.push( newOption );
        })
        .catch(error => {
          //flash(error.response.data.status_type, error.response.data.status);
        });
      },

      deleteEquipmentOption(equipmentOption) {
        let i = this.categoryOptionList.map(obj => obj.id).indexOf(equipmentOption.id);
        this.categoryOptionList.splice(i, 1) // remove it from array
      },

      applySavedCategoryOption(oldOptionId, responseOption) {
        responseOption.isNew = false;
        let index = this.categoryOptionList.map(option => option.id).indexOf(oldOptionId);
        this.categoryOptionList.splice(index, 1, responseOption);
      },

      saveSortOrder(event) {
        let endpoint = this.endpointSort,
            sort_order = this.categoryOptionList.map(option => option.id),
            submitData = {
              _method: 'PUT',
              sort_order: sort_order
            };
        axios.post(endpoint, submitData)
        .then((response) => {
          console.log(response);
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status); //show the error message
        });
      }
    }
  };
</script>
