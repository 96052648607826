<script>
  import ModalConfirm from '../../../notifications/ModalConfirm.vue';

  export default {
    props: [
      'parentCategoryOption',
      'parentSelectOptions',
    ],

    components: {
      ModalConfirm
    },

    data() {
      return {
        categoryOption: [],
        errors: []
      }
    },

    mounted() {
      this.categoryOption = this.parentCategoryOption;
    },

    computed: {
      endpointCreate() {
        return '/admin/settings/installer-equipment';
      },

      endpointUpdate() {
        return '/admin/settings/installer-equipment/' + this.categoryOption.id;
      },

      endpointDestroy() {
        return '/admin/settings/installer-equipment/' + this.categoryOption.id;
      },

      isNew() {
        return ( this.categoryOption.isNew == true ) ? true : false;
      },

      isDisabled() {
        return false;
      },

      typeSelectOptions() {
        return this.parentSelectOptions.typeOptions;
      },

      uomSelectOptions() {
        return this.parentSelectOptions.uomOptions;
      },

      hasErrors() {
        if( Object.keys(this.errors).length ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'installer_equipment['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      save(event) {
        event.preventDefault();
        var endpoint = ( this.isNew ) ? this.endpointCreate : this.endpointUpdate;
        var optionData = {};
            optionData[this.categoryOption.id] = _.clone(this.categoryOption);
        var submitData = {
              _method: ( this.isNew ) ? 'POST' : 'PUT',
              installer_equipment: optionData
            };

        axios.post(endpoint, submitData)
        .then((response) => {
          let responseOption = response.data.data.installerEquipmentOption;
          this.setErrors({});
          this.$parent.$parent.applySavedCategoryOption(this.categoryOption.id, responseOption);
          flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          this.setErrors(error.response.data.errors);
          flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
        });
      },

      destroyClick(event) {
        event.preventDefault();
        if( this.isNew == true ) {
          this.destroy();
        } else {
          this.$bvModal.show('modal-' + this.categoryOption.id);
        }
      },

      //Modal - Handle modal function call
      handleFunctionCall(functionName) {
        this[functionName]();
      },

      //Modal - Cancel out of an open/active modal without completeing action
      cancelModalAction() {
        return false;
      },

      destroy() {
        if( this.isNew === true ) {
          this.$parent.$parent.deleteEquipmentOption( this.categoryOption );
          flash('alert-success', 'Equipment record deleted');

        } else {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            this.$parent.$parent.deleteEquipmentOption( this.categoryOption );
            flash(response.data.status_type, response.data.status);
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      },

      setErrors(errors) {
        if( errors.hasOwnProperty('installer_equipment') && errors.installer_equipment.hasOwnProperty(this.categoryOption.id) ) {
          this.errors = errors.installer_equipment[this.categoryOption.id];
        } else {
          this.errors = errors;
        }
      }
    }
  };
</script>
