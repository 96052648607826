var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "modal-fade" } }, [
    _c("div", { staticClass: "modal-backdrop" }, [
      _c(
        "div",
        {
          staticClass: "modal",
          attrs: {
            role: "dialog",
            "aria-labelledby": "modalTitle",
            "aria-describedby": "modalDescription",
          },
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.uploadShade.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "header",
                { staticClass: "modal-header", attrs: { id: "modalTitle" } },
                [
                  _vm._t("header", function () {
                    return [_vm._v("\n            Upload Shades\n          ")]
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn-close",
                      attrs: { type: "button", "aria-label": "Close modal" },
                      on: { click: _vm.close },
                    },
                    [_vm._v("\n            x\n          ")]
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "section",
                {
                  staticClass: "modal-body",
                  attrs: { id: "modalDescription" },
                },
                [
                  _vm._t("body", function () {
                    return [
                      _c("input", {
                        staticClass: "form-control",
                        staticStyle: { margin: "auto" },
                        attrs: {
                          accept: "text/csv",
                          type: "file",
                          id: "upload_csv",
                          name: "upload_csv",
                        },
                        on: { change: _vm.handleFileUpload },
                      }),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "footer",
                { staticClass: "modal-footer" },
                [
                  _vm._t("footer", function () {
                    return [
                      _c("input", {
                        staticClass: "btn btn-green",
                        attrs: { type: "button", value: "UPLOAD" },
                        on: { click: _vm.uploadShade },
                      }),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }