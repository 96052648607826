var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "part-labors-container mt-2" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.partLaborList, function (partLabor, index) {
        return [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-sm-6 col-md-2" }, [
              index == 0
                ? _c(
                    "label",
                    { attrs: { for: _vm.getInputName(partLabor.id, "name") } },
                    [_vm._v("Labor Type")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: partLabor.id,
                    expression: "partLabor.id",
                  },
                ],
                attrs: {
                  type: "hidden",
                  name: _vm.getInputName(partLabor.id, "id"),
                },
                domProps: { value: partLabor.id },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(partLabor, "id", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: partLabor.labor_type_id,
                    expression: "partLabor.labor_type_id",
                  },
                ],
                attrs: {
                  type: "hidden",
                  name: _vm.getInputName(partLabor.id, "labor_type_id"),
                },
                domProps: { value: partLabor.labor_type_id },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(partLabor, "labor_type_id", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(partLabor.labor_type_name))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group col-sm-6 col-md-3" }, [
              index == 0
                ? _c(
                    "label",
                    {
                      attrs: { for: _vm.getInputName(partLabor.id, "minutes") },
                    },
                    [_vm._v("Time (in minutes)")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: partLabor.minutes,
                    expression: "partLabor.minutes",
                  },
                ],
                staticClass: "form-control",
                class:
                  _vm.errors[partLabor.id] &&
                  _vm.errors[partLabor.id]["minutes"]
                    ? "is-invalid"
                    : "",
                attrs: {
                  type: "text",
                  placeholder: "10",
                  maxlength: "5",
                  name: _vm.getInputName(partLabor.id, "minutes"),
                  id: _vm.getInputId(partLabor.id, "minutes"),
                  disabled: _vm.partLaborIsDisabled(partLabor),
                },
                domProps: { value: partLabor.minutes },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(partLabor, "minutes", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors[partLabor.id] && _vm.errors[partLabor.id]["minutes"]
                ? _c(
                    "span",
                    {
                      staticClass: "invalid-feedback",
                      attrs: { role: "alert" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.errors[partLabor.id]["minutes"][0]) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-sm-12 mb-2" }, [
        _c("h5", [_vm._v("Part Labor")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }