var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row" }, [
    _vm.showHeadingRow
      ? _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
          _c("h2", [_vm._v(_vm._s(_vm.headingRowHeadingText))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
      _c("table", { staticClass: "table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [_vm._v("Labor Total")]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.cs_labor_cost_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [_vm._v("Project Expense")]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.orderProposal.cs_job_cost_formatted))]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [_vm._v("Material Cost")]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.cs_material_cost_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Sales Tax")]),
            _vm._v(" "),
            _c(
              "td",
              [
                _vm.isLockedForEdits
                  ? [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.project.type) +
                          "\n            "
                      ),
                    ]
                  : [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.project.type,
                              expression: "project.type",
                            },
                            {
                              name: "proposal-field-directive",
                              rawName: "v-proposal-field-directive",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.errors["type"] ? "is-invalid" : "",
                          attrs: {
                            name: _vm.getInputName("type"),
                            id: _vm.getInputId("type"),
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.project,
                                "type",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("--Choose One--"),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.selectOptions.proposalProjectTypeOptions,
                            function (option) {
                              return [
                                _c(
                                  "option",
                                  { domProps: { value: option.id } },
                                  [_vm._v(_vm._s(option.value))]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.cs_sales_tax_cost_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Freight")]),
            _vm._v(" "),
            _c(
              "td",
              [
                _vm.isLockedForEdits
                  ? [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.orderProposal.freight_pct_formatted) +
                          "\n            "
                      ),
                    ]
                  : [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.orderProposal.freight_pct,
                              expression: "orderProposal.freight_pct",
                            },
                            {
                              name: "proposal-field-directive",
                              rawName: "v-proposal-field-directive",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.errors["freight_pct"] ? "is-invalid" : "",
                          attrs: {
                            name: _vm.getInputName("freight_pct"),
                            id: _vm.getInputId("freight_pct"),
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.orderProposal,
                                "freight_pct",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("--Choose One--"),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.selectOptions.proposalFreightExpenseOptions,
                            function (option) {
                              return [
                                _c(
                                  "option",
                                  { domProps: { value: option.value } },
                                  [_vm._v(_vm._s(option.label))]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.cs_freight_cost_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [
              _vm._v("Total Direct Cost"),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.es_total_direct_cost_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Contingency")]),
            _vm._v(" "),
            _c(
              "td",
              [
                _vm.isLockedForEdits
                  ? [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.orderProposal.contingency_pct_formatted) +
                          "\n            "
                      ),
                    ]
                  : [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.orderProposal.contingency_pct,
                              expression: "orderProposal.contingency_pct",
                            },
                            {
                              name: "proposal-field-directive",
                              rawName: "v-proposal-field-directive",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.errors["contingency_pct"]
                            ? "is-invalid"
                            : "",
                          attrs: {
                            name: _vm.getInputName("contingency_pct"),
                            id: _vm.getInputId("contingency_pct"),
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.orderProposal,
                                "contingency_pct",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("--Choose One--"),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.selectOptions.proposalContingencyExpenseOptions,
                            function (option) {
                              return [
                                _c(
                                  "option",
                                  { domProps: { value: option.value } },
                                  [_vm._v(_vm._s(option.label))]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.cs_contingency_cost_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [_vm._v("Subtotal")]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                _vm._s(_vm.orderProposal.es_contingency_subtotal_formatted)
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Overhead")]),
            _vm._v(" "),
            _c(
              "td",
              [
                _vm.isLockedForEdits
                  ? [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.orderProposal.overhead_pct_formatted) +
                          "\n            "
                      ),
                    ]
                  : [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.orderProposal.overhead_pct,
                              expression: "orderProposal.overhead_pct",
                            },
                            {
                              name: "proposal-field-directive",
                              rawName: "v-proposal-field-directive",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.errors["overhead_pct"] ? "is-invalid" : "",
                          attrs: {
                            name: _vm.getInputName("overhead_pct"),
                            id: _vm.getInputId("overhead_pct"),
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.orderProposal,
                                "overhead_pct",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("--Choose One--"),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.selectOptions.proposalOverheadExpenseOptions,
                            function (option) {
                              return [
                                _c(
                                  "option",
                                  { domProps: { value: option.value } },
                                  [_vm._v(_vm._s(option.label))]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.cs_overhead_cost_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [_vm._v("Subtotal")]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.es_overhead_subtotal_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Profit")]),
            _vm._v(" "),
            _c(
              "td",
              [
                _vm.isLockedForEdits
                  ? [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.orderProposal.profit_pct_formatted) +
                          "\n            "
                      ),
                    ]
                  : [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.orderProposal.profit_pct,
                              expression: "orderProposal.profit_pct",
                            },
                            {
                              name: "proposal-field-directive",
                              rawName: "v-proposal-field-directive",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.errors["profit_pct"] ? "is-invalid" : "",
                          attrs: {
                            name: _vm.getInputName("profit_pct"),
                            id: _vm.getInputId("profit_pct"),
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.orderProposal,
                                "profit_pct",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("--Choose One--"),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.selectOptions.proposalProfitOptions,
                            function (option) {
                              return [
                                _c(
                                  "option",
                                  { domProps: { value: option.value } },
                                  [_vm._v(_vm._s(option.label))]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.orderProposal.cs_profit_formatted))]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [_vm._v("Subtotal")]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.es_profit_subtotal_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Performance Bond")]),
            _vm._v(" "),
            _c(
              "td",
              [
                _vm.isLockedForEdits
                  ? [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.orderProposal.performance_bond_pct_formatted
                          ) +
                          "\n            "
                      ),
                    ]
                  : [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.orderProposal.performance_bond_pct,
                              expression: "orderProposal.performance_bond_pct",
                            },
                            {
                              name: "proposal-field-directive",
                              rawName: "v-proposal-field-directive",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.errors["performance_bond_pct"]
                            ? "is-invalid"
                            : "",
                          attrs: {
                            name: _vm.getInputName("performance_bond_pct"),
                            id: _vm.getInputId("performance_bond_pct"),
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.orderProposal,
                                "performance_bond_pct",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("--Choose One--"),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.selectOptions.proposalPerformanceBondOptions,
                            function (option) {
                              return [
                                _c(
                                  "option",
                                  { domProps: { value: option.value } },
                                  [_vm._v(_vm._s(option.label))]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.cs_bond_cost_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [_vm._v("Total")]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.cs_total_cost_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _vm.orderProposal.override_price_formatted !== null
            ? _c("tr", [
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v("Discount Total"),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm.orderProposal.override_price_formatted)),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("Cost ($)")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }