<script>
  import { mapMultiRowFields } from 'vuex-map-fields';

  import CostSummary from '../proposal/costSummary/CostSummary';
  import CustomColorEstimate from '../proposal/customColor/CustomColorEstimate';
  import DealerCalcTable from '../proposal/dealerCalcs/DealerCalcTable';
  import DealerCommission from '../proposal/dealerCommission/DealerCommission';
  import DistanceToJobsite from '../proposal/distanceToJobsite/DistanceToJobsite';
  import EstimateSummary from '../proposal/estimateSummary/EstimateSummary';
  import FieldMeasureLabor from '../proposal/fieldMeasureLabor/FieldMeasureLabor';
  import Flash from '../../notifications/Flash';
  import FoodAndLodging from '../proposal/foodAndLodging/FoodAndLodging';
  import InstallationLabor from '../proposal/installationLabor/InstallationLabor';
  import LaborSummary from '../proposal/laborSummary/LaborSummary';
  import ManufacturingLineItemCosts from '../proposal/manufacturing/ManufacturingLineItemCosts';
  import Margin from '../proposal/costAdjustmentRate/Margin';
  import MarkupCalculation from '../proposal/markupCalculation/MarkupCalculation';
  import MotorizedOrderItemAdditions from '../proposal/additionalPartsMotorized/MotorizedOrderItemAdditions';
  import OrderItemAdditions from '../proposal/additionalPartsNonmotorized/OrderItemAdditions';
  import ProfitCheck from '../proposal/profitCheck/ProfitCheck';
  import ProposalCoordinationLabors from '../proposal/coordinationLabor/ProposalCoordinationLabors';
  import ProposalMiscExpenses from '../proposal/miscExpense/ProposalMiscExpenses';
  import ProposalProjectExpenses from '../proposal/projectExpense/ProposalProjectExpenses';
  import SalesTax from '../proposal/salesTax/SalesTax';
  import ShadeInstallationTable from '../proposal/shadeInstallation/ShadeInstallationTable';
  import ShadeOrderProjectNotifications from './ShadeOrderProjectNotifications';
  import ShadeUnitConfigs from './shadeUnitConfigs/ShadeUnitConfigs';
  //import SpEstimateSummaryOutput from './temporaryTesting/SpEstimateSummaryOutput';
  import Takeoff from '../proposal/takeoffShadeLineItems/Takeoff';
  import TakeoffTab from './shades/TakeoffTab';
  import TravelExpense from '../proposal/travelExpense/TravelExpense';
  import UnitCostAndPrice from '../proposal/unitCost/UnitCostAndPrice';

  export default {
    props: [
      'primaryShadeSelectOptions',
      'masterOrderProposalSelectOptions',
      'masterShadeOrder',
      'masterOrderProposal',
      'masterProject',
      'masterShadeGroups',
      'masterShadeUnitConfigs',
      'masterEstimatorShadeUnitConfigs',
      'masterUserCanEditEstimate'
    ],

    components: {
      CostSummary,
      CustomColorEstimate,
      DealerCalcTable,
      DealerCommission,
      DistanceToJobsite,
      EstimateSummary,
      FieldMeasureLabor,
      Flash,
      FoodAndLodging,
      InstallationLabor,
      LaborSummary,
      ManufacturingLineItemCosts,
      Margin,
      MarkupCalculation,
      MotorizedOrderItemAdditions,
      OrderItemAdditions,
      ProfitCheck,
      ProposalCoordinationLabors,
      ProposalMiscExpenses,
      ProposalProjectExpenses,
      SalesTax,
      ShadeInstallationTable,
      ShadeOrderProjectNotifications,
      ShadeUnitConfigs,
      //SpEstimateSummaryOutput,
      Takeoff,
      TakeoffTab,
      TravelExpense,
      UnitCostAndPrice
    },

    created() {
      this.$store.dispatch('shadeOrder/applyShadeOrder', { shadeOrder: this.masterShadeOrder, userCanEdit: this.masterUserCanEditEstimate });
      this.$store.dispatch('estimationSelectOptions/initEstimationSelectOptions', {primaryShadeSelectOptions: this.primaryShadeSelectOptions});

      if( this.masterOrderProposal ) {
        this.$store.dispatch('orderProposal/initOrderProposal', {
            selectOptions: this.masterOrderProposalSelectOptions,
            orderProposal: this.masterOrderProposal,
            userCanEdit: this.masterUserCanEditEstimate
          });
      }

      //load the project and all other components
      this.$store.dispatch('orderProposalProject/applyProject', this.masterProject);
      this.$store.dispatch('shadeUnitConfigs/applyShadeUnitConfigs', this.masterShadeUnitConfigs);
      this.$store.dispatch('estimatorShadeUnitConfigs/initEstimatorShadeUnitConfigs', {estimatorShadeUnitConfigs: this.masterEstimatorShadeUnitConfigs});
      this.$store.dispatch('shadeGroups/applyShadeGroups', this.masterShadeGroups);
    },

    mounted() {
      document.onreadystatechange = () => {
        if (document.readyState == "complete") {
          //For locked OrderProposals
          this.lockAllFields;
        }
      }
    },

    computed: {
      ...mapMultiRowFields('orderProposal', ['orderProposals']),
      ...mapMultiRowFields('orderProposalProject', ['projects']),

      shadeOrder() {
        return this.$store.state.shadeOrder.shadeOrder;
      },

      orderProposal() {
        return this.orderProposals[0];
      },

      project() {
        return this.projects[0];
      },

      selectOptions() {
        return this.$store.state.shadeOrder.selectOptions;
      },

      projectNotifications() {
        let notificationList = [];
        let shadeOrderNotifications = this.$store.getters['shadeOrder/projectNotifications'];
        notificationList = notificationList.concat(shadeOrderNotifications);
        return notificationList;
      },

      isOrderProposalLocked() {
        if( (this.orderProposal && (this.orderProposal.is_locked_for_edits == true || this.orderProposal.is_accepted == true)) || this.orderProposal.userCanEdit == false ) {
          return true;
        }
        return false;
      },

      lockAllFields() {
        if( this.isOrderProposalLocked ) {
          let elements = document.getElementsByClassName('estimate-page')[0].querySelectorAll('input, select, textarea, radio, checkbox, button');

          for(let i = 0; i < elements.length; i++) {
              elements[i].disabled = true;
          }
        }
      },
    },

    methods: {
      getOrderProposalInputName(field) {
        return 'order_proposal[' + field + ']';
      },

      getOrderProposalInputId(field) {
        var inputName = this.getOrderProposalInputName(field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },
    }
  }

</script>
