<script>
  export default {
    props: [
      'parentCategories',
    ],

    components: {},

    data() {
      return {
        categories: []
      }
    },

    mounted() {
      this.categories = this.parentCategories;
    },

    computed: {
      endpointEdit() {
        return '/admin/document-library/category/edit/';
      },

      endpointSort() {
        return '/admin/document-library/category/sort/1';
      }
    },

    methods: {
      editCategoryLinkClick(event, categoryId) {
        event.preventDefault();
        window.location.href = this.endpointEdit + categoryId;
      },

      saveSortOrder(event) {
        let endpoint = this.endpointSort,
            sort_order = this.categories.map(category => category.id),
            submitData = {
              _method: 'PUT',
              sort_order: sort_order
            };

        axios.post(endpoint, submitData)
        .then((response) => {
          //
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status); //show the error message
        });
      },
    }
  }
</script>
