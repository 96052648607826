var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row estimate-widget cost-adj-rate" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-sm-12" }, [
      _c("table", { staticClass: "form" }, [
        _c("tbody", [
          _c("tr", [
            _c("td", { staticClass: "text-nowrap" }, [_vm._v("Adj Rate %")]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.orderProposal.margin_pct,
                    expression: "orderProposal.margin_pct",
                  },
                  {
                    name: "proposal-field-directive",
                    rawName: "v-proposal-field-directive",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors["margin_pct"] ? "is-invalid" : "",
                attrs: {
                  type: "text",
                  placeholder: "1",
                  name: _vm.getInputName("margin_pct"),
                  id: _vm.getInputId("margin_pct"),
                },
                domProps: { value: _vm.orderProposal.margin_pct },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.orderProposal,
                      "margin_pct",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "text-nowrap" }, [
              _vm._v("Part Adj Rate %"),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.orderProposal.part_cost_adjustment_pct,
                    expression: "orderProposal.part_cost_adjustment_pct",
                  },
                  {
                    name: "proposal-field-directive",
                    rawName: "v-proposal-field-directive",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors["part_cost_adjustment_pct"]
                  ? "is-invalid"
                  : "",
                attrs: {
                  type: "text",
                  placeholder: "1",
                  name: _vm.getInputName("part_cost_adjustment_pct"),
                  id: _vm.getInputId("part_cost_adjustment_pct"),
                },
                domProps: { value: _vm.orderProposal.part_cost_adjustment_pct },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.orderProposal,
                      "part_cost_adjustment_pct",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
      _c("h2", [_vm._v("Cost Adjustment Rate")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }