<script>
  export default {
    props: [
      'shadeUnit',
      'containerClass',
      'hembarStyleLabel',
      'defaultSelectOptionText',
      'errors',
      'disabled'
    ],

    computed: {
      parentContainerClass() {
        return this.containerClass;
      },

      isExteriorShade() {
        return this.$parent.isExteriorShade;
      },

      hasGuideCable() {
        return ( this.shadeUnit.product_model_guided_cable == 1 ) ? true : false;
      },

      selectOptions() {
        var selectOptions = [];

        if( this.isExteriorShade ) {
          selectOptions = this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.hembarStyleOptions.filter(function(option) {
            return option.exteriorShadeOnly == true;
          });

        } else if( this.shadeUnit.product_model_guided_cable == 1 ) {
          selectOptions = this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.hembarStyleOptions.filter(function(option) {
            return option.guideCableOnly == true;
          });
        } else {
          selectOptions = this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.hembarStyleOptions.filter(function(option) {
            return option.guideCableOnly == false && option.exteriorShadeOnly == false;
          });
        }

        let selectedOptionIndex = selectOptions.map(option => option.value).indexOf(this.shadeUnit.hembar_style);
        if( selectedOptionIndex < 0 ) {
          this.shadeUnit.hembar_style = null;
        }

        return selectOptions;
      },

      hasErrors() {
        if( typeof errors.hembar_style != 'undefined' ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return this.$parent.getInputName(id, field);
      }
    },

    watch: {
      'shadeUnit.hembar_style': function(newVal, oldVal) {
        if( newVal != 'Sealed Hembar' ) {
          this.shadeUnit.order_item_assembly_hembar_composition = 'A';
        }
      },

      'shadeUnit.product_model_exterior_mfg': function(newVal, oldVal) {
        if( newVal == 'Veue' ) {
          this.shadeUnit.hembar_style = 'RO Exterior Bottom Rail';
        } else if( newVal == 'Fixscreen' ) {
          this.shadeUnit.hembar_style = 'RE Exterior Bottom Rail';
        }
      }
    }
  };
</script>

<template>
  <div :class="parentContainerClass">
    <label v-bind:for="getInputName(shadeUnit.id, 'hembar_style')">
      {{ hembarStyleLabel }}
    </label>
    <select
      class="form-control"
      :class="errors['hembar_style'] ? 'is-invalid' : ''"
      v-bind:name="getInputName(shadeUnit.id, 'hembar_style')"
      v-bind:id="getInputName(shadeUnit.id, 'hembar_style')"
      v-model="shadeUnit.hembar_style"
      :disabled="disabled || isExteriorShade">
      <option :value="null">{{ defaultSelectOptionText }}</option>
      <option
        v-for="hembar in selectOptions"
        :value="hembar.value"
        :data-guide-cable="hembar.guideCableOnly">
          {{ hembar.label }}
      </option>
    </select>
    <span
      class="invalid-feedback"
      role="alert"
      v-if="errors['hembar_style']">
      {{ errors['hembar_style'][0] }}
    </span>
  </div>
</template>
