<script>
  import RolePermissions from './RolePermissions';

  export default {
    props: [
      'parentRole',
    ],

    components: {
      RolePermissions,
    },

    data() {
      return {
        role: {
          name: null,
          type: null
        },
        currentPermissions: []
      }
    },

    mounted() {
      this.role = { ...this.role, ...this.parentRole };
      this.currentPermissions = this.parentRole.permissions;
        delete this.role.permissions;

      /*
        console.log('Role.vue Mounted...');
        console.log('this.role');
        console.log(this.role);
        console.log('this.currentPermissions');
        console.log(this.currentPermissions);
        console.log('');
      */

    },

    computed: {
      endpointUpdate() {
        return '/admin/settings/user-permissions/user-group-permissions/update/' + this.role.id;
      }
    },

    methods: {
      getInputName(id) {
        return 'role['+ id +'][permissions][]';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      submitForm(event) {
        event.preventDefault();
        var rolePermissions = this.$children[0].getAppliedRolePermissions();

        var submitData = {
          _method: 'PUT',
          role_permissions: rolePermissions
        };

        axios.post(this.endpointUpdate, submitData)
        .then((response) => {
          if( response.data.redirectTo != '' ) {
            window.location.href = response.data.redirectTo;
          }
          flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          //this.setErrors(error.response.data.errors, parentForm);
          flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
        });
      }
    }
  }
</script>
