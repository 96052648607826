<script>
  import ShadeLineItems from './ShadeLineItems';

  export default {
    props: [
      'shadeGroupId'
    ],

    components: {
      ShadeLineItems
    },

    data() {
      return {
        inFocus: false,
        justSavedShade: false,
      }
    },

    computed: {
      shadeGroup() {
        return this.$store.getters['shadeGroups/find'](this.shadeGroupId);
      },

      orderProposal() {
        return this.$store.getters['orderProposal/currentOrderProposal'];
      },

      hasError() {
        var hasError = false;

        this.shadeGroup.shadeLineItems.some(function(sli) {
          if( Object.keys(sli.errors).length > 0 ) {
            hasError = true;
            return;
          }
        });

        return hasError;
      },

      sortOrderNumber() {
        return this.shadeGroup.sort_order_number;
      },
    },

    mounted() {
      /*
        console.log('ShadeGroup.vue mounted');
        console.log(this.shadeGroupId);
        console.log(this.shadeGroup);
        console.log(this.hasError);
        console.log('');
      */
    },

    methods: {
      getInputName(id, field) {
        return 'shade_group[' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      addCoupledShade() {
        if( this.shadeGroup.isNew ) {
          this.$store.dispatch('shadeGroups/createShadeGroup', this.shadeGroup.id)
          .then((response) => {
            this.$store.dispatch('shadeLineItems/addCoupledShade', this.shadeGroup.id);
          })
          .catch((error) => {
            //
          });

        } else {
          this.$store.dispatch('shadeGroups/updateShadeGroup', this.shadeGroup.id)
          .then((response) => {
            this.$store.dispatch('shadeLineItems/addCoupledShade', this.shadeGroup.id);
          })
          .catch((error) => {
            //
          });

        }
      },

      hasFocus() {
        this.inFocus = ( this.justSavedShade === true ) ? false : true;
        this.justSavedShade = false;
      },

      checkForChanges(event) {
        var hasChanges = false;
        this.shadeGroup.shadeLineItems.forEach(function(sli) {
          if( sli.hasChanges === true ) {
            hasChanges = true;
          }
        });

        if( hasChanges === false || this.inFocus === false ) {
          this.inFocus = false;
          return;

        } else {
          this.saveShadeGroup();
        }
      },

      saveShadeGroup() {
        //Dispatch to vuex - the saveShadeGroup method will route the request accordingly.
        this.$store.dispatch('shadeGroups/saveShadeGroup', this.shadeGroup.id);

        this.inFocus = false;
        this.justSavedShade = true;
      },
    },

    directives: {
      clickOutside: {
        bind: function (el, binding, vnode) {
          el.clickOutsideEvent = function (event) {
            if( $(event.target).closest(el).length <= 0 && vnode.context.inFocus === true ) {
              vnode.context[binding.expression](event);
            }
          };
          document.body.addEventListener('click', el.clickOutsideEvent);
        },
        unbind: function (el) {
          document.body.removeEventListener('click', el.clickOutsideEvent);
        }
      }
    }
  }
</script>
