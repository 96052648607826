<script>
  import PaymentTermDeleteModal from './PaymentTermDeleteModal.vue';

  export default {
    props: [
      'parentPaymentTerm',
    ],

    components: {
      PaymentTermDeleteModal
    },

    data() {
      return {
        paymentTerm: {
          id: null,
          title: null,
          proposal_type: null,
          payment_terms: null,
        },
        paymentTermCompanies: [],
        isNew: false,
        errors: [],
      }
    },

    mounted() {
      this.paymentTerm = { ...this.paymentTerm, ...this.parentPaymentTerm };
      this.paymentTermCompanies = ( this.paymentTerm.hasOwnProperty('companies') ) ? this.paymentTerm.companies : [];
        delete this.paymentTerm.companies;

      if( this.parentPaymentTerm.isNew == true ) {
        this.isNew = true;
      }
    },

    computed: {
      endpointCreate() {
        return '/admin/settings/app-config/proposals/payment-terms';
      },

      endpointUpdate() {
        return '/admin/settings/app-config/proposals/payment-terms/' + this.paymentTerm.id;
      },

      selectOptions() {
        return this.$parent.selectOptions;
      }
    },

    methods: {
      save(event) {
        event.preventDefault();
        var endpoint = ( this.isNew ) ? this.endpointCreate : this.endpointUpdate;
        var paymentTermData = _.clone(this.paymentTerm);
        var submitData = {
              _method: ( this.isNew ) ? 'POST' : 'PUT',
              payment_term: paymentTermData
            };

        axios.post(endpoint, submitData)
        .then((response) => {
          let updatedPaymentTerm = response.data.data.paymentTerm;
          this.setErrors({});
          this.paymentTerm = updatedPaymentTerm;
          this.isNew = false;
          flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          if( error.response.data.hasOwnProperty('errors') ) {
            this.setErrors(error.response.data.errors);
            flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
          } else {
            flash(error.response.data.status_type, error.response.data.status);
          }
        });
      },

      destroyClick(event) {
        event.preventDefault();

        if( this.isNew === true ) {
          //If it's a new/unsaved payment term - just go ahead and delete it.
          this.$parent.deletePaymentTermRow( this.paymentTerm );
          flash('alert-success', 'Payment term deleted');
        } else {
          //Otherwise, show the delete modal and let that handle things from here.
          this.$bvModal.show('deletePaymentTermModal' + this.paymentTerm.id);
        }
      },

      //Modal - Handle modal function call
      handleFunctionCall(functionName) {
        this[functionName]();
      },

      //Modal - Cancel out of an open/active modal without completeing action
      cancelModalAction() {
        return false;
      },

      setErrors(errors) {
        if( errors.hasOwnProperty('payment_term') ) {
          this.errors = errors.payment_term;
        } else {
          this.errors = errors;
        }
      }
    }
  };
</script>
