<template>
  <div class="child-item-list" v-show="hasPartDependencies"><!-- start: child-item-list -->
    <div class="content-block">
      <div class="form-row">
        <div class="form-group col-sm-12 toggle-list">
          <a href="#" @click="toggleFullView($event)" class="toggle-list-link">{{ toggleLinkText }}</a>
        </div>
      </div>

      <div
        class="list-items"
        v-for="dependentPart in partDependencies"
        v-show="showList">
        <div class="list-item">
          <div class="title-bar-wrapper">
            <div class="title-bar">
              <div class="summary">
                <p class="title">{{ dependentPart.part.name }}</p>
                <span class="setting-text">Part Number: {{ dependentPartNumber(dependentPart) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div><!-- end: child-item-list -->
</template>

<script>
  export default {
    props: [
      'productModelPart',
      'partDependencies'
    ],

    data() {
      return {
        showList: false
      }
    },

    computed: {
      hasPartDependencies() {
        return ( this.partDependencies.length ) ? true : false;
      },

      toggleLinkText() {
        return ( this.showList == true ) ? 'Hide Part Dependencies' : 'Show Part Dependencies';
      }
    },

    methods: {
      toggleFullView(event) {
        event.preventDefault();
        this.showList = ( this.showList == true ) ? false : true;
      },

      dependentPartNumber(dependentPart) {
        if( dependentPart.part.has_colors == false ) {
          if( dependentPart.part.part_number != null ) {
            return dependentPart.part.part_number;
          } else {
            return '--Not Specified--';
          }

        } else {
          var colorPartIdArr = dependentPart.part.part_colors.map(partColor => partColor.color + ': ' + partColor.part_number);
          return colorPartIdArr.join(", ");
        }
      }
    }
  }
</script>
