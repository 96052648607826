<script>
  import ProjectOrderType from './ProjectOrderType';

  export default {
    props: [
      'masterGlobalProjectOrderTypes',
      'noResultsText'
    ],

    components: {
      ProjectOrderType
    },

    data() {
      return {
        globalProjectOrderTypeList: [],
      }
    },

    created() {

    },

    mounted() {
      this.globalProjectOrderTypeList = this.masterGlobalProjectOrderTypes;

      /*
        console.log('OrderNotifications.vue (PLURAL) - Mounted');
        console.log('this.masterGlobalProjectOrderTypes');
        console.log(this.masterGlobalProjectOrderTypes);
        console.log('');
      */
    },

    computed: {

    },

    methods: {
      getTabId(value) {
        return value.toLowerCase().replace( /[^a-zA-Z0-9]/ , "_");
      },

      getTabListTabId(value) {
        return this.getTabId(value) + '-tab';
      },

      getTabListHref(value) {
        return '#' + this.getTabId(value);
      }
    }
  };
</script>

<template>
  <div>
    <template v-if="this.globalProjectOrderTypeList.length <= 0">
      <p>{{ noResultsText }}</p>
    </template>

    <template v-else>
      <div class="row">
        <div class="group col-sm-12">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" v-for="(projectOrderType, index) in globalProjectOrderTypeList">
              <a class="nav-link"
                  v-bind:class="(index == 0) ? 'active' : ''"
                  v-bind:href="getTabListHref(projectOrderType.name)"
                  v-bind:id="getTabListTabId(projectOrderType.name)"
                  data-toggle="tab"
                  role="tab"
                  v-bind:aria-controls="getTabId(projectOrderType.name)"
                  v-bind:aria-selected="(index == 0) ? 'active' : ''">
                {{ projectOrderType.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content">
        <template v-for="(projectOrderType, index) in globalProjectOrderTypeList">
          <project-order-type
            :parent-project-order-type="projectOrderType"
            :parent-project-order-type-index="index"
            :tab-container-id="getTabId(projectOrderType.name)">

          </project-order-type>
        </template>
      </div>
    </template>
  </div>
</template>



