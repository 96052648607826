<script>
  export default {
    props: [
      'parentProductModel',
      'productModelLabors',
    ],

    data() {
      return {
        productModelLaborList: [],
        errors: {}
      }
    },

    mounted() {
      this.productModel = this.parentProductModel;
      this.productModelLaborList = this.productModelLabors;

      /*
        console.log('ProductModelLabors.vue - Mounted');
        console.log('this.productModel')
        console.log(this.productModel);
        console.log('this.productModelLaborList')
        console.log(this.productModelLaborList);
        console.log('');
      */
    },

    methods: {
      getInputName(id, field) {
        return 'product_model_labors[' + id + '][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      setErrors(errors) {
        this.errors = errors;
      }
    }
  };
</script>

<template>
  <div class="product-model-labors-container mt-2">
    <div class="form-row">
      <div class="form-group col-sm-12 mb-2">
        <h5>Product Model Labor</h5>
      </div>
    </div>

    <template v-for="(labor, index) in productModelLaborList">
      <div class="form-row">
        <div class="form-group col-sm-6 col-md-2">
          <label v-bind:for="getInputName(labor.id, 'name')" v-if="index == 0">Labor Type</label>
          <input
            type="hidden"
            v-bind:name="getInputName(labor.id, 'id')"
            v-model="labor.id">
          <input
            type="hidden"
            v-bind:name="getInputName(labor.id, 'labor_type_id')"
            v-model="labor.labor_type_id">
          <p>{{ labor.labor_type_name }}</p>
        </div>

        <div class="form-group col-sm-6 col-md-3">
          <label v-bind:for="getInputName(labor.id, 'minutes')" v-if="index == 0">Time (in minutes)</label>
          <input type="text"
            placeholder="10"
            class="form-control"
            maxlength="5"
            v-bind:class="errors[labor.id] && errors[labor.id]['minutes'] ? 'is-invalid' : ''"
            v-bind:name="getInputName(labor.id, 'minutes')"
            v-bind:id="getInputId(labor.id, 'minutes')"
            v-model="labor.minutes">

          <span class="invalid-feedback" role="alert" v-if="errors[labor.id] && errors[labor.id]['minutes']">
            {{ errors[labor.id]['minutes'][0] }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>
