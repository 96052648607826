<script>
  import { createHelpers, mapMultiRowFields } from 'vuex-map-fields';
  import { shadeUnitConfigMixin } from '../../mixins/shadeUnitConfigMixin';

  import DualShade from '../shades/dualShade/DualShade';
  import GuideCable from '../shades/guideCable/GuideCable';
  import ModalConfirm from '../../../notifications/ModalConfirm.vue';
  import ShadeBackFascia from '../shades/shadeFascia/ShadeBackFascia';
  import ShadeFabric from '../shades/shadeFabric/ShadeFabric';
  import ShadeFasciaType from '../shades/shadeFascia/ShadeFasciaType';
  import ShadeFasciaColor from '../shades/shadeFascia/ShadeFasciaColor';
  import ShadeHembarStyle from '../shades/shadeHembarStyle/ShadeHembarStyle';
  import ShadeHembarColor from '../shades/shadeHembarColor/ShadeHembarColor';
  import ShadeHembarEndcapColor from '../shades/shadeHembarEndcapColor/ShadeHembarEndcapColor';
  import ShadeMotor from '../shades/shadeMotor/ShadeMotor.vue';
  import ShadePocket from '../shades/shadePocket/ShadePocket';
  import ShadePreferredFasciaSize from '../shades/shadeFascia/ShadePreferredFasciaSize';
  import WrappedFasciaFabric from '../shades/wrappedFasciaFabric/WrappedFasciaFabric';

  const { mapFields } = createHelpers({
    getterType: 'shadeUnitConfigs/getField',
    mutationType: 'shadeUnitConfigs/updateField',
  });

  export default {
    props: [
      'shadeUnit',
      'shadeUnitId'
    ],

    mixins: [shadeUnitConfigMixin],

    components: {
      DualShade,
      GuideCable,
      ModalConfirm,
      ShadeBackFascia,
      ShadeFabric,
      ShadeFasciaType,
      ShadeFasciaColor,
      ShadeHembarStyle,
      ShadeHembarColor,
      ShadeHembarEndcapColor,
      ShadeMotor,
      ShadePocket,
      ShadePreferredFasciaSize,
      WrappedFasciaFabric
    },

    data() {
      return {
        inFocus: false,
        permittedFabricPanelOnlyFields: []
      }
    },

    mounted() {
      //set permitted fabric panel only field list
      this.permittedFabricPanelOnlyFields = this.$store.getters['shadeUnitConfigs/fabricPanelOnlyPermittedFields'];
    },

    computed: {
      shadeConfigUnit() {
        return this.shadeUnit;
      },

      orderProposal() {
        return this.$store.getters['orderProposal/currentOrderProposal'];
      },

      selectOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions;
      },

      userCanEdit() {
        return this.$parent.userCanEdit;
      },

      isExteriorShade() {
        return this.$store.getters['shadeUnitConfigs/isExteriorShade'](this.shadeConfigUnit.id);
      },

      isMaximized() {
        return this.shadeConfigUnit.isMaximized;
      },

      shadeUnitName() {
        if( this.shadeConfigUnit.name != '' && this.shadeConfigUnit.name != null ) {
          return this.shadeConfigUnit.name;

        } else if ( this.shadeConfigUnit.isNew ) {
          return 'New Unit';

        } else {
          return 'Specify Name';
        }
      },

      hardwareColorSelectOptions() {
        return this.$store.getters['estimationSelectOptions/hardwareColorOptionsByShadeType'](this.shadeConfigUnit.product_model_shade_type);
      },

      getFabricSummary() {
        return this.$store.getters['shadeUnitConfigs/fabricSummaryText'](this.shadeConfigUnit.id);
      },

      getDualFabricSummary() {
        return this.$store.getters['shadeUnitConfigs/dualFabricSummaryText'](this.shadeConfigUnit.id);
      },

      getProductModelDriveMechanismSummary() {
        return ( this.shadeConfigUnit.product_model_drive_mechanism ) ? this.shadeConfigUnit.product_model_drive_mechanism.replace('_', ' ') : '';
      },

      isFabricPanelOnlyShade() {
        return ( this.shadeConfigUnit.product_model_drive_mechanism == 'fabric_only' ) ? true : false;
      },

      isManualCordlessShade() {
        return ( this.shadeConfigUnit.product_model_drive_mechanism == 'manual_cordless' ) ? true : false;
      },

      hasCustomSideChannelColor() {
        if( this.shadeConfigUnit.has_side_channel == true && this.shadeConfigUnit.side_channel_color == 'custom' ) {
          return true;
        }

        return false;
      },

      canSetHembarComposition() {
        if( this.shadeConfigUnit.hembar_style == 'Sealed Hembar' ) {
          return true;
        }
        this.shadeConfigUnit.order_item_assembly_hembar_composition = 'A';
        return false;
      },

      errors() {
        var errors = this.$store.getters['shadeUnitConfigs/errorsByRecordId'](this.shadeConfigUnit.id);
        if( Object.keys(errors).length ) {
          this.shadeConfigUnit.isMaximized = true;
        }

        return errors;
      },

      hasErrors() {
        if( Object.keys(this.errors).length ) {
          this.shadeConfigUnit.isMaximized = true;
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'shade_unit_config['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      toggleFullView($event) {
        event.preventDefault();
        this.shadeConfigUnit.isMaximized = ( this.isMaximized == true ) ? false : true;
      },

      isFieldDisabled(field) {
        if( this.isFabricPanelOnlyFieldDisabled(field) === true ) {
          return true;
        }
        return false;
      },

      isFabricPanelOnlyFieldDisabled(field) {
        if( this.isFabricPanelOnlyShade === true && this.permittedFabricPanelOnlyFields.includes(field) === false ) {
          return true;
        } else {
          return false;
        }
      },

      deleteShadeUnit() {
        this.$store.dispatch('shadeUnitConfigs/deleteShadeUnitConfig', this.shadeConfigUnit.id);
      },

      hasFocus() {
        this.inFocus = true;
      },

      checkForChanges(event) {
        if( this.shadeConfigUnit.hasChanges === false && this.shadeConfigUnit.isNew === false ) {
          //No changes were found - proceed as usual
          //flash('alert-warning', 'No changes detected. Shade Configuration up to date.');
          this.inFocus = false;
          return;
        }

        let assignedShadeLineItems = this.$store.getters['shadeUnitConfigs/shadeLineItems'](this.shadeUnit.id);
        if( assignedShadeLineItems.new.length > 0 || assignedShadeLineItems.existing.length > 0 ) {
          //We have shade units assigned to this configuration. Stop the save and show the modal
          event.stopImmediatePropagation();
          this.$bvModal.show('modal-' + this.shadeConfigUnit.id);

        } else {
          //otherwise, just save the changes (without modal)
          this.applyShadeUnitConfigChanges();
        }
      },

      applyShadeUnitConfigChanges() {
        this.$store.dispatch('shadeUnitConfigs/applyUpdatedState', this.shadeConfigUnit.id);
        this.inFocus = false;
      },

      disgardShadeUnitConfigChanges() {
        this.$store.dispatch('shadeUnitConfigs/revertToInitialState', this.shadeConfigUnit.id);
        this.inFocus = false;
      }
    },

    watch: {
      'shadeConfigUnit.product_model_shade_type': function (newVal, oldVal) {
        if( newVal != oldVal ) {
          this.shadeUnit.product_model_exterior_mfg = null;
          this.shadeUnit.hardware_color = null;
          this.shadeUnit.fabric_id = null;
          this.shadeUnit.dual_fabric_id = null;
        }

        if( newVal && newVal.toLowerCase() == 'exterior' ) {
          this.shadeUnit.product_model_exterior_mfg = 'Veue';
          this.shadeUnit.drive_type = 'M-I';
          this.shadeUnit.product_model_dual_shade = 0;
          this.shadeUnit.product_model_guided_cable = 0;
          this.shadeUnit.product_model_bottom_up = null;
          this.shadeUnit.product_model_drive_mechanism = 'motor';
          this.shadeUnit.motor_manufacturer_id = null;
          this.shadeUnit.dual_fabric_id = null;
          this.shadeUnit.dual_fabric_openness_factor_id = null;
          this.shadeUnit.dual_fabric_attribute_id = null;
          this.shadeUnit.hembar_style = null;
          this.shadeUnit.hembar_color = null;
          this.shadeUnit.hembar_endcap_color = null;
          this.shadeUnit.fascia_type = null;
          this.shadeUnit.back_fascia = 1;
          this.shadeUnit.preferred_fascia_size = null;
          this.shadeUnit.fascia_color = null;
          this.shadeUnit.fascia_color_custom_manufacturer = null;
          this.shadeUnit.fascia_color_custom_name = null;
          this.shadeUnit.wrapped_fascia_fabric = null;
          this.shadeUnit.has_side_channel = 0;
          this.shadeUnit.side_channel_color = null;
          this.shadeUnit.side_channel_color_custom_manufacturer = null;
          this.shadeUnit.side_channel_color_custom_color = null;
          this.shadeUnit.has_trim = 0;
          this.shadeUnit.trim_type = null;
          this.shadeUnit.trim_color = null;
          this.shadeUnit.trim_color_custom_manufacturer = null;
          this.shadeUnit.trim_color_custom_name = null;
          this.shadeUnit.pocket_height = null;
          this.shadeUnit.pocket_depth = null;
          this.shadeUnit.pocket_include_closure = 0;
          this.shadeUnit.pocket_closure_size = null;
        }
      },

      'shadeConfigUnit.product_model_exterior_mfg': function (newVal, oldVal) {
        if( newVal == null ) {
          this.shadeUnit.motor_manufacturer_id = null;
        } else {
          this.shadeUnit.motor_manufacturer_id = this.$store.getters['estimationSelectOptions/getExteriorMotorMfgIdByShadeMfg'](this.shadeConfigUnit.product_model_exterior_mfg);
        }

        if(newVal == 'Fixscreen') {
          this.shadeUnit.has_side_channel = 1;
        } else {
          this.shadeUnit.has_side_channel = 0;
        }
      },

      'shadeConfigUnit.has_side_channel': function (newVal, oldVal) {
        if( newVal == false || newVal == 0 ) {
          this.shadeUnit.side_channel_color = null;
        }
      },

      'shadeConfigUnit.side_channel_color': function (newVal, oldVal) {
        if( newVal == null || newVal.toLowerCase != 'custom' ) {
          this.shadeUnit.side_channel_color_custom_manufacturer = null;
          this.shadeUnit.side_channel_color_custom_name = null;
        }
      },

      'shadeConfigUnit.product_model_drive_mechanism': function(newVal, oldVal) {
        if( newVal == 'manual_chain' ) {
          this.shadeUnit.hold_down = '1';
        } else {
          this.shadeUnit.hold_down = '0';
        }

        //If changing product_model_drive_mechanism to fabric panel only, reset all non-permitted values to their defaults
        if( newVal == 'fabric_only' ) {
          this.$store.dispatch('shadeUnitConfigs/applyDefaultShadePropsForFabricOnly', this.shadeConfigUnit.id);
        } else if( oldVal == 'fabric_only' && newVal != 'fabric_only' ) {
          this.$store.dispatch('shadeUnitConfigs/removeShadePropsForFabricOnly', this.shadeConfigUnit.id);
        }

        if( newVal == 'manual_cordless' ) {
          this.shadeUnit.hardware_color = 'white';
        }
      }
    }
  };
</script>
