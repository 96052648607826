<script>
  export default {
    props: [
      'parentPartColor',
      'index'
    ],

    data() {
      return {
        partColor: this.parentPartColor,
        isNew: false
      }
    },

    mounted() {
      this.isNew = ( this.partColor.isNew === true ) ? true : false;
    },

    computed: {
      endpointDestroy() {
        return '/admin/settings/products/parts/part-color/' + this.partColor.id;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'part_color[' + id + '][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      destroy() {
        if( this.isNew === true ) {
          this.$parent.deleteColorOption( this.partColor );

        } else {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            this.$parent.deleteColorOption( this.partColor );
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      }
    }
  }
</script>
