<script>
  const masks = {
    convertToInches: {
      mask (value, scale) {
        var maskedValue = (value == null) ? null : value.toLocaleString(undefined, { minimumFractionDigits: scale }).replace('"', '') + '"';
        return maskedValue;
      },

      unmask (value) {
        if( value === null ) {
          return value;
        } else {
          value = parseFloat(value.toString().replace(/[^\d\.]/g, ""));
          return isNaN(value) ? null : value;
        }
      },
    },
  }

  export default {
    props: {
      value: null,
      maskType: String,
      scale: null,
      hasError: false,
      inputId: null,
      inputName: null,
      placeholder: null,
      isDisabled: false,
      inputClass: null,
      parentRecord: null,
      parentRecordFieldName: null,
      isManagedByVuex: false,
      roundDown: false,
    },

    data: function() {
      return {
        inputFocused: false,
      }
    },

    mounted() {
      this.$nextTick(() => {
        this.mask(this.parentRecord[this.parentRecordFieldName]);
      });
    },

    methods: {
      handleInputState (event) {
        this.inputFocused = event.type === 'focus'
      },

      unmask() {
        let unmasked = masks[this.maskType].unmask(this.parentRecord[this.parentRecordFieldName]);
        this.parentRecord[this.parentRecordFieldName] = unmasked;
      },

      mask() {
        let masked = masks[this.maskType].mask(this.parentRecord[this.parentRecordFieldName], this.scale);
        this.parentRecord[this.parentRecordFieldName] = masked;
      },

      filterInput() {
        this.parentRecord[this.parentRecordFieldName] = this.parentRecord[this.parentRecordFieldName].replace(/([^0-9.\-\/\s])/g, '');
      }
    },

    watch: {
      'inputFocused': function (newVal, oldVal) {
        if(newVal === true) {
          this.unmask();
        } else {
          this.mask();
        }
      },

      parentRecord: {
        deep: true,
        handler(newVal, oldVal) {
          //Ensure that record is masked when the parent record changes and input is not in focus
          if( this.isManagedByVuex && this.inputFocused === false ) {
            this.mask();
          }
        }
      }
    },

    directives: {
      convertMeasurement: {
        //Bind Hook
        bind(el, binding, vnode) {
          var applyMeasurementConversion = function(e) {
            if( !el.value ) {
              return null;
            }

            let newInputEvent = new Event("input", { bubbles: true });
            let formattedValue = 0;
            let inputValue = el.value.replace('"', '');

            if( inputValue.search('/') > 0 ) {
              let splitInputValue = inputValue.split(/[-\s]+/);

              if( splitInputValue.length == 1 ) {
                formattedValue = eval(splitInputValue[0]);
              } else if( splitInputValue.length == 2 ) {
                let wholeNumber = eval(splitInputValue[0]);
                let fraction = eval(splitInputValue[1]);
                  formattedValue = eval(wholeNumber+fraction);
              }
            } else {
              formattedValue = inputValue;
            }

            if( isNaN(formattedValue) ) {
              formattedValue = 0;
            }

            //If roundDown is true - round the formattedValue down to the nearest 1/8"
            if( vnode.context.roundDown == true ) {
              let wholeNumber = Math.floor(formattedValue),
                  fraction = formattedValue-wholeNumber;

              //rounding to nearest 1/8"
              fraction = Math.floor(fraction * 8);
              fraction = eval(fraction / 8);
              formattedValue = eval(wholeNumber+fraction).toFixed(3);
            }

            el.value = (formattedValue == null) ? null : (Number(formattedValue)).toFixed(3);
            el.dispatchEvent(newInputEvent);
          }
          el.addEventListener('blur', applyMeasurementConversion, true);
        }
      }
    }
  };
</script>

<template>
  <input
    type="text"
    v-bind:placeholder="placeholder"
    class="form-control"
    v-bind:class="[(inputClass != '') ? inputClass : '', hasError ? 'is-invalid' : '']"
    v-bind:id="inputId"
    v-bind:name="inputName"
    v-bind:disabled="isDisabled"
    v-model="parentRecord[parentRecordFieldName]"
    @focus="handleInputState"
    @blur="handleInputState"
    @input="filterInput"
    v-convert-measurement>
</template>
