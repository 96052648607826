var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      directives: [
        {
          name: "trim-cut-field-directive",
          rawName: "v-trim-cut-field-directive",
        },
      ],
    },
    [
      _c("td", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.trimCutLineItem.id,
              expression: "trimCutLineItem.id",
            },
          ],
          attrs: {
            type: "hidden",
            name: _vm.getInputName(_vm.trimCutLineItem.id, "id"),
            id: _vm.getInputId(_vm.trimCutLineItem.id, "id"),
          },
          domProps: { value: _vm.trimCutLineItem.id },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.trimCutLineItem, "id", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.trimCutLineItem.room,
                expression: "trimCutLineItem.room",
              },
            ],
            staticClass: "form-control required",
            class: _vm.errors["room"] ? "is-invalid" : "",
            attrs: {
              name: _vm.getInputName(_vm.trimCutLineItem.id, "room"),
              id: _vm.getInputId(_vm.trimCutLineItem.id, "room"),
            },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.trimCutLineItem,
                  "room",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", {
              domProps: {
                value: null,
                innerHTML: _vm._s(_vm.selectOptionPlaceholderText),
              },
            }),
            _vm._v(" "),
            _vm._l(_vm.selectOptions.roomNameOptions, function (option) {
              return _c("option", { domProps: { value: option.value } }, [
                _vm._v(_vm._s(option.label)),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("td", [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.trimCutLineItem.product_model_id,
                expression: "trimCutLineItem.product_model_id",
              },
            ],
            staticClass: "form-control required",
            class: _vm.errors["product_model_id"] ? "is-invalid" : "",
            attrs: {
              name: _vm.getInputName(
                _vm.trimCutLineItem.id,
                "product_model_id"
              ),
              id: _vm.getInputId(_vm.trimCutLineItem.id, "product_model_id"),
            },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.trimCutLineItem,
                  "product_model_id",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", {
              domProps: {
                value: null,
                innerHTML: _vm._s(_vm.selectOptionPlaceholderText),
              },
            }),
            _vm._v(" "),
            _vm._l(_vm.selectOptions.productModelOptions, function (option) {
              return _c("option", { domProps: { value: option.value } }, [
                _vm._v(_vm._s(option.label)),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("td", [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.trimCutLineItem.trim_type,
                expression: "trimCutLineItem.trim_type",
              },
            ],
            staticClass: "form-control required",
            class: _vm.errors["trim_type"] ? "is-invalid" : "",
            attrs: {
              name: _vm.getInputName(_vm.trimCutLineItem.id, "trim_type"),
              id: _vm.getInputId(_vm.trimCutLineItem.id, "trim_type"),
            },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.trimCutLineItem,
                  "trim_type",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", {
              domProps: {
                value: null,
                innerHTML: _vm._s(_vm.selectOptionPlaceholderText),
              },
            }),
            _vm._v(" "),
            _vm._l(_vm.selectOptions.trimTypeOptions, function (option) {
              return _c("option", { domProps: { value: option.value } }, [
                _vm._v(_vm._s(option.label)),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("td", [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.trimCutLineItem.trim_color,
                expression: "trimCutLineItem.trim_color",
              },
            ],
            staticClass: "form-control required",
            class: _vm.errors["trim_color"] ? "is-invalid" : "",
            attrs: {
              name: _vm.getInputName(_vm.trimCutLineItem.id, "trim_color"),
              id: _vm.getInputId(_vm.trimCutLineItem.id, "trim_color"),
            },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.trimCutLineItem,
                  "trim_color",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", {
              domProps: {
                value: null,
                innerHTML: _vm._s(_vm.selectOptionPlaceholderText),
              },
            }),
            _vm._v(" "),
            _vm._l(_vm.selectOptions.trimColorOptions, function (option) {
              return _c("option", { domProps: { value: option.value } }, [
                _vm._v(_vm._s(option.label)),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "td",
        [
          _c("convert-measurement-text-input-mask", {
            attrs: {
              "mask-type": "convertToInches",
              scale: "3",
              "parent-record": _vm.trimCutLineItem,
              "parent-record-field-name": "trim_width_in",
              "has-error": _vm.errors["trim_width_in"] ? true : false,
              "input-id": _vm.getInputId(
                _vm.trimCutLineItem.id,
                "trim_width_in"
              ),
              "input-name": _vm.getInputName(
                _vm.trimCutLineItem.id,
                "trim_width_in"
              ),
              placeholder: '60.25"',
              "input-class": "required",
            },
            model: {
              value: _vm.trimCutLineItem.trim_width_in,
              callback: function ($$v) {
                _vm.$set(_vm.trimCutLineItem, "trim_width_in", $$v)
              },
              expression: "trimCutLineItem.trim_width_in",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("td", [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.trimCutLineItem.closure_size,
                expression: "trimCutLineItem.closure_size",
              },
            ],
            staticClass: "form-control",
            class: _vm.errors["closure_size"] ? "is-invalid" : "",
            attrs: {
              name: _vm.getInputName(_vm.trimCutLineItem.id, "closure_size"),
              id: _vm.getInputId(_vm.trimCutLineItem.id, "closure_size"),
            },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.trimCutLineItem,
                  "closure_size",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", {
              domProps: {
                value: null,
                innerHTML: _vm._s(_vm.selectOptionPlaceholderText),
              },
            }),
            _vm._v(" "),
            _vm._l(_vm.selectOptions.closureSizeOptions, function (option) {
              return _c("option", { domProps: { value: option.value } }, [
                _vm._v(_vm._s(option.label)),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("td", [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.trimCutLineItem.pocket_height,
                expression: "trimCutLineItem.pocket_height",
              },
            ],
            staticClass: "form-control",
            class: _vm.errors["pocket_height"] ? "is-invalid" : "",
            attrs: {
              name: _vm.getInputName(_vm.trimCutLineItem.id, "pocket_height"),
              id: _vm.getInputId(_vm.trimCutLineItem.id, "pocket_height"),
              disabled: _vm.pocketHeightIsLocked,
            },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.trimCutLineItem,
                  "pocket_height",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", {
              domProps: {
                value: null,
                innerHTML: _vm._s(_vm.selectOptionPlaceholderText),
              },
            }),
            _vm._v(" "),
            _vm._l(_vm.selectOptions.pocketHeightOptions, function (option) {
              return _c("option", { domProps: { value: option.value } }, [
                _vm._v(_vm._s(option.label)),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("td", [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.trimCutLineItem.pocket_depth,
                expression: "trimCutLineItem.pocket_depth",
              },
            ],
            staticClass: "form-control",
            class: _vm.errors["pocket_depth"] ? "is-invalid" : "",
            attrs: {
              name: _vm.getInputName(_vm.trimCutLineItem.id, "pocket_depth"),
              id: _vm.getInputId(_vm.trimCutLineItem.id, "pocket_depth"),
              disabled: _vm.pocketDepthIsLocked,
            },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.trimCutLineItem,
                  "pocket_depth",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", {
              domProps: {
                value: null,
                innerHTML: _vm._s(_vm.selectOptionPlaceholderText),
              },
            }),
            _vm._v(" "),
            _vm._l(_vm.pocketDepthOptions, function (option) {
              return _c("option", { domProps: { value: option.value } }, [
                _vm._v(_vm._s(option.label)),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("td", [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.trimCutLineItem.notes,
              expression: "trimCutLineItem.notes",
            },
          ],
          staticClass: "form-control",
          class: _vm.errors["notes"] ? "is-invalid" : "",
          attrs: {
            rows: "1",
            placeholder: "Trim cut notes",
            name: _vm.getInputName(_vm.trimCutLineItem.id, "notes"),
            id: _vm.getInputId(_vm.trimCutLineItem.id, "notes"),
          },
          domProps: { value: _vm.trimCutLineItem.notes },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.trimCutLineItem, "notes", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "text-nowrap" }, [
        _c("a", {
          staticClass: "btn icon-only btn-save",
          attrs: { title: "Save" },
          on: {
            click: function ($event) {
              return _vm.save($event)
            },
          },
        }),
        _vm._v(" "),
        _c("a", {
          staticClass: "btn icon-only btn-delete",
          attrs: { title: "Delete" },
          on: {
            click: function ($event) {
              return _vm.destroy($event)
            },
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }