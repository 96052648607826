<script>
  import ModalConfirm from '../../../notifications/ModalConfirm.vue';

  export default {
    props: [
      'parentPartType',
    ],

    components: {
      ModalConfirm
    },

    data() {
      return {
        partType: [],
        isNew: false
      }
    },

    mounted() {
      this.partType = this.parentPartType;
      this.isNew = ( this.partType.isNew == true ) ? true : false;
    },

    computed: {
      endpointDestroy() {
        return '/admin/settings/products/part-types/' + this.partType.id;
      },

      isDisabled() {
        return (this.isNew == false) ? true : false;
      },

      hasParts() {
        return this.partType.has_parts;
      },

      canDelete() {
        return ( this.isNew == true || this.hasParts == false ) ? true : false;
      },

      errors() {
        if( this.$parent.errors.hasOwnProperty( this.partType.id ) ) {
          return this.$parent.errors[this.partType.id];
        }
        return [];
      },

      hasErrors() {
        if( Object.keys(this.errors).length ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'part_type['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      getInputTooltipId(id, field) {
        var inputId = this.getInputId(id, field);
        return inputId + '_' + id;
      },

      destroyClick(event) {
        event.preventDefault();
        if( this.isNew == true ) {
          this.destroy();
        } else {
          this.$bvModal.show('modal-' + this.partType.id);
        }
      },

      //Modal - Handle modal function call
      handleFunctionCall(functionName) {
        this[functionName]();
      },

      //Modal - Cancel out of an open/active modal without completeing action
      cancelModalAction() {
        return false;
      },

      destroy() {
        if( this.isNew === true ) {
          this.$parent.deletePartType( this.partType );
          flash('alert-success', 'Part type deleted');

        } else {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            this.$parent.deletePartType( this.partType );
            flash(response.data.status_type, response.data.status);
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      }
    }
  };
</script>
