<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'currentOrderProposal',
    ],

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('SalesTax.vue - Mounted');
        console.log(this.orderProposal);
        console.log('');
      */
    },


    computed: {
      orderProposal() {
        return this.currentOrderProposal;
      },

      errors() {
        return [];
      },
    },

    methods: {
      getInputName(field) {
        return this.$parent.getOrderProposalInputName(field);
      },

      getInputId(field) {
        return this.$parent.getOrderProposalInputId(field);
      },
    }
  };
</script>

<template>
  <div class="form-row">
    <div class="form-group col-sm-12 unit-cost table-responsive">
      <table class="table">
        <tbody>
          <tr>
            <td class="text-nowrap">Unit Cost</td>
            <td>{{ orderProposal.unit_cost_formatted }}</td>
          </tr>
          <tr>
            <td class="text-nowrap">Unit Price</td>
            <td>{{ orderProposal.unit_price_formatted }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
