<script>
  import TrimCutLineItems from './TrimCutLineItems';

  export default {
    props: [
      'masterShadeOrder',
      'masterTrimCutLineItems',
      'masterSelectOptions'
    ],

    components: {
      TrimCutLineItems
    },

    data() {
      return {
        trimCutLineItemsList: [],
      }
    },

    created() {

    },

    mounted() {
      this.trimCutLineItemsList = this.masterTrimCutLineItems;

      /*
        console.log('TrimCuts.vue Mounted');
        console.log('this.masterShadeOrder');
        console.log(this.masterShadeOrder);
        console.log('this.masterTrimCutLineItems');
        console.log(this.masterTrimCutLineItems);
        console.log('');
      */
    },

    computed: {
      endpointAddRow() {
        return '/projects/order/trim-cuts/add-row/' + this.masterShadeOrder.id;
      },

      endpointUpdate() {
        return '/projects/order/trim-cuts/update/' + this.masterShadeOrder.id;
      },
    },

    methods: {
      addRow(event) {
        event.preventDefault();

        axios.post(this.endpointAddRow)
        .then((response) => {
          const newOrderTrimCut = response.data.data.orderTrimCut;
          newOrderTrimCut.isNew = true;

          //this.masterTrimCutLineItems.push(newOrderTrimCut);
          this.trimCutLineItemsList.push(newOrderTrimCut);

          //flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status);
        });
      },

      saveAndContinueTrimCutsMixin(event) {
        event.preventDefault();
        this.updateRecords(event, null);
      },

      saveAndContinueTrimCuts(event) {
        event.preventDefault();
        this.updateRecords(event, false);
      },

      saveAndCompleteTrimCuts(event) {
        event.preventDefault();
        this.updateRecords(event, true);
      },

      updateRecords(event, updateRecords) {
        event.preventDefault();

        let hasChanges = false;
        const trimCutsList = this.$refs.trimCutLineItems.$children;
        const trimCutsObj = {};

        trimCutsList.forEach(function(obj) {
          trimCutsObj[obj.trimCutLineItem.id] = obj.trimCutLineItem;
          if (( obj.hasChanges === true ) && ((obj._data.isNew === false) || (updateRecords === false)) ) {
            hasChanges = true;
          }
        });

        if( hasChanges ) {
          const submitData = {
            _method: 'PUT',
            order_trim_cuts: trimCutsObj,
            //redirect: redirectToOrderForm
          };

          axios.post(this.endpointUpdate, submitData)
          .then((response) => {
            let updatedTrimCutList = response.data.data.orderTrimCuts;
            this.setErrors({});

            if( response.data.redirectTo != null && response.data.redirectTo != '' ) {
              window.location.href = response.data.redirectTo;
            } else {
              //Update the trimCutLineItemsList with the response from the server
              updatedTrimCutList.forEach(function(tc){
                tc.isNew = false;
                tc.hasChanges = false;
              });
              this.trimCutLineItemsList = updatedTrimCutList;

              flash(response.data.status_type, response.data.status);
            }
          })
          .catch(error => {
            this.setErrors(error.response.data.errors);
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      },

      setErrors(errors) {
        this.$refs.trimCutLineItems.setErrors(errors);
      }
    }
  };
</script>

<template>
  <div class="trim-cuts-container">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <th>Room</th>
          <th>Product Model</th>
          <th>Trim Type</th>
          <th>Trim Color</th>
          <th>Trim Width (in)</th>
          <th>Closure Size</th>
          <th>Pocket Height</th>
          <th>Pocket Depth</th>
          <th>Notes</th>
          <th>&nbsp;</th>
        </thead>

        <trim-cut-line-items
          :master-trim-cut-line-items="trimCutLineItemsList"
          :master-select-options="masterSelectOptions"
          ref="trimCutLineItems">
        </trim-cut-line-items>
      </table>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <p class="text-center mb-0">When you're done click "Finished." You will be taken back to the proposal form where you can submit your order.</p>
      </div>

      <div class="form-group col-sm-12 text-center mb-0">
        <a @click="saveAndCompleteTrimCuts($event)" class="btn btn-primary">Finished</a>
      </div>
    </div>
  </div>
</template>
