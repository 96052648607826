<script>
  import { mapState, mapGetters } from 'vuex';
  import ManufacturingLineItemCostsGroup from './ManufacturingLineItemCostsGroup';

  export default {
    props: [
      //'currentOrderProposal',
    ],

    components: {
      ManufacturingLineItemCostsGroup,
    },

    mounted() {},

    computed: {
      orderProposal() {
        return this.$store.getters['orderProposal/currentOrderProposal'];
      },

      ...mapState('shadeGroups', ['byId']),
      ...mapGetters("shadeGroups", { shadeGroups: "list" }),

      errors() {
        return [];
      },
    },

    methods: {

    }
  };
</script>

<template>
  <div class="form-row">
    <div class="form-group col-sm-12 table-responsive">
      <table class="table table-hover">
        <thead>
          <th>Item #</th>
          <th>Quantity</th>
          <th>Width (in)</th>
          <th>Length (in)</th>
          <th>Product</th>
          <th>Unit SqFt</th>
          <th>Unit SqYd</th>
          <th>Unit Hardware ($/shade)</th>
          <th>Unit Chain ($/shade)</th>
          <th>Unit Aluminum ($/shade)</th>
          <th>Total Aluminum ($)</th>
          <th>Unit Motorized ($/shade)</th>
          <th>Total Motorized ($)</th>
          <th>Unit Fabric 1 ($/shade)</th>
          <th>Unit Fabric 2 ($/shade)</th>
          <th>Total Fabric ($)</th>
          <th>Unit Material ($/shade)</th>
          <th>Total Material ($)</th>
          <th>Shade Mfg Unit Time (Hr/shade)</th>
          <th>Shade Mfg Unit Labor ($/shade)</th>
          <th>Trim Mfg Unit Time (Hr/shade)</th>
          <th>Trim Mfg Unit Labor ($/shade)</th>
          <th>Side Channel Mfg Unit Time (Hr/shade)</th>
          <th>Side Channel Mfg Unit Labor ($/shade)</th>
          <th>Mfg Unit Time (Hr/shade)</th>
          <th>Mfg Unit Labor ($/shade)</th>
          <th>Total Mfg Time (HR)</th>
          <th>Total Mfg Labor ($)</th>
        </thead>

        <template v-if="shadeGroups.length <= 0">
          <tbody>
            <tr>
              <td colspan="17" class="colored-bg text-center">No Shade Line Items</td>
            </tr>
          </tbody>
        </template>

        <template v-else>
          <template v-for="shadeGroup in shadeGroups">
            <manufacturing-line-item-costs-group
              :shade-group-id="shadeGroup.id">
            </manufacturing-line-item-costs-group>
          </template>

          <tbody>
            <tr class="colored-bg">
              <td colspan="10"></td>
              <td>{{ orderProposal.total_aluminum_cost_formatted }}</td>
              <td></td>
              <td>{{ orderProposal.total_motorized_cost_formatted }}</td>
              <td></td>
              <td></td>
              <td>{{ orderProposal.total_fabric_cost_formatted }}</td>
              <td></td>
              <td>{{ orderProposal.total_material_cost_formatted }}</td>
              <td colspan="8"></td>
              <td>{{ orderProposal.total_mfg_hours }}</td>
              <td>{{ orderProposal.total_mfg_labor_cost_formatted }}</td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>
