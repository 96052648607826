var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isExteriorShade
    ? _c("div", { class: _vm.parentContainerClass }, [
        _c(
          "label",
          {
            attrs: {
              for: _vm.getInputName(_vm.shadeUnit.id, "hembar_endcap_color"),
            },
          },
          [_vm._v("\n    " + _vm._s(_vm.hembarEndcapColorLabel) + "\n  ")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.shadeUnit.hembar_endcap_color,
                expression: "shadeUnit.hembar_endcap_color",
              },
            ],
            staticClass: "form-control",
            class: _vm.errors["hembar_endcap_color"] ? "is-invalid" : "",
            attrs: {
              name: _vm.getInputName(_vm.shadeUnit.id, "hembar_endcap_color"),
              id: _vm.getInputId(_vm.shadeUnit.id, "hembar_endcap_color"),
              disabled: _vm.isDisabled,
            },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.shadeUnit,
                  "hembar_endcap_color",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { domProps: { value: null } }, [
              _vm._v(_vm._s(_vm.defaultSelectOptionText)),
            ]),
            _vm._v(" "),
            _vm._l(_vm.selectOptions, function (endcapColor) {
              return _c("option", { domProps: { value: endcapColor.value } }, [
                _vm._v("\n        " + _vm._s(endcapColor.label) + "\n    "),
              ])
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.errors["hembar_endcap_color"]
          ? _c(
              "span",
              { staticClass: "invalid-feedback", attrs: { role: "alert" } },
              [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.errors["hembar_endcap_color"][0]) +
                    "\n  "
                ),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }