export const fieldFormattingMixin = {

  methods: {
    formatInches(value, scale) {
      if( value.toString().search('"') >= 0 ) {
        return value;
      } else {
        return (value == null) ? null : value.toLocaleString(undefined, { minimumFractionDigits: scale }) + '"'
      }
    }
  }
}

