<script>
  import FieldMeasureNote from './FieldMeasureNote';

  export default {
    props: [
      'parentInstallerFmNote',
      'parentFieldMeasureNotes',
      'parentInstallerFmResponses',
    ],

    components: {
      FieldMeasureNote
    },

    data() {
      return {
        installerFmNote: {
          installer_information_id: null,
          completed_by_contact_id: null,
          date_completed: null,
          notes: null
        },
        fieldMeasureNoteList: [],
        installerFmResponseList: [],
        errors: []
      }
    },

    mounted() {
      this.installerFmNote = { ...this.installerFmNote, ...this.parentInstallerFmNote };
        this.installerFmNote.date_completed = this.installerFmNote.date_completed_vue_formatted;
      this.fieldMeasureNoteList = this.parentFieldMeasureNotes;
      this.installerFmResponseList = this.parentInstallerFmResponses;
    },

    computed: {
      isDisabled() {
        return false;
      },

      completedByOptions() {
        return this.$parent.installationManagerOptions;
      },
    },

    methods: {
      getInputName(id, field) {
        return 'fm_note[' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      getSaveData() {
        var fmNote = _.clone(this.installerFmNote);
          delete fmNote.installer_fm_responses;
        fmNote['field_measure_checklist'] = {};

        if( Object.keys(this.$refs).length && this.$refs.fieldMeasureNote.length ) {
          this.$refs.fieldMeasureNote.forEach(function(obj) {
            fmNote.field_measure_checklist[obj.fieldMeasureNote.id] = {
              selected_value: obj.fieldMeasureNote.selected_value
            }
          });
        }
        return fmNote;
      },

      setErrors(errors) {
        this.errors = errors;
      }
    }
  };
</script>
