var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-row estimate-widget travel-expenses" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-sm-12 table-responsive" }, [
        _c("table", { staticClass: "table" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v("Install")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.orderProposal.distance_to_jobsite))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.travel_install_hours)),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.travel_install_trips)),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.orderProposal.install_extra_trips,
                      expression: "orderProposal.install_extra_trips",
                    },
                    {
                      name: "proposal-field-directive",
                      rawName: "v-proposal-field-directive",
                    },
                  ],
                  staticClass: "form-control min-width-field",
                  class: _vm.errors["install_extra_trips"] ? "is-invalid" : "",
                  attrs: {
                    type: "text",
                    placeholder: "1",
                    name: _vm.getInputName("install_extra_trips"),
                    id: _vm.getInputId("install_extra_trips"),
                  },
                  domProps: { value: _vm.orderProposal.install_extra_trips },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.orderProposal,
                        "install_extra_trips",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.orderProposal.install_techs,
                      expression: "orderProposal.install_techs",
                    },
                    {
                      name: "proposal-field-directive",
                      rawName: "v-proposal-field-directive",
                    },
                  ],
                  staticClass: "form-control min-width-field",
                  class: _vm.errors["install_techs"] ? "is-invalid" : "",
                  attrs: {
                    type: "text",
                    placeholder: "1",
                    name: _vm.getInputName("install_techs"),
                    id: _vm.getInputId("install_techs"),
                  },
                  domProps: { value: _vm.orderProposal.install_techs },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.orderProposal,
                        "install_techs",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.travel_install_total_labor)),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.orderProposal.travel_install_total_labor_cost_formatted
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Measure")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.orderProposal.distance_to_jobsite))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.travel_measure_hours)),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.travel_measure_trips)),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.orderProposal.field_measure_extra_trips,
                      expression: "orderProposal.field_measure_extra_trips",
                    },
                    {
                      name: "proposal-field-directive",
                      rawName: "v-proposal-field-directive",
                    },
                  ],
                  staticClass: "form-control min-width-field",
                  class: _vm.errors["field_measure_extra_trips"]
                    ? "is-invalid"
                    : "",
                  attrs: {
                    type: "text",
                    placeholder: "1",
                    name: _vm.getInputName("field_measure_extra_trips"),
                    id: _vm.getInputId("field_measure_extra_trips"),
                  },
                  domProps: {
                    value: _vm.orderProposal.field_measure_extra_trips,
                  },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.orderProposal,
                        "field_measure_extra_trips",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.orderProposal.measure_techs,
                      expression: "orderProposal.measure_techs",
                    },
                    {
                      name: "proposal-field-directive",
                      rawName: "v-proposal-field-directive",
                    },
                  ],
                  staticClass: "form-control min-width-field",
                  class: _vm.errors["measure_techs"] ? "is-invalid" : "",
                  attrs: {
                    type: "text",
                    placeholder: "1",
                    name: _vm.getInputName("measure_techs"),
                    id: _vm.getInputId("measure_techs"),
                  },
                  domProps: { value: _vm.orderProposal.measure_techs },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.orderProposal,
                        "measure_techs",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.travel_measure_total_labor)),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.orderProposal.travel_measure_total_labor_cost_formatted
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Total")]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.orderProposal.travel_total_trips))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.travel_total_extra_trips)),
              ]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.orderProposal.travel_total_hours))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.travel_total_cost_formatted)),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
      _c("h2", [_vm._v("Travel Expense")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("One-Way Miles")]),
        _vm._v(" "),
        _c("th", [_vm._v("Labor Time (Hours)")]),
        _vm._v(" "),
        _c("th", [_vm._v("# Trips")]),
        _vm._v(" "),
        _c("th", [_vm._v("Extra"), _c("br"), _vm._v("Trips")]),
        _vm._v(" "),
        _c("th", [_vm._v("# Techs")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total Labor")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total Labor"), _c("br"), _vm._v("Cost ($)")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }