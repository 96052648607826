var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-responsive" }, [
    _c(
      "table",
      { staticClass: "table table-hover" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm.shadeGroups.length <= 0
          ? [_vm._m(1)]
          : [
              _vm._l(_vm.shadeGroups, function (shadeGroup) {
                return [
                  _c("shade-installation-table-group", {
                    attrs: { "shade-group-id": shadeGroup.id },
                  }),
                ]
              }),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", { staticClass: "colored-bg" }, [
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.orderProposal.shade_quantity))]),
                  _vm._v(" "),
                  _c("td", { attrs: { colspan: "13" } }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm.orderProposal.install_labor_hours)),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.orderProposal.install_labor_cost_formatted)
                    ),
                  ]),
                ]),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [
          _c("input", {
            attrs: { type: "checkbox", onclick: "selectInstallation(this)" },
          }),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Item")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Quantity")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Product")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-nowrap", attrs: { scope: "col" } }, [
          _vm._v("Supply Only"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-nowrap", attrs: { scope: "col" } }, [
          _vm._v("After Hours"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Shade Install Unit Time (Hr)"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Shade Install Unit Labor ($/Hr)"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Trim Install Unit Time (Hr)"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Trim Install Unit Labor ($/Hr)"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Side Channel Install Unit Time (Hr)"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Side Channel Install Unit Labor ($/Hr)"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Extra Labor Unit Time (Min)"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Extra Labor ($/Hr)")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Install Unit Time (Hr)"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Install Unit Labor ($)"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Total Install Time (Hr)"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Total Install Labor ($)"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tbody", [
      _c("tr", [
        _c(
          "td",
          { staticClass: "colored-bg text-center", attrs: { colspan: "18" } },
          [_vm._v("No Shade Line Items")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }