<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'currentOrderProposal',
    ],

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('InstallationLabor.vue - Mounted');
        console.log(this.orderProposal);
        console.log('');
      */
    },

    computed: {
      orderProposal() {
        return this.currentOrderProposal;
      },

      errors() {
        return [];
      },
    },

    methods: {
      getInputName(field) {
        return this.$parent.getOrderProposalInputName(field);
      },

      getInputId(field) {
        return this.$parent.getOrderProposalInputId(field);
      },
    }
  };
</script>

<template>
  <div class="form-row">
    <div class="form-group col-sm-12 mb-0">
      <h2>Installation Labor</h2>
    </div>

    <div class="form-group col-sm-12">
      <table class="form">
        <thead>
          <tr>
            <th></th>
            <th>Total Time (Hours)</th>
            <th>Total Cost ($)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Field Measure</td>
            <td>{{ orderProposal.fm_labor_hours }}</td>
            <td>{{ orderProposal.fm_labor_cost_formatted }}</td>
          </tr>
          <tr>
            <td>Installation</td>
            <td>{{ orderProposal.install_labor_hours }}</td>
            <td>{{ orderProposal.install_labor_cost_formatted }}</td>
          </tr>
          <tr>
            <td>Travel</td>
            <td>{{ orderProposal.travel_total_hours }}</td>
            <td>{{ orderProposal.travel_lodging_total_formatted }}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>{{ orderProposal.total_labor_hours }}</td>
            <td>{{ orderProposal.total_labor_cost_formatted }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
