<script>
  import { mapMultiRowFields } from 'vuex-map-fields';
  import { mapDynamicMultiRowFields } from '../../../../helpers/vuexMapFieldsOverride';
  import { motorizedOrderItemAdditionMixin } from '../../mixins/motorizedOrderItemAdditionMixin';
  import MotorizedOrderItemAdditionCut from './MotorizedOrderItemAdditionCut';

  export default {
    props: [
      'parentOrderItemAddition',
      'additionalItem',
      'additionalItemId',
      'currentOrderProposal',
      'masterOrderItemAdditionCuts',
    ],

    mixins: [motorizedOrderItemAdditionMixin],

    components: {
      MotorizedOrderItemAdditionCut,
    },

    data() {
      return {}
    },

    mounted() {},

    computed: {
      ...mapDynamicMultiRowFields('motorizedOrderItemAdditionCuts', { motorizedOrderItemAdditionCuts: 'byOrderItemAdditionId[].motorizedOrderItemAdditionCuts'}, 'indexArray'),

      indexArray() {
        return this.additionalItemId;
      },

      orderProposal() {
        return this.currentOrderProposal;
      },

      errors() {
        return ( this.$parent.errors.hasOwnProperty('orderItemAdditionCuts') ) ? this.$parent.errors.orderItemAdditionCuts : [];
      },
    },

    methods: {
      addOrderItemAdditionCutRecord(event) {
        event.preventDefault();
        this.$store.dispatch('motorizedOrderItemAdditionCuts/addNewOrderItemAdditionCut', this.additionalItem.id);
      }
    }
  };
</script>

<template>
  <table class="table order-item-addition-cuts">
    <thead>
      <tr>
        <th>Cut #</th>
        <th>Cut Length (In)</th>
        <th>Quantity</th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <template v-if="motorizedOrderItemAdditionCuts" v-for="(cut, index) in motorizedOrderItemAdditionCuts">
        <motorized-order-item-addition-cut
          :additional-cut-item="cut">
        </motorized-order-item-addition-cut>
      </template>

      <tr>
        <td colspan="4" class="colored-bg text-center">
          <a
            href="#"
            v-bind:class="orderProposal.userCanEdit ? '' : 'disabled'"
            @click="addOrderItemAdditionCutRecord($event)">
              Add Cut
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>
