//
// Shade Groups
//
export const ENDPOINT_ADD_SHADE_GROUP = '/projects/estimate/shade-groups/new/';
export const ENDPOINT_IMPORT_SHADE_GROUP = '/projects/estimate/shade-groups/import/'; /* Added By Mansih OST */
export const ENDPOINT_CREATE_SHADE_GROUP = '/projects/estimate/shade-groups/';
export const ENDPOINT_UPDATE_SHADE_GROUP = '/projects/estimate/shade-groups/';
export const ENDPOINT_DESTROY_SHADE_GROUP = '/projects/estimate/shade-groups/';
export const ENDPOINT_COPY_SHADE_GROUP = '/projects/estimate/shade-groups/clone/';
export const ENDPOINT_SORT_SHADE_GROUP = '/projects/estimate/shade-groups/sort/';

//
// Shade Unit Configs for Estimates
//
export const ENDPOINT_SHADE_UNIT_CONFIG_ADD_ROW = '/projects/estimate/shade-unit-config/add-row/';
export const ENDPOINT_SHADE_UNIT_CONFIG_DESTROY = '/projects/estimate/shade-unit-config/';
export const ENDPOINT_SHADE_UNIT_CONFIG_CREATE = '/projects/estimate/shade-unit-config/';
export const ENDPOINT_SHADE_UNIT_CONFIG_UPDATE = '/projects/estimate/shade-unit-config/';
export const ENDPOINT_COPY_USER_CONFIGS_TO_ESTIMATE = '/projects/estimate/shade-unit-config/copy-user-defined-configs/';

//
// Shade Unit Configs for Users (I.e., User Global Shade Unit Configs)
//
export const ENDPOINT_USER_SHADE_UNIT_CONFIG_ADD_ROW = '/my-account/shade-configurations/add-row/';
export const ENDPOINT_USER_SHADE_UNIT_CONFIG_DESTROY = '/my-account/shade-configurations/';
export const ENDPOINT_USER_SHADE_UNIT_CONFIG_CREATE = '/my-account/shade-configurations';
export const ENDPOINT_USER_SHADE_UNIT_CONFIG_UPDATE = '/my-account/shade-configurations/';

//
// Shade Line Item Endpoints
//
export const ENDPOINT_NEW_COUPLED_SHADE = '/projects/estimate/coupled-shades/new/';
export const ENDPOINT_DESTROY_COUPLED_SHADE_LINE_ITEM = '/projects/estimate/coupled-shades/';
export const ENDPOINT_COPY_COUPLED_SHADE_LINE_ITEM = '/projects/estimate/coupled-shades/clone/';
export const ENDPOINT_SORT_COUPLED_SHADE_LINE_ITEM = '/projects/estimate/coupled-shades/sort/';

//
// Shade Order
//
export const ENDPOINT_SHADE_ORDER_CREATE = '/projects/estimate/shade-order/store/';
export const ENDPOINT_SHADE_ORDER_UPDATE = '/projects/estimate/shade-order/update/';

//
// Order Proposal
//
export const ENDPOINT_ORDER_PROPOSAL_UPDATE = '/projects/proposal/vue/';
export const ENDPOINT_ORDER_PROPOSAL_UNLOCK = '/projects/proposal/vue/unlock/';
export const ENDPOINT_ORDER_PROPOSAL_LOCK = '/projects/proposal/vue/lock/';
export const ENDPOINT_ORDER_PROPOSAL_ACCEPT = '/projects/proposal/vue/accept/';
export const ENDPOINT_ORDER_PROPOSAL_DOWNLOAD = '/projects/proposal/vue/download/';
export const ENDPOINT_ORDER_PROPOSAL_UPLOAD_SIGNED_PROPOSAL = '/projects/proposal/vue/upload/';
export const ENDPOINT_ORDER_PROPOSAL_DOWNLOAD_SIGNED_PROPOSAL = '/projects/proposal/vue/download-signed-proposal/';
export const ENDPOINT_ORDER_PROPOSAL_DELETE_SIGNED_PROPOSAL = '/projects/proposal/vue/delete-signed-proposal/';

//Proposal Coordination Labor
export const ENDPOINT_PROPOSAL_COORDINATION_LABOR_NEW = '/projects/proposal/proposal-coordination-labor/new/';
export const ENDPOINT_PROPOSAL_COORDINATION_LABOR_DESTROY = '/projects/proposal/proposal-coordination-labor/';

//Proposal Misc Expenses
export const ENDPOINT_PROPOSAL_MISC_EXPENSE_NEW = '/projects/proposal/proposal-misc-expense/new/';
export const ENDPOINT_PROPOSAL_MISC_EXPENSE_DESTROY = '/projects/proposal/proposal-misc-expense/';

//Order Item Addition
export const ENDPOINT_ORDER_ITEM_ADDITION_NEW = '/projects/estimate/order-item-additions/new/';
export const ENDPOINT_ORDER_ITEM_ADDITION_CREATE = '/projects/estimate/order-item-additions/';
export const ENDPOINT_ORDER_ITEM_ADDITION_UPDATE = '/projects/estimate/order-item-additions/';
export const ENDPOINT_ORDER_ITEM_ADDITION_DESTROY = '/projects/estimate/order-item-additions/';



//
// Utilities
//

// Fabrics
export const ENDPOINT_GET_FABRIC_OPENNESS_OPTIONS = '/projects/estimate/utilities/fabric-utility/get-fabric-openness-options';
export const ENDPOINT_GET_FABRIC_ATTRIBUTE_OPTIONS = '/projects/estimate/utilities/fabric-utility/get-fabric-attribute-options';

//Motors
export const ENDPOINT_GET_MOTOR_TYPE_OPTIONS = '/projects/estimate/utilities/motor-utility/get-motor-type-options';
export const ENDPOINT_GET_MOTOR_POWER_OPTIONS = '/projects/estimate/utilities/motor-utility/get-motor-power-options';
export const ENDPOINT_GET_MOTOR_SOUND_OPTIONS = '/projects/estimate/utilities/motor-utility/get-motor-sound-options';
