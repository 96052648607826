<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'projectExpense',
      'projectExpenseId',
      'canEdit'
    ],

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('ProposalProjectExpense.vue (SINGULAR) - Mounted');
        console.log(this.orderProposal);
        console.log(this.currentProjectExpense);
        console.log('');
      */
    },

    computed: {
      currentProjectExpense() {
        return this.projectExpense;
      },

      orderProposal() {
        return this.$parent.currentOrderProposal;
      },

      lockFields() {
        return ( this.canEdit == true ) ? false : true;
      },

      selectOptions() {
        return this.$store.state.orderProposal.selectOptions;
      },

      calculatedProjectExpenseCost() {
        var value = '$0.00';
        switch(this.currentProjectExpense.name) {
          case 'Tools, Scaffold':
            value = this.orderProposal.ts_expense_cost_formatted;
            break;

          case 'Safety':
            value = this.orderProposal.safety_expense_cost_formatted;
            break;

          case 'Clean Up':
            value = this.orderProposal.cleanup_expense_cost_formatted;
            break;

          case 'Warranty':
            value = this.orderProposal.warranty_expense_cost_formatted;
            break;

          case 'Insurance':
            value = this.orderProposal.insurance_expense_cost_formatted;
            break;

          case 'Material Storage':
            value = this.orderProposal.ms_expense_cost_formatted;
            break;
        }

        return value;
      },

      errors() {
        return [];
      },
    },

    methods: {
      getInputName(id, field) {
        return 'proposal_project_expenses['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },
    }
  };
</script>

<template>
  <tr>
    <td class="text-nowrap">{{ currentProjectExpense.name }}</td>
    <td class="min-width-field">
      <template v-if="lockFields == false">
        <select
          class="form-control"
          v-bind:class="errors['percent'] ? 'is-invalid' : ''"
          v-bind:name="getInputName(currentProjectExpense.id, 'percent')"
          v-bind:id="getInputId(currentProjectExpense.id, 'percent')"
          v-model="currentProjectExpense.percent"
          v-proposal-field-directive>
          <option :value="null">--Choose One--</option>
          <template v-for="option in selectOptions.proposalProjectExpenseOptions">
            <option :value="option.value">{{ option.label }}</option>
          </template>
        </select>
      </template>
      <template v-else>
        {{ currentProjectExpense.percent_formatted }}
      </template>
    </td>
    <td>{{ calculatedProjectExpenseCost }}</td>
  </tr>
</template>
