<script>
  import { mapMultiRowFields } from 'vuex-map-fields';
  import NonMotorizedPart from './NonMotorizedPart';

  export default {
    props: [],

    //mixins: [],

    components: {
      NonMotorizedPart,
    },

    data() {
      return {}
    },

    mounted() {},

    computed: {
      ...mapMultiRowFields('orderItemAddition', ['byId']),

      orderItemAdditions() {
        return this.byId;
      },

      orderProposal() {
        return this.$store.getters['orderProposal/currentOrderProposal'];
      },

      orderItemAdditionSelectOptions() {
        return this.$store.state.orderItemAddition.orderItemAdditionSelectOptions;
      },

      isMaximized() {
        return this.$parent.showNonMotorizedParts;
      },

      errors() {
        return this.$store.state.orderItemAddition.errors;
      },
    },

    methods: {

    },

    watch: {}
  };
</script>

<template>
  <transition name="slide">
    <tbody v-show="isMaximized">
      <template v-for="(additionalItem, index) in orderItemAdditions">
        <non-motorized-part
          :additional-item="additionalItem"
          :additional-item-id="additionalItem.id">
        </non-motorized-part>
      </template>
    </tbody>
  </transition>
</template>
