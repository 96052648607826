<script>
  import ModalDelete from '../../notifications/ModalDelete'

  export default {
    props: [
      'parentRecord',
    ],

    components: {
      ModalDelete
    },

    data() {
      return {
        record: {
          id: null
        }
      }
    },

    mounted() {
      this.record = this.parentRecord;
    },

    methods: {
      getLinkUrl(path, id) {
        return path + '/' + id;
      },

      deleteSortableRecord() {
        this.$parent.$parent.deleteSortableRecord( this.record );
      }
    }
  };
</script>
