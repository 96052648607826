<script>
  //import { proposalMixin } from '../mixins/proposalMixin';

  export default {
    props: [
      'notificationList',
    ],

    //mixins: [proposalMixin],

    mounted() {
      /*
        console.log('ShadeOrderProjectNotifications.vue - Mounted');
        console.log(this.notificationList);
        console.log(this.notifications);
        console.log('');
      */
    },

    computed: {
      notifications() {
        return this.notificationList;
      },

      hasNotifications() {
        return ( this.notifications.length ) ? true : false;
      },

      errors() {
        return [];
      },
    },

    methods: {
      getInputName(field) {
        return this.$parent.getOrderProposalInputName(field);
      },

      getInputId(field) {
        return this.$parent.getOrderProposalInputId(field);
      }
    }
  };
</script>

<template>
  <div class="row mb-0" v-if="hasNotifications">
    <div class="col-sm-12">
      <template v-for="notification in notifications">
        <div
          class="alert"
          v-bind:class="(notification.alert_type != '') ? notification.alert_type : 'alert-warning'"
          v-html="notification.alert_message">
        </div>
      </template>
    </div>
  </div>
</template>
