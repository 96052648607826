import { ENDPOINT_GET_FABRIC_OPENNESS_OPTIONS,
          ENDPOINT_GET_FABRIC_ATTRIBUTE_OPTIONS,
          ENDPOINT_GET_MOTOR_TYPE_OPTIONS,
          ENDPOINT_GET_MOTOR_POWER_OPTIONS,
          ENDPOINT_GET_MOTOR_SOUND_OPTIONS
        } from '../endpoints';

export default {
  namespaced: true,


  //
  // State
  //
  state: {
    primaryShadeSelectOptions: [],
  },


  // Getters
  getters: {
    //
    // Get Select Options
    primaryShadeSelectOptions: state => state.primaryShadeSelectOptions,

    //
    // Return list of hardware_color select options based on shade type
    hardwareColorOptionsByShadeType: (state, getters) => (type) => {
      if( type && type.toLowerCase() == 'exterior' ) {
        return getters.primaryShadeSelectOptions.exteriorShadeHardwareColorOptions;
      } else {
        return getters.primaryShadeSelectOptions.hardwareColorOptions;
      }
    },

    //
    // Return list of hardware_color select options based on shade type
    handednessOptionsByShadeType: (state, getters) => (type, mfg) => {
      if( type && type.toLowerCase() == 'exterior' ) {
        let exteriorHandednessOptions = getters.primaryShadeSelectOptions.exteriorShadeHandednessOptions;

        if( mfg && mfg.toLowerCase() == 'fixscreen' ) {
          let list = exteriorHandednessOptions.filter(obj => {
            return obj.renson === true;
          });
          return list;
        } else {
          let list = exteriorHandednessOptions.filter(obj => {
            return obj.rollease === true;
          });
          return list;
        }
        getters.primaryShadeSelectOptions.hardwareColorOptions

      } else {
        return getters.primaryShadeSelectOptions.handednessOptions;
      }
    },

    //
    // Return list of exterior shade motor manufacturer options based on selected shade manufacturer
    getExteriorMotorMfgIdByShadeMfg: (state, getters) => (mfgModelName) => {
      var shadeMfg = getters.primaryShadeSelectOptions.exteriorShadeMfgOptions.find(function(obj) {
        if( mfgModelName == obj.value ) {
          return obj;
        }
      });
      let motorManufacturerList = getters.primaryShadeSelectOptions.motorManufacturerOptions;
      let selectedMfg = motorManufacturerList.find(function(obj){
        if( shadeMfg.manufacturer && shadeMfg.manufacturer.toLowerCase() == 'renson' ) {
          return obj.name == 'Somfy';
        } else {
          return obj.name == shadeMfg.manufacturer;
        }
      });
      return ( selectedMfg ) ? selectedMfg.id : null;
    }
  }, //end getters


  //
  // Actions
  //
  actions: {
    //
    // Initial load of shade unit configs and corresponding settings
    initEstimationSelectOptions({dispatch}, payload) {
      dispatch('setPrimaryShadeSelectOptions', payload.primaryShadeSelectOptions);
    },

    //
    // Set the shade unit config select option data
    setPrimaryShadeSelectOptions({commit}, selectOptions) {
      commit('SET_PRIMARY_SHADE_SELECT_OPTIONS', selectOptions);
    },

    //
    // Get Fabric Openness Factor Options
    getFabricOpennessFactorOptions({dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.get(ENDPOINT_GET_FABRIC_OPENNESS_OPTIONS, {
          params: payload.reqParams
        })
        .then((response) => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
      });
    },

    //
    // Get Fabric Color Options
    getFabricColorOptions({dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.get(ENDPOINT_GET_FABRIC_ATTRIBUTE_OPTIONS, {
          params: payload.reqParams
        })
        .then((response) => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
      });
    },

    //
    // Get Motor Type Options
    getMotorTypeOptions({dispatch, getters}, payload) {
      return new Promise((resolve, reject) => {
        if( payload.isExteriorShade ) {
          let exteriorMotorTypes = getters.primaryShadeSelectOptions.exteriorShadeMotorTypeOptions;
          resolve({motorTypeOptions: exteriorMotorTypes});

        } else {
          axios.get(ENDPOINT_GET_MOTOR_TYPE_OPTIONS, {
            params: payload.reqParams
          })
          .then((response) => {
            resolve({motorTypeOptions: response.data.data});
          })
          .catch(error => {
            reject(error);
          });
        }
      });
    },

    //
    // Get Motor Power Options
    getMotorPowerOptions({dispatch, getters}, payload) {
      return new Promise((resolve, reject) => {
        if( payload.isExteriorShade ) {
          let exteriorMotorPowers = getters.primaryShadeSelectOptions.exteriorShadeMotorPowerOptions;
          resolve({motorPowerOptions: exteriorMotorPowers});

        } else {
          axios.get(ENDPOINT_GET_MOTOR_POWER_OPTIONS, {
            params: payload.reqParams
          })
          .then((response) => {
            resolve({motorPowerOptions: response.data.data});
          })
          .catch(error => {
            reject(error);
          });
        }
      });
    },

    //
    // Get Motor Sound Options
    getMotorSoundOptions({dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.get(ENDPOINT_GET_MOTOR_SOUND_OPTIONS, {
          params: payload.reqParams
        })
        .then((response) => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
      });
    },

  }, //end actions


  //
  // Mutations
  //
  mutations: {
    //
    // Set the select options used for shades and shade unit configs
    SET_PRIMARY_SHADE_SELECT_OPTIONS(state, selectOptions) {
      state.primaryShadeSelectOptions = selectOptions;
    }

  } //end MUTATIONS
}
