//import { TOGGLE_SIDEBAR } from '../mutation-types'

import { mapGetters, mapActions } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import {
  ENDPOINT_SHADE_ORDER_CREATE,
  ENDPOINT_SHADE_ORDER_UPDATE
} from '../endpoints'

export default {
  namespaced: true,


  //
  // State
  //
  state: {
    shadeOrder: {},
    shadeOrderInitialState: {},
    shadeOrderErrors: {}
  },


  //
  // Getters
  //
  getters: {
    //
    // shade order
    shadeOrder: state => state.shadeOrder,

    //
    // shade order errors
    shadeOrderErrors: state => state.shadeOrderErrors,

    //
    // project notifications
    projectNotifications: (state, getters, rootState, rootGetters) => {
      var notificationList = [];
      var shadeOrder = getters.shadeOrder;
      var orderProposalNotifications = rootGetters['orderProposal/projectNotifications'];

      //Add notifications for shadeOrder
        //none for right now.

      //Merge in orderProposal Notifications
      notificationList = notificationList.concat(orderProposalNotifications);

      return notificationList;
    },

    //
    // vuex-map-fields plugin
    getField,
  },


  //
  // Actions
  //
  actions: {
    //
    // Apply the shade order object state
    applyShadeOrder({commit, dispatch}, payload) {
      let shadeOrder = payload.shadeOrder;

      if( shadeOrder.hasOwnProperty('order_proposal') ) {
        delete shadeOrder.order_proposal;
      }

      shadeOrder.isNew = ( shadeOrder.hasOwnProperty('isNew') && shadeOrder.isNew == true ) ? true : false;
      shadeOrder.hasChanges = false;
      shadeOrder.userCanEdit = ( payload.userCanEdit === true ) ? true : false;
      shadeOrder.description = ( shadeOrder.description ) ? shadeOrder.description : null;
      commit('SET_SHADE_ORDER', shadeOrder);
    },

    //
    // Update the shade order object state
    updateShadeOrder({state, commit, dispatch, rootState, rootGetters}) {
      let shadeOrder = state.shadeOrder,
          endpoint = '/projects/proposal/shade-order/update/' + shadeOrder.id,
          submitData = {
            _method: 'PUT',
            shade_order: shadeOrder
          };

      axios.post(endpoint, submitData)
      .then((response) => {
        dispatch('shadeOrder/setFormErrors', {}, {root: true});
        dispatch('shadeOrder/applyShadeOrder', {shadeOrder: response.data.data.shadeOrder}, {root: true});

        if( response.data.data.hasOwnProperty('orderProposal') ) {
          dispatch('orderProposal/applyOrderProposal', response.data.data.orderProposal, {root: true});
        }

        flash(response.data.status_type, response.data.status);
      })
      .catch(error => {
        dispatch('shadeOrder/setFormErrors', error.response.data.errors, {root: true});
        flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
      });
    },

    //  ENDPOINT_SHADE_ORDER_CREATE = /projects/estimate/shade-order/store/
    // Save method for the Shade Order Settings form
    saveShadeOrderSettings({state, commit, rootState, dispatch, rootGetters}) {
      var checkbox_changed = ($("#checkbox_changed").length && $("#checkbox_changed").val() == "true") ? true : false;
      var is_pickup = ($("#is_pickup").length && $("#is_pickup").is(":checked")) ? 1 : 0;
      var override_credit_card_fee = ($("#override_credit_card_fee").length && $("#override_credit_card_fee").is(":checked")) ? 1 : 0;

      let shadeOrder = state.shadeOrder,
          orderProposal = rootGetters['orderProposal/orderProposals'][0],
          endpoint = ( shadeOrder.isNew == true ) ? ENDPOINT_SHADE_ORDER_CREATE + shadeOrder.project_id : ENDPOINT_SHADE_ORDER_UPDATE + shadeOrder.id,
          submitData = {
            shade_order: {
              name: shadeOrder.name,
              description: shadeOrder.description,
              is_pickup: is_pickup,
              override_credit_card_fee: override_credit_card_fee
            },
            order_proposal: {
              estimator_contact_id: orderProposal.estimator_contact_id,
              anticipated_order_date: orderProposal.anticipated_order_date
            }
          };

      //If the action has been triggered, but there are no changes, don't submit
      if( !checkbox_changed && !shadeOrder.hasChanges && !orderProposal.hasChanges  ) {
        return;
      }

      // projects/estimate/shade-order/update/5821
      console.log(endpoint);
      axios.post(endpoint, submitData)
      .then((response) => {
        //Clear the errors (is there a better way to do this?)
        dispatch('shadeOrder/setFormErrors', {}, {root: true});

        if( shadeOrder.isNew == false ) {
          let payload = {
            shadeOrder: response.data.data.shadeOrder,
            userCanEdit: response.data.data.userCanEdit
          };
          //apply the returned response to update the state
          dispatch('shadeOrder/applyShadeOrder', payload, {root: true});
          flash(response.data.status_type, response.data.status);

          //and update the estimator shade unit configs
          if( response.data.data.hasOwnProperty('estimatorShadeUnitConfigs') ) {
            dispatch('estimatorShadeUnitConfigs/initEstimatorShadeUnitConfigs', {estimatorShadeUnitConfigs: response.data.data.estimatorShadeUnitConfigs}, {root: true});
          }
          if (checkbox_changed) {
            location.reload();
          }
          
        } else {
          flash(response.data.status_type, response.data.status);
          window.location.href = response.data.redirectTo;
        }
      })
      .catch(error => {
        dispatch('shadeOrder/setFormErrors', error.response.data.errors, {root: true});
        flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
      });
    },

    //
    // Set form validation errors
    setFormErrors({commit, dispatch}, payload) {
      //Shade Order fields
      if( payload.hasOwnProperty('shade_order') ) {
        commit('SET_SHADE_ORDER_ERRORS', payload.shade_order);
      } else {
        commit('SET_SHADE_ORDER_ERRORS', {});
      }

      if( payload.hasOwnProperty('order_proposal') ) {
        let orderProposalErrors = {};
        orderProposalErrors.errors = {order_proposal: payload.order_proposal};
        dispatch('orderProposal/setFormErrors', orderProposalErrors, {root: true});
      } else {
        dispatch('orderProposal/setFormErrors', {}, {root: true});
      }
    }
  },


  //
  // Mutations
  //
  mutations: {
    SET_SHADE_ORDER(state, shadeOrder) {
      state.shadeOrder = shadeOrder;
      state.shadeOrderInitialState = _.cloneDeep(shadeOrder);
    },

    SET_SHADE_ORDER_ERRORS(state, errors) {
      state.shadeOrderErrors = errors;
    },

    // vuex-map-fields plugin
    //updateField,

    updateField(state, payload) {
      state.shadeOrder[payload.fieldName] = payload.value;

      //Tracking changes
      if( payload.fieldName != 'hasChanges' && state.shadeOrder[payload.fieldName] != state.shadeOrderInitialState[payload.fieldName] ) {
        state.shadeOrder.hasChanges = true;
      }
    }
  }
}
