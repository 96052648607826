var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row" }, [
    _c("div", { staticClass: "form-group col-sm-12 table-responsive" }, [
      _c(
        "table",
        { staticClass: "table table-hover" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.shadeGroups.length <= 0
            ? [_vm._m(1)]
            : [
                _vm._l(_vm.shadeGroups, function (shadeGroup) {
                  return [
                    _c("manufacturing-line-item-costs-group", {
                      attrs: { "shade-group-id": shadeGroup.id },
                    }),
                  ]
                }),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", { staticClass: "colored-bg" }, [
                    _c("td", { attrs: { colspan: "10" } }),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.orderProposal.total_aluminum_cost_formatted)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.orderProposal.total_motorized_cost_formatted)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.orderProposal.total_fabric_cost_formatted)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.orderProposal.total_material_cost_formatted)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { attrs: { colspan: "8" } }),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(_vm.orderProposal.total_mfg_hours)),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.orderProposal.total_mfg_labor_cost_formatted)
                      ),
                    ]),
                  ]),
                ]),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", [_vm._v("Item #")]),
      _vm._v(" "),
      _c("th", [_vm._v("Quantity")]),
      _vm._v(" "),
      _c("th", [_vm._v("Width (in)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Length (in)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Product")]),
      _vm._v(" "),
      _c("th", [_vm._v("Unit SqFt")]),
      _vm._v(" "),
      _c("th", [_vm._v("Unit SqYd")]),
      _vm._v(" "),
      _c("th", [_vm._v("Unit Hardware ($/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Unit Chain ($/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Unit Aluminum ($/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Total Aluminum ($)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Unit Motorized ($/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Total Motorized ($)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Unit Fabric 1 ($/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Unit Fabric 2 ($/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Total Fabric ($)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Unit Material ($/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Total Material ($)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Shade Mfg Unit Time (Hr/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Shade Mfg Unit Labor ($/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Trim Mfg Unit Time (Hr/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Trim Mfg Unit Labor ($/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Side Channel Mfg Unit Time (Hr/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Side Channel Mfg Unit Labor ($/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Mfg Unit Time (Hr/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Mfg Unit Labor ($/shade)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Total Mfg Time (HR)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Total Mfg Labor ($)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tbody", [
      _c("tr", [
        _c(
          "td",
          { staticClass: "colored-bg text-center", attrs: { colspan: "17" } },
          [_vm._v("No Shade Line Items")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }