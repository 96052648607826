<script>
  import draggable from 'vuedraggable';
  import ShadeGroup from './ShadeGroup';
  import { mapState, mapActions, mapGetters } from 'vuex';

  export default {
    props: [

    ],

    components: {
      ShadeGroup
    },

    data() {
      return {

      }
    },

    computed: {
      ...mapState('shadeGroups', ['byId']),
      ...mapGetters("shadeGroups", { shadeGroups: "list" }),

      shadeGroupList: {
        get() {
          return this.shadeGroups;
        },

        set(value) {
          this.$store.dispatch('shadeGroups/updateSortOrder', value);
        }
      },
    },

    mounted() {
      /*
        console.log('ShadeGroups mounted');
        console.log(this.shadeGroups);
        console.log('');
      */
    },

    methods: {
      getInputName(id, field) {
        return 'shade_group[' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      addShadeGroup() {
        this.$store.dispatch('shadeGroups/addNewShadeGroup');
      },

      //updateSortOrder(event) {
        //if( event.draggedContext.futureIndex == 0 && event.draggedContext.index != 0 ) {
        //  return false;
        //}
      //},
    }
  }
</script>
