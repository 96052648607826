<script>
  import { mapMultiRowFields } from 'vuex-map-fields';
  import { mapDynamicMultiRowFields } from '../../../../helpers/vuexMapFieldsOverride';
  import { orderItemAdditionMixin } from '../../mixins/orderItemAdditionMixin';
  import OrderItemAddition from './OrderItemAddition';

  export default {
    props: [
      'masterOrderItemAdditions',
      'currentOrderProposal',
      'includeHeadingRow',
      'headingText',
    ],

    mixins: [orderItemAdditionMixin],

    components: {
      OrderItemAddition,
    },

    data() {
      return {
        defaultHeadingText: 'Misc Materials'
      }
    },

    created() {
      this.$store.dispatch('orderItemAddition/applyOrderItemAddition', {
        orderItemAdditions: this.masterOrderItemAdditions,
        isUpdate: false
      });
      this.$store.dispatch('orderItemAddition/applyOrderItemAdditionSelectOptions', this.$store.state.orderProposal.selectOptions.orderItemAdditionOptions);
    },

    mounted() {},

    computed: {
      ...mapMultiRowFields('orderItemAddition', ['byId']),

      orderItemAdditions() {
        return this.byId;
      },

      orderItemAdditionsFromState() {
        return this.$store.state.orderItemAddition.byId;
      },

      orderProposal() {
        return this.currentOrderProposal;
      },

      orderItemAdditionSelectOptions() {
        return this.$store.state.orderItemAddition.orderItemAdditionSelectOptions;
      },

      errors() {
        return this.$store.state.orderItemAddition.errors;
      },

      showHeadingRow() {
        return ( this.includeHeadingRow == false ) ? false : true;
      },

      headingRowHeadingText() {
        var heading = '';

        if( this.showHeadingRow == true ) {
          heading = ( typeof this.headingText != 'undefined' && this.headingText != '' ) ? this.headingText : this.defaultHeadingText;
        }

        return heading;
      }
    },

    methods: {
      addOrderItemAdditionRecord(event) {
        event.preventDefault();
        let newOrderItemAdditions = this.orderItemAdditions.filter(oia => oia.isNew === true);

        //if we have new additional parts, save them first, then add a new/empty record.
        if (newOrderItemAdditions.length > 0) {
          this.$store.dispatch('orderItemAddition/saveOrderItemAddition', newOrderItemAdditions[0].id)
            .then(function(){
              this.$store.dispatch('orderItemAddition/addNewOrderItemAddition');
            })
            .catch(err => {
              return false;
            });
        } else {
          this.$store.dispatch('orderItemAddition/addNewOrderItemAddition');
        }
      }
    },

    watch: {}
  };
</script>

<template>

  <div class="form-row additional-item-container">
    <div class="form-group col-sm-12 mb-0" v-if="showHeadingRow">
      <h2>{{ headingRowHeadingText }}</h2>
    </div>

    <div class="form-group col-sm-12">
      <div class="table-responsive">
        <table class="table additional-items-table">
          <thead>
            <tr>
              <th class="part-id">Product Name</th>
              <th class="part-color-id">Color</th>
              <th class="part-number">Part #</th>
              <th class="part-uom">UOM</th>
              <th class="part-quantity">Quantity</th>
              <th class="part-unit-cost">Cost</th>
              <th class="part-total-cost">Total</th>
              <th class="part-actions"></th>
            </tr>
          </thead>

          <template v-if="orderItemAdditions.length > 0">
            <template v-for="(additionalItem, index) in orderItemAdditions">
              <order-item-addition
                :additional-item="additionalItem"
                :additional-item-id="additionalItem.id">
              </order-item-addition>
            </template>
          </template>

          <tbody>
            <tr>
              <td colspan="8" class="colored-bg text-center">
                <a
                  href="#"
                  v-bind:class="orderProposal.userCanEdit ? '' : 'disabled'"
                  @click="addOrderItemAdditionRecord($event)">
                    Add Item
                </a>
              </td>
            </tr>

            <tr>
              <td colspan="6" class="text-right">Total</td>
              <td>{{ orderProposal.addtl_items_total_formatted }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
