var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { staticClass: "require-all" }, [
    _c("td", [_vm._v(_vm._s(_vm.cutName))]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c("convert-measurement-text-input-mask", {
          directives: [
            {
              name: "order-item-addition-field-directive",
              rawName: "v-order-item-addition-field-directive",
            },
          ],
          attrs: {
            "mask-type": "convertToInches",
            scale: "3",
            "parent-record": _vm.currentAdditionalCutItem,
            "parent-record-field-name": "cut_length_in",
            "is-managed-by-vuex": "true",
            "has-error": _vm.errors["cut_length_in"] ? true : false,
            "input-id": _vm.getInputId(
              _vm.currentAdditionalCutItem.id,
              "cut_length_in"
            ),
            "input-name": _vm.getInputName(
              _vm.currentAdditionalCutItem.id,
              "cut_length_in"
            ),
            placeholder: '48.75"',
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("td", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentAdditionalCutItem.quantity,
            expression: "currentAdditionalCutItem.quantity",
          },
          {
            name: "order-item-addition-field-directive",
            rawName: "v-order-item-addition-field-directive",
          },
        ],
        staticClass: "form-control",
        class: _vm.errors["quantity"] ? "is-invalid" : "",
        attrs: {
          type: "number",
          placeholder: "1",
          name: _vm.getInputName(_vm.currentAdditionalCutItem.id, "quantity"),
          id: _vm.getInputId(_vm.currentAdditionalCutItem.id, "quantity"),
        },
        domProps: { value: _vm.currentAdditionalCutItem.quantity },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(
              _vm.currentAdditionalCutItem,
              "quantity",
              $event.target.value
            )
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("a", {
        staticClass: "btn icon-only btn-save",
        class: _vm.orderProposal.userCanEdit ? "" : "disabled",
        attrs: { href: "#", title: "Save" },
        on: {
          click: function ($event) {
            return _vm.saveOrderItemAddition($event)
          },
        },
      }),
      _vm._v(" "),
      _c("a", {
        staticClass: "btn icon-only btn-delete",
        class: _vm.canDeleteCut == true ? "" : "disabled",
        attrs: { href: "#", title: "Delete" },
        on: {
          click: function ($event) {
            return _vm.deleteOrderItemAdditionCut($event)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }