var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasNotifications
    ? _c("div", { staticClass: "row mb-0" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _vm._l(_vm.notifications, function (notification) {
              return [
                _c("div", {
                  staticClass: "alert",
                  class:
                    notification.alert_type != ""
                      ? notification.alert_type
                      : "alert-warning",
                  domProps: { innerHTML: _vm._s(notification.alert_message) },
                }),
              ]
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }