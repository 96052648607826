var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row estimate-widget misc-expenses" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-sm-12" }, [
      _c("table", { staticClass: "table" }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm.proposalMiscExpenses.length > 0
              ? [
                  _vm._l(
                    _vm.proposalMiscExpenses,
                    function (miscExpense, index) {
                      return [
                        _c("proposal-misc-expense", {
                          attrs: {
                            "misc-expense": miscExpense,
                            "misc-expense-id": miscExpense.id,
                          },
                        }),
                      ]
                    }
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "colored-bg text-center",
                  attrs: { colspan: "3" },
                },
                [
                  _c(
                    "a",
                    {
                      class:
                        _vm.orderProposal.userCanEdit == false
                          ? "disabled"
                          : "",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.addProposalMiscExpense($event)
                        },
                      },
                    },
                    [_vm._v("\n              Add Misc Charge\n            ")]
                  ),
                ]
              ),
            ]),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
      _c("h2", [_vm._v("Misc Charges")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Cost")]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }