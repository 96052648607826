var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row estimate-widget profit-check" }, [
    _vm.showHeadingRow
      ? _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
          _c("h2", [_vm._v(_vm._s(_vm.headingRowHeadingText))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-sm-12 table-responsive" }, [
      _c("table", { staticClass: "table" }, [
        _c("tbody", [
          _c("tr", [
            _c("td", { staticClass: "text-nowrap" }, [_vm._v("Direct Costs")]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.pc_direct_cost_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _vm._m(0),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", [
              _c("strong", [
                _vm._v(_vm._s(_vm.orderProposal.pc_price_to_charge_formatted)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "text-nowrap" }, [
              _vm._v("Overhead Costs"),
            ]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.pc_overhead_cost_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "text-nowrap" }, [_vm._v("Total Costs")]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.pc_total_cost_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "text-nowrap" }, [
              _vm._v("Net Profit ($)"),
            ]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.pc_net_profit_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "text-nowrap" }, [
              _vm._v("Net Profit (%)"),
            ]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.pc_net_profit_rate_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "text-nowrap" }, [
              _vm._v("Override Price"),
            ]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", { staticClass: "min-width-field" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.orderProposal.override_price,
                    expression: "orderProposal.override_price",
                  },
                  {
                    name: "proposal-field-directive",
                    rawName: "v-proposal-field-directive",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors["override_price"] ? "is-invalid" : "",
                attrs: {
                  type: "text",
                  placeholder: "",
                  name: _vm.getInputName("override_price"),
                  id: _vm.getInputId("override_price"),
                },
                domProps: { value: _vm.orderProposal.override_price },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.orderProposal,
                      "override_price",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "text-nowrap" }, [
              _vm._v("Actual Contract Price"),
            ]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                _vm._s(_vm.orderProposal.pc_actual_contract_price_formatted)
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "text-nowrap" }, [_vm._v("Gross Profit")]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.pc_gross_profit_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "text-nowrap" }, [
              _vm._v("Gross Profit Margin"),
            ]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                _vm._s(_vm.orderProposal.pc_gross_profit_margin_formatted)
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "text-nowrap" }, [
      _c("strong", [_vm._v("Price to Charge")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }