<script>
  export default {
    props: [
      'shadeUnit',
      'unitType',
      'motorManufacturerLabel',
      'motorTypeLabel',
      'motorPowerLabel',
      'motorSoundLabel',
      'defaultSelectOptionText',
      'errors',
      'disabled'
    ],

    data() {
      return {
        vuexModuleName: 'shadeLineItems'
      }
    },

    mounted() {
      this.vuexModuleName = ( this.unitType == 'shadeConfigUnit' ) ? 'shadeUnitConfigs' : 'shadeLineItems';
    },

    computed: {
      isExteriorShade() {
        return this.$parent.isExteriorShade;
      },

      isMotorized() {
        var isMotorized = ( this.shadeUnit.product_model_drive_mechanism == 'motor' ) ? true : false;
        if( isMotorized == false ) {
          this.shadeUnit.motor_manufacturer_id = null;
          this.shadeUnit.motor_type = null;
          this.shadeUnit.motor_power = null;
        }
        return isMotorized;
      },

      isBatteryPowered() {
        if( this.shadeUnit.motor_power == 'Internal Battery' || this.shadeUnit.motor_power == 'External Battery' ) {
          this.shadeUnit.product_model_battery_powered = 1;
          return true;

        } else {
          this.shadeUnit.product_model_battery_powered = 0;
          return false;
        }
      },

      motorManufacturerOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.motorManufacturerOptions;
      },
    },

    methods: {
      getInputName(id, field) {
        return this.$parent.getInputName(id, field);
      },

      updateMotorTypeOptions() {
        var payload = { id: this.shadeUnit.id };
        this.$store.dispatch(this.vuexModuleName + '/setMotorTypeOptions', payload)
        .then(response => {
          if( this.shadeUnit.motor_type_options.some(motorType => motorType.value == this.shadeUnit.motor_type) == false ) {
            this.shadeUnit.motor_type = null;
          }
        }, error => {});
      },

      updateMotorPowerOptions() {
        var payload = { id: this.shadeUnit.id};
        this.$store.dispatch(this.vuexModuleName + '/setMotorPowerOptions', payload)
        .then(response => {
          if( this.shadeUnit.motor_power_options.some(motorPower => motorPower.value == this.shadeUnit.motor_power) == false ) {
            this.shadeUnit.motor_power = null;
          }
        }, error => {});
      },

      updateMotorSoundOptions() {
        var payload = { id: this.shadeUnit.id};
        this.$store.dispatch(this.vuexModuleName + '/setMotorSoundOptions', payload)
        .then(response => {
          if( this.shadeUnit.motor_sound_options.some(motorSound => motorSound.value == this.shadeUnit.motor_sound) == false ) {
            this.shadeUnit.motor_sound = null;
          }
        }, error => {});
      }
    },

    watch: {
      'shadeUnit.product_model_exterior_mfg': function (newVal, oldVal) {
        if( newVal != oldVal) {
          this.updateMotorSoundOptions();
        }
      },

      'shadeUnit.motor_manufacturer_id': function (newVal, oldVal) {
        if( newVal != oldVal ) {
          this.updateMotorTypeOptions();
        }
      },

      'shadeUnit.motor_type': function (newVal, oldVal) {
        if( newVal != oldVal ) {
          this.updateMotorPowerOptions();
        }
      },

      'shadeUnit.motor_power': function (newVal, oldVal) {
        if( newVal != oldVal ) {
          this.updateMotorSoundOptions();
        }
      },

      'shadeUnit.motor_type_options': function(newVal, oldVal) {
        if( _.isEqual(newVal, oldVal) == false ) {
          this.updateMotorPowerOptions();
        }
      },

      'shadeUnit.motor_power_options': function(newVal, oldVal) {
        if( _.isEqual(newVal, oldVal) === false ) {
          this.updateMotorSoundOptions();
        }
      }
    }
  };
</script>

<template>
  <div class="motor-configuration col">
    <div class="form-row">
      <div class="form-group col-sm-12 col-md mb-4">
        <label for="getInputName(shadeUnit.id, 'motor_manufacturer_id')">
          {{ motorManufacturerLabel }}
        </label>

        <input type="hidden"
          v-bind:name="getInputName(shadeUnit.id, 'motor_manufacturer_id')"
          v-model="shadeUnit.motor_manufacturer_id">

        <select
          class="form-control"
          v-bind:class="errors['motor_manufacturer_id'] ? 'is-invalid' : ''"
          v-bind:name="getInputName(shadeUnit.id, 'motor_manufacturer_id')"
          v-bind:id="getInputName(shadeUnit.id, 'motor_manufacturer_id')"
          v-model="shadeUnit.motor_manufacturer_id"
          :disabled="disabled == true || isMotorized == false || isExteriorShade">
            <option :value="null">{{ defaultSelectOptionText }}</option>
            <option v-for="motorManufacturer in motorManufacturerOptions" :value="motorManufacturer.id">{{ motorManufacturer.name }}</option>
        </select>

        <span class="invalid-feedback" role="alert" v-if="errors['motor_manufacturer_id']">
          {{ errors['motor_manufacturer_id'][0] }}
        </span>
      </div>

      <div class="form-group col-sm-12 col-md mb-4">
        <label for="getInputName(shadeUnit.id, 'motor_type')">
          {{ motorTypeLabel }}
        </label>

        <input type="hidden" v-bind:name="getInputName(shadeUnit.id, 'motor_type')" v-model="shadeUnit.motor_type">
        <select
          class="form-control"
          v-bind:class="errors['motor_type'] ? 'is-invalid' : ''"
          v-bind:name="getInputName(shadeUnit.id, 'motor_type')"
          v-bind:id="getInputName(shadeUnit.id, 'motor_type')"
          v-model="shadeUnit.motor_type"
          :disabled="disabled == true || isMotorized == false || isExteriorShade">
            <option :value="null">{{ defaultSelectOptionText }}</option>
            <option v-for="motorType in shadeUnit.motor_type_options" :value="motorType.value">{{ motorType.label }}</option>
        </select>

        <span class="invalid-feedback" role="alert" v-if="errors['motor_type']">
          {{ errors['motor_type'][0] }}
        </span>
      </div>

      <div class="form-group col-sm-12 col-md mb-4">
        <label for="getInputName(shadeUnit.id, 'motor_power')">
          {{ motorPowerLabel }}
        </label>

        <input type="hidden" v-bind:name="getInputName(shadeUnit.id, 'motor_power')" v-model="shadeUnit.motor_power">
        <select
          class="form-control"
          v-bind:class="errors['motor_power'] ? 'is-invalid' : ''"
          v-bind:name="getInputName(shadeUnit.id, 'motor_power')"
          v-bind:id="getInputName(shadeUnit.id, 'motor_power')"
          v-model="shadeUnit.motor_power"
          :disabled="disabled == true || isMotorized == false || isExteriorShade">
            <option :value="null">{{ defaultSelectOptionText }}</option>
            <option v-for="motorPower in shadeUnit.motor_power_options" :value="motorPower.value">{{ motorPower.label }}</option>
        </select>

        <span class="invalid-feedback" role="alert" v-if="errors['motor_power']">
          {{ errors['motor_power'][0] }}
        </span>
      </div>

      <div class="form-group col-sm-12 col-md mb-4">
        <label for="getInputName(shadeUnit.id, 'motor_sound')">
          {{ motorSoundLabel }}
        </label>

        <input type="hidden" v-bind:name="getInputName(shadeUnit.id, 'motor_sound')" v-model="shadeUnit.motor_sound">
        <select
          class="form-control"
          v-bind:class="errors['motor_sound'] ? 'is-invalid' : ''"
          v-bind:name="getInputName(shadeUnit.id, 'motor_sound')"
          v-bind:id="getInputName(shadeUnit.id, 'motor_sound')"
          v-model="shadeUnit.motor_sound"
          :disabled="disabled == true || isMotorized == false">
            <option :value="null">{{ defaultSelectOptionText }}</option>
            <option v-for="motorSound in shadeUnit.motor_sound_options" :value="motorSound.value">{{ motorSound.label }}</option>
        </select>

        <span class="invalid-feedback" role="alert" v-if="errors['motor_sound']">
          {{ errors['motor_sound'][0] }}
        </span>
      </div>
    </div>
  </div>
</template>
