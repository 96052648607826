<script>
  import TrimCutLineItem from './TrimCutLineItem';

  export default {
    props: [
      'masterTrimCutLineItems',
      'masterSelectOptions'
    ],

    components: {
      TrimCutLineItem
    },

    data() {
      return {
        //trimCutLineItemsList: [],
        errors: [],
      }
    },

    created() {

    },

    mounted() {
      //this.trimCutLineItemsList = this.$parent.trimCutLineItemsList;

      /*
        console.log('TrimCutLineItems.vue (PLURAL) Mounted');
        console.log('this.masterTrimCutLineItems');
        console.log(this.masterTrimCutLineItems);
        console.log('this.trimCutLineItemsList');
        console.log(this.trimCutLineItemsList);
        console.log('');
      */
    },

    computed: {
      trimCutLineItemsList() {
        return this.$parent.trimCutLineItemsList;
      }
    },

    methods: {
      addRow(event) {
        event.preventDefault();
        this.$parent.addRow(event);
      },

      deleteRecord(record) {
        let i = this.trimCutLineItemsList.map(trimCut => trimCut.id).indexOf(record.id);
        this.trimCutLineItemsList.splice(i, 1) // remove it from array
      },

      setErrors(errors) {
        this.errors = errors;
      }
    }
  };
</script>

<template>
  <tbody>
    <template v-if="trimCutLineItemsList.length <= 0">
      <tr>
        <td colspan="10" class="colored-bg text-center">No Trim Cuts Specified. <a @click="addRow($event)" href="#">Add a record</a> to get started.</td>
      </tr>
    </template>

    <template v-else>
      <template v-for="(trimCutLineItem, index) in trimCutLineItemsList">
        <trim-cut-line-item
          :master-trim-cut-line-item="trimCutLineItem"
          :master-select-options="masterSelectOptions"
          :key="trimCutLineItem.id"
          :index="index">
        </trim-cut-line-item>
      </template>

      <tr>
        <td colspan="10" class="colored-bg text-center">
          <a @click="addRow($event)" href="#">Add Row</a>
        </td>
      </tr>
    </template>
  </tbody>
</template>
