<script>
  import { mapState, mapGetters } from 'vuex';
  import ShadeInstallationTableGroup from './ShadeInstallationTableGroup';

  export default {
    props: [],

    components: {
      ShadeInstallationTableGroup,
    },

    mounted() {},

    computed: {
      orderProposal() {
        return this.$store.getters['orderProposal/currentOrderProposal'];
      },

      ...mapState('shadeGroups', ['byId']),
      ...mapGetters("shadeGroups", { shadeGroups: "list" }),

      errors() {
        return [];
      },
    },

    methods: {

    }
  };
</script>

<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col"><input type="checkbox" onclick="selectInstallation(this)"></th>
          <th scope="col">Item</th>
          <th scope="col">Quantity</th>
          <th scope="col">Product</th>
          <th scope="col" class="text-nowrap">Supply Only</th>
          <th scope="col" class="text-nowrap">After Hours</th>
          <th scope="col">Shade Install Unit Time (Hr)</th>
          <th scope="col">Shade Install Unit Labor ($/Hr)</th>
          <th scope="col">Trim Install Unit Time (Hr)</th>
          <th scope="col">Trim Install Unit Labor ($/Hr)</th>
          <th scope="col">Side Channel Install Unit Time (Hr)</th>
          <th scope="col">Side Channel Install Unit Labor ($/Hr)</th>
          <th scope="col">Extra Labor Unit Time (Min)</th>
          <th scope="col">Extra Labor ($/Hr)</th>
          <th scope="col">Install Unit Time (Hr)</th>
          <th scope="col">Install Unit Labor ($)</th>
          <th scope="col">Total Install Time (Hr)</th>
          <th scope="col">Total Install Labor ($)</th>
        </tr>
      </thead>

      <template v-if="shadeGroups.length <= 0">
        <tbody>
          <tr>
            <td colspan="18" class="colored-bg text-center">No Shade Line Items</td>
          </tr>
        </tbody>
      </template>

      <template v-else>
        <template v-for="shadeGroup in shadeGroups">
          <shade-installation-table-group :shade-group-id="shadeGroup.id">
          </shade-installation-table-group>
        </template>

        <tbody>
          <tr class="colored-bg">
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>{{ orderProposal.shade_quantity }}</td>
            <td colspan="13">&nbsp;</td>
            <td>{{ orderProposal.install_labor_hours }}</td>
            <td>{{ orderProposal.install_labor_cost_formatted }}</td>
          </tr>
        </tbody>
      </template>
    </table>
  </div>
</template>
