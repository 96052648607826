var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.parentRecord[_vm.parentRecordFieldName],
        expression: "parentRecord[parentRecordFieldName]",
      },
      { name: "convert-measurement", rawName: "v-convert-measurement" },
    ],
    staticClass: "form-control",
    class: [
      _vm.inputClass != "" ? _vm.inputClass : "",
      _vm.hasError ? "is-invalid" : "",
    ],
    attrs: {
      type: "text",
      placeholder: _vm.placeholder,
      id: _vm.inputId,
      name: _vm.inputName,
      disabled: _vm.isDisabled,
    },
    domProps: { value: _vm.parentRecord[_vm.parentRecordFieldName] },
    on: {
      focus: _vm.handleInputState,
      blur: _vm.handleInputState,
      input: [
        function ($event) {
          if ($event.target.composing) return
          _vm.$set(
            _vm.parentRecord,
            _vm.parentRecordFieldName,
            $event.target.value
          )
        },
        _vm.filterInput,
      ],
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }