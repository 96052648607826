var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-responsive estimate-widget project-expenses" },
    [
      _c("table", { staticClass: "table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm.proposalProjectExpenses.length <= 0
              ? [_vm._m(1)]
              : _vm._l(
                  _vm.proposalProjectExpenses,
                  function (projectExpense, index) {
                    return [
                      _c("proposal-project-expense", {
                        attrs: {
                          "project-expense": projectExpense,
                          "project-expense-id": projectExpense.id,
                          "can-edit": _vm.canEdit,
                        },
                      }),
                    ]
                  }
                ),
            _vm._v(" "),
            _vm.proposalProjectExpenses.length > 0
              ? [
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [_vm._v("Subtotal")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.orderProposal.project_expense_subtotal_formatted
                        )
                      ),
                    ]),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Description")]),
        _vm._v(" "),
        _c("th", [_vm._v("%")]),
        _vm._v(" "),
        _c("th", [_vm._v("Cost ($)")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "td",
        { staticClass: "colored-bg text-center", attrs: { colspan: "3" } },
        [_vm._v("No Project Expenses")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }