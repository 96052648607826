var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      directives: [
        {
          name: "proposal-field-directive",
          rawName: "v-proposal-field-directive",
        },
      ],
      staticClass: "require-all",
    },
    [
      _c("td", [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currentCoordinationLabor.gbl_coord_labor_expense_id,
                expression:
                  "currentCoordinationLabor.gbl_coord_labor_expense_id",
              },
            ],
            staticClass: "form-control min-width-field",
            class: _vm.errors["gbl_coord_labor_expense_id"] ? "is-invalid" : "",
            attrs: {
              name: _vm.getInputName(
                _vm.currentCoordinationLabor.id,
                "gbl_coord_labor_expense_id"
              ),
              id: _vm.getInputId(
                _vm.currentCoordinationLabor.id,
                "gbl_coord_labor_expense_id"
              ),
            },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.currentCoordinationLabor,
                  "gbl_coord_labor_expense_id",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { domProps: { value: null } }, [
              _vm._v("--Choose One--"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.coordinationLaborSelectOptions, function (laborOption) {
              return [
                laborOption.isSelected === false ||
                laborOption.id ==
                  _vm.currentCoordinationLabor.gbl_coord_labor_expense_id
                  ? _c("option", { domProps: { value: laborOption.id } }, [
                      _vm._v(_vm._s(laborOption.position)),
                    ])
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("td", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentCoordinationLabor.hours,
              expression: "currentCoordinationLabor.hours",
            },
          ],
          staticClass: "form-control min-width-field",
          class: _vm.errors["hours"] ? "is-invalid" : "",
          attrs: {
            type: "text",
            placeholder: "1",
            name: _vm.getInputName(_vm.currentCoordinationLabor.id, "hours"),
            id: _vm.getInputId(_vm.currentCoordinationLabor.id, "hours"),
          },
          domProps: { value: _vm.currentCoordinationLabor.hours },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.currentCoordinationLabor,
                "hours",
                $event.target.value
              )
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "td",
        [
          _vm.currentCoordinationLabor.isNew == false
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.currentCoordinationLabor.rate_formatted) +
                    "\n    "
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "td",
        [
          _vm.currentCoordinationLabor.isNew == false
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.currentCoordinationLabor.total_labor_cost_formatted
                    ) +
                    "\n    "
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("td", [
        _c("a", {
          staticClass: "btn icon-only btn-delete",
          class: _vm.orderProposal.is_locked_for_edits ? "disabled" : "",
          attrs: { href: "#", title: "Delete" },
          on: {
            click: function ($event) {
              return _vm.deleteProposalCoordinationLabor($event)
            },
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }