<script>
  import ShadeGroups from './ShadeGroups';
  import Modal from './Modal'; /* Added By Mansih OST */

  export default {
    props: [],

    components: {
      ShadeGroups, /*(comma) Added By Mansih OST */
	  Modal /* Added By Mansih OST */
    },
	/* Start Here Added By Mansih OST */
	data() {
      return {
        isModalVisible: false,
      };
    },
	 /* End Here Added By Mansih OST */
    computed: {
      orderProposal() {
        return this.$store.getters['orderProposal/currentOrderProposal'];
      },
    },

    mounted() {
      /*
        console.log('TakeoffTab.vue mounted');
        console.log('');
      */
    },

    methods: {
      addShadeGroup() {
        this.$eventHub.$emit('minimizeOpenShades');
        this.$store.dispatch('shadeGroups/addNewShadeGroup');
      },
	  /* Start Here Added By Mansih OST */
	  showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      },
	  /* End Here Added By Mansih OST */
    },
  }
</script>
