<script>
  import ProductModelLabors from './ProductModelLabors';

  export default {
    props: [
      'productModel',
      'productModelLabors',
      'selectOptions'
    ],

    components: {
      ProductModelLabors
    },

    data() {
      return {
        model: {
          name: null,
          model_number: null,
          size_in: null,
          type: null,
          shade_type: null,
          drive_mechanism: null,
          dual_shade: null,
          guided_cable: null,
          bottom_up: null,
          battery_powered: null,
          skylight: null
        },
        submitted: false,
        errors: []
      }
    },

    mounted() {
      this.model = { ...this.model, ...this.productModel };
    },

    methods: {
      getInputName(id, field) {
        return 'product_model[' + field +']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      submitForm(event) {
        event.preventDefault();
        this.submitted = true;

        var parentForm = this.$el,
            serializedForm = $(parentForm).serialize()

        $('body').css('cursor', 'progress');

        axios.post(event.target.action, serializedForm)
        .then((response) => {
          this.submitted = false;
          $('body').css('cursor', 'default');
          flash(response.data.status_type, response.data.status);
          if( response.data.redirectTo != '' ) {
            window.location.href = response.data.redirectTo;
          }
        })
        .catch(error => {
          this.setErrors(error.response.data.errors, parentForm);
          this.submitted = false;
          $('body').css('cursor', 'default');
          flash('alert-danger', 'Whoops! A validation error was encountered. Please correct the highlighted fields above.');
        });
      },

      setErrors(errors, form) {
        if( Object.entries(errors).length ) {

          if( typeof errors.product_model != 'undefined' ) {
            this.errors = errors.product_model;
          } else {
            this.errors = [];
          }

          if( typeof errors.product_model_parts != 'undefined' ) {
            this.$refs.productModelParts.setErrors(errors.product_model_parts);
          } else {
            this.$refs.productModelParts.setErrors({});
          }

          if( typeof errors.product_model_labors != 'undefined' ) {
            this.$refs.productModelLabors.setErrors(errors.product_model_labors);
          } else {
            this.$refs.productModelLabors.setErrors({});
          }
        }

        //TODO: Need to set the focus automatically. This is temporary - not going to work long term..
        setTimeout( function() {
          $(form).find('.is-invalid:first').focus();
        }, 100);

      }
    }
  }
</script>
