var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-link", {
    directives: [
      {
        name: "b-popover",
        rawName: "v-b-popover.hover.click",
        value: _vm.popoverConfig,
        expression: "popoverConfig",
        modifiers: { hover: true, click: true },
      },
    ],
    class: _vm.addLinkClass,
    attrs: { placement: _vm.placement },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }