<script>
  import draggable from 'vuedraggable';
  import Manufacturer from './Manufacturer';

  export default {
    props: [
      'parentManufacturers',
      'selectOptions'
    ],

    components: {
      Manufacturer
    },

    data() {
      return {
        fabricManufacturersList: [],
        partManufacturersList: [],
        fabricCategoryHeading: 'Fabrics',
        fabricCategoryHtmlId: 'fabric',
        partCategoryHeading: 'Parts',
        partCategoryHtmlId: 'parts',
        errors: []
      }
    },

    mounted() {
      this.fabricManufacturersList = this.parentManufacturers['Fabric'];
      this.partManufacturersList = this.parentManufacturers['Parts'];
    },

    computed: {
      endpointAddRow() {
        return '/admin/settings/manufacturers/add-row';
      },

      endpointSave() {
        return '/admin/settings/manufacturers';
      }
    },

    methods: {
      addManufacturer(event, type = 'Part') {
        event.preventDefault();
        let submitData = {
          category: type
        };

        axios.post(this.endpointAddRow, submitData)
        .then((response) => {
          let newManufacturer = response.data.manufacturer;
            newManufacturer.isNew = true;

          if( type == 'Fabric' ) {
            this.fabricManufacturersList.push( newManufacturer );
          } else {
            this.partManufacturersList.push( newManufacturer );
          }
        })
        .catch(error => {
          //flash(error.response.data.status_type, error.response.data.status);
        });

        return false;
      },

      saveManufacturers(event) {
        event.preventDefault();
        let manufacturer = {};
        let fabMfgList = this.$refs.fabricManufacturersList;
        let partMfgList = this.$refs.partManufacturersList;

        //Add Fabric Manufacturers
        fabMfgList.forEach(function(obj) {
          manufacturer[obj.manufacturer.id] = obj.manufacturer;
        });

        //Add Part Manufacturers
        partMfgList.forEach(function(obj) {
          manufacturer[obj.manufacturer.id] = obj.manufacturer;
        });

        const submitData = {
          _method: 'PUT',
          manufacturer: manufacturer
        };

        axios.post(this.endpointSave, submitData)
        .then((response) => {
          if( response.data.redirectTo != '' ) {
            window.location.href = response.data.redirectTo;
          } else {
            this.fabricManufacturersList = this.parentManufacturers['Fabric'];
            this.partManufacturersList = this.parentManufacturers['Parts'];
            flash(response.data.status_type, response.data.status);
          }
        })
        .catch(error => {
          this.setErrors(error.response.data.errors);
          flash(error.response.data.status_type, error.response.data.status);
        });
      },

      deleteManufacturer(manufacturer) {
        if( manufacturer.type == 'Fabric' ) {
          let i = this.fabricManufacturersList.map(mfgRec => mfgRec.id).indexOf(manufacturer.id);
          this.fabricManufacturersList.splice(i, 1) // remove it from array

        } else {
          let i = this.partManufacturersList.map(mfgRec => mfgRec.id).indexOf(manufacturer.id);
          this.partManufacturersList.splice(i, 1) // remove it from array
        }
      },

      setErrors(errors) {
        this.errors = errors;
      },
    }
  };
</script>

<template>
  <div class="field-section">
    <div class="form-row heading w-bdr-btm manufacturer-heading">
      <div class="form-group col-sm-12">
        <h2>{{ partCategoryHeading }}</h2>
      </div>
    </div>
    <div class="manufacturers-container"
      :id="partCategoryHtmlId">

      <template v-for="(partManufacturer, index) in partManufacturersList">
        <manufacturer
          :parent-manufacturer="partManufacturer"
          :record-index="index"
          ref="partManufacturersList"
          :key="partManufacturer.id">
        </manufacturer>
      </template>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <button
          type="button"
          class="btn btn-secondary"
          @click="addManufacturer($event, 'Parts')">
          Add {{ partCategoryHeading }} Manufacturer
        </button>
      </div>
    </div>

    <div class="form-row heading w-bdr-btm manufacturer-heading">
      <div class="form-group col-sm-12">
        <h2>{{ fabricCategoryHeading }}</h2>
      </div>
    </div>
    <div class="manufacturers-container"
      :id="fabricCategoryHtmlId">

      <template v-for="(fabricManufacturer, index) in fabricManufacturersList">
        <manufacturer
          :parent-manufacturer="fabricManufacturer"
          :record-index="index"
          ref="fabricManufacturersList"
          :key="fabricManufacturer.id">
        </manufacturer>
      </template>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <button
          type="button"
          class="btn btn-secondary"
          @click="addManufacturer($event, 'Fabric')">
          Add {{ fabricCategoryHeading }} Manufacturer
        </button>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-12">
        <button
          type="submit"
          class="btn btn-primary"
          @click="saveManufacturers($event)">Submit</button>
      </div>
    </div>
  </div>
</template>
