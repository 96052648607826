//import { TOGGLE_SIDEBAR } from '../mutation-types'

import { mapGetters, mapActions } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import {
  ENDPOINT_PROPOSAL_COORDINATION_LABOR_NEW,
  ENDPOINT_PROPOSAL_COORDINATION_LABOR_DESTROY
} from '../../endpoints'


export default {
  namespaced: true,


  //state
  state: {
    proposalCoordinationLabors: [],
    errors: []
  },


  //getters
  getters: {
    proposalCoordinationLabors: state => state.proposalCoordinationLabors,
    //currentOrderProposal: state => state.orderProposals[0],
    //errors: state => state.errors,

    // vuex-map-fields plugin
    getField,
  },


  //actions
  actions: {
    applyProposalCoordinationLabor({commit, dispatch}, coordinationLabors) {
      if( coordinationLabors ) {
        coordinationLabors.forEach(function(labor, index) {
          if( !labor.hasOwnProperty('isNew') ) {
            labor.isNew = false;
          }
        });

        commit('SET_PROPOSAL_COORDINATION_LABORS', coordinationLabors);
      }
    },

    applyNewProposalCoordinationLabor({commit, dispatch}, coordinationLabor) {
      coordinationLabor.gbl_coord_labor_expense_id = null;
      commit('ADD_NEW_PROPOSAL_COORDINATION_LABOR', coordinationLabor);
    },


    //
    // Create a new coordination labor record on the fly
    //  This creates an empty "shell" that is used to generate the input fields within the vue component
    //
    addNewCoordinationLabor({dispatch, rootState, rootGetters}) {
      var currentOrderProposal = rootGetters['orderProposal/currentOrderProposal'];

      axios.post(ENDPOINT_PROPOSAL_COORDINATION_LABOR_NEW + currentOrderProposal.id)
      .then((response) => {
        response.data.data.proposalCoordinationLabor.isNew = true;

        dispatch('applyNewProposalCoordinationLabor', response.data.data.proposalCoordinationLabor);
        flash(response.data.status_type, response.data.status);
      })
      .catch(error => {
        flash(error.response.data.status_type, error.response.data.status);
      });
    },


    //
    // Delete coordination labor record from proposal
    //
    deleteCoordinationLabor({commit, state, dispatch}, id) {
      var targetUnitIndex = state.proposalCoordinationLabors.findIndex(coordinationLabor => coordinationLabor.id == id),
          targetUnit = state.proposalCoordinationLabors[targetUnitIndex];

      if( targetUnit.isNew === true ) {
        //Just remove the record from state
        commit('DELETE_NEW_COORDINATION_LABOR', targetUnit.id);

      } else {
        axios.delete(ENDPOINT_PROPOSAL_COORDINATION_LABOR_DESTROY + targetUnit.id)
        .then((response) => {
          dispatch('orderProposal/applyOrderProposal', response.data.data.orderProposal, {root: true});

          flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status);
        });

      }
    },
  },


  //mutations
  mutations: {
    SET_PROPOSAL_COORDINATION_LABORS(state, coordinationLabors) {
      state.proposalCoordinationLabors = coordinationLabors;
    },

    ADD_NEW_PROPOSAL_COORDINATION_LABOR(state, coordinationLabor) {
      state.proposalCoordinationLabors.push(coordinationLabor);
    },

    DELETE_NEW_COORDINATION_LABOR(state, coordinationLaborId) {
      var index = state.proposalCoordinationLabors.findIndex(coordinationLabor => coordinationLabor.id == coordinationLaborId);
      state.proposalCoordinationLabors.splice(index, 1);
    },

    // vuex-map-fields plugin
    updateField,
  }
}
