<script>
  import FabricAttributes from './FabricAttributes';
  import ModalConfirm from '../../../notifications/ModalConfirm.vue';

  export default {
    props: [
      'parentFabricOpennessFactor',
    ],

    components: {
      FabricAttributes,
      ModalConfirm
    },

    data() {
      return {
        opennessFactor: {
          name: null,
          part_number: null,
          percentage: null,
          percentage_formatted: null,
          notes: null,
          isNew: false
        },
        fabricAttributes: []
        //errors: []
      }
    },

    mounted() {
      this.opennessFactor = { ...this.opennessFactor, ...this.parentFabricOpennessFactor };
        this.opennessFactor.percentage = this.opennessFactor.percentage_formatted;
      this.fabricOpennessFactorList = this.$parent.fabricOpennessFactorList;

      this.fabricAttributes = (this.opennessFactor.hasOwnProperty('fabric_attributes') ) ? this.opennessFactor.fabric_attributes : [];
    },

    computed: {
      endpointDestroy() {
        return '/admin/settings/fabric-openness/' + this.opennessFactor.id;
      },

      isNew() {
        //If the parent fabric record is new, this must be true
        if( this.$parent.$parent.isNew == true ) {
          return true;
        }
        return this.opennessFactor.isNew;
      },

      isDisabled() {
        return false;
      },

      errors() {
        if( this.$parent.errors.hasOwnProperty(this.opennessFactor.id) ) {
          return this.$parent.errors[this.opennessFactor.id];
        } else {
          return [];
        }
      }
    },

    methods: {
      getInputName(id, field) {
        return 'fabric_openness_factors['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      destroyClick(event) {
        event.preventDefault();
        if( this.isNew == true ) {
          this.destroy();
        } else {
          this.$bvModal.show('modal-' + this.opennessFactor.id);
        }
      },

      //Modal - Handle modal function call
      handleFunctionCall(functionName) {
        this[functionName]();
      },

      //Modal - Cancel out of an open/active modal without completeing action
      cancelModalAction() {
        return false;
      },

      destroy() {
        if( this.isNew === true ) {
          this.$parent.deleteFabricOpennessFactor( this.opennessFactor );
          flash('alert-success', 'Openness factor deleted');

        } else {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            this.$parent.deleteFabricOpennessFactor( this.opennessFactor );
            flash(response.data.status_type, response.data.status);
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      },

      setErrors(errors) {
/*
        if( errors.hasOwnProperty('fabric_openness_factors') && errors.fabric_openness_factors.hasOwnProperty(this.opennessFactor.id) ) {
          this.errors = errors.fabric_openness_factors[this.opennessFactor.id];
        } else {
          this.errors = errors;
        }
*/
      }
    }
  };
</script>
