<script>
  import { mapMultiRowFields } from 'vuex-map-fields';
  import { proposalMixin } from '../../mixins/proposalMixin';

  import ProposalCoordinationLabor from './ProposalCoordinationLabor';

  export default {
    props: [
      'currentOrderProposal',
    ],

    mixins: [proposalMixin],

    components: {
      ProposalCoordinationLabor,
    },

    mounted() {
      /*
        console.log('ProposalCoordinationLabors.vue (PLURAL) - Mounted');
        console.log(this.orderProposal);
        console.log('');
      */
    },

    computed: {
      ...mapMultiRowFields('proposalCoordinationLabor', ['proposalCoordinationLabors']),

      orderProposal() {
        return this.currentOrderProposal;
      },

      errors() {
        return this.$store.state.proposalCoordinationLabor.errors;
      },

      laborIdList() {
        return this.proposalCoordinationLabors.map(labor => {
          return labor.gbl_coord_labor_expense_id;
        });
      },

      coordinationLaborSelectOptions() {
        var that = this,
            options = this.$store.state.orderProposal.selectOptions.coordinationLaborExpenseOptions;

        options.map(function(laborOption) {
          if( that.laborIdList.indexOf(laborOption.id) != -1 ) {
            laborOption.isSelected = true;
          } else {
            laborOption.isSelected = false;
          }
        });

        return options;
      },

      hasAvailableLaborOptions() {
        return (this.laborIdList.length === this.$store.state.orderProposal.selectOptions.coordinationLaborExpenseOptions.length) ? false : true;
      }
    },

    methods: {
      addProposalCoordinationLabor(event) {
        event.preventDefault();
        if( this.hasAvailableLaborOptions === true ) {
          this.$store.dispatch('proposalCoordinationLabor/addNewCoordinationLabor');
        }
      }
    }
  };
</script>

<template>
  <div class="form-row estimate-widget coord-labor-expenses">
    <div class="form-group col-sm-12 mb-0">
      <h2>Coordination Labor Expense</h2>
    </div>

    <div class="form-group col-sm-12 table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Position</th>
            <th>HR</th>
            <th>$/HR</th>
            <th>Total</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <template v-if="proposalCoordinationLabors.length > 0">
            <template v-for="(coordinationLabor, index) in proposalCoordinationLabors">
              <proposal-coordination-labor
                :coordination-labor="coordinationLabor"
                :coordination-labor-id="coordinationLabor.id">
              </proposal-coordination-labor>
            </template>

            <tr>
              <td colspan="5" class="colored-bg text-center">
                <a
                  href="#"
                  v-bind:class="orderProposal.userCanEdit == false ? 'disabled' : ''"
                  @click="addProposalCoordinationLabor($event)">
                  Add Coordination Labor
                </a>
              </td>
            </tr>
          </template>

          <template v-else>
            <tr>
              <td colspan="5" class="colored-bg text-center">
                <a
                  href="#"
                  v-bind:class="orderProposal.userCanEdit == false ? 'disabled' : ''"
                  @click="addProposalCoordinationLabor($event)">
                    Add Coordination Labor
                </a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
