var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "field-section" }, [
    _c(
      "div",
      { staticClass: "form-row heading w-bdr-btm manufacturer-heading" },
      [
        _c("div", { staticClass: "form-group col-sm-12" }, [
          _c("h2", [_vm._v(_vm._s(_vm.partCategoryHeading))]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "manufacturers-container",
        attrs: { id: _vm.partCategoryHtmlId },
      },
      [
        _vm._l(_vm.partManufacturersList, function (partManufacturer, index) {
          return [
            _c("manufacturer", {
              key: partManufacturer.id,
              ref: "partManufacturersList",
              refInFor: true,
              attrs: {
                "parent-manufacturer": partManufacturer,
                "record-index": index,
              },
            }),
          ]
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-sm-4" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.addManufacturer($event, "Parts")
              },
            },
          },
          [
            _vm._v(
              "\n        Add " +
                _vm._s(_vm.partCategoryHeading) +
                " Manufacturer\n      "
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-row heading w-bdr-btm manufacturer-heading" },
      [
        _c("div", { staticClass: "form-group col-sm-12" }, [
          _c("h2", [_vm._v(_vm._s(_vm.fabricCategoryHeading))]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "manufacturers-container",
        attrs: { id: _vm.fabricCategoryHtmlId },
      },
      [
        _vm._l(
          _vm.fabricManufacturersList,
          function (fabricManufacturer, index) {
            return [
              _c("manufacturer", {
                key: fabricManufacturer.id,
                ref: "fabricManufacturersList",
                refInFor: true,
                attrs: {
                  "parent-manufacturer": fabricManufacturer,
                  "record-index": index,
                },
              }),
            ]
          }
        ),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-sm-4" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.addManufacturer($event, "Fabric")
              },
            },
          },
          [
            _vm._v(
              "\n        Add " +
                _vm._s(_vm.fabricCategoryHeading) +
                " Manufacturer\n      "
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-sm-12" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                return _vm.saveManufacturers($event)
              },
            },
          },
          [_vm._v("Submit")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }