var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-row estimate-widget markup-calculation" },
    [
      _vm.showHeadingRow
        ? _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
            _c("h2", [_vm._v(_vm._s(_vm.headingRowHeadingText))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-sm-12 table-responsive" }, [
        _c("table", { staticClass: "table" }, [
          _c("tbody", [
            _c("tr", [
              _c("td", { staticClass: "text-nowrap" }, [
                _vm._v("Overhead Rate"),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.orderProposal.company_overhead_pct_formatted)
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "text-nowrap" }, [
                _vm._v("Profit Target"),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "min-width-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.orderProposal.desired_profit_pct,
                      expression: "orderProposal.desired_profit_pct",
                    },
                    {
                      name: "proposal-field-directive",
                      rawName: "v-proposal-field-directive",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors["desired_profit_pct"] ? "is-invalid" : "",
                  attrs: {
                    type: "text",
                    placeholder: "1",
                    name: _vm.getInputName("desired_profit_pct"),
                    id: _vm.getInputId("desired_profit_pct"),
                  },
                  domProps: { value: _vm.orderProposal.desired_profit_pct },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.orderProposal,
                        "desired_profit_pct",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "text-nowrap" }, [_vm._v("Total")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.orderProposal.total_overhead_profit_rate_formatted)
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "text-nowrap" }, [_vm._v("Markup")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.markup_rate_formatted)),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }