try {
  /**
   * jQuery & Popper
  */
  window.$ = window.jQuery = require('jquery');
  window.Popper = require('popper.js').default;

  /**
   * Axios
  */
  window.axios = require('axios');
  window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  axios.interceptors.request.use(
    function (config) {
      document.getElementById("app").classList.add('wait');

      //modify config and return
      return config;

    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function(response) {
      //clearInterval(window.loadingScreen);
      document.getElementById("app").classList.remove('wait');

      return response;
    },

    function(error) {
      clearInterval(window.loadingScreen);
      document.getElementById("app").classList.remove('wait');

      if( error.response.data.invalid_session !== undefined && error.response.data.invalid_session == true ) {
        window.location.href = error.response.data.redirectTo;
        flash(error.response.data.status_type, error.response.data.status);
      }

      return Promise.reject(error);
    }
  );

  /**
   * Lodash
   */
  var _ = require('lodash');

  /**
   * Imports
  */
  require('jquery-ui/ui/widgets/accordion.js');
  require('inputmask/dist/jquery.inputmask.js');
  const { v4: uuidv4 } = require('uuid');

  /**
   * Bootstrap
  */
  require('bootstrap');


  /**
   * Vue
   */
  window.Vue = require('vue');


  /**
   * Event Listeners
   */
   window.events = new Vue();

   window.flash = function(alert_type = 'alert-warning', alert_message, disable_message_autohide = false) {
    window.events.$emit('flash', { alert_type, alert_message, disable_message_autohide });

   }; //flash('alert-danger', 'example flash message...', false)

} catch (e) {}
