<template>
  <div>
    <b-modal
      :id="modalIdAttr"
      :size="modalSizeAttr"
      :title="modalTitleText"
      :ok-title="confirmButtonText"
      :cancel-title="dismissButtonText"
      :no-close-on-backdrop="true"
      :hide-header-close="hideHeaderCloseButton"
      @ok="emitConfirm"
      @cancel="emitCancel">

      <p class="my-4" v-html="modalBodyText"></p>

      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="secondary"
            v-html="dismissButtonText"
            @click="cancel()"
            v-if="hasDismissButton">
          </b-button>

          <b-button
            variant="primary"
            v-html="confirmButtonText"
            @click="ok()"
            v-if="hasConfirmButton">
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  export default {
    props: [
      'modalId',
      'modalIdAttrOverride',
      'modalSize',
      'modalTitle',
      'modalBody',
      'dismissButton',
      'confirmButton',
      'hideHeaderClose',
    ],

    data() {
      return {
        showModal: false
      }
    },

    mounted() {
      //
    },

    computed: {
      modalIdAttr() {
        if( this.modalIdAttrOverride ) {
          return this.modalIdAttrOverride + this.modalId;
        }
        return 'modal-' + this.modalId;
      },

      modalSizeAttr() {
        if( this.modalSize == null ) {
          return;
        }

        return this.modalSize;
      },

      modalTitleText() {
        return ( this.modalTitle != null ) ? this.modalTitle : 'Modal Title Text';
      },

      modalBodyText() {
        return ( this.modalBody != null ) ? this.modalBody : 'Modal Body Text';
      },

      dismissButtonText() {
        return ( this.dismissButton != null ) ? this.dismissButton : 'Cancel';
      },

      confirmButtonText() {
        return ( this.confirmButton != null ) ? this.confirmButton : 'Approve';
      },

      hasDismissButton() {
        return ( this.dismissButton == null ) ? false : true;
      },

      hasConfirmButton() {
        return ( this.confirmButton == null ) ? false : true;
      },

      hideHeaderCloseButton() {
        return ( this.hideHeaderClose == true ) ? true : false;
      }
    },

    methods: {
      emitConfirm() {
        this.$emit('confirmed');
      },

      emitCancel() {
        this.$emit('cancel');
      },
    }
  }
</script>
