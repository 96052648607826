<script>
  import OrderTypeStatusNotifications from './OrderTypeStatusNotifications';

  export default {
    props: [
      'parentProjectOrderType',
      'parentProjectOrderTypeIndex',
      'projectOrderTypeStatusNotifications',
      'tabContainerId',
    ],

    components: {
      OrderTypeStatusNotifications,
    },

    data() {
      return {
        projectOrderType: {
          id: null,
          name: null,
          global_order_notifications: [],
        },

        orderTypeStatusNotifications: [],
        errors: []
      }
    },

    mounted() {
      this.projectOrderType = { ...this.projectOrderType, ...this.parentProjectOrderType };
      this.orderTypeStatusNotifications = this.parentProjectOrderType.global_order_notifications;
    },

    computed: {
      isDefaultActiveTab() {
        return ( this.parentProjectOrderTypeIndex == 0 ) ? true : false;
      },

      activeTabClass() {
        if( this.isDefaultActiveTab ) {
          return 'show active';
        }

        return;
      },

      tabContainerAriaLabelledby() {
        return this.tabContainerId + '-tab';
      },

      tabDescriptionText() {
        return `<p>The following statuses are available for <strong>${this.projectOrderType.name}</strong> orders. Order statuses can be configured with or without email notifications. For statuses that include email notifications, the message will be sent to the specified recipients once the status is marked as "complete" in the order processing section of the application.</p>`;
      },

      endpointUpdate() {
        return '/admin/settings/app-config/order-notifications/update/' + this.projectOrderType.id;
      }
    },

    methods: {
      saveNotifications(event) {
        event.preventDefault();

        const statusNotificationList = this.$refs.orderTypeStatusNotifications.$children[0].$children;
        const statusNotificationsObj = {};

        statusNotificationList.forEach(function(obj) {
          statusNotificationsObj[obj.statusNotification.id] = obj.statusNotification;
        });

        const submitData = {
          _method: 'PUT',
          global_order_notifications: statusNotificationsObj
        };

        axios.post(this.endpointUpdate, submitData)
        .then((response) => {
          this.orderTypeStatusNotifications = response.data.data.globalOrderNotifications;
          flash(response.data.status_type, response.data.status);

        })
        .catch(error => {
          this.setErrors(error.response.data.errors);
          flash(error.response.data.status_type, error.response.data.status);
        });
      },

      setErrors(errors) {
        this.errors = this.$refs.orderTypeStatusNotifications.setErrors(errors);
      }
    }
  };
</script>

<template>
  <div
    class="tab-pane fade"
    v-bind:class="activeTabClass"
    v-bind:id="tabContainerId"
    role="tabpanel"
    :aria-labelledby="tabContainerAriaLabelledby">

    <div class="row">
      <div class="group col-sm-12" v-html="tabDescriptionText"></div>
    </div>

    <template v-if="orderTypeStatusNotifications.length">
      <order-type-status-notifications
        :parent-project-order-type="projectOrderType"
        :parent-order-type-status-notifications="orderTypeStatusNotifications"
        ref="orderTypeStatusNotifications">
      </order-type-status-notifications>
    </template>

    <div class="form-row">
      <div class="form-group col-sm-12">
        <a class="btn btn-primary" @click="saveNotifications($event)">Submit</a>
      </div>
    </div>
  </div>
</template>
