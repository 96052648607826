<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'currentOrderProposal',
    ],

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('TravelExpense.vue - Mounted');
        console.log(this.orderProposal);
        console.log('');
      */
    },

    computed: {
      orderProposal() {
        return this.currentOrderProposal;
      },

      errors() {
        return [];
      },
    },

    methods: {
      getInputName(field) {
        return this.$parent.getOrderProposalInputName(field);
      },

      getInputId(field) {
        return this.$parent.getOrderProposalInputId(field);
      },
    }
  };
</script>

<template>
  <div class="form-row estimate-widget travel-expenses">
    <div class="form-group col-sm-12 mb-0">
      <h2>Travel Expense</h2>
    </div>

    <div class="form-group col-sm-12 table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>One-Way Miles</th>
            <th>Labor Time (Hours)</th>
            <th># Trips</th>
            <th>Extra<br>Trips</th>
            <th># Techs</th>
            <th>Total Labor</th>
            <th>Total Labor<br>Cost ($)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Install</td>
            <td>{{ orderProposal.distance_to_jobsite }}</td>
            <td>{{ orderProposal.travel_install_hours }}</td>
            <td>{{ orderProposal.travel_install_trips }}</td>
            <td>
              <input type="text"
                     class="form-control min-width-field"
                     v-bind:class="errors['install_extra_trips'] ? 'is-invalid' : ''"
                     placeholder="1"
                     v-bind:name="getInputName('install_extra_trips')"
                     v-bind:id="getInputId('install_extra_trips')"
                     v-model="orderProposal.install_extra_trips"
                     v-proposal-field-directive>
            </td>
            <td>
              <input type="text"
                     class="form-control min-width-field"
                     v-bind:class="errors['install_techs'] ? 'is-invalid' : ''"
                     placeholder="1"
                     v-bind:name="getInputName('install_techs')"
                     v-bind:id="getInputId('install_techs')"
                     v-model="orderProposal.install_techs"
                     v-proposal-field-directive>
            </td>
            <td>{{ orderProposal.travel_install_total_labor }}</td>
            <td>{{ orderProposal.travel_install_total_labor_cost_formatted }}</td>
          </tr>
          <tr>
            <td>Measure</td>
            <td>{{ orderProposal.distance_to_jobsite }}</td>
            <td>{{ orderProposal.travel_measure_hours }}</td>
            <td>{{ orderProposal.travel_measure_trips }}</td>
            <td>
              <input type="text"
                     class="form-control min-width-field"
                     v-bind:class="errors['field_measure_extra_trips'] ? 'is-invalid' : ''"
                     placeholder="1"
                     v-bind:name="getInputName('field_measure_extra_trips')"
                     v-bind:id="getInputId('field_measure_extra_trips')"
                     v-model="orderProposal.field_measure_extra_trips"
                     v-proposal-field-directive>
            </td>
            <td>
              <input type="text"
                     class="form-control min-width-field"
                     v-bind:class="errors['measure_techs'] ? 'is-invalid' : ''"
                     placeholder="1"
                     v-bind:name="getInputName('measure_techs')"
                     v-bind:id="getInputId('measure_techs')"
                     v-model="orderProposal.measure_techs"
                     v-proposal-field-directive>
            </td>
            <td>{{ orderProposal.travel_measure_total_labor }}</td>
            <td>{{ orderProposal.travel_measure_total_labor_cost_formatted }}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td></td>
            <td></td>
            <td>{{ orderProposal.travel_total_trips }}</td>
            <td>{{ orderProposal.travel_total_extra_trips }}</td>
            <td></td>
            <td>{{ orderProposal.travel_total_hours }}</td>
            <td>{{ orderProposal.travel_total_cost_formatted }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
