var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c("td", { staticClass: "text-nowrap" }, [
      _vm._v(_vm._s(_vm.currentProjectExpense.name)),
    ]),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "min-width-field" },
      [
        _vm.lockFields == false
          ? [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.currentProjectExpense.percent,
                      expression: "currentProjectExpense.percent",
                    },
                    {
                      name: "proposal-field-directive",
                      rawName: "v-proposal-field-directive",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors["percent"] ? "is-invalid" : "",
                  attrs: {
                    name: _vm.getInputName(
                      _vm.currentProjectExpense.id,
                      "percent"
                    ),
                    id: _vm.getInputId(_vm.currentProjectExpense.id, "percent"),
                  },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.currentProjectExpense,
                        "percent",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: null } }, [
                    _vm._v("--Choose One--"),
                  ]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.selectOptions.proposalProjectExpenseOptions,
                    function (option) {
                      return [
                        _c("option", { domProps: { value: option.value } }, [
                          _vm._v(_vm._s(option.label)),
                        ]),
                      ]
                    }
                  ),
                ],
                2
              ),
            ]
          : [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.currentProjectExpense.percent_formatted) +
                  "\n    "
              ),
            ],
      ],
      2
    ),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.calculatedProjectExpenseCost))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }