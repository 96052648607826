import { ENDPOINT_COPY_USER_CONFIGS_TO_ESTIMATE } from '../../endpoints'
import { mapGetters, mapActions } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'


export default {
  namespaced: true,


  //
  // State
  //
  state: {
    byId: {},
    initialStateById: {},
    errors: {}
  },


  //
  // Getters
  //
  getters: {
    //
    // Shade Unit Configs (list/multiple) Getters
    shadeUnitConfigs: state => state.byId,

    //
    // Find record by id
    find: state => id => state.byId[id],

    //
    // Check if this is a shade unit config for an estimate, or a user global shade unit config
    isUserGlobalConfig: (state, getters) => (id) => {
      let targetUnit = getters.find(id);
      return ( targetUnit.is_user_global_config == true ) ? true : false;
    },

    //
    // Check if shade is exterior shade
    isExteriorShade: (state, getters) => (id) => {
      let shadeUnit = getters.find(id),
          shadeType = ( shadeUnit.product_model_shade_type ) ? shadeUnit.product_model_shade_type.toLowerCase() : null;
      return ( shadeType == 'exterior' ) ? true : false;
    },

    //
    // Get list of selected estimator shade unit configs
    getSelectedEstimatorConfigs: (state, getters) => {
      let configList = getters.shadeUnitConfigs,
          selectedConfigs = Object.keys(configList)
                    .map(key => configList[key])
                    .filter(config => {
                      return config.is_selected == true;
                    });
      return selectedConfigs;
    },

    //
    // Get all errors
    errors: state => state.errors,

    //
    // Fabric summary text
    fabricSummaryText: (state, rootState, store, getters) => (id) => {
      let shadeUnit = getters['estimatorShadeUnitConfigs/find'](id),
          isExteriorShade = getters['estimatorShadeUnitConfigs/isExteriorShade'](id);

      if( isExteriorShade ) {
        var fabricOptions = store.estimationSelectOptions.primaryShadeSelectOptions.exteriorFabricOptions;
      } else {
        var fabricOptions = store.estimationSelectOptions.primaryShadeSelectOptions.interiorFabricOptions;
      }

      if( typeof shadeUnit === 'undefined' || !shadeUnit.fabric_attribute_id ) {
        return null;
      }

      var fabric = shadeUnit.fabric_attribute_options.find(fabColor => fabColor.id == shadeUnit.fabric_attribute_id);
      var fabricOpenness = shadeUnit.fabric_openness_factor_options.find(fabOpenness => fabOpenness.id == shadeUnit.fabric_openness_factor_id);
      var fabricMan = '';
      var fabricManufacturer = '';

      for(fabricManufacturer in fabricOptions) {
        fabricMan = fabricOptions[fabricManufacturer].find(fabMfr => fabMfr.id == shadeUnit.fabric_id);
        if( fabricMan ) {
          break;
        }
      }
      return fabricMan.name + ' ' + fabric.color + ' ' + fabricOpenness.name;
    },

    //
    // Dual Fabric summary text
    dualFabricSummaryText: (state, rootState, store, getters) => (id) => {
      let shadeUnit = getters['estimatorShadeUnitConfigs/find'](id),
          isExteriorShade = getters['estimatorShadeUnitConfigs/isExteriorShade'](id);

      if( isExteriorShade ) {
        var fabricOptions = store.estimationSelectOptions.primaryShadeSelectOptions.exteriorFabricOptions;
      } else {
        var fabricOptions = store.estimationSelectOptions.primaryShadeSelectOptions.interiorFabricOptions;
      }

      var fabric = shadeUnit.dual_fabric_attribute_options.find(fabColor => fabColor.id == shadeUnit.dual_fabric_attribute_id);
      var fabricOpenness = shadeUnit.dual_fabric_openness_factor_options.find(fabOpenness => fabOpenness.id == shadeUnit.dual_fabric_openness_factor_id);
      var fabricMan = '';
      var fabricManufacturer = '';

      for(fabricManufacturer in fabricOptions) {
        fabricMan = fabricOptions[fabricManufacturer].find(fabMfr => fabMfr.id == shadeUnit.dual_fabric_id);
        if( fabricMan ) {
          break;
        }
      }
      return fabricMan.name + ' ' + fabric.color + ' ' + fabricOpenness.name;
    },

    //
    // vuex-map-fields plugin
    getField,

  }, //end getters


  //
  // Actions
  //
  actions: {
    //
    // Initial load of estimator shade unit configs
    initEstimatorShadeUnitConfigs({commit, dispatch}, payload) {
      commit('CLEAR_ALL_SHADE_UNIT_CONFIGS');
      dispatch('setEstimatorShadeUnitConfigs', payload.estimatorShadeUnitConfigs);
    },

    //
    // Load the initial set of estimator shade unit config records into state
    setEstimatorShadeUnitConfigs({commit}, shadeUnitConfigs) {
      if( shadeUnitConfigs && shadeUnitConfigs.length ) {
        shadeUnitConfigs.forEach(function(shadeConfig, index) {
          shadeConfig.is_selected = false;
          shadeConfig.isMaximized = false;
          commit('SET_SHADE_UNIT_CONFIG', shadeConfig);
        });
      } else {
        commit('CLEAR_ALL_SHADE_UNIT_CONFIGS');
      }
    },

    //
    // Toggle maximized state for selected config unit
    toggleMaximizedState({commit}, id) {
      commit('TOGGLE_MAXIMIZED_STATE', id);
    },

    //
    // Save selected estimator configs to the parent estimate
    saveSelectedEstimatorConfigs({commit, getters, dispatch, rootGetters}, payload) {
      let endpointCopyUserConfigsToEstimate = ENDPOINT_COPY_USER_CONFIGS_TO_ESTIMATE + rootGetters['shadeOrder/shadeOrder'].id,
          selectedEstimatorConfigs = getters.getSelectedEstimatorConfigs.map(function(obj){
            return { id: obj.id };
          }),
          submitData = {
            estimator_shade_unit_configs: selectedEstimatorConfigs
          };

      //The AXIOS POST call
      return new Promise((resolve, reject) => {
        axios.post(endpointCopyUserConfigsToEstimate, submitData)
        .then((response) => {
          //dispatch the setFormErrors action
          dispatch('setFormErrors', {});

          //Add the new shade unit configs to the estimate...
          if( response.data.data.hasOwnProperty('shadeUnitConfigs') ) {
            dispatch('shadeUnitConfigs/applyShadeUnitConfigs', response.data.data.shadeUnitConfigs, {root: true});
          }

          //Update the estimator shade unit config list - for good measure.
          if( response.data.data.hasOwnProperty('estimatorShadeUnitConfigs') ) {
            dispatch('setEstimatorShadeUnitConfigs', response.data.data.estimatorShadeUnitConfigs);
          }

          //Resolve the promise
          resolve(response);
        })
        .catch(error => {
          //dispatch the setFormErrors action
          dispatch('setFormErrors', error.response.data.errors);

          //Show the error
          flash(error.response.data.status_type, error.response.data.status);

          //Reject the promise
          reject(error);
        });
      });

    },

    //
    // Set form validation errors
    setFormErrors({commit, state, getters}, payload) {
      //Set shade unit config form fields...
      if( payload.hasOwnProperty('estimator_shade_unit_configs') ) {
        commit('SET_ESTIMATOR_SHADE_UNIT_CONFIG_ERRORS', payload.estimator_shade_unit_configs);
      } else {
        commit('SET_ESTIMATOR_SHADE_UNIT_CONFIG_ERRORS', {});
      }
    }

  }, //end actions


  //
  // Mutations
  //
  mutations: {
    SET_SHADE_UNIT_CONFIG(state, shadeUnitConfig) {
      Vue.set(state.byId, shadeUnitConfig.id, shadeUnitConfig);
      Vue.set(state.initialStateById, shadeUnitConfig.id, _.cloneDeep(shadeUnitConfig));
    },

    CLEAR_ALL_SHADE_UNIT_CONFIGS(state) {
      Vue.set(state, "byId", {});
      Vue.set(state, "initialStateById", {});
      Vue.set(state, "errors", {});
    },

    TOGGLE_MAXIMIZED_STATE(state, id) {
      let targetUnit = state.byId[id];
      targetUnit.isMaximized = ( targetUnit.isMaximized == true ) ? false : true;
    },

    SET_ESTIMATOR_SHADE_UNIT_CONFIG_ERRORS(state, errors) {
      state.errors = errors;
    },

    //
    // vuex-map-fields plugin
    updateField

  } //end MUTATIONS
}
