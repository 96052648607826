<script>
  export default {
    props: [
      'shadeUnit',
      'unitType',
      'containerClass',
      'errors',
      'disabled'
    ],

    mounted() {
      /*
        console.log('WrappedFasciaFabric.vue Mounted');
        console.log(this.shadeUnit);
        console.log( this.wrappedFasciaFabricOptions );
        console.log('');
      */
    },

    computed: {
      parentContainerClass() {
        return this.containerClass;
      },

      hasFabricWrappedFascia() {
        if( this.unitType == 'shadeConfigUnit' ) {
          var hasFabricWrappedFascia = this.$store.getters['shadeUnitConfigs/hasFabricWrappedFascia'](this.shadeUnit.id);
        } else {
          var hasFabricWrappedFascia = this.$store.getters['shadeLineItems/hasFabricWrappedFascia'](this.shadeUnit.id);
        }

        if( hasFabricWrappedFascia == false ) {
          this.shadeUnit.wrapped_fascia_fabric = null;
        } else if ( this.shadeUnit.wrapped_fascia_fabric == null ) {
          this.shadeUnit.wrapped_fascia_fabric = 'fabric_1';
        }

        return hasFabricWrappedFascia;
      },

      isWrappedFasciaFabricSelectDisabled() {
        if( this.disabled == false && this.hasFabricWrappedFascia == true && this.shadeUnit.product_model_dual_shade == 1) {
          return false;
        }

        return true;
      },

      wrappedFasciaFabricOptions() {
        return this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.wrappedFasciaFabricOptions;
      },

      hasErrors() {
        return this.$parent.hasErrors;
      }
    },

    methods: {
      getInputName(id, field) {
        return this.$parent.getInputName(id, field);
      },

      getInputId(id, field) {
        return this.$parent.getInputId(id, field);
      },
    },

    watch: {
      'shadeUnit.product_model_dual_shade': function(newVal, oldVal) {
        if( this.hasFabricWrappedFascia  ) {
          this.shadeUnit.wrapped_fascia_fabric = 'fabric_1';
        }
      },
    }
  }
</script>
