var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row estimate-widget custom-color" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-sm-12" }, [
      _c("table", { staticClass: "table" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", [
            _c("td", { class: _vm.tableColumnCssClass }, [
              _vm._v("Custom Color"),
            ]),
            _vm._v(" "),
            _c("td", { class: _vm.tableColumnCssClass }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.orderProposal.custom_color_cost,
                    expression: "orderProposal.custom_color_cost",
                  },
                  {
                    name: "proposal-field-directive",
                    rawName: "v-proposal-field-directive",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors["custom_color_cost"] ? "is-invalid" : "",
                attrs: {
                  type: "text",
                  placeholder: "$500.00",
                  name: _vm.getInputName("custom_color_cost"),
                  id: _vm.getInputId("custom_color_cost"),
                  disabled: _vm.lockCustomColorFieldRow,
                },
                domProps: { value: _vm.orderProposal.custom_color_cost },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.orderProposal,
                      "custom_color_cost",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("td", { class: _vm.tableColumnCssClass }, [
              _vm._v("\n             \n          "),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
      _c("h2", [_vm._v("Custom Components")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Cost")]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }