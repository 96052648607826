var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-row estimate-widget jobsite-distance" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-sm-12" }, [
        _c("table", { staticClass: "table" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.orderProposal.distance_to_jobsite,
                      expression: "orderProposal.distance_to_jobsite",
                    },
                    {
                      name: "proposal-field-directive",
                      rawName: "v-proposal-field-directive",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors["distance_to_jobsite"] ? "is-invalid" : "",
                  attrs: {
                    type: "text",
                    placeholder: "1",
                    name: _vm.getInputName("distance_to_jobsite"),
                    id: _vm.getInputId("distance_to_jobsite"),
                  },
                  domProps: { value: _vm.orderProposal.distance_to_jobsite },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.orderProposal,
                        "distance_to_jobsite",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.mileage_rate_formatted)),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
      _c("h2", [_vm._v("Job Distance/Travel")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Distance to Job")]),
        _vm._v(" "),
        _c("th", [_vm._v("Mileage Rate ($)")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }