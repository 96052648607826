<script>
  import { fieldFormattingMixin } from '../../../mixins/fieldFormattingMixin'
  import ProductModelPartDependencies from './ProductModelPartDependencies';

  export default {
    props: [
      'productModelPart'
    ],

    mixins: [fieldFormattingMixin],

    components: {
      ProductModelPartDependencies
    },

    data() {
      return {
        modelPart: {
          part_id: null,
          deduct_inches: null,
          max_roll_width_inches: null,
          max_roll_width_inches_formatted: null,
          max_dual_width_inches: null,
          max_dual_width_inches_formatted: null,
          always_included: null,
          is_couple: 0,
          option_number: null,
          quantity_multiplier: null,
          part: {
            name: null,
            part_dependencies: {},
            part_type: {
              name: null
            }
          }
        },
        isNew: false,
        isMaximized: false,
        selectOptions: []
      }
    },

    mounted() {
      this.modelPart = { ...this.modelPart, ...this.productModelPart };
        this.modelPart.deduct_inches = this.modelPart.deduct_inches_formatted;
        this.modelPart.fascia_deduct_inches = this.modelPart.fascia_deduct_inches_formatted;
        this.modelPart.fabric_deduct_inches = this.modelPart.fabric_deduct_inches_formatted;
        this.modelPart.max_roll_width_inches = this.modelPart.max_roll_width_inches_formatted;
        this.modelPart.max_dual_width_inches = this.modelPart.max_dual_width_inches_formatted;
      this.isNew = ( this.modelPart.isNew == true ) ? true : this.isNew;
      this.selectOptions = this.$parent.$parent.selectOptions;
      this.isMaximized = ( this.isNew == true ) ? true : this.isMaximized;

      //Hotfix
      if( !this.modelPart.part.part_type.hasOwnProperty('name') ) {
        this.modelPart.part.part_type.name = null;
      }

      if( this.isNew ) {
        this.modelPart.part.name = 'New Part';
      }
    },

    computed: {
      endpointDestroy() {
        return '/admin/settings/products/models/parts/' + this.modelPart.id;
      },

      endpointGetSelectedPart() {
        return '/admin/settings/products/models/parts/get-selected-part-by-id';
      },

      partDependencies() {
        return this.modelPart.part.part_dependencies;
      },

      isSortableContainerClass() {
        return 'is-sortable';
      },

      errors() {
        if( this.$parent.$parent.errors.hasOwnProperty( this.modelPart.id ) ) {
          return this.$parent.$parent.errors[this.modelPart.id];
        }
        return [];
      },

      hasErrors() {
        if( Object.keys(this.errors).length ) {
          this.isMaximized = true;
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'product_model_parts[' + id + '][' + field +']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      getInputTooltipId(id, field) {
        var inputId = this.getInputId(id, field);
        return inputId + '_' + id;
      },

      toggleFullView(event) {
        event.preventDefault();
        this.isMaximized = ( this.isMaximized == true ) ? false : true;
      },

      setModelPart(event) {
        var $selectedOption = $(event.target).find(':selected');

        //
        // TODO - This was a quick change. Please refactor.
        //
        if( event.target.value != '' ) {
          var postData = {
            part_id: event.target.value
          }

          axios.post(this.endpointGetSelectedPart, postData)
          .then((response) => {
            this.modelPart.part = response.data.part;
          })
          .catch(error => {
            //flash(error.response.data.status_type, error.response.data.status);
          });


        } else {
          if( typeof $selectedOption.data('part-name') != 'undefined' && $selectedOption.data('part-name') != '' ) {
            this.modelPart.part.name = $selectedOption.data('part-name');
          } else {
            this.modelPart.part.name = 'Choose a Part';
          }

          if( typeof $selectedOption.data('part-type') != 'undefined' && $selectedOption.data('part-type') != '' ) {
            this.modelPart.part.part_type.name = $selectedOption.data('part-type');
          } else {
            this.modelPart.part.part_type.name = '';
          }
        }
      },

      destroy() {
        if( this.isNew === true ) {
          this.$parent.$parent.deleteProductModelPart( this.modelPart );
          flash('alert-success', 'Model part deleted.');

        } else {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            this.$parent.$parent.deleteProductModelPart( this.modelPart );
            flash(response.data.status_type, response.data.status);
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      }

    }
  }
</script>
