<template>
  <div class="alert fixed-alert alert-flash"
       :class="type"
       role="alert"
       v-show="show"
       v-html="message">
  </div>
</template>

<script>
  export default {
    props: [
      'alert_type',
      'alert_message',
      'disable_message_autohide',
    ],

    data() {
      return {
        type: 'alert-warning',
        message: '',
        show: false
      }
    },

    mounted() {
      if( this.alert_message ) {
        this.display(this);
      }

      window.events.$on('flash', data => this.display(data));
    },

    computed: {
      disableAutohide() {
        return ( this.disable_message_autohide === true ) ? true : false;
      }
    },

    methods: {
      display(data) {
        this.type = data.alert_type;
        this.message = data.alert_message;
        this.show = true;

        if( this.disableAutohide === false ) {
          this.hide();
        }
      },

      hide() {
        setTimeout(() => {
          this.show = false;
        }, 3000);
      }
    }
  }
</script>
