<script>
  import draggable from 'vuedraggable';
  import FieldMeasureQuestion from './FieldMeasureQuestion';

  export default {
    props: [
      'parentFieldMeasureQuestions',
    ],

    components: {
      FieldMeasureQuestion,
    },

    data() {
      return {
        fieldMeasureQuestionList: []
      }
    },

    mounted() {
      this.fieldMeasureQuestionList = this.parentFieldMeasureQuestions;
        this.fieldMeasureQuestionList.forEach(q => q.key_id = q.id);
    },

    computed: {
      endpointAddRow() {
        return '/admin/settings/field-measure/add-row';
      },

      endpointSort() {
        return '/admin/settings/field-measure-option/sort';
      }
    },

    methods: {
      addFieldMeasureQuestion(event) {
        event.preventDefault();
        axios.post(this.endpointAddRow)
        .then((response) => {
          let newQuestion = response.data.fieldMeasureOption;
            newQuestion.isNew = response.data.isNew;
          this.fieldMeasureQuestionList.push( newQuestion );
        })
        .catch(error => {
          //flash(error.response.data.status_type, error.response.data.status);
        });
      },

      deleteFieldMeasureQuestion(fieldMeasureQuestion) {
        let i = this.fieldMeasureQuestionList.map(obj => obj.id).indexOf(fieldMeasureQuestion.id);
        this.fieldMeasureQuestionList.splice(i, 1) // remove it from array
      },

      applySavedFieldMeasureQuestion(oldQuestionId, updatedQuestion) {
        updatedQuestion.isNew = false;
        let index = this.fieldMeasureQuestionList.map(question => question.id).indexOf(oldQuestionId);
        this.fieldMeasureQuestionList.splice(index, 1, updatedQuestion);
      },

      saveSortOrder(event) {
        let endpoint = this.endpointSort,
            sort_order = this.fieldMeasureQuestionList.map(question => question.id),
            submitData = {
              _method: 'PUT',
              sort_order: sort_order
            };
        axios.post(endpoint, submitData)
        .then((response) => {
          //
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status); //show the error message
        });
      }
    }
  };
</script>
