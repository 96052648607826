var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      this.globalProjectOrderTypeList.length <= 0
        ? [_c("p", [_vm._v(_vm._s(_vm.noResultsText))])]
        : [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "group col-sm-12" }, [
                _c(
                  "ul",
                  { staticClass: "nav nav-tabs", attrs: { role: "tablist" } },
                  _vm._l(
                    _vm.globalProjectOrderTypeList,
                    function (projectOrderType, index) {
                      return _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            class: index == 0 ? "active" : "",
                            attrs: {
                              href: _vm.getTabListHref(projectOrderType.name),
                              id: _vm.getTabListTabId(projectOrderType.name),
                              "data-toggle": "tab",
                              role: "tab",
                              "aria-controls": _vm.getTabId(
                                projectOrderType.name
                              ),
                              "aria-selected": index == 0 ? "active" : "",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(projectOrderType.name) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    }
                  ),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tab-content" },
              [
                _vm._l(
                  _vm.globalProjectOrderTypeList,
                  function (projectOrderType, index) {
                    return [
                      _c("project-order-type", {
                        attrs: {
                          "parent-project-order-type": projectOrderType,
                          "parent-project-order-type-index": index,
                          "tab-container-id": _vm.getTabId(
                            projectOrderType.name
                          ),
                        },
                      }),
                    ]
                  }
                ),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }