var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row estimate-widget cost-summary" }, [
    _vm.showHeadingRow
      ? _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
          _c("h2", [_vm._v(_vm._s(_vm.headingRowHeadingText))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group col-sm-12 table-responsive text-nowrap" },
      [
        _c("table", { staticClass: "table" }, [
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v("Labor")]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.cs_labor_cost_formatted)),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Job Expenses")]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.cs_job_cost_formatted)),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Material")]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.cs_material_cost_formatted)),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Sales Tax")]),
              _vm._v(" "),
              _c("td", { staticClass: "min-width-field" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.project.type,
                        expression: "project.type",
                      },
                      {
                        name: "proposal-field-directive",
                        rawName: "v-proposal-field-directive",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.errors["type"] ? "is-invalid" : "",
                    attrs: {
                      name: _vm.getInputName("type"),
                      id: _vm.getInputId("type"),
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.project,
                          "type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("--Choose One--"),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.selectOptions.proposalProjectTypeOptions,
                      function (option) {
                        return [
                          _c("option", { domProps: { value: option.id } }, [
                            _vm._v(_vm._s(option.value)),
                          ]),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "min-width-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.orderProposal.sales_tax_pct,
                      expression: "orderProposal.sales_tax_pct",
                    },
                    {
                      name: "proposal-field-directive",
                      rawName: "v-proposal-field-directive",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors["sales_tax_pct"] ? "is-invalid" : "",
                  attrs: {
                    type: "text",
                    placeholder: "1",
                    name: _vm.getInputName("sales_tax_pct"),
                    id: _vm.getInputId("sales_tax_pct"),
                  },
                  domProps: { value: _vm.orderProposal.sales_tax_pct },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.orderProposal,
                        "sales_tax_pct",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.cs_sales_tax_cost_formatted)),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Freight")]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", { staticClass: "min-width-field" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.orderProposal.freight_pct,
                        expression: "orderProposal.freight_pct",
                      },
                      {
                        name: "proposal-field-directive",
                        rawName: "v-proposal-field-directive",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.errors["freight_pct"] ? "is-invalid" : "",
                    attrs: {
                      name: _vm.getInputName("freight_pct"),
                      id: _vm.getInputId("freight_pct"),
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.orderProposal,
                          "freight_pct",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("--Choose One--"),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.selectOptions.proposalFreightExpenseOptions,
                      function (option) {
                        return [
                          _c("option", { domProps: { value: option.value } }, [
                            _vm._v(_vm._s(option.label)),
                          ]),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.cs_freight_cost_formatted)),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Contingency")]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", { staticClass: "min-width-field" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.orderProposal.contingency_pct,
                        expression: "orderProposal.contingency_pct",
                      },
                      {
                        name: "proposal-field-directive",
                        rawName: "v-proposal-field-directive",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.errors["contingency_pct"] ? "is-invalid" : "",
                    attrs: {
                      name: _vm.getInputName("contingency_pct"),
                      id: _vm.getInputId("contingency_pct"),
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.orderProposal,
                          "contingency_pct",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("--Choose One--"),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.selectOptions.proposalContingencyExpenseOptions,
                      function (option) {
                        return [
                          _c("option", { domProps: { value: option.value } }, [
                            _vm._v(_vm._s(option.label)),
                          ]),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.cs_contingency_cost_formatted)),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Overhead")]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", { staticClass: "min-width-field" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.orderProposal.overhead_pct,
                        expression: "orderProposal.overhead_pct",
                      },
                      {
                        name: "proposal-field-directive",
                        rawName: "v-proposal-field-directive",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.errors["overhead_pct"] ? "is-invalid" : "",
                    attrs: {
                      name: _vm.getInputName("overhead_pct"),
                      id: _vm.getInputId("overhead_pct"),
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.orderProposal,
                          "overhead_pct",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("--Choose One--"),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.selectOptions.proposalOverheadExpenseOptions,
                      function (option) {
                        return [
                          _c("option", { domProps: { value: option.value } }, [
                            _vm._v(_vm._s(option.label)),
                          ]),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.cs_overhead_cost_formatted)),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Profit")]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", { staticClass: "min-width-field" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.orderProposal.profit_pct,
                        expression: "orderProposal.profit_pct",
                      },
                      {
                        name: "proposal-field-directive",
                        rawName: "v-proposal-field-directive",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.errors["profit_pct"] ? "is-invalid" : "",
                    attrs: {
                      name: _vm.getInputName("profit_pct"),
                      id: _vm.getInputId("profit_pct"),
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.orderProposal,
                          "profit_pct",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("--Choose One--"),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.selectOptions.proposalProfitOptions,
                      function (option) {
                        return [
                          _c("option", { domProps: { value: option.value } }, [
                            _vm._v(_vm._s(option.label)),
                          ]),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.orderProposal.cs_profit_formatted))]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Bond")]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", { staticClass: "min-width-field" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.orderProposal.performance_bond_pct,
                        expression: "orderProposal.performance_bond_pct",
                      },
                      {
                        name: "proposal-field-directive",
                        rawName: "v-proposal-field-directive",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.errors["performance_bond_pct"]
                      ? "is-invalid"
                      : "",
                    attrs: {
                      name: _vm.getInputName("performance_bond_pct"),
                      id: _vm.getInputId("performance_bond_pct"),
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.orderProposal,
                          "performance_bond_pct",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("--Choose One--"),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.selectOptions.proposalPerformanceBondOptions,
                      function (option) {
                        return [
                          _c("option", { domProps: { value: option.value } }, [
                            _vm._v(_vm._s(option.label)),
                          ]),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.cs_bond_cost_formatted)),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Total")]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.cs_total_cost_formatted)),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Shipping")]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.shipping_charge_formatted)),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }