var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.sort_order_number))]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.room))]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.quantity))]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.actual_width_formatted))]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.actual_length_formatted))]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.product_model_name))]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.fascia_type))]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.is_coupled ? "Yes" : "No"))]),
    _vm._v(" "),
    _c("td", [
      _vm._v(_vm._s(_vm.shadeLineItem.has_side_channel ? "Yes" : "No")),
    ]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.fabricName))]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.dualFabricName))]),
    _vm._v(" "),
    _c(
      "td",
      [
        _vm.shadeLineItem.motor
          ? [
              _vm._v(
                "\n      " + _vm._s(_vm.shadeLineItem.motor_name) + "\n    "
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c("td", [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.shadeLineItem.extra_labor_minutes,
              expression: "shadeLineItem.extra_labor_minutes",
            },
            {
              name: "proposal-field-directive",
              rawName: "v-proposal-field-directive",
            },
          ],
          staticClass: "form-control",
          class: _vm.errors["extra_labor_minutes"] ? "is-invalid" : "",
          attrs: {
            name: _vm.getInputName(_vm.shadeLineItem.id, "extra_labor_minutes"),
            id: _vm.getInputId(_vm.shadeLineItem.id, "extra_labor_minutes"),
          },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.shadeLineItem,
                "extra_labor_minutes",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { domProps: { value: null } }, [_vm._v("--None--")]),
          _vm._v(" "),
          _vm._l(_vm.extraLaborSelectOptions, function (option) {
            return _c("option", { domProps: { value: option.value } }, [
              _vm._v(_vm._s(option.label)),
            ])
          }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("td", [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.shadeLineItem.after_hours,
              expression: "shadeLineItem.after_hours",
            },
            {
              name: "proposal-field-directive",
              rawName: "v-proposal-field-directive",
            },
          ],
          staticClass: "form-control",
          class: _vm.errors["after_hours"] ? "is-invalid" : "",
          attrs: {
            name: _vm.getInputName(_vm.shadeLineItem.id, "after_hours"),
            id: _vm.getInputId(_vm.shadeLineItem.id, "after_hours"),
          },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.shadeLineItem,
                "after_hours",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        _vm._l(_vm.afterHoursLaborSelectOptions, function (option) {
          return _c("option", { domProps: { value: option.value } }, [
            _vm._v(_vm._s(option.label)),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("td", [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.shadeLineItem.supply_only,
              expression: "shadeLineItem.supply_only",
            },
            {
              name: "proposal-field-directive",
              rawName: "v-proposal-field-directive",
            },
          ],
          staticClass: "form-control",
          class: _vm.errors["supply_only"] ? "is-invalid" : "",
          attrs: {
            name: _vm.getInputName(_vm.shadeLineItem.id, "supply_only"),
            id: _vm.getInputId(_vm.shadeLineItem.id, "supply_only"),
          },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.shadeLineItem,
                "supply_only",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        _vm._l(_vm.supplyOnlySelectOptions, function (option) {
          return _c("option", { domProps: { value: option.value } }, [
            _vm._v(_vm._s(option.label)),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }