<script>
  export default {
    props: [
      'parentPart',
      'partLabors',
    ],

    data() {
      return {
        partLaborList: [],
        errors: {}
      }
    },

    mounted() {
      this.part = this.parentPart;
      this.partLaborList = this.partLabors;

      /*
        console.log('PartLabors.vue - Mounted');
        console.log('this.part')
        console.log(this.part);
        console.log('this.partLaborList')
        console.log(this.partLaborList);
        console.log('');
      */
    },

    methods: {
      getInputName(id, field) {
        return 'part_labors[' + id + '][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      partLaborIsDisabled(laborType) {
        //Installation
        if( laborType.labor_type_name == 'Installation' ) {
          if( this.$parent.isTrim || this.$parent.isCassette || this.$parent.isSideChannel ) {
            return false;
          }
          laborType.minutes = null;
          return true;
        }

        //All others
        return false;
      },

      getSubmitData() {
        var partLabors = {};
        if( this.partLaborList.length ) {
          this.partLaborList.forEach(function(record) {
            partLabors[record.id] = _.clone(record);
          });
        }
        return partLabors;
      },

      setErrors(errors) {
        this.errors = errors;
      }
    }
  };
</script>

<template>
  <div class="part-labors-container mt-2">
    <div class="form-row">
      <div class="form-group col-sm-12 mb-2">
        <h5>Part Labor</h5>
      </div>
    </div>

    <template v-for="(partLabor, index) in partLaborList">
      <div class="form-row">
        <div class="form-group col-sm-6 col-md-2">
          <label v-bind:for="getInputName(partLabor.id, 'name')" v-if="index == 0">Labor Type</label>
          <input
            type="hidden"
            v-bind:name="getInputName(partLabor.id, 'id')"
            v-model="partLabor.id">
          <input
            type="hidden"
            v-bind:name="getInputName(partLabor.id, 'labor_type_id')"
            v-model="partLabor.labor_type_id">
          <p>{{ partLabor.labor_type_name }}</p>
        </div>

        <div class="form-group col-sm-6 col-md-3">
          <label v-bind:for="getInputName(partLabor.id, 'minutes')" v-if="index == 0">Time (in minutes)</label>
          <input type="text"
            placeholder="10"
            class="form-control"
            maxlength="5"
            v-bind:class="errors[partLabor.id] && errors[partLabor.id]['minutes'] ? 'is-invalid' : ''"
            v-bind:name="getInputName(partLabor.id, 'minutes')"
            v-bind:id="getInputId(partLabor.id, 'minutes')"
            v-model="partLabor.minutes"
            :disabled="partLaborIsDisabled(partLabor)">

          <span class="invalid-feedback" role="alert" v-if="errors[partLabor.id] && errors[partLabor.id]['minutes']">
            {{ errors[partLabor.id]['minutes'][0] }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>
