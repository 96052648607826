<script>
  import { mapMultiRowFields } from 'vuex-map-fields';
  import { mapDynamicMultiRowFields } from '../../../../helpers/vuexMapFieldsOverride';
  import ShadeLineItem from './ShadeLineItem';

  export default {
    props: [
      'shadeGroupId'
    ],

    components: {
      ShadeLineItem
    },

    data() {
      return {
        isLoaded: false
      }
    },

    mounted() {
      this.isLoaded = true;
      /*
        console.log('ShadeLineItems vue Mounted...');
        console.log(this.shadeGroup);
        console.log( this.shadeGroupId );
        console.log(this);
      */
    },

    computed: {
      shadeOrder() {
        return this.$store.state.shadeOrder.shadeOrder;
      },

      shadeGroup() {
        return this.$store.getters['shadeGroups/find'](this.shadeGroupId);
      },

      ...mapDynamicMultiRowFields('shadeLineItems', { shadeLineItems: 'byGroupId[].shadeLineItems'}, 'indexArray'),

      shadeLineItemListForDraggable: {
        get() {
          return this.shadeLineItems;
        },

        set(value) {
          var payload = {
            shadeGroupId: this.shadeGroup.id,
            shadeLineItems: value
          };
          this.$store.dispatch('shadeLineItems/updateSortOrder', payload);
        }
      },

      indexArray() {
        return this.shadeGroupId;
      },

      hasErrorsInChildren() {
        var hasChildErrors = false;
        this.shadeLineItems.forEach(function(shade) {
          if( Object.keys(shade.errors).length > 0 ) {
            //we have a validation error on a child shade.
            hasChildErrors = true;
          }
        });
        return hasChildErrors;
      },

      canAddInnerShade() {
        return ( this.hasErrorsInChildren ) ? false : true;
      },
    }
  }
</script>
