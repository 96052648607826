var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentAdditionalItem.isNew == false
    ? _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.currentAdditionalItemPart.name))]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(_vm.currentAdditionalItem.addtl_item_material_cost_formatted)
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(
              _vm.currentAdditionalItem.addtl_item_mfg_labor_cost_formatted
            )
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(
              _vm.currentAdditionalItem.addtl_item_pkg_labor_hours_formatted
            )
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(
              _vm.currentAdditionalItem.addtl_item_pkg_labor_cost_formatted
            )
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentAdditionalItem.supply_only,
                  expression: "currentAdditionalItem.supply_only",
                },
                {
                  name: "motorized-order-item-addition-field-directive",
                  rawName: "v-motorized-order-item-addition-field-directive",
                },
              ],
              staticClass: "form-control",
              class: _vm.errors["supply_only"] ? "is-invalid" : "",
              attrs: { disabled: _vm.orderProposal.userCanEdit == false },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.currentAdditionalItem,
                    "supply_only",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            _vm._l(_vm.supplyOnlySelectOptions, function (option) {
              return _c("option", { domProps: { value: option.value } }, [
                _vm._v(_vm._s(option.label)),
              ])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(
              _vm.currentAdditionalItem.addtl_item_install_labor_cost_formatted
            )
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(_vm.currentAdditionalItem.addtl_item_total_cost_formatted)
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(_vm.currentAdditionalItem.addtl_item_unit_cost_formatted)
          ),
        ]),
        _vm._v(" "),
        _c("td"),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(
              _vm.currentAdditionalItem.addtl_item_material_expense_formatted
            )
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(
              _vm.currentAdditionalItem.addtl_item_total_gross_profit_formatted
            )
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(_vm.currentAdditionalItem.addtl_item_subtotal_formatted)
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(
              _vm.currentAdditionalItem
                .addtl_item_dealer_commission_fee_formatted
            )
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(_vm.currentAdditionalItem.addtl_item_total_price_formatted)
          ),
        ]),
        _vm._v(" "),
        _c("td"),
        _vm._v(" "),
        _c("td"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }