<script>
  import FabricAttribute from './FabricAttribute';

  export default {
    props: [],

    components: {
      FabricAttribute
    },

    data() {
      return {
        //errors: []
      }
    },

    mounted() {},

    computed: {
      fabricOpennessFactor() {
        return this.$parent.opennessFactor;
      },

      fabricAttributeList() {
        return this.$parent.fabricAttributes;
      },

      endpointAddRow() {
        return '/admin/settings/fabric-attribute/' + this.fabricOpennessFactor.id + '/add-row';
      },

      isDisabled() {
        return false;
      },

      errors() {
        if( this.$parent.errors.hasOwnProperty('fabric_attributes') ) {
          return this.$parent.errors.fabric_attributes;
        } else {
          return [];
        }
      }
    },

    methods: {
      addFabricAttribute(event) {
        event.preventDefault();
        axios.post(this.endpointAddRow)
        .then((response) => {
          let newFabricAttribute = response.data.fabricAttribute;
            newFabricAttribute.isNew = true;
          this.fabricAttributeList.push( newFabricAttribute );
        })
        .catch(error => {
          //flash(error.response.data.status_type, error.response.data.status);
        });
      },

      deleteFabricAttribute(fabricAttribute) {
        let i = this.fabricAttributeList.map(obj => obj.id).indexOf(fabricAttribute.id);
        this.fabricAttributeList.splice(i, 1) // remove it from array
      },

      getSaveData() {
        var fabricAttributeData = {};
        if( this.$refs.fabricAttribute.length ) {
          this.$refs.fabricAttribute.forEach(function(obj){
            fabricAttributeData[obj.fabricAttribute.id] = obj.fabricAttribute;
          });
        }
        return fabricAttributeData;
      }
    }
  };
</script>
