var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isExteriorShade
    ? _c("div", { class: _vm.parentContainerClass }, [
        _c(
          "label",
          {
            attrs: {
              for: _vm.getInputName(
                _vm.shadeUnit.id,
                "product_model_guided_cable"
              ),
            },
          },
          [_vm._v("\n    " + _vm._s(_vm.guideCableLabel) + "\n  ")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.shadeUnit.product_model_guided_cable,
                expression: "shadeUnit.product_model_guided_cable",
              },
            ],
            staticClass: "form-control",
            class: _vm.errors["product_model_guided_cable"] ? "is-invalid" : "",
            attrs: {
              name: _vm.getInputName(
                _vm.shadeUnit.id,
                "product_model_guided_cable"
              ),
              id: _vm.getInputName(
                _vm.shadeUnit.id,
                "product_model_guided_cable"
              ),
              disabled: _vm.disabled,
            },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.shadeUnit,
                  "product_model_guided_cable",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          _vm._l(_vm.guidedCableSelectOptions, function (option) {
            return _c("option", { domProps: { value: option.value } }, [
              _vm._v("\n      " + _vm._s(option.label) + "\n    "),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _vm.errors["product_model_guided_cable"]
          ? _c(
              "span",
              { staticClass: "invalid-feedback", attrs: { role: "alert" } },
              [
                _vm._v(
                  "\n\n    " +
                    _vm._s(_vm.errors["product_model_guided_cable"][0]) +
                    "\n  "
                ),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }