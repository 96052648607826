var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.elementType == "link"
        ? [
            _c(
              "a",
              {
                class: _vm.elementCssClass,
                attrs: { href: "#", title: "Copy" },
                on: {
                  click: function ($event) {
                    return _vm.submitForm($event)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.elementText) + "\n    ")]
            ),
          ]
        : [
            _c(
              "button",
              {
                class: _vm.elementCssClass,
                attrs: { type: "button", title: "Copy" },
                on: {
                  click: function ($event) {
                    return _vm.submitForm($event)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.elementText) + "\n    ")]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }