<template>
  <div v-cloak>
    <b-modal
      :id="modalIdAttr"
      :size="modalSizeAttr"
      :title="modalTitleText"
      :ok-title="confirmButtonText"
      :cancel-title="dismissButtonText"
      :hide-header-close="hideHeaderCloseButton"

      :hide-header="hideHeader"
      :hide-footer="hideFooter"
      :centered="true"
      :no-close-on-backdrop="noCloseOnBgClick"
      @ok="emitConfirm"
      @cancel="emitCancel">

      <div>
        <div class="embed-responsive embed-responsive-16by9 modal-content">
          <iframe scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" v-bind:src="videoIframeSrc" allow="autoplay; fullscreen;"></iframe>
        </div>
      </div>

      <template #modal-footer></template>
    </b-modal>
  </div>
</template>

<script>
  export default {
    props: [
      'modalId',
      'modalSize',
      'videoUrl',
      'autoPlay',
      'noCloseOnBackgroundClick',

      'modalTitle',
      'modalBody',
      'dismissButton',
      'confirmButton',
      'hideHeaderClose',

    ],

    data() {
      return {
        showModal: false,
        hideHeader: true,
        hideFooter: true,
        autoPlayVideo: false,
        noCloseOnBgClick: false
      }
    },

    mounted() {
      this.autoPlayVideo = ( this.autoPlay == true ) ? true : this.autoPlayVideo;
      this.noCloseOnBgClick = ( this.noCloseOnBackgroundClick == true ) ? true : this.noCloseOnBgClick;
    },

    computed: {
      modalIdAttr() {
        return 'videoModal-' + this.modalId;
      },

      modalSizeAttr() {
        if( this.modalSize == null ) {
          return;
        }

        return this.modalSize;
      },

      modalTitleText() {
        return ( this.modalTitle != null ) ? this.modalTitle : 'Modal Title Text';
      },

      modalBodyText() {
        return ( this.modalBody != null ) ? this.modalBody : 'Modal Body Text';
      },

      dismissButtonText() {
        return ( this.dismissButton != null ) ? this.dismissButton : 'Cancel';
      },

      confirmButtonText() {
        return ( this.confirmButton != null ) ? this.confirmButton : 'Approve';
      },

      hasDismissButton() {
        return ( this.dismissButton == null ) ? false : true;
      },

      hasConfirmButton() {
        return ( this.confirmButton == null ) ? false : true;
      },

      hideHeaderCloseButton() {
        return ( this.hideHeaderClose == true ) ? true : false;
      },

      videoIframeSrc() {
        let videoUrl = this.videoUrl,
            playerSettings = this.getPlayerSettings;

        if( playerSettings.length ) {
          videoUrl = videoUrl + '?' + playerSettings.join('&');
        }

        return videoUrl;
      },

      getPlayerSettings() {
        let playerSettings = [];
        if( this.autoPlayVideo ) {
          playerSettings.push('autoplay=1');
        }
        return playerSettings;
      }
    },

    methods: {
      emitConfirm() {
        this.$emit('confirmed');
      },

      emitCancel() {
        this.$emit('cancel');
      },
    }
  }
</script>
