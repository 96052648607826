var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    { staticClass: "mb-0" },
    [
      _c("a", {
        attrs: { href: "#" },
        domProps: { innerHTML: _vm._s(_vm.viewAttachmentLinkText) },
        on: {
          click: function ($event) {
            return _vm.viewAttachment($event)
          },
        },
      }),
      _vm._v(" "),
      _c("video-modal", {
        attrs: {
          "modal-id": _vm.attachment.id,
          "video-url": _vm.attachment.attachment_path,
          "auto-play": true,
          "modal-size": "lg",
          "modal-title": "",
          "modal-body": "",
          "dismiss-button": "",
          "confirm-button": "",
          "hide-header-close": false,
        },
        on: {
          confirmed: function ($event) {
            return _vm.handleFunctionCall(_vm.modalDestroyConfirmButtonAction)
          },
          cancel: function ($event) {
            return _vm.handleFunctionCall(_vm.modalCancelAction)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }