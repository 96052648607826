var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { staticClass: "draggable-resource-attachments" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.attachmentTypeText))]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                return _vm.viewAttachment($event)
              },
            },
          },
          [_vm._v(_vm._s(_vm.attachmentFileNameText))]
        ),
        _vm._v(" "),
        _c("video-modal", {
          attrs: {
            "modal-id": _vm.attachment.id,
            "video-url": _vm.attachment.attachment_path,
            "auto-play": true,
            "modal-size": "lg",
            "modal-title": "Are you sure?",
            "modal-body":
              "Are you sure you want to delete this attachment? This action cannot be undone.",
            "dismiss-button": "Cancel",
            "confirm-button": "Delete",
            "hide-header-close": false,
          },
          on: {
            confirmed: function ($event) {
              return _vm.handleFunctionCall(_vm.modalDestroyConfirmButtonAction)
            },
            cancel: function ($event) {
              return _vm.handleFunctionCall(_vm.modalCancelAction)
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      [
        _c("a", {
          staticClass: "btn icon-only btn-delete",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.destroyAttachmentClick($event)
            },
          },
        }),
        _vm._v(" "),
        _c("modal-confirm", {
          attrs: {
            "modal-id-attr-override": _vm.deleteAttachmentModalId,
            "modal-id": _vm.attachment.id,
            "modal-title": "Are you sure?",
            "modal-body":
              "Are you sure you want to delete this attachment? This action cannot be undone.",
            "dismiss-button": "Cancel",
            "confirm-button": "Delete",
            "hide-header-close": true,
          },
          on: {
            confirmed: function ($event) {
              return _vm.handleFunctionCall(_vm.modalDestroyConfirmButtonAction)
            },
            cancel: function ($event) {
              return _vm.handleFunctionCall(_vm.modalCancelAction)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [_c("div", { staticClass: "sort-handle" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }