<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'currentOrderProposal',
    ],

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('CustomColorEstimate.vue - Mounted');
        console.log(this.orderProposal);
        console.log(this.currentOrderProposal);
        console.log('');
      */
    },

    computed: {
      orderProposalState() {
        return this.$store.getters['orderProposal/currentOrderProposal'];
      },

      orderProposal() {
        return this.currentOrderProposal;
      },

      requiresCustomColorEstimate() {
        return this.orderProposal.is_custom_color_estimate_required;
      },

      lockCustomColorFieldRow() {
        return ( this.requiresCustomColorEstimate == true || this.orderProposal.custom_color_cost != null) ? false : true;
      },

      tableColumnCssClass() {
        if( this.lockCustomColorFieldRow === true ) {
          return 'colored-bg';
        } else {
          return '';
        }
      },

      errors() {
        return [];
      },
    },

    methods: {
      getInputName(field) {
        return this.$parent.getOrderProposalInputName(field);
      },

      getInputId(field) {
        return this.$parent.getOrderProposalInputId(field);
      }
    }
  };
</script>

<template>
  <div class="form-row estimate-widget custom-color">
    <div class="form-group col-sm-12 mb-0">
      <h2>Custom Components</h2>
    </div>

    <div class="form-group col-sm-12">
      <table class="table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Cost</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td :class="tableColumnCssClass">Custom Color</td>
            <td :class="tableColumnCssClass">
              <input type="text"
                     class="form-control"
                     v-bind:class="errors['custom_color_cost'] ? 'is-invalid' : ''"
                     placeholder="$500.00"
                     v-bind:name="getInputName('custom_color_cost')"
                     v-bind:id="getInputId('custom_color_cost')"
                     v-model="orderProposal.custom_color_cost"
                     :disabled="lockCustomColorFieldRow"
                     v-proposal-field-directive>
            </td>
            <td :class="tableColumnCssClass">
              &nbsp;
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
