<script>
  //import { mapDynamicMultiRowFields } from '../../helpers/vuexMapFieldsOverride';
  import { mapState, mapGetters } from 'vuex';
  import { mapMultiRowFields } from 'vuex-map-fields';
  import { proposalMixin } from '../../mixins/proposalMixin';

  import TakeoffShadeLineItem from './TakeoffShadeLineItem';

  export default {
    props: [
      'currentOrderProposal',
      //'masterShadeGroups',
    ],

    mixins: [proposalMixin],

    components: {
      TakeoffShadeLineItem,
    },


    mounted() {

    },

    computed: {
      ...mapState('shadeGroups', ['byId']),
      ...mapGetters("shadeGroups", { shadeGroups: "list" }),
      ...mapMultiRowFields('shadeLineItems', ['byId']),

      orderProposal() {
        return this.currentOrderProposal;
      },

      errors() {
        return [];
      },
    },

    methods: {
      getInputName(id, field) {
        return 'order_items['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },
    }
  };
</script>

<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Item</th>
          <th scope="col">Room</th>
          <th scope="col">Quantity</th>
          <th scope="col">Width (in)</th>
          <th scope="col">Length (in)</th>
          <th scope="col">Product Type</th>
          <th scope="col">Trim Material</th>
          <th scope="col">Coupled</th>
          <th scope="col">Side Channel</th>
          <th scope="col">Fabric 1</th>
          <th scope="col">Fabric 2</th>
          <th scope="col">Motor</th>
          <th scope="col">Extra Labor</th>
          <th scope="col">After Hours</th>
          <th scope="col">Supply Only</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="byId.length <= 0">
          <tr>
            <td colspan="15" class="colored-bg text-center">No Shade Line Items</td>
          </tr>
        </template>

        <template v-else v-for="(shadeLineItem) in byId">
          <takeoff-shade-line-item
            :shade-unit="shadeLineItem"
            :shade-unit-id="shadeLineItem.id">
          </takeoff-shade-line-item>
        </template>

      </tbody>
    </table>
  </div>
</template>
