<script>
  import { trimCutMixin } from '../../mixins/trimCutsMixin';
  import { fieldFormattingMixin } from '../../../mixins/fieldFormattingMixin'

  export default {
    props: [
      'masterTrimCutLineItem',
      'masterSelectOptions',
      'index'
    ],

    mixins: [fieldFormattingMixin, trimCutMixin],

    components: {},

    data() {
      return {
        trimCutLineItem: {
          id: null,
          room: null,
          trim_type: null,
          trim_width_in: null,
          trim_color: null,
          closure_size: null,
          pocket_height: null,
          pocket_depth: null,
          notes: null,
          isNew: false,
          hasChanges: false,
        },
        isNew: false,
        dataLoaded: false
      }
    },

    created() {

    },

    mounted() {
      this.trimCutLineItem = { ...this.trimCutLineItem, ...this.masterTrimCutLineItem };

      if( this.trimCutLineItem.isNew == true ) {
        this.isNew = true;
      }

      this.$nextTick(() => {
        this.dataLoaded = true;
      });
    },

    computed: {
      endpointDestroy() {
        return '/projects/order/trim-cuts/delete/' + this.trimCutLineItem.id;
      },

      hasChanges() {
        return this.trimCutLineItem.hasChanges;
      },

      pocketDepthOptions() {
        let allPocketDepthOptions = this.selectOptions.pocketDepthOptions;
        let availablePocketDepthOptions = [];

        switch(this.trimCutLineItem.pocket_height) {
          case '5.00':
            availablePocketDepthOptions = allPocketDepthOptions.filter(option => {
              return option.value <= 5.00;
            });
            break;

          case '7.00':
            availablePocketDepthOptions = allPocketDepthOptions.filter(option => {
              return option.value > 5.00;
            });
            break;

          default:
            availablePocketDepthOptions = allPocketDepthOptions;
            break;

        }

        return availablePocketDepthOptions;
      },

      isPocketTrimType() {
        var pocketValues = ['Pocket w/Lip', 'Pocket No Lip', '7" Pocket No Lip'];

        if( pocketValues.includes(this.trimCutLineItem.trim_type) ) {
          return true;
        }

        this.trimCutLineItem.pocket_height = null;
        this.trimCutLineItem.pocket_depth = null;
        return false;
      },

      pocketHeightIsLocked() {
        return (this.isPocketTrimType) ? false : true;
      },

      pocketDepthIsLocked() {
        if( this.isPocketTrimType === false || this.trimCutLineItem.pocket_height == null || this.trimCutLineItem.pocket_height == '5.00') {
          return true;
        }
        return false;
      },

      selectOptions() {
        return this.masterSelectOptions;
      },

      selectOptionPlaceholderText() {
        return '--Choose One--';
      },

      errors() {
        if( this.$parent.errors && this.$parent.errors.hasOwnProperty('order_trim_cuts')
              && this.$parent.errors.order_trim_cuts.hasOwnProperty(this.trimCutLineItem.id) ) {
          return this.$parent.errors['order_trim_cuts'][this.trimCutLineItem.id];
        }
        return [];
      },

      hasErrors() {
        if( Object.keys(this.errors).length > 0 ) {
          return true;
        }

        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return 'order_trim_cuts['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      destroy(event) {
        event.preventDefault();

        if( this.isNew == true ) {
          this.$parent.deleteRecord( this.trimCutLineItem );
          return;

        } else {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            this.$parent.deleteRecord( this.trimCutLineItem );
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      },

      save(event) {
        this.$parent.$parent.saveAndContinueTrimCuts(event);
      },
    },

    watch: {
      trimCutLineItem: {
        // This will let Vue know to look inside the array
        deep: true,

        // We have to move our method to a handler field
        handler(value) {
          if( this.dataLoaded === true && this.masterTrimCutLineItem !== this.trimCutLineItem ) {
            this.trimCutLineItem.hasChanges = true;
          }
        }
      },

      'trimCutLineItem.pocket_height': function (newVal, oldVal) {
        switch(newVal) {
          case '5.00':
            this.trimCutLineItem.pocket_depth = '5.00'
            break;

          case '7.00':
            if (this.pocketDepthOptions.filter(opt => opt.value == this.trimCutLineItem.pocket_depth).length <= 0) {
              this.trimCutLineItem.pocket_depth = null;
            }
            break;

          default:
            break;
        }
      }
    }

  };
</script>

<template>
  <tr v-trim-cut-field-directive>
    <td>
      <!-- //TODO - check the update method of the TrimCut Service and pull the requirement for this field out -->
      <input
        type="hidden"
        v-bind:name="getInputName(trimCutLineItem.id, 'id')"
        v-bind:id="getInputId(trimCutLineItem.id, 'id')"
        v-model="trimCutLineItem.id">

      <select
        class="form-control required"
        v-bind:class="errors['room'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(trimCutLineItem.id, 'room')"
        v-bind:id="getInputId(trimCutLineItem.id, 'room')"
        v-model="trimCutLineItem.room">

        <option :value="null" v-html="selectOptionPlaceholderText"></option>
        <option v-for="option in selectOptions.roomNameOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
    <td>
      <select
        class="form-control required"
        v-bind:class="errors['product_model_id'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(trimCutLineItem.id, 'product_model_id')"
        v-bind:id="getInputId(trimCutLineItem.id, 'product_model_id')"
        v-model="trimCutLineItem.product_model_id">

        <option :value="null" v-html="selectOptionPlaceholderText"></option>
        <option v-for="option in selectOptions.productModelOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
    <td>
      <select
        class="form-control required"
        v-bind:class="errors['trim_type'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(trimCutLineItem.id, 'trim_type')"
        v-bind:id="getInputId(trimCutLineItem.id, 'trim_type')"
        v-model="trimCutLineItem.trim_type">

        <option :value="null" v-html="selectOptionPlaceholderText"></option>
        <option v-for="option in selectOptions.trimTypeOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
    <td>
      <select
        class="form-control required"
        v-bind:class="errors['trim_color'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(trimCutLineItem.id, 'trim_color')"
        v-bind:id="getInputId(trimCutLineItem.id, 'trim_color')"
        v-model="trimCutLineItem.trim_color">

        <option :value="null" v-html="selectOptionPlaceholderText"></option>
        <option v-for="option in selectOptions.trimColorOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
    <td>
      <convert-measurement-text-input-mask
        :mask-type="'convertToInches'"
        :scale="'3'"
        :parent-record="trimCutLineItem"
        :parent-record-field-name="'trim_width_in'"
        :has-error="errors['trim_width_in'] ? true : false"
        :input-id="getInputId(trimCutLineItem.id, 'trim_width_in')"
        :input-name="getInputName(trimCutLineItem.id, 'trim_width_in')"
        placeholder='60.25"'
        input-class="required"
        v-model="trimCutLineItem.trim_width_in">
      </convert-measurement-text-input-mask>
    </td>
    <td>
      <select
        class="form-control"
        v-bind:class="errors['closure_size'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(trimCutLineItem.id, 'closure_size')"
        v-bind:id="getInputId(trimCutLineItem.id, 'closure_size')"
        v-model="trimCutLineItem.closure_size">

        <option :value="null" v-html="selectOptionPlaceholderText"></option>
        <option v-for="option in selectOptions.closureSizeOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
    <td>
      <select
        class="form-control"
        v-bind:class="errors['pocket_height'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(trimCutLineItem.id, 'pocket_height')"
        v-bind:id="getInputId(trimCutLineItem.id, 'pocket_height')"
        v-model="trimCutLineItem.pocket_height"
        :disabled="pocketHeightIsLocked">

        <option :value="null" v-html="selectOptionPlaceholderText"></option>
        <option v-for="option in selectOptions.pocketHeightOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
    <td>
      <select
        class="form-control"
        v-bind:class="errors['pocket_depth'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(trimCutLineItem.id, 'pocket_depth')"
        v-bind:id="getInputId(trimCutLineItem.id, 'pocket_depth')"
        v-model="trimCutLineItem.pocket_depth"
        :disabled="pocketDepthIsLocked">

        <option :value="null" v-html="selectOptionPlaceholderText"></option>
        <option v-for="option in pocketDepthOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
    <td>
      <textarea
        rows="1"
        class="form-control"
        placeholder="Trim cut notes"
        v-bind:class="errors['notes'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(trimCutLineItem.id, 'notes')"
        v-bind:id="getInputId(trimCutLineItem.id, 'notes')"
        v-model="trimCutLineItem.notes">
      </textarea>
    </td>
    <td class="text-nowrap">
      <a @click="save($event)" class="btn icon-only btn-save" title="Save"></a>
      <a @click="destroy($event)" class="btn icon-only btn-delete" title="Delete"></a>
    </td>
  </tr>
</template>
