<script>
  import ShadeUnitConfigs from '../estimating/estimate/shadeUnitConfigs/ShadeUnitConfigs';

  export default {
    props: [
      'currentUser',
      'shadeUnitConfigs',
      'primaryShadeSelectOptions'
    ],

    components: {
      ShadeUnitConfigs,
    },

    created() {
      this.$store.dispatch('estimationSelectOptions/initEstimationSelectOptions', {primaryShadeSelectOptions: this.primaryShadeSelectOptions});
      this.$store.dispatch('shadeUnitConfigs/initShadeUnitConfigs', {shadeUnitConfigs: this.shadeUnitConfigs});
    },

    mounted() {
      //
    },

    computed: {
      //
    },

    methods: {

    }
  };
</script>
