<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'currentOrderProposal',
    ],

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('FieldMeasureLabor.vue - Mounted');
        console.log(this.orderProposal);
        console.log('');
      */
    },

    computed: {
      orderProposal() {
        return this.currentOrderProposal;
      },

      errors() {
        return [];
      }
    },

    methods: {
      getInputName(field) {
        return this.$parent.getOrderProposalInputName(field);
      },

      getInputId(field) {
        return this.$parent.getOrderProposalInputId(field);
      },
    }
  };
</script>

<template>
  <div class="form-row estimate-widget field-measure-labor-expenses">
    <div class="form-group col-sm-12 mb-0">
      <h2>Field Measure Labor</h2>
    </div>

    <div class="form-group col-sm-12 table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th># Trips</th>
            <th>Labor Time (Hours)</th>
            <th>Total Labor Time (Hours)</th>
            <th>Total Labor Cost ($)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <input type="text"
                     class="form-control min-width-field"
                     v-bind:class="errors['field_measure_trips'] ? 'is-invalid' : ''"
                     placeholder="1"
                     v-bind:name="getInputName('field_measure_trips')"
                     v-bind:id="getInputId('field_measure_trips')"
                     v-model="orderProposal.field_measure_trips"
                     v-proposal-field-directive>
            </td>
            <td>
              <input type="text"
                     class="form-control min-width-field"
                     v-bind:class="errors['field_measure_labor_hours'] ? 'is-invalid' : ''"
                     placeholder="1"
                     v-bind:name="getInputName('field_measure_labor_hours')"
                     v-bind:id="getInputId('field_measure_labor_hours')"
                     v-model="orderProposal.field_measure_labor_hours"
                     v-proposal-field-directive>
            </td>
            <td>{{ orderProposal.fm_total_hours }}</td>
            <td>{{ orderProposal.fm_total_cost_formatted }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
