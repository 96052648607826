<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'currentOrderProposal',
    ],

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('DistanceToJobsite.vue - Mounted');
        console.log(this.orderProposal);
        console.log(this.currentOrderProposal);
        console.log('');
      */
    },

    computed: {
      orderProposalState() {
        return this.$store.getters['orderProposal/currentOrderProposal'];
      },

      orderProposal() {
        return this.currentOrderProposal;
      },

      errors() {
        return [];
      },
    },

    methods: {
      getInputName(field) {
        return this.$parent.getOrderProposalInputName(field);
      },

      getInputId(field) {
        return this.$parent.getOrderProposalInputId(field);
      }
    }
  };
</script>

<template>
  <div class="form-row estimate-widget jobsite-distance">
    <div class="form-group col-sm-12 mb-0">
      <h2>Job Distance/Travel</h2>
    </div>

    <div class="form-group col-sm-12">
      <table class="table">
        <thead>
          <tr>
            <th>Distance to Job</th>
            <th>Mileage Rate ($)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <input type="text"
                     class="form-control"
                     v-bind:class="errors['distance_to_jobsite'] ? 'is-invalid' : ''"
                     placeholder="1"
                     v-bind:name="getInputName('distance_to_jobsite')"
                     v-bind:id="getInputId('distance_to_jobsite')"
                     v-model="orderProposal.distance_to_jobsite"
                     v-proposal-field-directive>
            </td>
            <td>{{ orderProposal.mileage_rate_formatted }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
