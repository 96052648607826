<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'currentOrderProposal',
      'includeHeadingRow',
      'headingText',
    ],

    mixins: [proposalMixin],

    data() {
      return {
        defaultHeadingText: 'Markup Calculation'
      }
    },

    mounted() {
      /*
        console.log('MarkupCalculation.vue - Mounted');
        console.log(this.orderProposal);
        console.log('');
      */
    },

    computed: {
      orderProposal() {
        return this.currentOrderProposal;
      },

      errors() {
        return [];
      },

      showHeadingRow() {
        return ( this.includeHeadingRow == false ) ? false : true;
      },

      headingRowHeadingText() {
        var heading = '';

        if( this.showHeadingRow == true ) {
          heading = ( typeof this.headingText != 'undefined' && this.headingText != '' ) ? this.headingText : this.defaultHeadingText;
        }

        return heading;
      }
    },

    methods: {
      getInputName(field) {
        return this.$parent.getOrderProposalInputName(field);
      },

      getInputId(field) {
        return this.$parent.getOrderProposalInputId(field);
      },
    }
  };
</script>

<template>
  <div class="form-row estimate-widget markup-calculation">
    <div class="form-group col-sm-12 mb-0" v-if="showHeadingRow">
      <h2>{{ headingRowHeadingText }}</h2>
    </div>

    <div class="form-group col-sm-12 table-responsive">
      <table class="table">
        <tbody>
          <tr>
            <td class="text-nowrap">Overhead Rate</td>
            <td>{{ orderProposal.company_overhead_pct_formatted }}</td>
          </tr>
          <tr>
            <td class="text-nowrap">Profit Target</td>
            <td class="min-width-field">
              <input type="text"
                     class="form-control"
                     v-bind:class="errors['desired_profit_pct'] ? 'is-invalid' : ''"
                     placeholder="1"
                     v-bind:name="getInputName('desired_profit_pct')"
                     v-bind:id="getInputId('desired_profit_pct')"
                     v-model="orderProposal.desired_profit_pct"
                     v-proposal-field-directive>
            </td>
          </tr>
          <tr>
            <td class="text-nowrap">Total</td>
            <td>{{ orderProposal.total_overhead_profit_rate_formatted }}</td>
          </tr>
          <tr>
            <td class="text-nowrap">Markup</td>
            <td>{{ orderProposal.markup_rate_formatted }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
