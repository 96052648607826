<template>
  <div class="search-container" v-click-outside="clearSearch">
    <div class="wrapper">
      <input v-model="queryString"
              @keyup=startSearch
              class="form-control search-input"
              placeholder="Company, Project, Part, etc."
              autocomplete="no">
    </div>

    <div class="search-results" v-show="showResults">
      <template v-if="hasResults() == false">
        <div class="result no-results">
          <p class="record">No Results.</p>
        </div>
      </template>
      <template v-else>
        <div class="result" v-if="results.companies.length" v-for="record in results.companies">
          <a :href="companyUrl(record.id)" class="record">
            <span class="record-type">Company:</span>
            <span class="record-text">{{ record.name }}</span>
          </a>
        </div>
        <div class="result" v-if="results.projects.length" v-for="record in results.projects">
          <a :href="projectUrl(record.id)" class="record">
            <span class="record-type">Project:</span>
            <span class="record-text">{{ record.name }}</span>
          </a>
        </div>
        <div class="result" v-if="results.productModels.length" v-for="record in results.productModels">
          <a :href="productModelUrl(record.id)" class="record">
            <span class="record-type">Product Model:</span>
            <span class="record-text">{{ record.name }}</span>
          </a>
        </div>
        <div class="result" v-if="results.parts.length" v-for="record in results.parts">
          <a :href="partUrl(record.id)" class="record">
            <span class="record-type">Part:</span>
            <span class="record-text">{{ record.name }}</span>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {

    data() {
      return {
        queryString: '',
        results: [],
        showResults: false,
        minimumSearchChars: 3
      }
    },

    directives: {
      clickOutside: {
        bind: function (el, binding, vnode) {
          el.clickOutsideEvent = function (event) {
            // Was the click outside of the parent container and it's children?
            if (!(el == event.target || el.contains(event.target))) {
              vnode.context[binding.expression](event);
            }
          };
          document.body.addEventListener('click', el.clickOutsideEvent);
        },
        unbind: function (el) {
          document.body.removeEventListener('click', el.clickOutsideEvent);
        },
      }
    },

    computed: {
      endpoint() {
        return '/search/results';
      },

      axiosParams() {
        const params = new URLSearchParams();
        params.append('q', this.queryString);
        return params;
      }
    },

    methods: {
      startSearch() {

        if( this.queryString.length < this.minimumSearchChars ) {
          this.showResults = false;

        } else {
          axios.get(this.endpoint, {
            params: this.axiosParams
          })
          .then((response) => {
            this.results = response.data;
            this.showResults = true;
          })
          .catch(error => {
          });
        }
      },

      hasResults() {
        if( this.results.length == 0 ) {
          return false;
        } else {
          if( !this.results.companies.length
              && !this.results.projects.length
              && !this.results.productModels.length
              && !this.results.parts.length ) {
            return false;
          }
        }

        return true;
      },

      clearSearch() {
        this.queryString = '';
        this.showResults = false;
      },

      companyUrl(id) {
        return '/companies/' + id;
      },

      projectUrl(id) {
        return '/projects/' + id + '/show';
      },

      partUrl(id) {
        return '/admin/settings/products/parts/' + id + '/edit';
      },

      productModelUrl(id) {
        return '/admin/settings/products/models/' + id + '/edit';
      },
    }
  }
</script>
