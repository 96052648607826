<script>
  import draggable from 'vuedraggable';
  import ModalConfirm from '../../../notifications/ModalConfirm.vue';

  export default {
    props: [
      'parentManufacturer',
      'recordIndex'
    ],

    components: {
      ModalConfirm
    },

    data() {
      return {
        manufacturer: {
          id: null,
          name: null,
          type: null,
          isNew: false,
          can_delete_manufacturer: true
        }
      }
    },

    mounted() {
      this.manufacturer = { ...this.manufacturer, ...this.parentManufacturer };
    },

    computed: {
      endpointDestroy() {
        return '/admin/settings/manufacturers/' + this.manufacturer.id;
      },

      selectOptions() {
        return this.$parent.selectOptions;
      },

      isNew() {
        return ( this.manufacturer.isNew ) ? true : false;
      },

      canChangePartType() {
        return false;
      },

      canDeleteManufacturer() {
        return this.manufacturer.can_delete_manufacturer;
      },

      deleteButtonTooltip() {
        return 'All records assigned to this manufacturer must be removed before it can be deleted';
      },

      modalCancelAction() {
        return 'cancelModalAction';
      },

      modalDestroyConfirmButtonAction() {
        return 'destroy';
      },

      errors() {
        if( this.$parent.errors.hasOwnProperty('manufacturer') && this.$parent.errors.manufacturer.hasOwnProperty(this.manufacturer.id) ) {
          return this.$parent.errors['manufacturer'][this.manufacturer.id];
        }
        return [];
      },
    },


    methods: {
      getInputName(id, field) {
        return 'manufacturer['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      destroyManufacturerClick(event) {
        event.preventDefault();
        if( this.isNew == true ) {
          this.destroy();
        } else {
          this.$bvModal.show('modal-' + this.manufacturer.id);
        }
      },

      //Modal - Handle modal function call
      handleFunctionCall(functionName) {
        this[functionName]();
      },

      //Modal - Cancel out of an open/active modal without completeing action
      cancelModalAction() {
        return false;
      },

      destroy() {
        if( this.isNew === true ) {
          this.$parent.deleteManufacturer( this.manufacturer );

        } else {
          axios.delete(this.endpointDestroy)
          .then((response) => {
            this.$parent.deleteManufacturer( this.manufacturer );
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      },

      setErrors(errors) {
        this.errors = errors;
      },
    }
  };
</script>

<template>
  <div class="form-row manufacturer-record">
    <div class="form-group col-sm-12 col-md-5">
      <input
        type="text"
        placeholder="Manufacturer Name"
        class="form-control"
        v-bind:class="errors['name'] ? 'is-invalid' : ''"
        maxlength="255"
        v-bind:name="getInputName(manufacturer.id, 'name')"
        v-bind:id="getInputId(manufacturer.id, 'name')"
        v-model="manufacturer.name">

      <span class="invalid-feedback" role="alert" v-if="errors['name']">
        {{ errors['name'][0] }}
      </span>
    </div>

    <div class="form-group col-sm-12 col-md-2">
      <select
        class="form-control"
        v-bind:class="errors['type'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(manufacturer.id, 'type')"
        v-bind:id="getInputId(manufacturer.id, 'type')"
        v-model="manufacturer.type"
        :disabled="!canChangePartType">
        <option :value="null">--Choose One--</option>
        <option v-for="type in selectOptions.manufacturerTypeOptions" :value="type.value">{{ type.label }}</option>
      </select>

      <span class="invalid-feedback" role="alert" v-if="errors['type']">
        {{ errors['type'][0] }}
      </span>
    </div>

    <div class="form-group col-sm-12 col-md-3">
      <div class="btn-toolbar" role="toolbar">
        <div class="btn-group mr-2" role="group">
          <template v-if="canDeleteManufacturer">
            <button
              type="button"
              class="btn icon-only btn-delete"
              @click="destroyManufacturerClick($event)"
              title="Delete">
            </button>

            <modal-confirm
              :modal-id="manufacturer.id"
              :modal-title="'Are you sure?'"
              :modal-body="'Are you sure you want to delete this manufacturer? This action cannot be undone.'"
              :dismiss-button="'Cancel'"
              :confirm-button="'Delete'"
              :hide-header-close="true"
              @confirmed="handleFunctionCall(modalDestroyConfirmButtonAction)"
              @cancel="handleFunctionCall(modalCancelAction)">
            </modal-confirm>
          </template>
          <template v-else>
            <div
              class="tooltip-wrapper"
              data-toggle="tooltip"
              data-html="true"
              :title="deleteButtonTooltip">
              <button
                type="button"
                class="btn icon-only btn-delete"
                :disabled="!canDeleteManufacturer">
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
