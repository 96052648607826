//import { TOGGLE_SIDEBAR } from '../mutation-types'

import { mapGetters, mapActions } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'


export default {
  namespaced: true,


  //state
  state: {
    proposalProjectExpenses: [],
    errors: []
  },


  //getters
  getters: {
    proposalProjectExpenses: state => state.proposalProjectExpenses,
    //currentOrderProposal: state => state.orderProposals[0],
    //errors: state => state.errors,

    // vuex-map-fields plugin
    getField,
  },


  //actions
  actions: {
    applyProposalProjectExpense({commit, dispatch}, projectExpenses) {
      if( projectExpenses ) {
        projectExpenses.forEach(function(expense, index) {
          expense.percent = expense.percent_formatted;

          if( !expense.hasOwnProperty('isNew') ) {
            expense.isNew = false;
          }
        });

        commit('SET_PROPOSAL_PROJECT_EXPENSES', projectExpenses);
      }
    },
  },


  //mutations
  mutations: {
    SET_PROPOSAL_PROJECT_EXPENSES(state, projectExpenses) {
      state.proposalProjectExpenses = projectExpenses;
    },

    // vuex-map-fields plugin
    updateField,
  }
}
