<script>
  import PaymentTerm from './PaymentTerm';

  export default {
    props: [
      'paymentTerms',
      'selectOptions'
    ],

    components: {
      PaymentTerm
    },

    data() {
      return {
        paymentTermsList: []
      }
    },

    mounted() {
      this.paymentTermsList = this.paymentTerms;
    },

    computed: {
      endpointAddRow() {
        return '/admin/settings/app-config/proposals/payment-terms/add-row';
      },

      hasPaymentTerms() {
        return ( this.paymentTermsList && this.paymentTermsList.length > 0 ) ? true : false;
      },
    },

    methods: {
      deletePaymentTermRow(record) {
        let i = this.paymentTermsList.map(term => term.id).indexOf(record.id);
        this.paymentTermsList.splice(i, 1) // remove it from array
      },

      addPaymentTermRecord(record, isNew) {
        record.isNew = ( isNew === true ) ? true : false;
        this.paymentTermsList.push(record);
      },

      addPaymentTerm(event) {
        event.preventDefault();
        axios.post(this.endpointAddRow)
        .then((response) => {
          this.addPaymentTermRecord( response.data.data.paymentTerm, true );
        })
        .catch(error => {
          //flash(error.response.data.status_type, error.response.data.status);
        });

        return false;
      }
    }
  }
</script>
