var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-responsive" }, [
    _c("table", { staticClass: "table table-hover" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm.byId.length <= 0
            ? [_vm._m(1)]
            : _vm._l(_vm.byId, function (shadeLineItem) {
                return [
                  _c("takeoff-shade-line-item", {
                    attrs: {
                      "shade-unit": shadeLineItem,
                      "shade-unit-id": shadeLineItem.id,
                    },
                  }),
                ]
              }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Item")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Room")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Quantity")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Width (in)")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Length (in)")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Product Type")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Trim Material")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Coupled")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Side Channel")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Fabric 1")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Fabric 2")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Motor")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Extra Labor")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("After Hours")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Supply Only")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "td",
        { staticClass: "colored-bg text-center", attrs: { colspan: "15" } },
        [_vm._v("No Shade Line Items")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }