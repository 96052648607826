<script>
  import InstallationEquipment from './InstallationEquipment';

  export default {
    props: [
      'parentInstallationEquipments',
      'parentInstallationEquipmentSelections'
    ],

    components: {
      InstallationEquipment
    },

    data() {
      return {
        installationEquipmentSelections: []
      }
    },

    mounted() {
      this.installationEquipmentSelections = this.parentInstallationEquipmentSelections;
    },

    computed: {
      equipmentList() {
        return this.$parent.equipmentList;
      },

      isDisabled() {
        return false;
      },

      errors() {
        return this.$parent.errors;
      }
    }
  };
</script>
