<script>
  import { motorizedOrderItemAdditionMixin } from '../../mixins/motorizedOrderItemAdditionMixin';
  import MotorizedOrderItemAdditionCuts from './MotorizedOrderItemAdditionCuts';

  export default {
    props: [
      'additionalItem',
      'additionalItemId'
    ],

    mixins: [motorizedOrderItemAdditionMixin],

    components: {
      MotorizedOrderItemAdditionCuts,
    },

    mounted() {},

    computed: {
      orderProposal() {
        return this.$parent.currentOrderProposal;
      },

      currentAdditionalItem() {
        return this.additionalItem;
      },

      currentAdditionalItemPart() {
        return this.$store.getters['motorizedOrderItemAddition/orderItemAdditionPartById'](this.additionalItem.part_id);
      },

      currentAdditionalItemPartColor() {
        if( this.currentAdditionalItem && this.currentAdditionalItem.part_color_id ) {
          let selectedPartColor = this.currentAdditionalItemPart.part_colors.find(partColor => {
            return partColor.id == this.currentAdditionalItem.part_color_id;
          });
          return selectedPartColor;
        }
        return;
      },

      currentAdditionalItemCuts() {
        return [];
      },

      orderItemAdditionSelectOptions() {
        return this.$parent.orderItemAdditionSelectOptions;
      },

      isLinearFootPart() {
        if( this.partUom == 'Linear Feet' ) {
          return true;
        }
        return false;
      },

      hasSelectedPart() {
        return ( this.currentAdditionalItemPart !== null ) ? true : false;
      },

      hasPartColors() {
        if( this.hasSelectedPart == true && this.currentAdditionalItemPart.hasOwnProperty('part_colors') && this.currentAdditionalItemPart.part_colors.length) {
          return true;
        } else {
          return false;
        }
      },

      partColorOptions() {
        if( this.hasPartColors == false ) {
          return [];
        } else {
          return this.currentAdditionalItemPart.part_colors;
        }
      },

      partNumber() {
        if( this.hasSelectedPart == true ) {
          if( this.currentAdditionalItemPartColor ) {
            return this.currentAdditionalItemPartColor.part_number;
          } else {
            return this.currentAdditionalItemPart.part_number;
          }
        }
        return '';
      },

      partUom() {
        if( this.hasSelectedPart == true ) {
          return this.currentAdditionalItemPart.uom;
        } else {
          return '';
        }
      },

      errors() {
        return this.$store.getters['motorizedOrderItemAddition/getErrorsById'](this.currentAdditionalItem.id);
      }
    },

    methods: {
      getInputName(id, field) {
        return 'order_item_additions['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      saveOrderItemAddition(event) {
        event.preventDefault();
        this.$store.dispatch('motorizedOrderItemAddition/saveOrderItemAddition', this.additionalItem.id);
      },

      deleteOrderItemAddition(event) {
        event.preventDefault();
        this.$store.dispatch('motorizedOrderItemAddition/deleteOrderItemAddition', this.currentAdditionalItem.id);
      }
    },

    watch: {
      'partUom': function (newVal, oldVal) {
        if( newVal == 'Linear Feet' && this.currentAdditionalItem.isNew == true ) {
          this.$store.dispatch('motorizedOrderItemAdditionCuts/addNewOrderItemAdditionCut', this.currentAdditionalItem.id);
        }
      },

      'currentAdditionalItem.part_id': function (newVal, oldVal) {
        if( newVal != oldVal ) {
          this.currentAdditionalItem.part_color_id = null;
          //this.currentAdditionalItem.quantity = null;
        }
      },

      'currentAdditionalItem.quantity': function (newVal, oldVal) {
        if( newVal != oldVal ) {
          //this.currentAdditionalItem.addtl_item_total_cost_formatted = null;
        }
      },
    }
  };
</script>

<template>
  <tbody>
    <tr class="require-all">
      <td>
        <select
          class="form-control"
          v-bind:class="errors['part_id'] ? 'is-invalid' : ''"
          v-bind:name="getInputName(currentAdditionalItem.id, 'part_id')"
          v-bind:id="getInputId(currentAdditionalItem.id, 'part_id')"
          v-model="currentAdditionalItem.part_id"
          v-motorized-order-item-addition-field-directive>
          <option :value="null">--Choose One--</option>
          <optgroup v-for="partType in orderItemAdditionSelectOptions" :label="partType.name">
            <option v-for="part in partType.parts" :value="part.id">{{ part.name }}</option>
          </optgroup>
        </select>
      </td>
      <td>
        <select
          class="form-control"
          v-bind:class="errors['part_color_id'] ? 'is-invalid' : ''"
          v-bind:name="getInputName(currentAdditionalItem.id, 'part_color_id')"
          v-bind:id="getInputId(currentAdditionalItem.id, 'part_color_id')"
          v-model="currentAdditionalItem.part_color_id"
          :disabled="hasPartColors == false"
          v-motorized-order-item-addition-field-directive>
          <option :value="null">--Choose One--</option>
          <template v-if="hasPartColors == true" v-for="partColor in partColorOptions">
            <option :value="partColor.id">{{ partColor.color }}</option>
          </template>
        </select>
      </td>
      <td>{{ partNumber }}</td>
      <td>{{ partUom }}</td>
      <td>
        <input type="integer"
               class="form-control"
               v-bind:class="errors['quantity'] ? 'is-invalid' : ''"
               placeholder="1"
               v-bind:name="getInputName(currentAdditionalItem.id, 'quantity')"
               v-bind:id="getInputId(currentAdditionalItem.id, 'quantity')"
               v-model="currentAdditionalItem.quantity"
               :disabled="isLinearFootPart"
               v-motorized-order-item-addition-field-directive>
      </td>
      <td>{{ currentAdditionalItem.addtl_item_unit_price_formatted }}</td>
      <td>{{ currentAdditionalItem.addtl_item_total_price_formatted }}</td>
      <td>
        <a
          href="#"
          class="btn icon-only btn-save"
          v-bind:class="orderProposal.userCanEdit ? '' : 'disabled'"
          @click="saveOrderItemAddition($event)"
          title="Save">
        </a>
        <a
          href="#"
          class="btn icon-only btn-delete"
          v-bind:class="orderProposal.userCanEdit ? '' : 'disabled'"
          @click="deleteOrderItemAddition($event)"
          title="Delete">
        </a>
      </td>
    </tr>

    <template v-if="isLinearFootPart">
      <tr class="require-all">
        <td colspan="8">
          <motorized-order-item-addition-cuts
            :additional-item="currentAdditionalItem"
            :additional-item-id="additionalItem.id"
            :current-order-proposal="orderProposal"
            :master-order-item-addition-cuts="currentAdditionalItem.order_item_addition_cuts">
          </motorized-order-item-addition-cuts>
        </td>
      </tr>
    </template>
  </tbody>
</template>
