<script>
  import { orderItemAdditionMixin } from '../../mixins/orderItemAdditionMixin';

  export default {
    props: [
      'additionalCutItem'
    ],

    mixins: [orderItemAdditionMixin],

    mounted() {},

    computed: {
      orderProposal() {
        return this.$parent.currentOrderProposal;
      },

      additionalItem() {
        return this.$parent.additionalItem;
      },

      currentAdditionalCutItem() {
        return this.additionalCutItem;
      },

      cutIndex() {
        let payload = {
          orderItemAdditionId: this.additionalCutItem.order_item_addition_id,
          orderItemAdditionCutId: this.additionalCutItem.id
        };
        return this.$store.getters['orderItemAdditionCuts/getCutIndex'](payload);
      },

      cutName() {
        return 'Cut ' + (this.cutIndex + 1);
      },

      currentAdditionalItemPart() {
        return this.$store.getters['orderItemAddition/orderItemAdditionPartById'](this.additionalItem.part_id);
      },

      canDeleteCut() {
        if( !this.orderProposal.userCanEdit ) {
          return false;
        }

        if( this.$parent.orderItemAdditionCuts.length <= 1 ) {
          return false;
        }

        return true;
      },

      errors() {
        return ( this.$parent.errors[this.cutIndex] ) ? this.$parent.errors[this.cutIndex] : [];
      },
    },

    methods: {
      getInputName(id, field) {
        return 'order_item_additions['+ id +'][cut][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      saveOrderItemAddition(event) {
        this.$parent.$parent.saveOrderItemAddition(event);
      },

      deleteOrderItemAdditionCut(event) {
        event.preventDefault();
        this.$store.dispatch('orderItemAdditionCuts/deleteOrderItemAdditionCut', this.currentAdditionalCutItem.id);
      }
    },

    watch: {
      'currentAdditionalCutItem.quantity': function (newVal, oldVal) {
        if( newVal != oldVal ) {
          let totalFeet = this.$store.getters['orderItemAdditionCuts/getTotalLinearFeetByOrderItemAdditionId'](this.additionalCutItem.order_item_addition_id);
          this.$parent.$parent.currentAdditionalItem.quantity = totalFeet;
        }
      },

      'currentAdditionalCutItem.cut_length_in': function (newVal, oldVal) {
        if( newVal != oldVal ) {
          let totalFeet = this.$store.getters['orderItemAdditionCuts/getTotalLinearFeetByOrderItemAdditionId'](this.additionalCutItem.order_item_addition_id);
          this.$parent.$parent.currentAdditionalItem.quantity = totalFeet;
        }
      }
    }
  };
</script>

<template>
  <tr class="require-all">
    <td>{{ cutName }}</td>
    <td>
      <convert-measurement-text-input-mask
        :mask-type="'convertToInches'"
        :scale="'3'"
        :parent-record="currentAdditionalCutItem"
        :parent-record-field-name="'cut_length_in'"
        :is-managed-by-vuex="'true'"
        :has-error="errors['cut_length_in'] ? true : false"
        :input-id="getInputId(currentAdditionalCutItem.id, 'cut_length_in')"
        :input-name="getInputName(currentAdditionalCutItem.id, 'cut_length_in')"
        placeholder='48.75"'
        v-order-item-addition-field-directive>
      </convert-measurement-text-input-mask>
    </td>
    <td>
      <input type="number"
             class="form-control"
             v-bind:class="errors['quantity'] ? 'is-invalid' : ''"
             placeholder="1"
             v-bind:name="getInputName(currentAdditionalCutItem.id, 'quantity')"
             v-bind:id="getInputId(currentAdditionalCutItem.id, 'quantity')"
             v-model="currentAdditionalCutItem.quantity"
             v-order-item-addition-field-directive>
    </td>
    <td>
      <a
        href="#"
        class="btn icon-only btn-save"
        v-bind:class="orderProposal.userCanEdit ? '' : 'disabled'"
        @click="saveOrderItemAddition($event)"
        title="Save">
      </a>
      <a
        href="#"
        class="btn icon-only btn-delete"
        v-bind:class="(canDeleteCut == true) ? '' : 'disabled'"
        @click="deleteOrderItemAdditionCut($event)"
        title="Delete">
      </a>
    </td>
  </tr>
</template>
