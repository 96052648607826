//import { TOGGLE_SIDEBAR } from '../mutation-types'

import { mapGetters, mapActions } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import {
  ENDPOINT_ORDER_ITEM_ADDITION_NEW,
  ENDPOINT_ORDER_ITEM_ADDITION_DESTROY
} from '../../endpoints'
import { v4 as uuidv4 } from 'uuid'


export default {
  namespaced: true,


  //state
  state: {
    byId: {},
    byOrderItemAdditionId: {},
    allIds: [],
    initialStateById: {},
    errors: []
  },


  //getters
  getters: {
    //
    // Flattened Data Approach
    //
    find: state => id => state.byId[id],

    list: (state, getters) => {
      return state.allIds.map(id => getters.find(id));
    },

    getCutIndex: (state, getters) => payload => {
      return state.byOrderItemAdditionId[payload.orderItemAdditionId].motorizedOrderItemAdditionCuts.findIndex(cut => cut.id === payload.orderItemAdditionCutId);
    },

    getTotalLinearFeetByOrderItemAdditionId: (state, getters) => id => {
      let orderItemAdditionCuts = state.byOrderItemAdditionId[id].motorizedOrderItemAdditionCuts,
          totalInches = 0,
          totalFeet = 0;

      if( orderItemAdditionCuts.length ) {
        orderItemAdditionCuts.forEach(cut => {
          let cutLength = (cut.cut_length_in === null) ? 0 : cut.cut_length_in.toString().replace('"', ''); //remove the inches formatting
          let lengthInches = ( cutLength && isNaN(cutLength) === false ) ? cutLength : 0,
              quantity = ( cut.quantity && isNaN(cut.quantity) === false ) ? cut.quantity : 0;
          totalInches += (lengthInches * quantity);
        });
      }
      totalFeet = Math.ceil(totalInches / 12);
      return totalFeet;
    },

    getInitialStateById: state => id => state.initialStateById[id],

    // vuex-map-fields plugin
    getField,
  },


  //actions
  actions: {
    //
    // Apply orderItemAdditionsCuts
    //
    applyOrderItemAdditionCuts({state, commit, dispatch}, orderItemAdditionCuts) {
      if( Array.isArray(orderItemAdditionCuts) && orderItemAdditionCuts.length > 0 ) {
        orderItemAdditionCuts.forEach(function(item, index) {
          if( !item.hasOwnProperty('isNew') ) {
            item.isNew = false;
          }

          commit('ADD_ORDER_ITEM_ADDITION_CUT', item);
        });
      }
    },

    //
    // Update orderItemAdditionsCuts
    //
    updateOrderItemAdditionCuts({state, commit, dispatch}, orderItemAdditionCuts) {
      //dispatch('setFormErrors', {});
      if( Array.isArray(orderItemAdditionCuts) && orderItemAdditionCuts.length > 0 ) {
        orderItemAdditionCuts.forEach(function(item, index) {
          item.isNew = false;
          commit('UPDATE_EXISTING_ORDER_ITEM_ADDITION_CUT', item);
        });
      }
    },

    //
    // Update NEW orderItemAdditionsCuts
    //   New Order Item Addition Cuts are records have just been saved to the database
    //   The initial records exist in state, this method is going to swap the old records with these new ones.
    //
    updateNewOrderItemAdditionCuts({state, commit, dispatch}, payload) {
      let orderItemAdditionId = payload.orderItemAdditionId,
          oldOrderItemAdditionId = payload.oldOrderItemAdditionId,
          orderItemAdditionCuts = payload.orderItemAdditionCuts;

      if( Array.isArray(orderItemAdditionCuts) && orderItemAdditionCuts.length > 0 ) {
        orderItemAdditionCuts.forEach(function(item, index) {
          item.isNew = false;

          let oiaCutPayload = {
            orderItemAdditionId: orderItemAdditionId,
            oldOrderItemAdditionId: oldOrderItemAdditionId,
            item: item
          };
          commit('UPDATE_NEW_ORDER_ITEM_ADDITION_CUT', oiaCutPayload);
        });
      }
    },

    //
    // Add a new orderItemAdditionCut Record
    //
    addNewOrderItemAdditionCut({commit, state, dispatch}, orderItemAdditionId) {
      let newCut = {
        id: uuidv4(),
        sort_order_number: null,
        order_item_addition_id: orderItemAdditionId,
        quantity: null,
        cut_length_in: null,
        isNew: true
      };

      dispatch('motorizedOrderItemAddition/addNewOrderItemAdditionCut', {
          orderItemAdditionId: orderItemAdditionId,
          newCut: newCut
        }, { root: true });

      commit('ADD_ORDER_ITEM_ADDITION_CUT', newCut);
    },

    //
    // Delete order item addition record
    //
    deleteOrderItemAdditionCut({commit, state, getters, dispatch, rootGetters}, id) {
      let targetRecord = getters.find(id),
          orderItemAdditionCutList = state.byOrderItemAdditionId[targetRecord.order_item_addition_id].motorizedOrderItemAdditionCuts;

      if( orderItemAdditionCutList.length <= 1 ) {
        flash('alert-danger', 'Linear foot parts must have at least 1 cut specified');
        return false;
      }

      let payload = {
        orderItemAdditionId: targetRecord.order_item_addition_id,
        orderItemAdditionCutId: targetRecord.id
      };
      dispatch('motorizedOrderItemAddition/deleteOrderItemAdditionCut', payload, {root: true});

      if( targetRecord.isNew ) {
        commit('DELETE_ORDER_ITEM_ADDITION_CUT', targetRecord);
      } else {
        commit('DELETE_ORDER_ITEM_ADDITION_CUT', targetRecord);
        dispatch('motorizedOrderItemAddition/saveOrderItemAddition', targetRecord.order_item_addition_id, {root: true});
      }
    },
  },


  //mutations
  mutations: {
    ADD_ORDER_ITEM_ADDITION_CUT(state, item) {
      //Set state for cuts by id
      Vue.set(state.byId, item.id, item);

      //Set the initial state (so we can easily revert to previous object if necessary)
      Vue.set(state.initialStateById, item.id, _.cloneDeep(item));

      if( typeof state.byOrderItemAdditionId[item.order_item_addition_id] == 'undefined' ) {
        state.byOrderItemAdditionId = Object.assign({}, state.byOrderItemAdditionId, { [item.order_item_addition_id]: { motorizedOrderItemAdditionCuts: [] } });
      }
      state.byOrderItemAdditionId[item.order_item_addition_id].motorizedOrderItemAdditionCuts.push(item);

      //Set state for all shade line item ids
      if (state.allIds.includes(item.id)) return;
      state.allIds.push(item.id);
    },

    UPDATE_EXISTING_ORDER_ITEM_ADDITION_CUT(state, updatedItem) {
      var item = state.byId[updatedItem.id];
      item = _.merge(item, updatedItem);

      //Set state for shade line items byId
      Vue.set(state.byId, item.id, item);

      //Set the initial state (so we can easily revert to previous object if necessary)
      Vue.set(state.initialStateById, item.id, _.cloneDeep(item));

      if( typeof state.byOrderItemAdditionId[item.order_item_addition_id] != 'undefined' ) {
        //And the group record...
        var index = state.byOrderItemAdditionId[item.order_item_addition_id].motorizedOrderItemAdditionCuts.map(oiaCut => oiaCut.id).indexOf(item.id);
        state.byOrderItemAdditionId[item.order_item_addition_id].motorizedOrderItemAdditionCuts.splice(index, 1, item);
      }
    },

    UPDATE_NEW_ORDER_ITEM_ADDITION_CUT(state, payload) {
      let orderItemAdditionId = payload.orderItemAdditionId,
          oldOrderItemAdditionId = payload.oldOrderItemAdditionId,
          item = payload.item;

      //Set state for cuts by id
      Vue.set(state.byId, item.id, item);

      //Set the initial state (so we can easily revert to previous object if necessary)
      Vue.set(state.initialStateById, item.id, _.cloneDeep(item));

      if( typeof state.byOrderItemAdditionId[item.order_item_addition_id] == 'undefined' ) {
        state.byOrderItemAdditionId = Object.assign({}, state.byOrderItemAdditionId, { [item.order_item_addition_id]: { motorizedOrderItemAdditionCuts: [] } });
      }
      state.byOrderItemAdditionId[item.order_item_addition_id].motorizedOrderItemAdditionCuts.push(item);

      //Set state for all shade line item ids
      if (state.allIds.includes(item.id)) return;
      state.allIds.push(item.id);

      if( item.order_item_addition_id != oldOrderItemAdditionId ) {
        //Clear out the old records
        delete state.byOrderItemAdditionId[oldOrderItemAdditionId];
      }
    },

    DELETE_ORDER_ITEM_ADDITION_CUT(state, item) {
      var index = state.allIds.indexOf(item.id);
      state.allIds.splice(index, 1);

      delete state.byId[item.id];
      delete state.initialStateById[item.id];

      state.byOrderItemAdditionId[item.order_item_addition_id].motorizedOrderItemAdditionCuts.forEach(function(oiaCut, index) {
        if( oiaCut.id == item.id ) {
          state.byOrderItemAdditionId[item.order_item_addition_id].motorizedOrderItemAdditionCuts.splice(index, 1);
        }
      });
    },

    // vuex-map-fields plugin
    updateField,
  }
}
