<script>
  export default {
    props: [
      'recordId',
      'formEndpoint',
      'followResponseRedirect',
      'actionElementType',
      'actionElementText',
      'actionElementCssClass',
      'actionElementExcludeIcon',
    ],

    data() {
      return {
        elementType: 'link',
        elementText: null,
        elementCssClass: null,
        elementExcludeIcon: false,
        followRedirect: true
      }
    },

    mounted() {
      this.elementType = ( this.actionElementType != 'button' ) ? 'link' : 'button';
      this.elementText = this.actionElementText;
      this.elementCssClass = ( this.actionElementCssClass != '' ) ? this.actionElementCssClass : this.elementCssClass;
      this.followRedirect = ( this.followResponseRedirect == false ) ? false : this.followRedirect;
      this.elementExcludeIcon = ( this.actionElementExcludeIcon == true ) ? true : false;
    },

    computed: {
      endpointCopy() {
        if( this.formEndpoint == '' || this.recordId == '' ) {
          return '';
        }
        return this.formEndpoint + '/' + this.recordId;
      }
    },

    methods: {
      submitForm(event) {
        event.preventDefault();
        if( this.endpointCopy != '' ) {
          axios.post(this.endpointCopy)
          .then((response) => {
            if( this.followRedirect == true && response.data.redirectTo != null ) {
              window.location.href = response.data.redirectTo;
            }

            if( response.data.status_type != null && response.data.status != null ) {
              flash(response.data.status_type, response.data.status);
            }
          })
          .catch(error => {
            flash(error.response.data.status_type, error.response.data.status);
          });
        }
      },
    }
  };
</script>

<template>
  <div>
    <template v-if="elementType == 'link'">
      <a href="#" v-bind:class="elementCssClass" @click="submitForm($event)" title="Copy">
        {{ elementText }}
      </a>
    </template>
    <template v-else>
      <button type="button" v-bind:class="elementCssClass" @click="submitForm($event)" title="Copy">
        {{ elementText }}
      </button>
    </template>
  </div>
</template>
