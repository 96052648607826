var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-sm-12" }, [
      _c("table", { staticClass: "form" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", [
            _c("td", [_vm._v("Field Measure")]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.orderProposal.fm_labor_hours))]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.fm_labor_cost_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Installation")]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.orderProposal.install_labor_hours))]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.install_labor_cost_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Travel")]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.orderProposal.travel_total_hours))]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.travel_lodging_total_formatted)),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Total")]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.orderProposal.total_labor_hours))]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.orderProposal.total_labor_cost_formatted)),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
      _c("h2", [_vm._v("Installation Labor")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("Total Time (Hours)")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total Cost ($)")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }