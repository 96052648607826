<script>
  export default {
    props: [
      'masterUser',
      'masterContactInfo',
      'masterCompanyTypeId'
    ],

    components: {},

    data() {
      return {
        user: {
          id: null,
          contact_id: null,
          email: null,
          inactive: null,
          password: null,
          password_confirmation: null,
          deleted_at: null,
        },
        contactInfo: {
          first_name: null,
          last_name: null,
          title: null,
          email: null,
          office_phone: null,
          office_phone_ext: null,
          cell_phone: null,
          fax_number: null
        },
        user_role: null,

        errorsContactInfo: [],
        errorsUser: [],
        errorsUserRole: []
      }
    },

    mounted() {
      this.user = { ...this.user, ...this.masterUser };
      this.contactInfo = { ...this.contactInfo, ...this.masterContactInfo };

      /*
        console.log('UserForNewCompanyForm.vue Mounted...');
        console.log('this.masterUser');
        console.log(this.masterUser);
        console.log('this.masterContactInfo');
        console.log(this.masterContactInfo);
        console.log('this.user');
        console.log(this.user);
        console.log('this.contactInfo');
        console.log(this.contactInfo);
        console.log('this.masterSelectOptions');
        console.log(this.masterSelectOptions);
        console.log('this.selectOptions');
        console.log(this.selectOptions);
        console.log(this.userRoleSelectOptions);
        console.log('');
      */
    },

    computed: {
      selectOptions() {
        return this.$parent.selectOptions.userSelectOptions;
      },

      userRoleSelectOptions() {
        var userRoleOptions = this.selectOptions.userRoles;
        var options = [];

        if( userRoleOptions.length ) {
          switch( this.masterCompanyTypeId ) {
            case 1 :
              options = userRoleOptions.filter(function(opt) {
                if( opt.type == 'Admin' || opt.type == 'Lutek Employee' ) {
                  return opt;
                }
              });
              break;

            case 2 :
              options = userRoleOptions.filter(function(opt) {
                return opt.type == 'Dealer';
              });
              break;

            case 3 :
              options = userRoleOptions.filter(function(opt) {
                return opt.type == 'Designer';
              });
              break;
          }
        }
        return options;
      },

      isFieldDisabled() {
        return this.$parent.isFieldDisabled;
      }
    },

    methods: {
      getInputName(field) {
        return 'user['+ field +']';
      },

      getInputId(field) {
        var inputName = this.getInputName(field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      getContactInfoInputName(field) {
        return 'contact_info['+ field +']';
      },

      getContactInfoInputId(field) {
        var inputName = this.getContactInfoInputName(field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      getUserRoleInputName(field) {
        return 'user_role';
      },

      getUserRoleInputId(field) {
        return this.getUserRoleInputName(field);
      },

      setErrors(errors) {
        this.errorsUser = ( errors.hasOwnProperty('user') ) ? errors.user : {};
        this.errorsContactInfo = ( errors.hasOwnProperty('contact_info') ) ? errors.contact_info : {};
        this.errorsUserRole = ( errors.hasOwnProperty('user_role') ) ? errors.user_role : {};
      }
    }
  }
</script>
