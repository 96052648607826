<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'currentOrderProposal',
    ],

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('Margin.vue - Mounted');
        console.log(this.orderProposal);
        console.log('');
      */
    },

    computed: {
      orderProposal() {
        return this.currentOrderProposal;
      },

      errors() {
        return this.$store.state.orderProposal.errors;
      },
    },

    methods: {
      getInputName(field) {
        return this.$parent.getOrderProposalInputName(field);
      },

      getInputId(field) {
        return this.$parent.getOrderProposalInputId(field);
      },
    }
  };
</script>

<template>
  <div class="form-row estimate-widget cost-adj-rate">
    <div class="form-group col-sm-12 mb-0">
      <h2>Cost Adjustment Rate</h2>
    </div>

    <div class="form-group col-sm-12">
      <table class="form">
        <tbody>
          <tr>
            <td class="text-nowrap">Adj Rate %</td>
            <td>
              <input type="text"
                     class="form-control"
                     v-bind:class="errors['margin_pct'] ? 'is-invalid' : ''"
                     placeholder="1"
                     v-bind:name="getInputName('margin_pct')"
                     v-bind:id="getInputId('margin_pct')"
                     v-model="orderProposal.margin_pct"
                     v-proposal-field-directive>
            </td>
          </tr>
          <tr>
            <td class="text-nowrap">Part Adj Rate %</td>
            <td>
              <input type="text"
                     class="form-control"
                     v-bind:class="errors['part_cost_adjustment_pct'] ? 'is-invalid' : ''"
                     placeholder="1"
                     v-bind:name="getInputName('part_cost_adjustment_pct')"
                     v-bind:id="getInputId('part_cost_adjustment_pct')"
                     v-model="orderProposal.part_cost_adjustment_pct"
                     v-proposal-field-directive>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
