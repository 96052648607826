<script>
  import draggable from 'vuedraggable'
  import SortableRecord from './SortableRecord'

  export default {
    props: [
      'parentSortableList',
      'endpointSort'
    ],

    components: {
      draggable,
      SortableRecord,
    },

    data() {
      return {
        sortableList: []
      }
    },

    mounted() {
      this.sortableList = this.parentSortableList;
    },

    methods: {
      saveSortOrder(event) {
        let endpoint = this.endpointSort,
            sort_order = this.sortableList.map(record => record.id),
            submitData = {
              _method: 'PUT',
              sort_order: sort_order
            };
        axios.post(endpoint, submitData)
        .then((response) => {
          //
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status); //show the error message
        });
      },

      deleteSortableRecord(record) {
        let i = this.sortableList.map(obj => obj.id).indexOf(record.id);
        this.sortableList.splice(i, 1) // remove it from array
      },
    }
  };
</script>
