<script>
  import CoordinationLabor from './CoordinationLabor';

  export default {
    props: [
      'coordinationLabors'
    ],

    components: {
      CoordinationLabor
    },

    data() {
      return {
        coordinationLaborList: []
      }
    },

    mounted() {
      this.coordinationLaborList = this.coordinationLabors;
    },

    computed: {
      endpointAddRow() {
        return '/admin/settings/app-config/proposals/coordination-labors/add-row';
      },

      hasCoordinationLabors() {
        return ( this.coordinationLaborList && this.coordinationLaborList.length > 0 ) ? true : false;
      },
    },

    methods: {
      deleteCoordinationLaborRow(record) {
        let i = this.coordinationLaborList.map(laborRec => laborRec.id).indexOf(record.id);
        this.coordinationLaborList.splice(i, 1) // remove it from array
      },

      addCoordinationLaborRecord(record, isNew) {
        record.isNew = ( isNew === true ) ? true : false;
        this.coordinationLaborList.push(record);
      },

      addCoordinationLabor(event) {
        event.preventDefault();
        axios.post(this.endpointAddRow)
        .then((response) => {
          this.addCoordinationLaborRecord( response.data.data.coordinationLabor, true );
        })
        .catch(error => {
          //flash(error.response.data.status_type, error.response.data.status);
        });

        return false;
      },

      setErrors(errors) {
        var that = this;

        $.each(errors, function(index, coordinationLaborError) {
          let i = that.coordinationLaborList.map(laborRec => laborRec.id).indexOf(parseInt(index));
          if( i >= 0 ) {
            var refName = 'coordinationLabor_'+that.coordinationLaborList[i].id;
            that.$refs[refName][0].setErrors(coordinationLaborError);
          }
        });
      }
    }
  }
</script>
