var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row manufacturer-record" }, [
    _c("div", { staticClass: "form-group col-sm-12 col-md-5" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.manufacturer.name,
            expression: "manufacturer.name",
          },
        ],
        staticClass: "form-control",
        class: _vm.errors["name"] ? "is-invalid" : "",
        attrs: {
          type: "text",
          placeholder: "Manufacturer Name",
          maxlength: "255",
          name: _vm.getInputName(_vm.manufacturer.id, "name"),
          id: _vm.getInputId(_vm.manufacturer.id, "name"),
        },
        domProps: { value: _vm.manufacturer.name },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.manufacturer, "name", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _vm.errors["name"]
        ? _c(
            "span",
            { staticClass: "invalid-feedback", attrs: { role: "alert" } },
            [_vm._v("\n      " + _vm._s(_vm.errors["name"][0]) + "\n    ")]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-sm-12 col-md-2" }, [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.manufacturer.type,
              expression: "manufacturer.type",
            },
          ],
          staticClass: "form-control",
          class: _vm.errors["type"] ? "is-invalid" : "",
          attrs: {
            name: _vm.getInputName(_vm.manufacturer.id, "type"),
            id: _vm.getInputId(_vm.manufacturer.id, "type"),
            disabled: !_vm.canChangePartType,
          },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.manufacturer,
                "type",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { domProps: { value: null } }, [
            _vm._v("--Choose One--"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.selectOptions.manufacturerTypeOptions, function (type) {
            return _c("option", { domProps: { value: type.value } }, [
              _vm._v(_vm._s(type.label)),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.errors["type"]
        ? _c(
            "span",
            { staticClass: "invalid-feedback", attrs: { role: "alert" } },
            [_vm._v("\n      " + _vm._s(_vm.errors["type"][0]) + "\n    ")]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-sm-12 col-md-3" }, [
      _c("div", { staticClass: "btn-toolbar", attrs: { role: "toolbar" } }, [
        _c(
          "div",
          { staticClass: "btn-group mr-2", attrs: { role: "group" } },
          [
            _vm.canDeleteManufacturer
              ? [
                  _c("button", {
                    staticClass: "btn icon-only btn-delete",
                    attrs: { type: "button", title: "Delete" },
                    on: {
                      click: function ($event) {
                        return _vm.destroyManufacturerClick($event)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("modal-confirm", {
                    attrs: {
                      "modal-id": _vm.manufacturer.id,
                      "modal-title": "Are you sure?",
                      "modal-body":
                        "Are you sure you want to delete this manufacturer? This action cannot be undone.",
                      "dismiss-button": "Cancel",
                      "confirm-button": "Delete",
                      "hide-header-close": true,
                    },
                    on: {
                      confirmed: function ($event) {
                        return _vm.handleFunctionCall(
                          _vm.modalDestroyConfirmButtonAction
                        )
                      },
                      cancel: function ($event) {
                        return _vm.handleFunctionCall(_vm.modalCancelAction)
                      },
                    },
                  }),
                ]
              : [
                  _c(
                    "div",
                    {
                      staticClass: "tooltip-wrapper",
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-html": "true",
                        title: _vm.deleteButtonTooltip,
                      },
                    },
                    [
                      _c("button", {
                        staticClass: "btn icon-only btn-delete",
                        attrs: {
                          type: "button",
                          disabled: !_vm.canDeleteManufacturer,
                        },
                      }),
                    ]
                  ),
                ],
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }