<script>
  import { mapMultiRowFields } from 'vuex-map-fields';
  import ShadeUnitConfig from './ShadeUnitConfig';
  import ModalAddEstimatorShadeUnitConfigs from './ModalAddEstimatorShadeUnitConfigs';

  export default {
    props: [
      'isUserGlobalConfigs',
      'maxConfigsAllowed'
    ],

    components: {
      ShadeUnitConfig,
      ModalAddEstimatorShadeUnitConfigs
    },

    mounted() {},

    computed: {
      shadeOrder() {
        return this.$store.state.shadeOrder.shadeOrder;
      },

      orderProposal() {
        return this.$store.getters['orderProposal/currentOrderProposal'];
      },

      showShadeUnitConfigs() {
        return ( this.shadeOrder.isNew ) ? false : true;
      },

      ...mapMultiRowFields('shadeUnitConfigs', ['byId']),

      canAddConfig() {
        return ( this.byId.length < this.maxConfigsAllowed ) ? true : false;
      },

      userCanEdit() {
        if( this.orderProposal && this.orderProposal.userCanEdit == false ) {
          return false;
        }
        return true;
      }
    },

    methods: {
      addConfigUnit() {
        this.$store.dispatch('shadeUnitConfigs/addNewShadeUnitConfig', { isUserGlobalConfig: this.isUserGlobalConfigs });
      },

      addEstimatorDefinedConfigUnit(event) {
        event.preventDefault();
        this.$bvModal.show('modal-addEstimatorConfigs');
      }
    }
  };
</script>
