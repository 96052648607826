var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shadeLineItem.isNew === false
    ? _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.sort_order_number_textual))]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.total_material_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.total_mfg_labor_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.pkg_labor_hours_formatted))]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.pkg_labor_cost_formatted))]),
        _vm._v(" "),
        _c("td", [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.shadeLineItem.supply_only,
                  expression: "shadeLineItem.supply_only",
                },
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.checkForChanges,
                  expression: "checkForChanges",
                },
              ],
              staticClass: "form-control",
              class: _vm.errors["supply_only"] ? "is-invalid" : "",
              attrs: {
                disabled:
                  _vm.shadeLineItem.isInnerCoupledShade ||
                  _vm.orderProposal.userCanEdit == false,
              },
              on: {
                click: _vm.hasFocus,
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.shadeLineItem,
                    "supply_only",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            _vm._l(_vm.supplyOnlySelectOptions, function (option) {
              return _c("option", { domProps: { value: option.value } }, [
                _vm._v(_vm._s(option.label)),
              ])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.install_total_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.total_shade_cost_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.unit_cost_formatted))]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.general_labor_expense_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(_vm._s(_vm.shadeLineItem.general_material_expense_formatted)),
        ]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.gross_profit_formatted))]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.subtotal_formatted))]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.commission_fee_formatted))]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.total_formatted))]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.cost_per_shade_formatted))]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.shadeLineItem.pct_of_total_formatted))]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }