var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-row estimate-widget food-and-lodging-expenses" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-sm-12 table-responsive" }, [
        _c("table", { staticClass: "form" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.orderProposal.install_techs))]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.orderProposal.install_nights,
                      expression: "orderProposal.install_nights",
                    },
                    {
                      name: "proposal-field-directive",
                      rawName: "v-proposal-field-directive",
                    },
                  ],
                  staticClass: "form-control min-width-field",
                  class: _vm.errors["install_nights"] ? "is-invalid" : "",
                  attrs: {
                    type: "text",
                    placeholder: "1",
                    name: _vm.getInputName("install_nights"),
                    id: _vm.getInputId("install_nights"),
                  },
                  domProps: { value: _vm.orderProposal.install_nights },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.orderProposal,
                        "install_nights",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.orderProposal.install_overnight_lodging_rate,
                      expression:
                        "orderProposal.install_overnight_lodging_rate",
                    },
                    {
                      name: "proposal-field-directive",
                      rawName: "v-proposal-field-directive",
                    },
                  ],
                  staticClass: "form-control min-width-field",
                  class: _vm.errors["install_overnight_lodging_rate"]
                    ? "is-invalid"
                    : "",
                  attrs: {
                    type: "text",
                    placeholder: "1",
                    name: _vm.getInputName("install_overnight_lodging_rate"),
                    id: _vm.getInputId("install_overnight_lodging_rate"),
                  },
                  domProps: {
                    value: _vm.orderProposal.install_overnight_lodging_rate,
                  },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.orderProposal,
                        "install_overnight_lodging_rate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.fl_lodging_total_formatted)),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.orderProposal.install_days,
                      expression: "orderProposal.install_days",
                    },
                    {
                      name: "proposal-field-directive",
                      rawName: "v-proposal-field-directive",
                    },
                  ],
                  staticClass: "form-control min-width-field",
                  class: _vm.errors["install_days"] ? "is-invalid" : "",
                  attrs: {
                    type: "text",
                    placeholder: "1",
                    name: _vm.getInputName("install_days"),
                    id: _vm.getInputId("install_days"),
                  },
                  domProps: { value: _vm.orderProposal.install_days },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.orderProposal,
                        "install_days",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.per_diem_food_rate_formatted)),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.orderProposal.fl_food_total_formatted)),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.orderProposal.fl_total_formatted))]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
      _c("h2", [_vm._v("Food & Lodging Expense")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", { attrs: { colspan: "3" } }, [_vm._v("Lodging")]),
        _vm._v(" "),
        _c("th", { attrs: { colspan: "3" } }, [_vm._v("Food")]),
        _vm._v(" "),
        _c("th"),
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("th", [_vm._v("# Techs")]),
        _vm._v(" "),
        _c("th", [_vm._v("# Nights")]),
        _vm._v(" "),
        _c("th", [_vm._v("Nightly Rate ($)")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total ($)")]),
        _vm._v(" "),
        _c("th", [_vm._v("# Days")]),
        _vm._v(" "),
        _c("th", [_vm._v("Per Diem Rate ($)")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total ($)")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total Cost ($)")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }