import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import leftSidebar from './modules/leftSidebar'
import shadeOrder from './modules/shadeOrder'
import orderProposal from './modules/orderProposal'
import estimationSelectOptions from './modules/estimationSelectOptions'
import shadeUnitConfigs from './modules/shadeUnitConfigs/shadeUnitConfigs'
import estimatorShadeUnitConfigs from './modules/shadeUnitConfigs/estimatorShadeUnitConfigs'
import shadeGroups from './modules/shades/shadeGroups'
import shadeLineItems from './modules/shades/shadeLineItems'
import proposalCoordinationLabor from './modules/proposal/proposalCoordinationLabor'
import proposalMiscExpense from './modules/proposal/proposalMiscExpense'
import proposalProjectExpense from './modules/proposal/proposalProjectExpense'
import orderItemAddition from './modules/proposal/orderItemAddition'
import orderItemAdditionCuts from './modules/proposal/orderItemAdditionCuts'
import motorizedOrderItemAddition from './modules/proposal/motorizedOrderItemAddition'
import motorizedOrderItemAdditionCuts from './modules/proposal/motorizedOrderItemAdditionCuts'
import orderProposalProject from './modules/proposal/orderProposalProject'
import proposalShadeGroups from './modules/proposal/proposalShadeGroups'
import proposalShadeLineItems from './modules/proposal/proposalShadeLineItems'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
const dataState = createPersistedState({
  paths: ['leftSidebar']
})

export default new Vuex.Store({
  modules: {
    leftSidebar,
    shadeOrder,
    estimationSelectOptions,
    shadeUnitConfigs,
    estimatorShadeUnitConfigs,
    shadeGroups,
    shadeLineItems,
    orderProposal,
    orderProposalProject,
    proposalCoordinationLabor,
    proposalMiscExpense,
    proposalProjectExpense,
    proposalShadeGroups,
    proposalShadeLineItems,
    orderItemAddition,
    orderItemAdditionCuts,
    motorizedOrderItemAddition,
    motorizedOrderItemAdditionCuts,
  },

  plugins: [dataState],

  strict: debug,

  state: {
    windowWidth: window.innerWidth,
  },

  mutations: {
    setWindowWidth(state) {
      state.windowWidth = window.innerWidth;
    }
  }
})
