<script>
  export default {
    computed: {
      open() {
        return this.$store.state.leftSidebar.sidebarOpen;
      },

      sidebarStateClass() {
        return ( this.open == true ) ? '' : 'minimized';
      },

      windowWidth() {
        return this.$store.state.windowWidth;
      }
    },

    methods: {
      handleSidebarToggle() {
        this.$store.dispatch('leftSidebar/toggleSidebar');
      }
    },

    watch: {
      windowWidth: function() {
        if (this.windowWidth < 768 && this.open == true) {
          this.$store.dispatch('leftSidebar/toggleSidebar');
        }
      }
    }
  }
</script>
