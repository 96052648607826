<script>
  export default {
    props: [
      'shadeUnit',
      'containerClass',
      'hembarColorLabel',
      'defaultSelectOptionText',
      'errors',
      'disabled'
    ],

    data() {
      return {
        disabledBasedOnHembarType: false
      }
    },

    mounted() {
      //For estimates that were created BEFORE the hembar_color and hembar_endcap_color fields were added...
      if( this.shadeUnit.hembar_style == 'Sealed Hembar' || this.shadeUnit.hembar_style == 'Open Ends w/Flat Endcaps' ) {
        this.disabledBasedOnHembarType = true;
        this.shadeUnit.hembar_color = 'mill';
        this.shadeUnit.hembar_endcap_color = (this.shadeUnit.hembar_style == 'Open Ends w/Flat Endcaps') ? this.shadeUnit.hardware_color : null;
      }
    },

    computed: {
      parentContainerClass() {
        return this.containerClass;
      },

      selectOptions() {
        var selectOptions = [];
        selectOptions = this.$store.state.estimationSelectOptions.primaryShadeSelectOptions.hembarColorOptions;
        return selectOptions;
      },

      isExteriorShade() {
        return this.$parent.isExteriorShade;
      },

      isDisabled() {
        if( this.disabled == true || this.disabledBasedOnHembarType == true ) {
          return true;
        }
        return false;
      },

      hasErrors() {
        if( typeof this.errors.hembar_color != 'undefined' ) {
          return true;
        }
        return false;
      }
    },

    methods: {
      getInputName(id, field) {
        return this.$parent.getInputName(id, field);
      },

      getInputId(id, field) {
        return this.$parent.getInputId(id, field);
      }
    },

    watch: {
      'shadeUnit.hembar_style': function(newVal, oldVal) {
        if( newVal == 'Sealed Hembar' || newVal == 'Open Ends w/Flat Endcaps' ) {
          this.disabledBasedOnHembarType = true;
          this.shadeUnit.hembar_color = 'mill';
          this.shadeUnit.hembar_endcap_color = (newVal == 'Open Ends w/Flat Endcaps') ? this.shadeUnit.hardware_color : null;
        } else {
          this.disabledBasedOnHembarType = false;
        }
      },

      'shadeUnit.hembar_color': function(newVal, oldVal) {
        if( this.shadeUnit.hembar_style != 'Sealed Hembar' && this.shadeUnit.hembar_style != 'Open Ends w/Flat Endcaps' ) {
          if( newVal == null ) {
            this.shadeUnit.hembar_endcap_color = null;
          } else if( newVal == 'anodized' || newVal == 'mill' ) {
            this.shadeUnit.hembar_endcap_color = 'gray';
          } else if( newVal == 'black' || newVal == 'bronze' ) {
            this.shadeUnit.hembar_endcap_color = 'black';
          } else {
            this.shadeUnit.hembar_endcap_color = 'white';
          }
        }
      }
    }
  };
</script>

<template>
  <div :class="parentContainerClass" v-if="!isExteriorShade">
    <label v-bind:for="getInputName(shadeUnit.id, 'hembar_color')">
      {{ hembarColorLabel }}
    </label>

    <select
      class="form-control"
      :class="errors['hembar_color'] ? 'is-invalid' : ''"
      v-bind:name="getInputName(shadeUnit.id, 'hembar_color')"
      v-bind:id="getInputId(shadeUnit.id, 'hembar_color')"
      v-model="shadeUnit.hembar_color"
      :disabled="isDisabled">
      <option :value="null">{{ defaultSelectOptionText }}</option>
      <option
        v-for="hembarColor in selectOptions"
        :value="hembarColor.value">
          {{ hembarColor.label }}
      </option>
    </select>

    <span
      class="invalid-feedback"
      role="alert"
      v-if="errors['hembar_color']">
      {{ errors['hembar_color'][0] }}
    </span>
  </div>
</template>
