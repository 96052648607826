<script>
  export default {

    props: [
      'errorType',
      'errorList',
      'addLinkClass',
    ],

    data() {
      return {
        placement: 'left'
      }
    },

    mounted() {
      /*
        console.log('shadeLineItemErrorPopover.vue - Mounted');
        console.log(this.errorType);
        console.log(this.titleText);
        console.log(this.errorList);
        console.log('');
      */
    },

    beforeDestroy() {
      clearInterval(this.timer)
    },

    computed: {
      titleText() {
        var titleText = 'Notice';

        switch( this.errorType ) {
          case 'assembly-error':
            titleText = 'Warning';
            break;

          default:
            titleText = 'Validation Error';
            break;
        }

        return titleText;
      },

      bodyHeadText() {
        var text = '';

        switch( this.errorType ) {
          case 'assembly-error':
            text = '<p>The following errors were encountered:</p>';
            break;

          default:
            text = '';
            break;
        }

        return text;
      },

      bodyErrorList() {
        var errorList = '';
        if( this.errorType == 'assembly-error' ) {
          this.errorList.forEach(function(error) {
            errorList += '<li><strong>' + error.component + '</strong><br>' + error.error_text;
          });
          return '<ul>' + errorList + '</ul>';
        }

        return errorList;
      },

      bodyClosingText() {
        if( this.errorType == 'error' ) {
          return '<p>Field validation errors. Please correct highlighed fields and re-save the unit.</p>';

        } else if( this.errorType == 'assembly-error' ) {
          return '<p>These errors must be resolve before this unit can be manufactured.</p>';
        }
      },

      bodyTextFull() {
        return this.bodyHeadText + this.bodyErrorList + this.bodyClosingText;
      },

      popoverConfig() {
        return {
          html: true,
          placement: this.placement,
          customClass: 'danger popover-error',
          title: () => {
            return this.titleText
          },
          content: () => {
            return this.bodyTextFull;
          }
        }
      }
    },

    methods: {

    }
  };
</script>

<template>
  <b-link v-b-popover.hover.click="popoverConfig" :placement="placement" :class="addLinkClass"></b-link>
</template>
