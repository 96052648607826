var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { staticClass: "require-all" }, [
    _c("td", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentMiscExpense.name,
            expression: "currentMiscExpense.name",
          },
          {
            name: "proposal-field-directive",
            rawName: "v-proposal-field-directive",
          },
        ],
        staticClass: "form-control min-width-field",
        class: _vm.errors["name"] ? "is-invalid" : "",
        attrs: {
          type: "text",
          placeholder: "Parking, On site storage, etc.",
          name: _vm.getInputName(_vm.currentMiscExpense.id, "name"),
          id: _vm.getInputId(_vm.currentMiscExpense.id, "name"),
        },
        domProps: { value: _vm.currentMiscExpense.name },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.currentMiscExpense, "name", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentMiscExpense.cost,
            expression: "currentMiscExpense.cost",
          },
          {
            name: "proposal-field-directive",
            rawName: "v-proposal-field-directive",
          },
        ],
        staticClass: "form-control min-width-field",
        class: _vm.errors["cost"] ? "is-invalid" : "",
        attrs: {
          type: "text",
          placeholder: "$100.00",
          name: _vm.getInputName(_vm.currentMiscExpense.id, "cost"),
          id: _vm.getInputId(_vm.currentMiscExpense.id, "cost"),
        },
        domProps: { value: _vm.currentMiscExpense.cost },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.currentMiscExpense, "cost", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("a", {
        staticClass: "btn icon-only btn-delete",
        class: _vm.orderProposal.is_locked_for_edits ? "disabled" : "",
        attrs: { href: "#", title: "Delete" },
        on: {
          click: function ($event) {
            return _vm.deleteProposalMiscExpense($event)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }