<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'coordinationLabor',
      'coordinationLaborId'
    ],

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('ProposalCoordinationLabor.vue (SINGULAR) - Mounted');
        console.log(this.orderProposal);
        console.log(this.currentCoordinationLabor);
        console.log('');
      */
    },

    computed: {
      currentCoordinationLabor() {
        return this.coordinationLabor;
      },

      coordinationLaborSelectOptions() {
        //return this.$store.state.orderProposal.selectOptions.coordinationLaborExpenseOptions;
        return this.$parent.coordinationLaborSelectOptions;
      },

      orderProposal() {
        return this.$parent.currentOrderProposal;
      },

      errors() {
        return [];
      },
    },

    methods: {
      getInputName(id, field) {
        return 'proposal_coordination_labors['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      deleteProposalCoordinationLabor(event) {
        event.preventDefault();
        this.$store.dispatch('proposalCoordinationLabor/deleteCoordinationLabor', this.currentCoordinationLabor.id);
      },
    }
  };
</script>

<template>
  <tr class="require-all" v-proposal-field-directive>
    <td>
      <select
        class="form-control min-width-field"
        v-bind:class="errors['gbl_coord_labor_expense_id'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(currentCoordinationLabor.id, 'gbl_coord_labor_expense_id')"
        v-bind:id="getInputId(currentCoordinationLabor.id, 'gbl_coord_labor_expense_id')"
        v-model="currentCoordinationLabor.gbl_coord_labor_expense_id">
        <option :value="null">--Choose One--</option>
        <template v-for="laborOption in coordinationLaborSelectOptions">
          <option v-if="laborOption.isSelected === false || laborOption.id == currentCoordinationLabor.gbl_coord_labor_expense_id" :value="laborOption.id">{{ laborOption.position }}</option>
        </template>
      </select>
    </td>
    <td>
      <input type="text"
             class="form-control min-width-field"
             v-bind:class="errors['hours'] ? 'is-invalid' : ''"
             placeholder="1"
             v-bind:name="getInputName(currentCoordinationLabor.id, 'hours')"
             v-bind:id="getInputId(currentCoordinationLabor.id, 'hours')"
             v-model="currentCoordinationLabor.hours">
    </td>
    <td>
      <template v-if="currentCoordinationLabor.isNew == false">
        {{ currentCoordinationLabor.rate_formatted }}
      </template>
    </td>
    <td>
      <template v-if="currentCoordinationLabor.isNew == false">
        {{ currentCoordinationLabor.total_labor_cost_formatted }}
      </template>
    </td>
    <td>
      <a
        href="#"
        class="btn icon-only btn-delete"
        v-bind:class="orderProposal.is_locked_for_edits ? 'disabled' : ''"
        @click="deleteProposalCoordinationLabor($event)"
        title="Delete">
        </a>
      </td>
  </tr>
</template>
